<div [id]="fieldId" class="therrax-select" [ngClass]="{'disabled' : disabled}">
    <div class="label">{{label}}</div>
    <div class="value">
      {{displayValue}}
    </div>
    <div class="icon"><i class="ti ti-chevron-down"></i></div>
    <div class="hints" *ngIf="isOpen">
      <div class="results">
        <div class="item p-2 d-inline-flex w-100" *ngIf="allowSearch">
          <input type="text" placeholder="Szukaj" class="blank">
        </div>
        <div class="item p-2 d-inline-flex w-100" *ngFor="let option of options" (click)="select(option)">
          <div class="item-data w-100">
            <div class="item-name">
                <div>
                    {{option[displayProperty]}}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>