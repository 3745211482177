import { Component } from '@angular/core';
import { take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';

@Component({
  selector: 'announcements-list',
  templateUrl: './announcements-list.component.html',
  styleUrls: ['./announcements-list.component.scss']
})
export class AnnouncementsListComponent {

  announcements = []

  constructor(
    private _apiService: ApiService
  ) 
  {
    this._getAnnouncements()
  }

  private _getAnnouncements(): void {
    this._apiService.getAnnouncements().pipe(take(1)).subscribe({
      next: (response: APIResponse) => {
        if (response.success) {
          let filterArray: any[] = [];
          const storedAnnouncements: string | null = localStorage.getItem('announcements');
          if (storedAnnouncements) {
            filterArray = JSON.parse(storedAnnouncements);
          }
          this.announcements = response.data.filter((x: any) => !filterArray.includes(x.id));
        }
      }
    });
  }

  public read(id: any): void {
    let filterArray: any[] = [];
    const storedAnnouncements: string | null = localStorage.getItem('announcements');
    if (storedAnnouncements) {
      filterArray = JSON.parse(storedAnnouncements);
    }
    filterArray.push(id);
    const index: number = this.announcements.findIndex((z: any) => z.id === id);
    if (index !== -1) {
      this.announcements.splice(index, 1);
    }
    localStorage.setItem('announcements', JSON.stringify(filterArray));
  }
}
