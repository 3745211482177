import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {

  announcements = []

  constructor(private _apiService:ApiService) {

  }

  ngOnInit(): void {
    this._get()
  }

  private _get() {
    this._apiService.getAnnouncementsAsAdmin().pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        this.announcements = response.data
      }
    })
  }
}
