import { Component } from '@angular/core';

@Component({
  selector: 'app-faktura-vat',
  templateUrl: './faktura-vat.component.html',
  styleUrls: ['./faktura-vat.component.scss']
})
export class FakturaVatComponent {

}
