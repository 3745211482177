import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle, ChartComponent } from 'ng-apexcharts';
import { take } from 'rxjs';
import { CONST } from 'shared/const';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { Utils } from 'fakturnia-shared';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class AdminDashboardComponent implements AfterViewInit {

  @ViewChild("chart") chart: ChartComponent;
  public newDocumentsAndUsersChart: any = null
  public newDocumentsAndUsersMonthlyChart: any = null

  startDate: Date = new Date(new Date().getFullYear(), 0, 1)
  endDate: Date = new Date(new Date().getFullYear(), 11, 31)
  constructor(
    private _apiService: ApiService
  ) {

  }

  convert() {
    this._apiService.convertData().subscribe({
      next: (response) => {
        console.log(response)
      }
    })
  }
  
  rangeChanged(startOrEnd: string, event: MatDatepickerInputEvent<any, any>) {

    if (event.value == null) return
    const date = new Date(event.value)

    if (startOrEnd == 'end') {
      if (date != null) {
        this.endDate = date
        if (this.startDate >= this.endDate) {
          this.endDate = new Date(date.getTime() + CONST.DAYINMILLIS);
        }
        this._getNewDocumentsAndUsersChart()
      }
    }


  }
  ngAfterViewInit(): void {
    this._getNewDocumentsAndUsersChart()
  }

  private _getNewDocumentsAndUsersChart() {
    this._apiService.getNewDocumentsAndUsersChart(this.startDate, this.endDate)
      .pipe(take(1))
      .subscribe({
        next: (response: APIResponse) => {
          if (response.success) {
            console.log(response.data)
            this._buildNewDocumentsAndUsersChart(response.data)
          }
          else console.log("Error: ", response)
        }
      })
  }

  loaded = false
  private _buildNewDocumentsAndUsersChart(data: any[]) {

    const series: any = []

    const newUsersData: any = []
    const newDocumentsData: any = []
    const activeUsersData: any = []

    data.forEach((day: any) => {
      newUsersData.push({
        x: new Date(day.date),
        y: day.userCount
      })
      newDocumentsData.push({
        x: new Date(day.date),
        y: day.documentCount
      })
      activeUsersData.push({
        x: new Date(day.date),
        y: day.distinctUserCount.length
      })
    })
    series.push({
      name: "Nowi użytkownicy",
      data: newUsersData
    })

    series.push({
      name: "Wygenerowane dokumenty",
      data: newDocumentsData
    })
    series.push({
      name: "Aktywni użytkownicy",
      data: activeUsersData
    })

    this.newDocumentsAndUsersChart = {
      chart: {
        height: '350px',
        type: 'area',
      },
      // colors: ['orange', '#667EEA', 'red'],
      // fill: {
      //   colors: ['#CED9FB', '#AECDFD', 'purple'],
      //   opacity: 0.5,
      //   type: 'solid',
      // },
      series: series,
      // stroke: {
      //   curve: 'straight',
      //   width: 2,
      // },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      tooltip: {
        followCursor: true,
        theme: 'dark',
        x: {
          format: 'MMM dd, yyyy',
        },
        y: {
          formatter: (value, opts): string => {
            return value
          }
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis     : {
        axisTicks : {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        min       : (min): number => min - 750,
        max       : (max): number => max + 250,
        tickAmount: 5,
        show      : false
      }
    }


    // Montly chart
    const seriesMonthly: any = []

    const newUsersDataMonthly: any = []
    const newDocumentsDataMonthly: any = []
    const activeUsersMonthly: any = []

    const categories = []
    for (let i = 0; i <= 11; i++) {

      let sumUsers = 0
      let sumDocs = 0
      let sumActiveUsers = 0

      sumUsers = this._sumCountForMonth(data, 'userCount', i)
      sumDocs = this._sumCountForMonth(data, 'documentCount', i)
      sumActiveUsers = this._sumCountDistinctUsersForMonth(data, i)


      categories
      newUsersDataMonthly.push({
        x: Utils.getMonthName(i),
        y: sumUsers
      })
      newDocumentsDataMonthly.push({
        x: Utils.getMonthName(i),
        y: sumDocs
      })
      activeUsersMonthly.push({
        x: Utils.getMonthName(i),
        y: sumActiveUsers
      })
    }

    seriesMonthly.push({
      name: "Nowi użytkownicy",
      data: newUsersDataMonthly
    })

    seriesMonthly.push({
      name: "Wygenerowane dokumenty",
      data: newDocumentsDataMonthly
    })

    seriesMonthly.push({
      name: "Aktywni użytkownicy",
      data: activeUsersMonthly
    })

    this.newDocumentsAndUsersMonthlyChart = {
      series: seriesMonthly,
      chart: {
        height: 350,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        categories: categories
      },
      tooltip: {
        x: {
          format: ""
        }
      }
    };


    this._calculateOverlap()
    this.loaded = true
  }

  private _calculateOverlap() {
    // Function to calculate percentage overlap
    const calculateOverlapPercentage = (previousUsers, currentUsers) => {
      const previousSet = new Set(previousUsers);
      const commonUsers = currentUsers.filter(userId => previousSet.has(userId));
      return (commonUsers.length / previousUsers.length) * 100;
    };

    // Iterate through month indices
    for (let i = 0; i <= Object.keys(this.monthUsers).length; i++) {
      const previousMonthIndex = i - 1;
      const currentMonthIndex = i;

      // Check if there are previous and current month data
      if (this.monthUsers[previousMonthIndex] && this.monthUsers[currentMonthIndex]) {
        const previousUsers = this.monthUsers[previousMonthIndex];
        const currentUsers = this.monthUsers[currentMonthIndex];

        const overlapPercentage = calculateOverlapPercentage(previousUsers, currentUsers);
        if(!Number.isNaN(overlapPercentage)) {
          console.log(`Pokrycie użytkowników w miesiącu ${Utils.getMonthName(currentMonthIndex)} a miesiącem ${Utils.getMonthName(previousMonthIndex)}: ${overlapPercentage.toFixed(2)}%`);
        }
      }
    }
  }
  private _sumCountForMonth(data, field, monthIndex) {
    // Filter data for the specified month
    const filteredData = data.filter(item => {
      const date = new Date(item.date);
      return date.getMonth() === monthIndex;
    });

    // Sum fields
    const sum = filteredData.reduce((total, item) => total + item[field], 0);

    return sum;
  }


  monthUsers: any = {}
  private _sumCountDistinctUsersForMonth(data, monthIndex) {
    // Filter data for the specified month
    const filteredData = data.filter(item => {
      const date = new Date(item.date);
      return date.getMonth() === monthIndex;
    });

    const distinctPerMonth = new Set<string>()
    filteredData.forEach(day => {
      day.distinctUserCount.forEach((userId) => {
        distinctPerMonth.add(userId)
      })
    });

    this.monthUsers[monthIndex] = Array.from(distinctPerMonth)
    return distinctPerMonth.size;
  }
}
