import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import {MatExpansionModule} from '@angular/material/expansion';
import { settings } from 'environments/environment';
@Component({
  selector: 'main-footer',
  standalone: true,
  imports: [CommonModule, RouterLink, MatExpansionModule ],
  templateUrl: './main-footer.component.html',
  styleUrl: './main-footer.component.scss'
})
export class MainFooterComponent {

  currentYear = new Date().getFullYear()
  config = settings

}
