import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import { ErrorInterceptor } from './error.interceptor';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private _authService: AuthService
  ) 
  {
    
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let headers = request.headers
    .set('x-access-token', this._authService.accessToken || '')
    .set('x-session-id', this._authService.sessionId || '');

    request = request.clone({
      withCredentials: true,
      headers: headers
    })

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes('auth/login') &&
          error.status === 401
        ) 
        {
          return this._handleError(request, next);
        }

        return throwError(() => error);
      })
    );
  }
  
  private _handleError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this._authService.isLoggedIn()) {
        return this._authService.getRefreshToken().pipe(
          switchMap(() => {
            this.isRefreshing = false;

            let headers = request.headers
            .set('x-access-token', this._authService.accessToken || '')
            .set('x-session-id', this._authService.sessionId || '');
            // Get new access token
            request = request.clone({
              withCredentials: true,
              headers: headers
            })
            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing = false;

            
            // this.eventBusService.emit(new EventData('logout', null));
            this._authService.logout({ muted: false});
            
            return throwError(() => error);
          })
        );
      }
    }

    return next.handle(request);
  }
}
export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
}
