import { Component, Input } from '@angular/core';
import { ApiService } from 'shared/services/api.service';

@Component({
  selector: 'user-tools',
  templateUrl: './user-tools.component.html',
  styleUrls: ['./user-tools.component.scss']
})
export class UserToolsComponent {

  @Input('user') user  


}
