import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { UserSettings, Utils } from 'fakturnia-shared';
// import { UserService } from 'app/core/user/user.service';
// import { User } from 'app/core/user/user.types';
import { Subject, take, takeUntil } from 'rxjs';
import { AuthService } from 'shared/services/auth.service';
import { SettingsService } from 'shared/services/settings.service';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user',
    standalone     : true,
    imports        : [MatButtonModule, MatTooltipModule, MatMenuModule, RouterLink, NgIf, MatIconModule, NgClass, MatDividerModule],
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = false;
    user: any = null;

    public isAdmin = false
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _authService: AuthService,
        private _settingsService: SettingsService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._authService.isLoggedIn$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isLoggedIn) =>
            {
                if(!isLoggedIn) {
                    this.user = null
                    return
                }
                this._settingsService.getUserSettings().subscribe({
                    next: (userSettings:UserSettings) => {
                      if(Utils.isDefined(userSettings, "user.role")) this.isAdmin = userSettings.user.role == 'administrator' ? true : false
                      this.user = userSettings.user;
                      this.user.status = 'online'
                      // Mark for check
                      this._changeDetectorRef.markForCheck();
                    }
                  })
                
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void
    {
        // Return if user is not available
        if ( !this.user )
        {
            return;
        }

        // Update the user
        // this._userService.update({
        //     ...this.user,
        //     status,
        // }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._authService.logout({ muted: false})
    }
}
