<div class="container with-min-height my-5">

    <div class="table-header">
        Produkty
        <span *ngIf="this.results.length == count">({{count}})</span>
        <span *ngIf="this.results.length != count"> ({{this.results.length}} z {{count}})</span>
    </div>


    <div class="f-card">

        <div class="f-card-body">
            <ul>
                <li>
                    <div class="list-item align-items-center">

                        <mat-icon class="tools-icon">search</mat-icon>

                        <input #searchInput (keyup)="search($event.target.value)" class="search-input"
                            placeholder="Szukaj produktu..." type="text">

                            <div class="ms-auto">
                                <button mat-button (click)="openProductPanel('create')" color="primary">
                                    <mat-icon>add</mat-icon>
                                    Nowy produkt
                                </button>
                            </div>
                    </div>
                </li>

                <li>
                    <div class="list-item align-items-center">
                        <mat-icon class="tools-icon">turn_right</mat-icon>
                        <div class="tools-button" [ngClass]="{'disabled' : selected.size <= 0}"
                            (click)="deleteSelected()">
                            <mat-icon>close</mat-icon>
                            Usuń
                        </div>
                        <div class="ms-auto d-flex align-items-center">
                            <pagination class="d-flex align-items-center" [currentPage]="currentPage"
                                [totalPages]="allPages" (onPageChange)="onPageChange($event)"></pagination>
                        </div>
                    </div>
                </li>

                <li>
                    <div class="list-item align-items-center">

                        <mat-checkbox color="primary" (change)="selectAll($event)"></mat-checkbox>

                        <div class="big-col">
                            <div class="header">Produkt</div>
                        </div>

                        <div class="small-col">
                            <div class="header">Data dodania</div>
                        </div>

                        <div class="medium-col text-end">
                            <div class="header">Wartość brutto</div>
                        </div>
                    </div>

                </li>

                <li *ngFor="let product of products" class="clickable">
                    <div class="list-item align-items-center">
                        <mat-checkbox [checked]="selected.has(product._id) ? true : false" color="primary"
                            (change)="toggleSelect(product._id)"></mat-checkbox>

                        <div class="big-col" (click)="openProductPanel('update',product)">
                            <div class="company-name">
                                <span *ngIf="product.name != ''">{{product?.name}} </span>
                            </div>
                        </div>

                        <div class="small-col" (click)="openProductPanel('update',product)">
                            <date-formatter [date]="product.createdAt"></date-formatter>
                        </div>

                        <div class="medium-col text-end" (click)="openProductPanel('update',product)"> <span class="price">{{product?.grossValue?.toFixed(2)}}</span> <span
                            class="currency">{{product?.currency}}</span></div>

                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
