import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Utils } from 'fakturnia-shared';
import { debounce } from 'lodash';

@Component({
  selector: 'therrax-input',
  templateUrl: './therrax-input.component.html',
  styleUrls: ['./therrax-input.component.scss']
})
export class TherraxInputComponent implements OnDestroy, OnChanges {

  private _subscriptions:Subscription[] = []
  @ViewChild('input', { static: false }) inputElement: ElementRef;
  
  @Input('icon') icon:string;
  @Input('label') label:string;
  @Input('value') value:string;
  
  control:FormControl = new FormControl('')
  @Output() updateValue = new EventEmitter();

  constructor()
  {
    this.inputChange = debounce(this.inputChange,100)
    this._subscriptions.push(this.control.valueChanges.subscribe({
      next: (data) => this.inputChange(data)
    }))
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(Utils.isDefined(changes,"value.currentValue")) {
      this.control.setValue(changes['value'].currentValue)
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  focusInput() {
    if(typeof this.inputElement !== 'undefined') this.inputElement.nativeElement.focus();
  }
  
  inputChange(value:string)
  {
    this.updateValue.emit(value);  
  }
}
