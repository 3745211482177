import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subscription, BehaviorSubject, take } from 'rxjs';
import { ApiService } from 'shared/services/api.service';
import { DatabaseService } from 'shared/services/database.service';
import { DialogService } from 'shared/services/dialog.service';
import { Utils } from 'fakturnia-shared';
import { debounce } from 'lodash';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { DbDocumentObject, DocumentsService } from 'shared/services/documents.service';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';
import { NotificationData } from 'fakturnia-shared';
import { ToastService } from 'shared/services/toast.service';
import JPKVatGenerator from 'shared/utils/jpk-vat-generator';
import { InvoicesService } from 'shared/services/invoices.service';
import { Invoice } from 'fakturnia-shared';
import { DbDocument } from 'fakturnia-shared';
import { SnackbarService } from 'shared/services/snackbar.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { CompanyNameComponent } from '../company-name/company-name.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaginationComponent } from '../pagination/pagination.component';

@Component({
  standalone: true,
  selector: 'invoice-table',
  imports: [CommonModule, CompanyNameComponent, RouterLink, MatTooltipModule, MatCheckboxModule, MatButtonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatIconModule, PaginationComponent],
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss']
})
export class InvoiceTableComponent  implements OnInit, OnChanges, OnDestroy {

  private _subscriptions: Subscription[] = [];

  activeFilters = new Set<any>();
  bsActiveFilters = new BehaviorSubject<any>(this.activeFilters);
  currentActiveFilters = this.bsActiveFilters.asObservable();

  @Input() addBoxShadow = true
  @Input('showTitle') showTitle = true
  @Input('selection') selection: 'single' | 'multiple' = 'multiple'
  @Input('allowNavigation') allowNavigation = true
  @Input('selectedInvoicesIds') selectedInvoicesIds: string[] = []
  @Input('title') title = ""
  @Input('filters') filters:any[] = []
  @Input('showTools') showTools = true
  @Input('showSelectors') showSelectors = true
  @Input('showMarkAsPaidButton') showMarkAsPaidButton = false
  @Input('showIssueSimilarButton') showIssueSimilarButton = false
  @Input('showAddNewButton') showAddNewButton = false
  @Input('showMarkAsTemplateButton') showMarkAsTemplateButton = false
  @Input('showMarkAsPrintedButton') showMarkAsPrintedButton = false
 
  @Output('selectionChange') selectionChange = new EventEmitter<string[]>()
  searchResults:any;
 
  // Pagination
  defaultPageSize = 10;
  allPages = 0;
  currentPage = 1;
  selectedDocs = new Set<string>();

  count = 0;

  documents: DbDocument[] | null = null

  searchControl = new FormControl('')

  ngOnInit(): void {

    this._subscriptions.push(
      this.searchControl.valueChanges.subscribe({
        next: (value) => {
          this.search(value)
        }
      })
    )

    this.browseDocuments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof changes['selectedInvoicesIds'] != 'undefined') {
      this.selectedDocs = new Set(changes['selectedInvoicesIds'].currentValue)
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  constructor(
    private _documentsService:DocumentsService,
    private _dataSynchronizerService:DataSynchronizerService,
    private _synchronizationService:SynchronizationService,
    private apiService:ApiService,
    private dialogService:DialogService,
    private router:Router,
    private _toastService:ToastService,
    private _invoicesService:InvoicesService,
    private _snackbarService:SnackbarService
  )
  {
    this.browseDocuments = debounce(this.browseDocuments,10)
    this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
      next: (data)=>{
        if(data == null) return
        if(data.table == 'documents') this.browseDocuments()
      }
    }))

    this._subscriptions.push(this.currentActiveFilters.subscribe({
      next:  (data)=>
        {
          this.activeFilters = data;
          setTimeout( async ()=>{
            this.browseDocuments();
          })
        }
      }
    ))

    this.browseDocuments();
  }

  public reset() {
    this.searchControl.setValue('')
  }

  public toDecimal(value) {
    return Utils.toDecimal(value)
  }

  navigateToDocument(id) {
    if(!this.allowNavigation) {
      this.toggleSelect(id)
      return
    }
    let path = '/konto/dokumenty/' + id + '/podglad'

    this.router.navigateByUrl(path)
  }

  search(value)
  {
    this.currentPage = 1;
    this.updateFilterSet('searchText',value)
    this.browseDocuments();
  }

  time;
  results:any = [];
  resultsCount = 0;
  searchResultsCount = 0;

  generateJPKVAT() {

    if(!this.documents) return
    if(this.documents.length == 0) return

    const invoices:Invoice[] = []
    this.documents.forEach(document=>{
      const invoice = this._invoicesService.getById(document._id)
      if(invoice  == null) console.warn("Invalid invoice")
      else invoices.push(invoice)
    })
    
    JPKVatGenerator.create(invoices)
  }

  browseDocuments() {
    const pagination = this._documentsService.browseDocuments({
      currentPage: this.currentPage,
      defaultPageSize: this.defaultPageSize,
      filters: this.filters,
      activeFilters: this.activeFilters,
    })

    this.allPages = pagination.allPages
    this.time = pagination.time
    this.resultsCount = pagination.resultsCount
    this.documents = pagination.results
  }

  onPageChange(page)
  {
    this.currentPage = page;
    this.browseDocuments();
  }


  newDocument()
  {
    this.router.navigateByUrl('/konto/dokumenty/wystaw')
  }
  
  issueSimilar(){
    if(!this.documents) return
    if(this.selectedDocs.size != 1) return
    let document = this.documents.find(x=>x._id == this.selectedDocs.values().next().value)
    if(typeof document === 'undefined') return
    this.show(document,true)
  }

  show(document, copy = false)
  {
    // Navigate and create a copy
    if(copy) {
      this.router.navigateByUrl(`/konto/dokumenty/${document._id}?action=copy`)
      return
    }
    this.router.navigateByUrl('/konto/dokumenty/wystaw')
  }
 
  selectAll(e:any)
  {
    if(!this.documents) return
    if(this.selection == 'single') return
    if(e.checked == true) {
      this.documents.forEach((document:DbDocument)=>{
        this.selectedDocs.add(document._id)
      })
    }
    else this.selectedDocs = new Set()
  }

  toggleSelect(id)
  {
    if(this.selectedDocs.has(id)) this.selectedDocs.delete(id)
    else  {
      if(this.selection == 'single') {
        this.selectedDocs = new Set()
        this.selectedDocs.add(id)
      }
      else if(this.selection == 'multiple') {
        this.selectedDocs.add(id)
      }
    }

    this.selectionChange.emit(Array.from(this.selectedDocs))
  }

  private updateFilterSet(key, value) {
    if (!value || value == '') {
      // Jeśli wartość jest pustym ciągiem lub białym znakiem, usuń filtr z zestawu
      for (let filter of this.activeFilters) {
        if (filter[0] === key) {
          this.activeFilters.delete(filter);
          // this.searchResults = Object.entries(this.databaseService.data.value.documents).filter((document:[any,DbDocument])=> !document[1].isDeleted).map(x=>x[1]);
          this.bsActiveFilters.next(this.activeFilters)
          return;
        }
      }
      return;
    }
    // Sprawdzenie, czy dany klucz już istnieje w secie
    for (let filter of this.activeFilters) {
      if (filter[0] === key) {
        // Jeśli klucz istnieje, zaktualizuj wartość filtra
        // Jezeli nowa wartość filtra jest inna od obecnego
        if(filter[1] != value) {
          // Jezeli jest stringiem
          if(typeof filter[1] == 'string')
          {
            // Zwieksz zakres tylko jezeli wartosc jest mniejsza
            //sprawdz czy jest mniejsza (zwieksz zakres)
            if(filter[1].length > value.length)
            {
              // this.searchResults = Object.entries(this.databaseService.data.value.documents).filter((document:[any,DbDocument])=> !document[1].isDeleted).map(x=>x[1]);
            }

            filter[1] = value;
          }
          // Jezeli jest pozostałym typem
          else {
            filter[1] = value;
            // this.searchResults = Object.entries(this.databaseService.data.value.documents).filter((document:[any,DbDocument])=> !document[1].isDeleted).map(x=>x[1]);
          }
        } 

        
        this.bsActiveFilters.next(this.activeFilters)
        return;
      }
    }
    // Jeśli klucz nie istnieje, dodaj nowy filtr
    this.activeFilters.add([key, value]);
    this.bsActiveFilters.next(this.activeFilters)
  }


  public markAsPaidOrTemplate(isPaid) {

    if(this.selectedDocs.size > 10) {
      this._snackbarService.showSnackBar('Jednorazowo można oznaczyć maksymalnie 10 dokumentów.','error')
      return
    }

    if(typeof this.selectedDocs === 'undefined') return;
    if(this.selectedDocs.size === 0) return;
    
    this._subscriptions.push(
      this.dialogService.confirmMarkAsPaidOrTemplateDialog(
        isPaid,
        Array.from(this.selectedDocs)
      )
      .subscribe({
        next: (data:any) => {
          if(!data) return
          this.selectedDocs = new Set()
        }
      })
    )
    
  }
}
