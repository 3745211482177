<div class="flex justify-between">

    <div>
        <h5>
            Wyślij przez e-mail
        </h5>
        <div class="text-secondary mb-2">
            Dodaj maksymalnie 3 odbiorców.
        </div>
    </div>


    <button mat-icon-button [matDialogClose]="undefined">
        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>

</div>

<div class="w-full">

    <mat-form-field class="w-full">
        <mat-label>Nowy odbiorca</mat-label>
        <input type="text" matInput [formControl]="newReceiver" placeholder="Wprowadź adres e-mail. " (keyup.enter)="addReceiver()">
        <button (click)="addReceiver()" matSuffix mat-icon-button><i class="ti ti-plus"></i></button>
    </mat-form-field>

    <div class="h6 text-color-black">Odbiorcy ({{receivers.size}})</div>
    <div *ngFor="let emailAddress of availableReceivers">
        <mat-checkbox (change)="toggleReceiver($event, emailAddress)" [checked]="receivers.has(emailAddress)"
            color="primary" class="mr-2">{{emailAddress}}</mat-checkbox>
    </div>

    <hr />

    <div class="h6 text-color-black">Dodatkowe opcje</div>
    <mat-checkbox [formControl]="sendCopyToUser" color="primary" class="mr-2">Wyślij kopie również do
        mnie</mat-checkbox>

</div>


<mat-dialog-actions align="end">
    <button [disabled]="receivers.size == 0" mat-flat-button color="primary" (click)="sendEmail()">Wyślij
        wiadomość</button>
    <button mat-button (click)="close()">Zamknij</button>
</mat-dialog-actions>