<h1 style="display:none;">Faktura VAT Marża - Co to jest? Bezpłatny program do wystawiania faktur przez przeglądarkę
    Fakturnia.pl</h1>

<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
        <div class="grid grid-cols-2 gap-8">
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto tracking-wide">
                <h2>
                    <strong>Faktura VAT Marża</strong> - Co to jest?<br />

                </h2>
                <p class="mt-2">
                    Marża w podatku VAT, zgodnie z przepisami, to różnica między kwotą, którą płaci nabywca towarów lub
                    usług, a ceną zakupu, pomniejszoną o podatek. Innymi słowy, sprzedawca odprowadza VAT jedynie od
                    marży, czyli zysku, jaki uzyskuje ze sprzedaży. W tej sytuacji nie ma możliwości odliczenia VAT z
                    faktury zakupu.

                    Chociaż na fakturze VAT marża nie jest uwzględniona kwota podatku, sprzedawca wciąż musi ją obliczyć
                    i wpłacić do urzędu skarbowego. Faktura VAT marża to atrakcyjne rozwiązanie dla sprzedawców.
                    Standardowo VAT nalicza się od pełnej wartości sprzedanych towarów lub usług, jednak w przypadku
                    faktury VAT marża opodatkowaniu podlega tylko marża, czyli prowizja sprzedawcy. Dzięki temu
                    sprzedawca płaci do urzędu skarbowego niższy podatek VAT.

                    W ustawie o VAT zawarte są szczegółowe zasady dotyczące korzystania z faktury VAT marża oraz
                    wskazujące, jakie transakcje można opodatkować w tym systemie.
                </p>

                <h2 class="mt-5">
                    <strong>Faktura VAT marża</strong> dotyczy podatników, którzy: <br />
                </h2>

                <div class="mt-2">
                    <ul class="">
                        <li>świadczą usługi turystyczne,</li>
                        <li>dokonują dostaw towarów używanych</li>
                        <li>dokonują dostaw przedmiotów kolekcjonerskich i antyków</li>
                        <li>dokonują dostaw dzieł sztuki</li>
                    </ul>
                </div>
            </div>
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto tracking-wide">
                <picture>
                    <img class="img-fluid" src="/assets/images/faktura_vat_marza_fakturnia_pl.png"
                        alt="faktura vat wygenerowana w programie do faktur fakturnia.pl">
                </picture>

                <a [routerLink]="'/konto/dokumenty/wystaw'">
                    Wystaw taki dokument w naszym programie <i class="ti ti-arrow-narrow-right ms-2"></i>
                </a>
            </div>
        </div>
    </div>
</section>

<read-more [path]="'/funkcjonalnosci-aplikacji/faktura-vat'" [text]="'Faktura vat'"></read-more>