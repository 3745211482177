import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { APIResponse, Utils } from 'fakturnia-shared';
import { Subscription, finalize, take } from 'rxjs';
import { ApiService } from 'shared/services/api.service';
import { ViesApiService } from 'shared/services/api/vies.api.service';
import { DialogService } from 'shared/services/dialog.service';
import { GUSToDbClientConverter } from 'shared/utils/gus-to-db-client-converter';

@Component({
  selector: 'app-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  styleUrls: ['./add-client-dialog.component.scss']
})
export class AddClientDialogComponent implements OnDestroy {

  loading = false

  private _subscriptions:Subscription[] = [];
  nipControl = new UntypedFormControl('',[Validators.required, Validators.minLength(2)])
  
  company = {}
  public countryCodes = [
    { id: 'PL', name: 'Polska' },
    { id: 'AT', name: 'Austria' },
    { id: 'BE', name: 'Belgia' },
    { id: 'BG', name: 'Bułgaria' },
    { id: 'HR', name: 'Chorwacja' },
    { id: 'CY', name: 'Cypr' },
    { id: 'CZ', name: 'Czechy' },
    { id: 'DK', name: 'Dania' },
    { id: 'EE', name: 'Estonia' },
    { id: 'FI', name: 'Finlandia' },
    { id: 'FR', name: 'Francjaa' },
    { id: 'EL', name: 'Grecja' },
    { id: 'ES', name: 'Hiszpania' },
    { id: 'NL', name: 'Holandia' },
    { id: 'IE', name: 'Irlandia ' },
    { id: 'LT', name: 'Litwa' },
    { id: 'LU', name: 'Luksemburg' },
    { id: 'LV', name: 'Łotwa' },
    { id: 'MT', name: 'Malta' },
    { id: 'DE', name: 'Niemcy' },
    { id: 'PT', name: 'Portugalia' },
    { id: 'RO', name: 'Rumunia' },
    { id: 'SK', name: 'Słowacja' },
    { id: 'SI', name: 'Słowenia ' },
    { id: 'SE', name: 'Szwecja' },
    { id: 'HU', name: 'Węgry' },
    { id: 'IT', name: 'Włochy' }
  ]

  countryCode = this.countryCodes[0].id
 
  constructor(
    private dialogRef:MatDialogRef<AddClientDialogComponent>,
    private apiService:ApiService,
    private _dialogService: DialogService,
    private _viesApiService: ViesApiService
  ) {

  }

  selectCountryCode(countryCode:string) {
    this.countryCode = countryCode
  }

  close(data) {
    this.dialogRef.close(data)
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  search() {
    if(this.countryCode == 'PL') {
      this.searchInGUS()
      return
    }

    this.searchInVies()
  }
  searchInGUS() {

    if(this.loading) return
    if(this.nipControl.valid) {
      this.loading = true;

      this._subscriptions.push(this.apiService.searchInGUS(this.nipControl.value)
      .pipe(take(1))
      .subscribe({
        next: (response:APIResponse) => {

          if(response.data == null) {
            this.nipControl.setErrors({
              notfound: true
            })
            this.loading = false
            return
          }

          if(typeof response.data.Nip != 'undefined') {

            this.company = GUSToDbClientConverter.convertOne(response.data)
            this.showCreateOrUpdateClientDialog(this.company)
          }

        },
        error: (err) => {
          this.loading = false
        },
        complete: ()=> {
          this.loading = false
        }
      }))
    }
   
  }

  searchInVies() {

    if(Utils.isNullOrEmpty(this.countryCode) || Utils.isNullOrEmpty(this.nipControl.value)) return
    if(this.nipControl.invalid) return

    this.loading = true
 
    this._viesApiService.validateVatNumber(this.countryCode, this.nipControl.value)
    .pipe(take(1))
    .pipe(finalize(()=> {
      this.loading = false
    }))
    .subscribe({
      next: (response: APIResponse) => {
        if(response.success != true) return

        if(response.data.valid == false) {
          this.nipControl.setErrors({
            notactive: true
          })
          return
        }
        
        const companyData = response.data
        const country = this.countryCodes.find(x=>x.id == this.countryCode)

        this.showCreateOrUpdateClientDialog({
            name: companyData.name,
            type: 'company',
            street: companyData.street,
            postalCode: companyData.postalCode,
            city: companyData.city,
            country: country?.name || '',
            nip: this.nipControl.value,
        })
      }
    })

  }

  showCreateOrUpdateClientDialog(client) {

    // Do not close before showing
    // It wont update client if it close now
    this._dialogService.showCreateOrUpdateClientDialog(client)
    .subscribe({
      next: (data) => {
 
        if(data == null) return
        if(data.success == true) {
          this.close({success: true, data: data.client});
          return
        }
      }
    })
  }
}
