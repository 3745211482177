<h2>Blog
    <a class="h5 ms-3" routerLink="post/add">Dodaj nowy post</a>
</h2>
<mat-divider></mat-divider>
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page">Administracja</li>
      <li class="breadcrumb-item active" aria-current="page">Blog</li>
    </ol>
</nav>
<div class="row">
    <div class="col-12 col-md-6 d-flex">
        <div class="input-group my-2" style="width:250px;">
            <div class="form-floating">
                <input #searchInput type="text" class="form-control text-left" id="search"
                    placeholder="Szukaj post" (keyup)="search($event.target.value)">
                <label for="companyName">Szukaj post</label>
            </div>
            <span class="input-group-text cursor-pointer">
                <span class="material-symbols-outlined ">
                    search
                </span>
            </span>
        </div>

    </div>
    <div class="col-12">
        <div class="table-responsive stable">
            <table class="table table-hovered table-striped">
                <thead>
                    <tr>
                        <th>Nazwa</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let post of posts" class="cursor-pointer" [routerLink]="'post/edit/'+ post?.url">
                        <td>
                            <span *ngIf="post.title != ''">{{post?.title}}</span>
                            <mat-icon *ngIf="!post?.isActive" matTooltip="Post niewidoczny">visibility_off</mat-icon>
                        </td>
                        <td>
                            <a [routerLink]="'post/edit/'+ post?.url" mat-stroked-button color="dark" class="ms-2">
                                <mat-icon>person</mat-icon>
                                Pokaż
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination [currentPage]="currentPage" [totalPages]="allPages" (onPageChange)="onPageChange($event)"></pagination>
        </div>
    </div>
</div>  

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />