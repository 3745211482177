import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef } from '@angular/core'
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal'
import { ToastComponent } from '../components/toast/toast.component'
import { NotificationData} from 'fakturnia-shared'

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    private toastStack: any | ComponentRef<ToastComponent>[] = [];

    private _audio
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
        this._audio = new Audio('assets/sounds/pop-01.mp3');
    }

    //Nieoczekiwany błąd, API nie jest w stanie przetworzyć żadania, błąd po stronie serwera   
    warning(message) {
        this.showToast(new NotificationData({
            title: 'Komunikat',
            status: 'warning',
            message: message
        }))
    }
    critical() {
        this.showToast(new NotificationData({
            title: 'Wystąpił błąd',
            status: 'danger',
            message: 'Wystąpił nieoczekiwany błąd. Administrator został o nim powiadomiony.'
        }))
    }
    showToast(n: NotificationData) {

        const container = document.getElementById('notifications-container')

        if (!container) {
            console.error("[ToastService]: Notifications container not found. Can't display notification.");
            return;
        }

        let portalHost = new DomPortalOutlet(container, this.componentFactoryResolver, this.appRef, this.injector);
        const portal: any = new ComponentPortal(ToastComponent);

        let componentRef: any = null;
        // Check if there's an existing portal attached
        componentRef = portalHost.attach(portal);
        componentRef.instance.data = n;

        componentRef.instance.portalHost = portalHost;
        componentRef.instance.portal = portal;

        this.toastStack.unshift(portal);
        const maxToasts = 50; // Adjust as needed
        if (this.toastStack.length > maxToasts) {
            const removedPortal: any = this.toastStack.pop();
            removedPortal.detach();
        }
        if (n.sound) this._playSound()
        if (n.timeToClose && n.timeToClose > 0) this._scheduleDetachment(portalHost, portal, n.timeToClose);
        return componentRef
    }

    private _scheduleDetachment(host: DomPortalOutlet, portal: ComponentPortal<ToastComponent>, timeToClose: number): void {
        setTimeout(() => {
            host.detach();
            const index = this.toastStack.indexOf(portal);
            if (index !== -1) {
                this.toastStack.splice(index, 1);
            }
        }, timeToClose);
    }

    private _playSound() {
        if (!this._audio) console.log("[ToastService]: Can't load audio notification sound.")
        this._audio.play();
    }
}