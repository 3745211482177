import { DbClient } from "fakturnia-shared";

export class GUSToDbClientConverter {
  public static convertOne(company: any) {


    const street = this.createAddress(company.Ulica, company.NrNieruchomosci, company.NrLokalu)
    const client = new DbClient({
      name: company.Nazwa,
      postalCode: company.KodPocztowy,
      city: company.Miejscowosc,
      country: "Polska",
      regon: company.Regon,
      nip: company.Nip,
      street: street
    })
    return client

  }
  public static convert(companies: any[]) {
    const results: any = [];

    companies.forEach((company: any) => {

      const street = this.createAddress(company.Ulica[0], company.NrNieruchomosci[0], company.NrLokalu[0])
      const client = new DbClient({
        name: company.Nazwa,
        postalCode: company.KodPocztowy,
        city: company.Miejscowosc,
        country: "Polska",
        regon: company.Regon,
        nip: company.Nip,
        street: street
      })
      results.push(client)
    })

    return results
  }

  private static createAddress(street, building, flat) {
    // Sprawdź, czy parametr "street" zawiera "ul. ", jeżeli tak, usuń go
    if (street.includes("ul. ")) {
      street = street.replace("ul. ", "");
    }

    // Jeżeli parametr "flat" jest zdefiniowany, dodaj ukośnik przed "building"
    if (flat && flat != '') {
      return `${street} ${building}/${flat}`;
    } else {
      return `${street} ${building}`;
    }
  }
}