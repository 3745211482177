import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SettingsService } from '../services/settings.service';
import { UserSettings } from "fakturnia-shared";

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedAdminGuard implements OnDestroy{

  private _subscriptions:Subscription[] = []
  userSettings:UserSettings
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private _settingsService:SettingsService){
      this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
        next: (userSettings:UserSettings) => {
          this.userSettings = userSettings
        }
      }))
    }
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    return this.authService.isLoggedIn$.pipe(
      tap(isLoggedin => {
        const role = this.userSettings.user.role
        console.log(role)
        if(!isLoggedin || role !== 'administrator'){
          this.router.navigate(['logowanie']);
        }
      })
    );
  }
  
}
