import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[thrx-autocomplete]',
})
export class AutocompleteDirective implements OnInit {
  @Input('suggestions') suggestions: string[];
  @Output('onSelect') onSelect: EventEmitter<any> = new EventEmitter<any>();

  private readonly AUTOCOMPLETE_CLASS = 'autocomplete';
  private selectedOptionIndex = -1;
  private autocompleteList: HTMLUListElement;

  mapFields = ['name']
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.el.nativeElement.addEventListener('input', this.handleInput.bind(this));
    this.el.nativeElement.addEventListener('click', this.handleInput.bind(this));
    // this.el.nativeElement.addEventListener('blur', this.handleBlur.bind(this));
    document.addEventListener('click', this.handleDocumentClick.bind(this));
  }

  private handleInput(event: Event) {
    const value = this.el.nativeElement.value;
    this.autocompleteList = this.createAutocompleteList(value);
    // this.autocompleteList.addEventListener('blur',  () => setTimeout(this.handleBlur.bind(this),10));
    this.showAutocompleteList();
  }

  private handleDocumentClick(event: Event) {
    const clickedElement = event.target as HTMLElement;
  
    // Sprawdź, czy kliknięcie nastąpiło poza kontrolką input oraz listą "autocomplete"
    if (
      clickedElement !== this.el.nativeElement &&
      !this.el.nativeElement.contains(clickedElement) &&
      (!this.autocompleteList || !this.autocompleteList.contains(clickedElement))
    ) {
      this.hideAutocompleteList();
    }
  }

  private createAutocompleteList(value: string): HTMLUListElement {
    this.autocompleteList = this.renderer.createElement('ul');
    this.renderer.addClass(this.autocompleteList, this.AUTOCOMPLETE_CLASS);
    this.renderer.appendChild(document.body, this.autocompleteList);

    const inputRect = this.el.nativeElement.getBoundingClientRect();
    
    // Calculate the top offset for the autocomplete list
    const topOffset = inputRect.bottom + window.scrollY;

    this.renderer.setStyle(this.autocompleteList, 'position', 'fixed');
    this.renderer.setStyle(this.autocompleteList, 'left', `${inputRect.left}px`);
    this.renderer.setStyle(this.autocompleteList, 'top', `${topOffset}px`);
    
    if (!value) {
      return this.autocompleteList;
    }

    this.suggestions
      .filter((suggestion:any) => suggestion.name.toLowerCase().includes(value.toLowerCase()))
      .forEach((suggestion:any) => {
        const item = document.createElement('li');
        item.textContent = suggestion.name + ` (${suggestion.grossValue} brutto, ${suggestion.quantity}x, Stawka: ${!Number.isNaN(suggestion.vatRate) ? suggestion.vatRate + '%' : suggestion.vatRate})`;
        item.addEventListener('click', () => this.selectSuggestion(suggestion));
        this.autocompleteList.appendChild(item);
      });


    return this.autocompleteList;
  }

  private showAutocompleteList() {
    this.hideAutocompleteList();
    if (this.autocompleteList.children.length > 0) {
      this.el.nativeElement.parentNode.insertBefore(this.autocompleteList, this.el.nativeElement.nextSibling);
      this.renderer.listen(this.el.nativeElement, 'keydown', this.handleListKeydown.bind(this));
    }
  }
  
  private hideAutocompleteList() {
    const existingList = document.querySelector(`.${this.AUTOCOMPLETE_CLASS}`);
    if (existingList) {
      existingList.remove();
    }
    this.selectedOptionIndex = -1;
  }

  private selectSuggestion(suggestion: any) {
    this.el.nativeElement.value = suggestion.name;
    this.onSelect.emit(suggestion)
    this.hideAutocompleteList();
  }

  private handleListKeydown(event: KeyboardEvent) {
    
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this.selectNextOption();
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.selectPreviousOption();
        break;
      case 'Enter':
        event.preventDefault();
        this.selectHighlightedOption();
        break;
    }
  }

  private selectNextOption() {
    const options:any = Array.from(this.autocompleteList.children);
    const numOptions = options.length;
    if (this.selectedOptionIndex < numOptions - 1) {
      this.removeOptionHighlight();
      this.selectedOptionIndex++;
      this.highlightOption(options[this.selectedOptionIndex]);
    }
  }

  private selectPreviousOption() {
    if (this.selectedOptionIndex > 0) {
      this.removeOptionHighlight();
      this.selectedOptionIndex--;
      const options:any = Array.from(this.autocompleteList.children);
      this.highlightOption(options[this.selectedOptionIndex]);
    }
  }

 

  private highlightOption(option: HTMLElement) {
    option.classList.add('highlighted');
    option.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  private removeOptionHighlight() {
    const highlightedOption = this.autocompleteList.querySelector('.highlighted');
    if (highlightedOption) {
      highlightedOption.classList.remove('highlighted');
    }
  }

  private selectHighlightedOption() {
    const highlightedOption = this.autocompleteList.querySelector('.highlighted');
    if (highlightedOption) {
      const suggestion = highlightedOption.textContent;
      this.el.nativeElement.value = suggestion;
      this.hideAutocompleteList();
    }
  }
}
