import { Component, OnDestroy } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { AppData } from 'fakturnia-shared';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { SettingsService } from 'shared/services/settings.service';

@Component({
  selector: 'default-vat-rates',
  templateUrl: './default-vat-rates.component.html',
  styleUrls: ['./default-vat-rates.component.scss']
})
export class DefaultVatRatesComponent implements OnDestroy {

  subscriptions:Subscription[] = [];

  vatRates = AppData.VatRates;
  userVatRates: Set<string> = new Set();

  constructor(
    private settingsService:SettingsService,
    private apiService:ApiService
    ) {
    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data)=> {
        this.userVatRates = new Set(data.settings.vatRates);
      }
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  changeVatRate(event,vatRate)
  {
    if(event.checked) this.userVatRates.add(vatRate.value)
    else this.userVatRates.delete(vatRate.value)
 
    this.saveChanges();
  }

  saveChanges() {
    const vatRates = Array.from(this.userVatRates)

    this.apiService.updateVatRates(vatRates)
    .pipe(take(1))
    .subscribe({
      next: (response:APIResponse)=> {
        if(response.success == true) this.settingsService.refreshSettings()
      }
    })
  }
}
