import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrintManyDialogComponent } from 'shared/dialogs/print-many-dialog/print-many-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  constructor(private _dialog: MatDialog){ }

  showPrintManyDialog() {
    this._dialog.open(PrintManyDialogComponent, {
      width: '500px',
      panelClass: 'fakturnia-responsive-dialog'
    })
  }
  
}
