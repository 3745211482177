import { Component } from '@angular/core';
import { DbDocument } from 'fakturnia-shared';
import { FakturaKoncowa } from 'fakturnia-shared';
import { FakturaKorygujaca } from 'fakturnia-shared';
import { FakturaMPP } from 'fakturnia-shared';
import { FakturaVAT } from 'fakturnia-shared';
import { FakturaVATMarza } from 'fakturnia-shared';
import { FakturaZaliczka } from 'fakturnia-shared';
import { DocumentsService } from 'shared/services/documents.service';
import { InvoicesService } from 'shared/services/invoices.service';
import { Utils } from 'fakturnia-shared';
import { Invoice } from 'fakturnia-shared';

@Component({
  selector: 'invoice-card',
  templateUrl: './invoice-card.component.html',
  styleUrls: ['./invoice-card.component.scss']
})
export class InvoiceCardComponent {

  Object = Object
  countFieldOptions: { value: 'vatAmount' | 'netSum' | 'grossSum', text: string }[] = [
    { value: 'netSum', text: 'Wartość netto'},
    { value: 'vatAmount', text: 'Kwota VAT'},
    { value: 'grossSum', text: 'Wartość brutto'},
  ]

  selectedFieldId = this.countFieldOptions[0]
  // Range of months
  range = 12
  data: MonthYearInvoices[] = []
  constructor(
    private _documentsService:DocumentsService,
    private _invoicesService:InvoicesService
    ) {
    this._initialize()
  }

  public toDecimal(value) {
    return Utils.toDecimal(value)
  }
  private _initialize() {
    this._buildRange()
    this._addInvoices()
  }

  private _buildRange() {
    this.data = [];
    const currentDate = new Date();
    let currentMonth = currentDate.getMonth(); // getMonth() returns 0 for January, 11 for December
    let currentYear = currentDate.getFullYear();
  
    for (let i = 0; i < 12; i++) {
      this.data.push(new MonthYearInvoices({ month: currentMonth, year: currentYear }));
      
      // Decrement the month and adjust the year if necessary
      currentMonth--;
      if (currentMonth < 0) {
        currentMonth = 11;
        currentYear--;
      }
    }

    this.data[0].expanded = true
  }

  private _addInvoices() {
    const invoiceTypes = [
      new FakturaKoncowa(),
      new FakturaKorygujaca(),
      new FakturaMPP(),
      new FakturaVATMarza(),
      new FakturaVAT(),
      new FakturaZaliczka()
    ].map(x=>x['id'])

    this.data.forEach((element:MonthYearInvoices) => {
      const issuedInvoices = this._documentsService.getDocumentsByMonthAndYear(invoiceTypes, element.month, element.year)
      issuedInvoices.forEach((document:DbDocument)=> {
        const invoice = this._invoicesService.getById(document._id)
        if(invoice) element.addInvoice(invoice)
      })
    })
  }
}
export class CurrencySummary {
  currency: string
  summary: number
}

export class MonthYearInvoices {

  month: number
  monthName: string
  year: number
  invoices: Invoice[] = []
  expanded: boolean = false
  sum = {}
  summary:CurrencySummary[] = []
  countFieldId: 'vatAmount' | 'netSum' | 'grossSum' = 'netSum'

  constructor({month, year}: {month: number, year: number}) {
    this.month = month
    console.log(Utils.getMonthName(this.month), month)
    this.monthName = Utils.getMonthName(this.month)
    this.year = year
  }

  public toggleExpand() {
    this.expanded = !this.expanded
  }

  public addInvoice(invoice:Invoice) {
    this.invoices.push(invoice)
    this._count()
  }

  public setCountField(fieldId: 'vatAmount' | 'netSum' | 'grossSum') {
    this.countFieldId = fieldId
    this._count()
  }

  private _count() {

    this.sum = {}
    if(this.invoices.length == 0)  return

    this.invoices.forEach((invoice:Invoice) => {
      if(typeof invoice[this.countFieldId] == 'undefined' || invoice[this.countFieldId] == null) {
        console.warn(`[InvoiceCardComponent]: Invalid input value in count(); invoice[this.countFieldId]; ${invoice[this.countFieldId]}`)
      }

      if(typeof this.sum[invoice.currency] == 'undefined') this.sum[invoice.currency] = 0
      this.sum[invoice.currency] += invoice[this.countFieldId]
    })

    this.summary = Object.keys(this.sum).map(key =>  { return { currency: key, summary: this.sum[key]}})
  }
}