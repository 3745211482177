import { Injectable } from "@angular/core";
import { BehaviorSubject, take, tap } from "rxjs";
import { API_HOST, settings } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ApiService } from "./api.service";
import { SettingsService } from "./settings.service";
import { PanelService } from "./panel.service";
import { DialogService } from "./dialog.service";
import { SynchronizationService } from "./synchronization.service";
import { DbService } from "./db.service";
import { ToastService } from "./toast.service";
import { APIResponse } from "fakturnia-shared";
import { NotificationData } from "fakturnia-shared";
import { UserSettings } from "fakturnia-shared";


declare let gtag: Function

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this._isLoggedIn$.asObservable();


  public readonly ACCESS_TOKEN = settings.accessTokenKey;
  public readonly REFRESH_TOKEN = settings.refreshTokenKey;

  constructor(
    private router: Router,
    private http: HttpClient,
    private _dbService:DbService,
    private apiService:ApiService,
    private settingsService:SettingsService,
    private panelService:PanelService,
    private dialogService:DialogService,
    private _synchronizationService:SynchronizationService,
    private _toastService:ToastService
  ) {
    this._isLoggedIn$.next(!!this.accessToken);
  }

  get accessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }
  get refreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  isLoggedIn() {
    return this._isLoggedIn$.value;
  }

  loginOrRegister(action:string, emailAddress: string | any, password: string | any, redirect: boolean = true) {

    this.http.post(API_HOST + `auth/${action}`, { emailAddress, password })
    .subscribe({
        next: (response:APIResponse) => {

          if(response.success == false) {
            this._toastService.warning("Adres e-mail lub hasło jest nieprawidłowe.")
            return
          }

          if(response.success == true && action === 'login') {

            if(typeof response.data.id == 'undefined') { this.logout({muted: true}) }

            this._isLoggedIn$.next(true);
            localStorage.setItem(this.ACCESS_TOKEN, response.data.accessToken);
            localStorage.setItem(this.REFRESH_TOKEN, response.data.refreshToken);
            this._dbService.setDatabase(response.data.id);
            this.apiService.getUserSettings().pipe(take(1)).subscribe({

              next: (settingsResponse:APIResponse) => {

                if(settingsResponse.success == true) {
                  this.settingsService.updateUserSettings(<UserSettings>settingsResponse.data);
                  if(
                    settingsResponse.data.user.hasCompletedRegistration == false || 
                    typeof settingsResponse.data.user.hasCompletedRegistration == 'undefined') 
                    {
                        this.dialogService.showSetUpCompanyDialog()
                        .pipe(take(1))  
                        .subscribe()
                    }
                } else {
                  this._toastService.warning("Nie udało się pobrać ustawień konta.")
                  return
                }
                
              }

            })

            this._synchronizationService.synchronize();


            this._toastService.showToast(new NotificationData({
              title: 'Zalogowano!',
              status: 'info',
              message: 'Witaj, życzymy przyjemnej pracy!',
            }))

            if(redirect == true) this.router.navigate(['konto']);
          }

          else if (response.success == true && action === 'register')
          {
            this._toastService.showToast(new NotificationData({
              title: 'Sukces',
              status: 'success',
              message: 'Konto zostało utworzone.',
            }))

            gtag('event', 'conversion_event_signup_1', {})
            this.loginOrRegister('login',emailAddress, password, redirect)
          }
        }
      }
    )
  }

  getRefreshToken() {
    return this.http.post(API_HOST + 'auth/refresh-token', { refreshToken: this.refreshToken }).pipe(
      tap(
        (response: APIResponse) => {
          localStorage.setItem(this.ACCESS_TOKEN, response.data.accessToken);
          localStorage.setItem(this.REFRESH_TOKEN, response.data.refreshToken);
        }
      )
    );
  }

  changePassword(data) {
    return this.http.post(API_HOST + 'auth/change-password', { currentPassword: data.currentPassword, newPassword: data.newPassword });
  }
  changeEmailAddress(data) {
    return this.http.post(API_HOST + 'auth/change-email-address', { password: data.password, emailAddress: data.emailAddress });
  }
  restoreAccount(data) {
    return this.http.post(API_HOST + 'auth/restore-account', data);
  }
  setNewPassword(data) {
    return this.http.post(API_HOST + 'auth/set-new-password', data);
  }

  logout({ muted = false}) {
    this._isLoggedIn$.next(false);
    localStorage.removeItem(this.ACCESS_TOKEN)
    localStorage.removeItem('role')
    localStorage.removeItem(this.REFRESH_TOKEN)

    if(muted == false) {
      this._toastService.showToast(new NotificationData({
        title: 'Do zobaczenia',
        status: 'info',
        message: 'Zostałeś prawidłowo wylogowany z aplikacji.',
      }))
    }
    
    this.router.navigateByUrl('/logowanie');
    this.panelService.close();
  }


}