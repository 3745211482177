import { Component, Input, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'shared/services/auth.service';
import { emailValidator } from 'shared/validators/email.validator';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  
  private _subscriptions: Subscription[] = [];

  @Input('redirect') redirect = true

  loginForm = this.formBuilder.group({
    emailAddress: ['', [Validators.required, emailValidator()]],
    password: ['',[ Validators.required, Validators.minLength(8)]]
  })

  showPassword = false;
  constructor(
    private authService: AuthService,
    private formBuilder:FormBuilder
    ) {

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  login() {

    if(!this.loginForm.valid) return;
    
    this.authService.loginOrRegister(
      "login",
      this.loginForm.controls.emailAddress.value,
      this.loginForm.controls.password.value,
      this.redirect
    )
    
  }
}
