import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import { Location } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { plLocale } from 'ngx-bootstrap/locale';
import { ApiService } from 'shared/services/api.service';
import { AuthService } from 'shared/services/auth.service';
import { BehaviorSubject, Subscription, combineLatest, take } from 'rxjs';
import { SettingsService } from 'shared/services/settings.service';
import { DialogService } from 'shared/services/dialog.service';
import { debounce } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { DbDocumentObject, DocumentsService } from 'shared/services/documents.service';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';
import { InvoicePreviewService } from 'shared/services/invoice-preview.service';
import { ToastService } from 'shared/services/toast.service';
import { NotificationData } from 'fakturnia-shared';
import { ProductsService } from 'shared/services/products.service';
import { cloneDeep } from 'lodash'
import { BankAccountsApiService } from 'shared/services/api/bank-accounts.api.service';
import { Invoice } from 'fakturnia-shared';
import { AppData } from 'fakturnia-shared';
import { DbProduct } from 'fakturnia-shared';
import { Company } from 'fakturnia-shared';
import { DbClient } from 'fakturnia-shared';
import { DbDocument } from 'fakturnia-shared';
import { InvoiceGenerator } from 'fakturnia-shared';
import { FakturaVAT } from 'fakturnia-shared';
import { IBankAccount } from 'fakturnia-shared';
import { APIResponse, TemplateUtils, Settings, Product, Utils } from 'fakturnia-shared';
import { UserSettings } from "fakturnia-shared";
import { DocumentsApiService } from 'shared/services/api/documents.api.service';
import { NgxSeoService } from 'shared/modules/ngx-seo/ngx-seo.service';
import { ClientsService } from 'shared/services/clients.service';
import { SnackbarService } from 'shared/services/snackbar.service';

defineLocale('pl', plLocale)
@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.scss']
})
export class IssueComponent implements AfterViewInit, OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  measurementUnits = AppData.MeasurementUnits;
  vatRates: any = AppData.DefaultVatRates;
  additionalDateHeaders = AppData.AdditionalDateHeaders;
  paymentMethods = AppData.PaymentMethods;
  paymentStatuses = AppData.PaymentStatuses;
  paymentDateHeaders = AppData.PaymentDateHeaders.map(x=>x.name)
  subheaderDateHeaders = AppData.AdditionalDateHeaders.map(x=>x.name)
  documents: DbDocumentObject
  document: any = new FakturaVAT();

  tableHeaders = this.document.table.headers;

  invoice: Invoice = new Invoice();
  settings: Settings;

  isAuthenticated = false;

  loadingStatus = new BehaviorSubject<{ isDataLoaded: boolean, isDocumentLoaded: boolean, areSettingsLoaded: boolean }>({ isDataLoaded: false, isDocumentLoaded: false, areSettingsLoaded: false });
  currentLoadingStatus = this.loadingStatus.asObservable();

  bankAccounts: IBankAccount[] = []

  generalFieldsToShow = new Set<string>()
  products:DbProduct[] = []
  isLoaded = false

  gtu = AppData.GTU

  mode: 'edit' | 'create' | 'create_copy' = 'create'

  marginProcedures = [
    // { id: null, text: 'Nie wybrano'},
    ...AppData.MarginProcedures
  ]

  availableDocs = [
    { id: 'FakturaVAT', name: 'Faktura VAT' },
    { id: 'FakturaProforma', name: 'Faktura proforma' },
    { id: 'FakturaZaliczka', name: 'Faktura zaliczka'},
    { id: 'FakturaKoncowa', name: 'Faktura końcowa'},
    { id: 'FakturaKorygujaca', name: 'Faktura korygująca'},
    { id: 'FakturaVATMarza', name: 'Faktura VAT marża'},
    { id: 'FakturaVATMarzaProforma', name: 'Faktura VAT marża proforma'},
    { id: 'FakturaMPP', name: 'Faktura MPP (mechanizm podzielonej płatności)'},
    { id: 'FakturaProformaBezVAT', name: 'Faktura proforma (bez vat)' },
  ]

  bankAccountIsDiffrentThanInSettings = false
  showPdfOverlay = false;

  // sellerFieldsToShow = []
  // buyerFieldsToShow = []
  // receiverFieldsToShow = []

  ngOnInit(): void {

    this.subscriptions.push(this._productsService.currentProducts.subscribe({
      next: (data) => {
        this.products = Object.values(data)
      }
    }))

    this.subscriptions.push(this._documentsService.currentDocuments.subscribe({
      next: (data) => {
        this.documents = data
      }
    }))

    this.subscriptions.push(
      combineLatest({
        params: this.route.params,
        queryParams: this.route.queryParams,
        userSettings: this.settingsService.getUserSettings()
      })
      .subscribe({
        next: (data) => {

          const params: any = data.params
          const queryParams: any = data.queryParams

          this.bankAccounts = data.userSettings.bankAccounts
          this.settings = data.userSettings.settings;
          if(typeof this.settings != 'string') {
            this.vatRates = AppData.VatRates.filter(rate => this.settings.vatRates.includes(rate.value))
          }
         
          this.checkVatRates();

          if(Utils.isNullOrEmpty(this.settings?.autocomplete?.issuePlace)) {
            this.generalFieldsToShow.add('issuePlace')
          }

          if(!Utils.isNullOrEmpty(this.settings?.autocomplete?.signatures) && this.settings?.autocomplete?.signatures) {
            this.generalFieldsToShow.add('signatures')
          }

          if (typeof params.id == 'undefined')  return

            // Creating brand new document
            if (params.id == 'wystaw') {
              if(!this.isLoaded) {
                this.mode = 'create'
                this.isLoaded = true
                this.newDocument(data.userSettings)
                this.setNextDocumentName();
              }

              // Issue invoice for clientId
              if(typeof queryParams.clientId !== 'undefined') {
                const client = this._clientsService.getById(queryParams.clientId)
                if(!client) {
                  this._snackbarService.showSnackBar('Nie znaleziono klienta o wskazanym identyfikatorze.', 'error')
                  return
                } 
 
                this.updateBuyer(client)
 
              }
            }

            // Creating new documents based on another
            else if(typeof queryParams.action != 'undefined' && queryParams.action == 'copy') {
              this.mode = 'create_copy'
              this.invoice = this.loadDocument(params.id)
              this.setDocumentType(this.invoice.documentType)
              this.checkBankAccounts()
              this.invoice._id = null
              this.setNextDocumentName();
              this.invoice.count()
            }

            // Editing document
            else {
              this.mode = 'edit'
              this.isLoaded = true
              this.invoice = this.loadDocument(params.id)
              // this.buyerFieldsToShow = this.invoice.buyer.fieldsToShow
              // this.sellerFieldsToShow = this.invoice.seller.fieldsToShow
              // this.receiverFieldsToShow = this.invoice.receiver.fieldsToShow
              
              if(this.invoice.prepaymentIds.length > 0) {
                this.invoice.prepayments = []
                this.invoice.prepaymentIds.forEach(prepaymentId => {
                  const prepayment = this._documentsService.getById(prepaymentId)
                  if(prepayment) this.invoice.addPrepayment(prepayment)
                })
              }

              if(!Utils.isNullOrEmpty(this.invoice.correctedInvoiceId)) {
                this.invoice.correctedInvoice = null
                const correctedInvoice = this._documentsService.getById(this.invoice.correctedInvoiceId)
                if(correctedInvoice) this.invoice.addCorrectedInvoice(correctedInvoice, false)
              }

              if(this.invoice.hasReceiver) {
                this.generalFieldsToShow.add('hasReceiver')
              }

              if(this.invoice.hasComments) {
                this.generalFieldsToShow.add('hasComments')
              }

              if(this.invoice.hasGTU) {
                this.generalFieldsToShow.add('hasGTU')
              }

              if(this.invoice.hasDiscount) {
                this.generalFieldsToShow.add('hasDiscount')
              }

              if(this.invoice.additionalPaymentDateHeader.show) {
                this.generalFieldsToShow.add('hasAdditionalPaymentDate')
              }

              if(this.invoice.hasPaymentDescription) {
                this.generalFieldsToShow.add('hasPaymentDescription')
              }

              if(this.invoice.signatures.issuerSignature.show == false || this.invoice.signatures.receiverSignature.show == false) {
                this.generalFieldsToShow.delete('signatures')
              }

              this.checkBankAccounts()
              this.seo.setTitle(this.invoice.invoiceName + ' - Fakturnia.pl')
              this.setDocumentType(this.invoice.documentType)
              this.checkVatRates();
             

              this.invoice.count()
              console.log(this.invoice)
            }


            this.removeInvoiceObserver()
            this.addInvoiceObserver()
            this.renderPreview()
          
        }
      })
    )

    this.subscriptions.push(this.authService.isLoggedIn$.subscribe({
      next: (data) => {
        this.isAuthenticated = data
      }
    }))

  }

  ngAfterViewInit(): void {
    this.addInvoiceObserver()
  }

  ngOnDestroy(): void {
    this.removeInvoiceObserver()
    this.invoice.prepaymentIds = []

    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  constructor(
    private localeService: BsLocaleService,
    private apiService: ApiService,
    private authService: AuthService,
    private _productsService: ProductsService,
    private _documentsService:DocumentsService,
    private settingsService: SettingsService,
    private _dialogService: DialogService,
    private seo: NgxSeoService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private _synchronizationService:SynchronizationService,
    private _dataSynchronizerService:DataSynchronizerService,
    private _invoicePreviewService:InvoicePreviewService,
    private _toastService:ToastService,
    private _bankAccountApiService:BankAccountsApiService,
    private _documentsApiService:DocumentsApiService,
    private _clientsService:ClientsService,
    private _snackbarService:SnackbarService
  ) {

    this.checkInvoiceName = debounce(this.checkInvoiceName, 10)
    // this.renderPreview = debounce(this.renderPreview, 10)

    this.localeService.use('pl');
  }

  loadDocument(documentId):Invoice {
    const document = this._documentsService.getById(documentId)
    if (!document || typeof document == 'undefined') {
      console.warn("[IssueComponent]: Document not found.")
      this.router.navigateByUrl('/konto/dokumenty')
      return new Invoice()
    }

    return new Invoice().fromDbDocument(cloneDeep(document))
  }

  showUpdateSellerDialog() {
    this.showCreateOrUpdateClientDialog(this.invoice.seller, 'seller')
  }

  showCreateOrUpdateClientDialog(client, type: 'seller' | 'buyer' | 'receiver') {
    this.subscriptions.push(this._dialogService.showCreateOrUpdateClientDialog(client, type )
    .subscribe({
      next: (data) => {


        if(data == null) return
        if(data.success != true) return

        this.invoice[type].fieldsToShow = data.client.fieldsToShow

        if(type == 'seller') {
          this.updateSeller(data.client)
        }
        else if(type == 'buyer') {
          this.updateBuyer(data.client)
        }
        else if(type == 'receiver') {
          this.updateReceiver(data.client)
        }

        this.renderPreview()
      }
    }))
  }



  showToggleFormFieldsDialog() {
    this._dialogService.showToggleFormFieldsDialog({
      currency: this.invoice.currency,
      generalFieldsToShow: Array.from(this.generalFieldsToShow),
    }
     
    )
    .subscribe({
      next: (result) => {

        if(!result) return
        if(result.success !== true) return
        
        if(typeof result.data.dateHeader != 'undefined') {
          this.invoice.setSubHeader(result.data.dateHeader)
        }

        if(typeof result.data.currency != 'undefined') {
          this.invoice.set('currency', result.data.currency)
        }

        if(typeof result.data.generalFieldsToShow != 'undefined') {
          this.generalFieldsToShow = new Set(result.data.generalFieldsToShow)
        
          this.invoice.toggleSignatures(this.generalFieldsToShow.has('signatures'))
          this.invoice.hasReceiver = this.generalFieldsToShow.has('hasReceiver')
          this.invoice.hasComments = this.generalFieldsToShow.has('hasComments')
          this.invoice.hasGTU = this.generalFieldsToShow.has('hasGTU')
          this.invoice.hasDiscount = this.generalFieldsToShow.has('hasDiscount')
          this.invoice.hasPaymentDescription = this.generalFieldsToShow.has('hasPaymentDescription')
          this.invoice.setAdditionalPaymentDateShow(this.generalFieldsToShow.has('hasAdditionalPaymentDate'))


          if(this.invoice.hasDiscount == false) {
            this.invoice.products.forEach(product=> {
              product.setDiscount(0)
            })
          } 
        }

        if(result.success === true) {
          this.renderPreview()
        }
      }
    })
  }


  isCreating = false
  newDocument(userSettings: UserSettings) {

    if(this.isCreating) return
    this.isCreating = true
    
    this.invoice = new Invoice();
    this.invoice.setDocument(this.document);

    this.invoice.addProducts([
      new Product(
        {
          _id: '',
          name: '',
          unitOfMeasure:'szt.',
          quantity:1,
          discount:0,
          netPrice:0,
          vatRate: 23,
          parent: this.invoice
        }
      )
    ])

    if (Utils.isNullOrEmpty(userSettings)) return

    this.invoice.seller = new Company({
      name: userSettings.company.name,
      firstName: userSettings.user.firstName,
      lastName: userSettings.user.lastName,
      pesel: userSettings.user.pesel,
      idNumber: userSettings.user.idNumber,
      type: userSettings.company.type,
      nip: userSettings.company.nip,
      regon: userSettings.company.regon,
      bdo: userSettings.company.bdo,
      krs: userSettings.company.krs,
      phoneNumber: userSettings.user.phoneNumber,
      email: userSettings.user.emailAddress,
      fax: userSettings.company.fax,
      street: userSettings.address.street,
      postalCode: userSettings.address.postalCode,
      city: userSettings.address.city,
      description: userSettings.company.description
    })

    const defaultBankAccount = this.bankAccounts.find(x=>x.isDefault == true)
    if(defaultBankAccount) {
      this.invoice.setBankAccountNumber(defaultBankAccount.accountNumber)
    }

    if (userSettings.settings.autocomplete.documentNumbering) {
      this.setNextDocumentName();
    }

    if (Utils.isDefined(userSettings, "address.city")) {
      this.invoice.setPlaceHeaderValue(userSettings.address.city)
    }

    if (!Utils.isNullOrEmpty(userSettings.settings.autocomplete.issuePlace)) {
      this.invoice.setPlaceHeaderValue(userSettings.settings.autocomplete.issuePlace)
    }

    if (!Utils.isNullOrEmpty(userSettings.settings.autocomplete.signatures) && userSettings.settings.autocomplete.signatures == true) {
      this.invoice.setIssuerSignatureShow(true)
      this.invoice.setIssuerSignatureValue(`${userSettings.user.firstName} ${userSettings.user.lastName}`)
      this.invoice.setReceiverSignatureShow(true)
      this.invoice.setReceiverSignatureValue('')
    }

  }

  // Observe invoice change
  observer = (data: any) => {
    this.renderPreview()
  };

  addedObserver = false

  addInvoiceObserver() {
    if (this.addedObserver) return;
    if (Utils.isNullOrEmpty(this.invoice)) return;
    if (typeof this.invoice.addObserver == 'undefined') { console.warn("[OBSERVER] - Can't register invoice observer.", this.invoice); return; }
    this.addedObserver = true;
    this.invoice.addObserver(this.observer);
  }

  removeInvoiceObserver() {
    this.addedObserver = false
    if (!this.addedObserver) return;
    if (Utils.isNullOrEmpty(this.invoice)) return;
    if (typeof this.invoice.removeObservers == 'undefined') return;
    this.invoice.removeObservers()
  }

  // On updates in forms
  updateSeller(company: Company) {
    this.invoice.setSeller(company);
    // Refresh signatures on updates
    this.invoice.toggleSignatures(this.generalFieldsToShow.has('signatures'))
    this.renderPreview()
  }

  updateBuyer(client: DbClient) {
    this.invoice.setBuyer(new Company(client));
    // Refresh signatures on updates
    this.invoice.toggleSignatures(this.generalFieldsToShow.has('signatures'))
    if(this.invoice._id != null) this.updateDocument(false)
    this.renderPreview()
  }

  updateReceiver(client: DbClient) {
    this.invoice.setReceiver(new Company(client));
    if(this.invoice._id != null) this.updateDocument(false)
    this.renderPreview()
  }

 

  checkBankAccounts() {
    if (typeof this.invoice == 'undefined') return;
    if (Utils.isNullOrEmpty(this.invoice.bankAccountNumber.value)) return;
      if (!this.bankAccounts.find(x => x.accountNumber == this.invoice.bankAccountNumber.value)) {
        const newBankAccount = { bank: 'Konto bankowe', accountNumber: this.invoice.bankAccountNumber.value}
        this.bankAccounts.push(newBankAccount)
      }
  }

  checkVatRates() {
    if (typeof this.invoice == 'undefined') return;
    this.invoice.products.forEach((product: Product) => {
      if (!this.vatRates.find(x => x.value == product.vatRate)) {
        const vatRate = AppData.VatRates.find(x => x.value == product.vatRate)
        if (typeof vatRate != 'undefined') this.vatRates.push(vatRate)
      }
    })
    this.vatRates.sort((a, b) => a.value - b.value);
  }

  zoom: number | 'auto' = 66
  zoomIn() {

    if(this.zoom == 'auto') this.zoom = 100
    this.zoom += 7
    if(this.zoom > 100) this.zoom = 100
  }

  zoomOut() {
    if(this.zoom == 'auto') this.zoom = 100
    this.zoom -= 7
    if(this.zoom < 30) this.zoom = 30
  }
  

  getNextDocumentName() {
    if (this.invoice == null) return;

    const next = this._documentsService.getNextDocumentNumber(this.invoice)
   
    return this.document.createDocName(Utils.numberInDocument(next.nextNumber), next.currentMonth, next.currentYear);
  }

  setNextDocumentName() {
    if (!Utils.isNullOrEmpty(this.invoice._id)) return;
    if (!Utils.isDefined(this.settings, "autocomplete.documentNumbering") || !this.settings.autocomplete.documentNumbering) return;
    const newInvoiceName = this.getNextDocumentName();
    if (typeof newInvoiceName != 'undefined') this.invoice.set('invoiceName', newInvoiceName)

    this.renderPreview()
  }

  checkInvoiceName() {
    if (!Utils.isNullOrEmpty(this.invoice._id)) return;
    if (!Utils.isDefined(this.settings, "autocomplete.documentNumbering") || !this.settings.autocomplete.documentNumbering) return;
    const newInvoiceName = this.getNextDocumentName();
    if (typeof newInvoiceName != 'undefined') {
      if (newInvoiceName != this.invoice.invoiceName) {
        this._dialogService.confirmDialog({
          confirmText: "Zmień",
          message: `Zaktualizować numer dokumentu? Sugerowana nazwa to ${newInvoiceName}`,
          title: 'Nazwa dokumentu',
          showIcon: true,
          cancelText: "Nie",
          type: "info"
        }).subscribe({
          next: (data) => {
            if (data != null && data == true) {
              this.invoice.set('invoiceName', newInvoiceName)
            }
          }
        })
      }
    }
  }

  foundProducts: any = [];
  findProducts(productName) {
    // this.foundProducts = [];
    productName = productName.toLowerCase();
    this.foundProducts = this.products
      .filter((product:  DbProduct) => product?.name?.toLowerCase()?.includes(productName) && !product.isDeleted)
  }

  saveProduct(product:Product) {

    if(Utils.isDefined(product,"_id")) return

    const productObj = product.toObject()
    
    this.apiService.saveProduct(productObj)
    .pipe(take(1))
    .subscribe(
      {
        next: (response:APIResponse) => {

          if(response.success == false) {
            this._toastService.showToast(new NotificationData({
              title: 'Wystąpił błąd',
              status: 'warning',
              message: 'Nie udało się zapisać danych produktu.',
            }))
            return
          }

          this._dataSynchronizerService.next("products",[response.data])
          this._synchronizationService.synchronize()
          product._id = response.data._id
          this._toastService.showToast(new NotificationData({
            title: 'Informacja',
            status: 'info',
            message: 'Dane produktu zostały zapisane.',
          }))
        }
      }
    )
  }


  showSelectPrepaymentDialog() {
    const dialog = this._dialogService.showSelectInvoiceDialog({
      filters: [
        (document: DbDocument)=> { return (!document.isDeleted && document.isPaid)},
        (document: DbDocument)=> { return (document.documentType == 'FakturaVAT' || document.documentType == 'FakturaZaliczka')}
      ], 
      selection: 'multiple'
    })

    const sub = dialog.subscribe({
      next: (data)=> {
        if(data == null || typeof data == 'undefined') return

        if(Array.isArray(data)) {
          data.forEach(invoiceId => {
            if(this.invoice.prepayments.findIndex(x=>x._id == invoiceId) == -1) {
              const invoice = this._documentsService.getById(invoiceId)
              if(invoice) this.invoice.addPrepayment(cloneDeep(invoice))
            }
          });
        }
          
        if(data == false) sub.unsubscribe()
        
      }
    })
  }

  showSelectCorrectedInvoiceDialog() {
    const dialog = this._dialogService.showSelectInvoiceDialog({
      filters: [
        (document: DbDocument)=> { return (!document.isDeleted && document.isPaid)},
        (document: DbDocument)=> { return (document.documentType == 'FakturaVAT' || document.documentType == 'FakturaZaliczka')}
      ],
      selection: 'single'
    })

    const sub = dialog.subscribe({
      next: (data)=> {
        if(data == null || typeof data == 'undefined') return

        if(Array.isArray(data)) {
          data.forEach(invoiceId => {
            const invoice = this._documentsService.getById(invoiceId)
            if(invoice) this.invoice.addCorrectedInvoice(cloneDeep(invoice))
          });
        }
          
        if(data == false) sub.unsubscribe()
        
      }
    })
  }

  removeProduct(indexToRemove: number) {
    this.invoice.products.splice(indexToRemove, 1)
    this.invoice.count();
  }

  addProduct() {
    this.invoice.addProducts(new Product(
      {
        _id: '',
        name: '',
        gtu: '',
        unitOfMeasure:'szt.',
        quantity:1,
        discount:0,
        netPrice:0,
        vatRate: 23,
        parent: this.invoice
      }
    ))
  }

  createPDF(mode: 'download' | 'render') {
    return InvoiceGenerator.createPDF(this.document, this.invoice, 0, mode)
  }

  prevZoom:any = null
  showingPreview = false
  togglePreview() {

    if(this.showingPreview) this.closePreview()
    else this.showPreview()
   
  }

  closePreview() {
    this.zoom = this.prevZoom
    this.showingPreview = false
  }

  showPreview() {
    this.showingPreview = true
    this.prevZoom = this.zoom
    this.zoom = 'auto'
  }


  dataUrl:any = null
  previewHeight:any = 'auto'
  async renderPreview(): Promise<any> {
    const docDefinition = this.createPDF('render');

    pdfMake.createPdf(<any>docDefinition).getBase64(dataUrl => {
      this.dataUrl = dataUrl
      this.previewHeight = window.innerHeight - 200;
      this._invoicePreviewService.next(docDefinition)
    })
  }


  getRest(value: number | string) {
    return Utils.getRest(value)
  }

  toDecimal(value: any) {
    return Utils.toDecimal(value);
  }

  setMarginProcedure(e) {
    this.invoice.setMarginProcedure(e)
  }

  setDocumentType(id: string) {
    this.document = TemplateUtils.GetDocumentById(id)
  }

  switchDocument(id: string) {
    
    this.document = TemplateUtils.GetDocumentById(id)
    this.invoice.setDocument(this.document);
    // Maybe it should have new number
    this.setNextDocumentName()

  }

  // Call create document endpoint to save new document
  public createDocument(redirect = true) {

    this._documentsApiService.create(this.invoice)
      .pipe(take(1))
      .subscribe({
        next: (response:APIResponse) => this._processDocumentResponse(response, redirect, true)
      })
  }

  // Call update document endpoit to save changes
  public updateDocument(redirect = true) {

    if(Utils.isNullOrEmpty(this.invoice._id)) return

    this._documentsApiService.update(this.invoice._id, this.invoice)
    .pipe(take(1))
    .subscribe({
      next: (response:APIResponse) => this._processDocumentResponse(response, redirect, false)
    })
  }


  private _processDocumentResponse = (response:APIResponse, redirect:boolean, isCreating:boolean) => {
    if(response.success == false)  {
      this._toastService.showToast(new NotificationData({
        title: 'Wystąpił błąd',
        status: 'warning',
        message: 'Nie udało się zapisać dokumentu.',
      }))
      return
    }
    
  
    if (typeof response.data != 'undefined' && typeof response.data._id != 'undefined') {
      // Update data in memomery
      this._dataSynchronizerService.next('documents',[response.data])
      // Update _id to avoid save confirmation - document is already saved
      this.invoice._id = response.data._id
      // Navigate to new created document
      if(redirect) this.router.navigate(['/konto/dokumenty/'+ response.data._id+'/podglad'])
      // Synchronize database
      this._synchronizationService.synchronize()
    }

    this._toastService.showToast(new NotificationData({
      title: 'Sukces',
      status: 'success',
      message: 'Dokument został zapisany!',
    }))

    if(isCreating) {
      const bankAccount = this.bankAccounts.find(x=>x.accountNumber == this.invoice.bankAccountNumber.value)
      if(typeof bankAccount === 'undefined' && !Utils.isNullOrEmpty(this.invoice.bankAccountNumber.value)) {
        this._bankAccountApiService.createBankAccount({
          accountNumber: this.invoice.bankAccountNumber.value,
          bank: 'Konto bankowe'
        })
        .pipe(take(1))
        .subscribe({
          next: (response:APIResponse)=> {
            if(response.success === true) {
              this.settingsService.refreshSettings()
              this._toastService.showToast(new NotificationData({
                title: 'Informacja',
                status: 'info',
                message: 'Konta bankowe zostały zaktualizowane.',
              }))
            }
          }
        })
      }

      if(Utils.isNullOrEmpty(this.settings.autocomplete.issuePlace)) {
        this.apiService.updateIssuePlace(
          this.invoice.placeHeader.value,
        )
        .pipe(take(1))
        .subscribe({
          next: (response:APIResponse)=> {
            if(response.success === true) {
              this.settingsService.refreshSettings()
              this._toastService.showToast(new NotificationData({
                title: 'Informacja',
                status: 'info',
                message: 'Zapisano domyślne miejsce wystawienia.',
              }))
            }
          }
        })
      }
    }
  }

  removeQueryParams() {
    const currentUrl = this.location.path();
    const baseUrl = currentUrl.split('?')[0]; // Extract the base URL without query params
  
    this.location.replaceState(baseUrl);
  }
  updateRouteParameter(newId: string): void {
    // Get the current route snapshot
    const currentSnapshot = this.route.snapshot;
  
    // Create a copy of the current route parameters
    const updatedParams = { ...currentSnapshot.params };
  
    // Update the parameter with the new value
    updatedParams['id'] = newId;
  
    // Create a new ActivatedRouteSnapshot with the updated parameters
    const newSnapshot:any = { ...currentSnapshot, params: updatedParams };
  
    // Replace the current route snapshot with the updated one
    this.route.snapshot = newSnapshot;
  }
}


