import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DataSynchronizerService } from "./data-synchronization.service";
import { DbService } from "./db.service";
import { DbDocumentObject } from "./documents.service";
import { AppDb } from "fakturnia-shared";
import { DbProduct } from "fakturnia-shared";

@Injectable({
    providedIn: 'root'
})
export class ProductsService implements OnDestroy {

 
    private _subscriptions:Subscription[] = [];
    private _products = new BehaviorSubject<DbProductObject>({});
    currentProducts = this._products.asObservable();

    private _db:AppDb
    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }

    constructor(
        private _dbService:DbService,
        private _dataSynchronizerService:DataSynchronizerService
        )
    {
            this._subscriptions.push(this._dbService.getDatabase().subscribe({
                next: (data)=> {
                    this._db = data
                }
            }))

            this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {
                    if(!data) return
                    if(data.table != 'products') return
    
                    // Process documents
                    // data.value = this._processDocuments(data.value)
                    // Update
                    data.value.forEach((product: DbProduct) => {
                        this._products.value[<string>product._id] = product
                    });
    
                    // Filter by status
                    // Not a good idea
                    // const filtered: DbDocumentObject = Object.entries(this._documents.value)
                    //     .filter(([_, doc]) => doc.isDeleted === false)
                    //     .reduce((acc, [key, value]) => {
                    //         acc[key] = value;
                    //         return acc;
                    //     }, {} as DbDocumentObject);
    
                    // Push changes
                    this._products.next(this._products.value)
    
                    this._dataSynchronizerService.received()
                }
            }))
    }

    getAll(): Observable<any> {
        if(!this._db) return of(null)
        const s1 = new Date().getTime();
        const loader = this._db.transaction("r", this._db.products, () => {
            return this._db.products.orderBy('createdAt').reverse().toArray();
        }).then((result: DbProduct[]) => {
            const object: DbProductObject = {};
            // result = this._processDocuments(result)
            result.forEach(prod => {
                object[<string>prod._id] = prod;
            });
            return object;
        });

        return forkJoin([from(loader)]).pipe(
            map(([products]) => {
                // Update your subjects
                this._products.next(products);
                return { products };
            }),
            tap(() => console.log(`[Products Service]: Built in ${new Date().getTime() - s1}ms.`))
        );
    }
}
export interface DbProductObject {
    [key: number]: DbProduct;
}
