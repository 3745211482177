<h1 style="display:none;">Bezpłatny program do wystawiania faktur przez przeglądarkę. Fakturnia.pl to narzędzie, które
    ułatwi Ci generowania dokumentów, usprawniając Twoją pracę.</h1>

<div class="max-w-200 mx-8 md:mx-auto bg-card rounded-xl my-12 lg:my-24 tracking-wide p-8">

    <div class="h3">
        Regulamin<br />
        <small>dostosowany do regulacji art. 28-30 RODO</small>
    </div>

    <h2 class="section-title">1. Postanowienia ogólne</h2>
    <p class="section-content">Niniejszy Regulamin określa warunki świadczenia usług drogą elektroniczną za
        pomocą serwisu fakturnia.pl oraz na innych domenach wskazujących na poszczególne części serwisu przez
        THERRAX IT SOLUTIONS Jerzy Kołakowski z siedzibą w Ustroniu Morskim. Regulamin obowiązuje od momentu
        rejestracji w serwisie i jego akceptacji przez Użytkownika. Zawarcie umowy na świadczenie usług
        opisanych w Regulaminie następuje po zapoznaniu się i akceptacji treści niniejszego Regulaminu i jest
        warunkiem rejestracji w Serwisie.</p>
    <p class="section-content">Niniejszy Regulamin jest regulaminem, o którym mowa w art. 8 ustawy z dnia 18
        lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2002 r. Nr 144, poz. 1204 z późn. zm. –
        dalej Ustawy o świadczeniu usług drogą elektroniczną).</p>

    <h2 class="section-title">2. Definicje</h2>
    <dl class="definition-list">
        <dt>2.1 Regulamin</dt>
        <dd>Rozumie się przez to niniejszy Regulamin</dd>
        <dt>2.2 Operator</dt>
        <dd>THERRAX IT SOLUTIONS z siedzibą w Ustroniu Morskim ul. Spokojna 3/4, 78-111 Ustronie Morskie,
            wpisaną do rejestru przedsiębiorców pod numerem NIP: 6711852031, REGON: 523080439.</dd>
        <dt>
            2.3 Serwis
        </dt>
        <dd>
            rozumie się przez to Serwis internetowy fakturnia.pl, umożliwiający korzystanie z Usług.
        </dd>
        <dt>
            2.4 Usługa
        </dt>
        <dd>
            usługa świadczona w ramach Serwisu Fakturnia.pl.
        </dd>
        <dt>
            2.5 Użytkownik
        </dt>
        <dd>
            rozumie się przez to korzystającą z Serwisu osobę fizyczną, osobę prawną lub jednostkę organizacyjną
            nie mającą osobowości prawnej, utworzoną zgodnie z obowiązującymi przepisami i składającą Zamówienie
            określające warunki umowy, na podstawie której Strony będą zobowiązane do świadczeń wynikających z
            Umowy
        </dd>
        <dt>
            2.6 Umowa
        </dt>
        <dd>
            umowa o świadczeniu Usług w sposób ciągły, rozliczana cyklicznie w powtarzających się okresach
            rozliczeniowych.
        </dd>
        <dt>
            2.7 Cennik
        </dt>
        <dd>
            publikowana na stronie głównej Serwisu informacja, w której Operator określił warunki i listę Usług
            pozostających w ofercie oraz ich cenę. Zmiana Cennika nie oznacza zmiany Regulaminu.</dd>
        <dt>
            2.8 Abonament
        </dt>
        <dd>
            opłata uiszczana przez Użytkownika za świadczenie Usługi w danym okresie rozliczeniowym.
        </dd>
        <dt>
            2.9 Okres rozliczeniowy
        </dt>
        <dd>
            przedział czasowy, będący podstawą rozliczenia usług świadczonych w trybie abonamentowym.
        </dd>
    </dl>

    <h2 class="section-title">3. Prawa autorskie</h2>
    <dl class="definition-list">
        <dt>3.1</dt>
        <dd>
            Wyłączne prawa autorskie do Serwisu fakturnia.pl należą do Operatora. Prawa autorskie związane z Serwisem
            fakturnia.pl podlegają ochronie Ustawy o prawie autorskim i prawach pokrewnych z dnia 4 lutego 1994 r. (Dz.
            U. z 1994r. nr 24, Poz.83 ze zm.).
        </dd>
    </dl>

    <h2 class="section-title">4. Bezpłatne konto Użytkownika</h2>
    <dl class="definition-list">
        <dt>4.1</dt>
        <dd>
            Założenie indywidualnego konta Użytkownika w Serwisie jest wymagane w celu zawierania umów dotyczących
            poszczególnych Usług. Prowadzenie konta Użytkownika jest bezpłatne. Użytkownik zakładając konto użytkownika,
            wyraża jednocześnie zgodę na akceptację warunków Regulaminu, oraz jest zobowiązany podać dane umożliwiające
            przesłanie mu linku aktywacyjnego do konta w postaci poprawnego adresu e-mail.
        </dd>
        <dt>4.2</dt>
        <dd>
            Aby Serwis mógł funkcjonować prawidłowo, Użytkownik powinien wprowadzić dane zgodne ze stanem faktycznym i
            prawnym.
        </dd>
    </dl>

    <h2 class="section-title">5. Zawieranie umowy dotyczącej Usługi</h2>
    <dl class="definition-list">
        <dt>5.1</dt>
        <dd>
            W celu zawarcia Umowy na konkretną Usługę z oferty dodatkowej Operatora Serwisu Użytkownik wybiera
            interesującą go opcję Abonamentu dotyczącą tej Usługi, a następnie Użytkownik zobowiązany jest podać
            wszelkie dane niezbędne do wystawienia mu faktury.
        </dd>
        <dt>5.2</dt>
        <dd>
            W przypadku wyboru Usługi płatnej Użytkownik zobowiązany jest do uiszczenia opłaty za tą Usługę z góry w
            wysokości wskazanej w Cenniku.
        </dd>
        <dt>5.3</dt>
        <dd>
            Użytkownik, chcąc uzyskać dostęp do funkcji, która nie występuje w wykupionym przez Niego pakiecie
            abonamentowym, zobowiązany jest do zmiany pakietu abonamentowego na wyższy, zgodnie z Cennikiem lub
            indywidualnymi ustaleniami z Operatorem Serwisu.
        </dd>
        <dt>5.4</dt>
        <dd>
            Umowa zostaje zawarta w momencie potwierdzenia zaksięgowania środków na koncie Operatora Serwisu. Użytkownik
            otrzymuje automatyczne powiadomienie na adres e-mail z informacją o aktywacji usługi i jej okresie
            abonamentowym.
        </dd>
        <dt>5.5</dt>
        <dd>
            Po upływie danego okresu rozliczeniowego Serwis przesyła automatyczną wiadomość dotyczącą konieczności
            dokonania kolejnej płatności.
        </dd>
        <dt>5.6</dt>
        <dd>
            W przypadku niedokonania płatności lub rezygnacji Użytkownik zachowuje prawo do bezpłatnego dostępu przez
            czas nieograniczony do usług, o których mowa w pkt. 4.1 i opisanych w Cenniku jako bezpłatne.
        </dd>
    </dl>

    <h2 class="section-title">6. Zaprzestanie świadczenia usług</h2>
    <dl class="definition-list">
        <dt>6.1</dt>
        <dd>
            Zaprzestanie świadczenia usług przez Operatora może nastąpić w następujących przypadkach:
            <ul>
                <li>6.1.1 rezygnacji przez Użytkownika</li>
                <li>6.1.2 ustania bytu prawnego Użytkownika</li>
                <li>6.1.3 nieuiszczenia przez Użytkownika należności w terminie za usługi dodatkowe, z zastrzeżeniem
                    ust. 4, 5.6</li>
                <li>6.1.4 niezaakceptowania regulaminu Serwisu</li>
            </ul>
        </dd>
        <dt>6.2</dt>
        <dd>
            Operator zastrzega sobie prawo do zaprzestania świadczenia Usług w trybie natychmiastowym, jeśli Użytkownik:
            <ul>
                <li>6.2.1 umyślnie narusza postanowienia ninejszego Regulaminu</li>
                <li>6.2.2 dopuszcza się działań zmierzających do naruszenia bezpieczeństwa danych znajdujących się w
                    Serwisie lub podejmuje nieuprawnioną próbę dostępu do Serwisu</li>
                <li>6.2.3 dokonuje czynności niezgodnych z prawem</li>
                <li>6.2.4 działa na szkodę Operatora lub innych Użytkowników Serwisu</li>
                <li>6.2.5 podał nieprawdziwe dane</li>
                <li>6.2.6 w innych szczególnie uzasadnionych wypadkach</li>
            </ul>
        </dd>
        <dd>
            W takich przypadkach Użytkownikowi nie należą się żadne roszczenia od Operatora, a Operator ma prawo do
            całkowitego zablokowania dostępu do konta Użytkownika nawet za miesiące księgowe, za które Użytkownik
            dokonał opłat.
            W przypadku określonym w pkt. 6.1.2., następcy prawni powinni zgłosić ten fakt Operatorowi, a w przypadku
            określonym w pkt. 6.1.3. powiadomić powinien Użytkownik.
        </dd>
        <dt>6.3</dt>
        <dd>
            W przypadku otrzymania zgłoszenia o bezprawnym charakterze danych, Operator Serwisu ma prawo, zgodnie z art.
            14 Ustawy o świadczeniu usług drogą elektroniczną do zablokowania dostępu do danych Użytkownika. W takim
            przypadku Użytkownik zostanie poinformowany o wprowadzeniu blokady konta.
        </dd>
    </dl>

    <h2 class="section-title">7. Płatności</h2>
    <dl class="definition-list">
        <dt>7.1</dt>
        <dd>
            Operator wystawia Fakturę VAT po uiszczeniu zapłaty przez Użytkownika w ciągu 7 dni od wpłynięcia zapłaty na
            konto, ze wskazaniem danych określonych przez Użytkownika podczas zakupu. Operator przekazuje fakturę VAT
            Użytkownikowi drogą elektroniczną.
        </dd>
        <dt>7.2</dt>
        <dd>
            Koszty związane z realizacją płatności za Usługi ponosi Użytkownik.
        </dd>
        <dt>7.3</dt>
        <dd>
            Za dzień zapłaty uznaje się datę uznania kwoty na rachunku bankowym Operatora Serwisu.
        </dd>
        <dt>7.4</dt>
        <dd>
            Opłatę należy uiszczać zgodnie z Cennikiem.
        </dd>
        <dt>7.5</dt>
        <dd>
            Opłatę za każdy okres Abonamentowy należy uiszczać z góry.
        </dd>
        <dt>7.6</dt>
        <dd>
            Zakres usług, świadczonych bezpłatnie określony jest w cenniku.
        </dd>
        <dt>7.7</dt>
        <dd>
            Operator udostępnia następujące formy płatności:
            <ul>
                <li>
                    7.7.1 przelew internetowy oraz przelew bankowy na konto wskazane w potwierdzeniu zakupu
                </li>
                <li>
                    7.7.2 Karta kredytowa lub e-przelewem przy płatności internetowej za pośrednictwem operatora PayU
                    lub PayPro S.A.,
                </li>
            </ul>
        </dd>
        <dt>7.8</dt>
        <dd>
            Przy dokonywaniu płatności przelewem należy wpisać w tytule przelewu numer zamówienia nadany przez Operatora
            oraz nazwę firmy lub adres e-mail użytkownika.
        </dd>
        <dt>7.9</dt>
        <dd>
            Płatność kartą kredytową może zrealizować właściciel karty lub upoważniona przez niego osoba. Użycie cudzej
            karty kredytowej bez upoważnienia jest przestępstwem przeciw mieniu, a próba dokonania takiej transakcji
            zostanie zgłoszona organom ścigania.
        </dd>
    </dl>

    <h2 class="section-title">8. Reklamacje</h2>
    <p>
        Operator serwisu stara się zapewnić najlepszą jakość obsługi klienta.
        Procedura reklamacyjna pozwala na skuteczne i szybkie rozwiązywanie problemów. W razie zaistnienia takiej
        potrzeby, prosimy o kontakt na adres mailowy kontakt&#64;fakturnia.pl.
    </p>
    <dl class="definition-list">
        <dt>8.1</dt>
        <dd>
            Każdą reklamację staramy się rozwiązać w przeciągu 7 dni roboczych.
        </dd>
        <dt>8.2</dt>
        <dd>
            W celu sprawnego rozwiązania Państwa problemu, prosimy o:
            <ul>
                <li>
                    8.2.1 przedstawienie wszystkich ważnych faktów, które mają związek ze sprawą
                </li>
                <li>
                    8.2.2 załączenie całej dokumentacji, która ma związek z reklamacją (korespondencja mailowa, listowna
                    lub zapis rozmów z konsultantem),
                </li>
                <li>
                    8.2.3 podanie adresu e-mailowego używanego w serwisie
                </li>
                <li>
                    8.2.4 wysłanie reklamacji na adres mailowy kontakt&#64;fakturnia.pl z tematem "Reklamacja"
                </li>
            </ul>
        </dd>
    </dl>

    <h2 class="section-title">9. Obowiązki Stron</h2>
    <dl class="definition-list">
        <dt>9.1</dt>
        <dd>
            Serwis fakturnia.pl dostępny jest dla Użytkowników przez całą dobę. Operator serwisu zastrzega sobie prawo
            do przerw wynikających z okresowej konserwacji systemu lub spowodowanych zdarzeniami losowymi.
        </dd>
        <dt>9.2</dt>
        <dd>
            Operator podejmuje wszelkie starania, aby zapewnić prawidłowe funkcjonowanie Serwisu pod względem
            technicznym, formalnym i prawnym.
        </dd>
        <dt>9.3</dt>
        <dd>
            Operator Serwisu zobowiązuje się do niezwłocznej naprawy wszelkich usterek utrudniających bądź
            uniemożliwiających dostęp do serwisu i usług. Użytkownik może wypowiedzieć umowę ze skutkiem natychmiastowym
            w przypadku nie usunięcia zgłaszanych awarii w terminie dłuższym niż 7 dni roboczych.
        </dd>
        <dt>9.4</dt>
        <dd>
            Operator Serwisu nie ponosi odpowiedzialności za jakiekolwiek szkody i straty wynikające bezpośrednio lub
            pośrednio (łącznie ze szkodami z tytułu utraty zysków z prowadzenia przedsiębiorstwa, przerw w działalności
            przedsiębiorstwa lub utraty informacji gospodarczej oraz innymi szkodami o charakterze majątkowym),
            powstałymi z powodu użycia, niemożliwości użycia lub błędnego działania Serwisu.
        </dd>
        <dt>9.5</dt>
        <dd>
            Użytkownik zobowiązuje się do korzystania z usługi w sposób nienaruszający praw osób trzecich (dóbr
            osobistych, praw autorskich), dobrych obyczajów, przepisów prawa.
        </dd>
        <dt>9.6</dt>
        <dd>
            Operator Serwisu potwierdza, że właścicielem całej treści wprowadzonej za pomocą Usług świadczonych przez
            Serwis jest Użytkownik.
        </dd>
        <dt>9.7</dt>
        <dd>
            Operator serwisu nie ponosi żadnej odpowiedzialności za treści prezentowane i wprowadzane do Serwisu przez
            Użytkownika. Również za treści rozsyłane i udostępniane przez Użytkownika z Serwisu Operatora.
        </dd>
        <dt>9.8</dt>
        <dd>
            Wszelkie powinności wynikające z przepisów prawa dotyczące publikacji treści leżą po stronie Użytkownika.
        </dd>
    </dl>

    <h2 class="section-title">10. Dane osobowe</h2>
    <dl class="definition-list">
        <dt>10.1</dt>
        <dd>
            Administratorem danych osobowych Użytkownika podawanych w trakcie rejestracji jest Operator Serwisu.
            Użytkownik oświadcza, że został poinformowany o celu oraz zakresie przetwarzania danych osobowych przez
            Operatora Serwisu, prawie wglądu do swoich danych i ich poprawiania, uprawnieniach do żądania zaprzestania
            przetwarzania danych oraz wniesienia sprzeciwu wobec przetwarzania danych, wynikających z art. 32 ustawy z
            dnia 29 sierpnia 1997 r. o ochronie danych osobowych (tekst jend.:Dz.U.2002, nr 101, poz. 926 z późn. zm.).
            Podanie danych jest dobrowolne, ale brak zgody uniemożliwia założenie konta i skorzystanie z serwisu
            fakturnia.pl.
        </dd>
        <dt>10.2</dt>
        <dd>
            Dane osobowe Użytkownika zamawiającego usługę są umieszczane i przetwarzane w bazie danych Operatora
            Serwisu. Dane są przetwarzane wyłącznie przez Operatora Serwisu w celu świadczenia usług i nie będą
            udostępniane innym podmiotom. Podanie przez Użytkownika danych osobowych jest dobrowolne, ale konieczne do
            realizacji Usług.
            <br />
            Użytkownik może wyrazić odrębną zgodę na przetwarzanie jego danych osobowych w celu marketingu
            bezpośredniego poprzez otrzymywanie informacji dotyczących usług i produktów Operatora Serwisu i jego
            kontrahentów, przekazywanej przy użyciu urządzeń końcowych zgodnie z art. 172 ust. 1 ustawy z dnia 16 lipca
            2004 r. Prawo Telekomunikacyjne (t.j. Dz. U. z 2014 poz. 243) oraz zgodnie z Ustawą.
            <br />
            W zakresie, w jakim Operator pozostaje Administratorem danych prowadzi on Rejestr Czynności Przetwarzania
            (dalej RCP), w którym odnotowuje:
            <ul>
                <li>10.2.1 nazwę i dane kontaktowe administratora danych</li>
                <li>10.2.2 cel przetwarzania danych osobowych</li>
                <li>
                    10.2.3 opis kategorii osób, których dane dotyczą oraz zakres danych (wrażliwe lub zwykłe)
                </li>
                <li>
                    10.2.4 kategorie odbiorców, którym dane zostały lub zostaną udostępnione
                </li>
                <li>
                    10.2.5 informację o przekazywaniu danych do państwa trzeciego wraz z dokumentacją opisującą
                    zastosowane zabezpieczenia w tym procesie
                </li>
                <li>
                    10.2.6 planowany termin usunięcia danych osobowych
                </li>
                <li>
                    10.2.6 ogólny opis zastosowanych zabezpieczeń technicznych i organizacyjnych
                </li>
            </ul>
        </dd>
        <dt>10.3</dt>
        <dd>
            Użytkownik oświadcza, że jest administratorem danych osobowych, w rozumieniu ustawy z dnia 29 sierpnia 1997
            r. o ochronie danych osobowych (Dz. U. z 2002, Nr 101, poz. 926 j.t. ze zm., zwanej dalej „Ustawą”), w
            odniesieniu do danych osobowych kontrahentów Użytkownika przekazywanych Operatorowi. Jednocześnie Użytkownik
            oświadcza, że jest uprawniony do przetwarzania w/w danych i powierzania ich przetwarzania podmiotom trzecim.
        </dd>
        <dt>10.4</dt>
        <dd>
            Zgodnie z art. 31 Ustawy Użytkownik powierza Operatorowi do przetwarzania dane osobowe, o których mowa w
            pkt. 10.3., wyłącznie w celu wykonywania usług możliwych w Serwisie fakturnia.pl i w zakresie niezbędnym do
            ich wykonania tj. w celu zagwarantowania prawidłowej realizacji usługi Operator jest uprawniony do
            przekazania danych podwykonawcom.
        </dd>
        <dt>10.5</dt>
        <dd>
            W zakresie, o którym stanowi pkt 10.4 Operator poozstaje procesorem danych i prowadzi odrębny rejestr
            czynności przetwarzania (dalej RCP Procesora). RCP Procesora obejmuje:
            <ul>
                <li>
                    10.5.1 nazwę i dane kontaktowe procesora lub procesorów oraz dane kontaktowe wszystkich
                    administratorów danych w imieniu, których działa procesor,
                </li>
                <li>
                    10.5.2 formy przetwarzania, które wykonuje procesor danych tj. np. przechowywanie, modyfikowanie i
                    usuwanie,
                </li>
                <li>
                    10.5.3 informację o przekazywaniu danych do państwa trzeciego wraz z dokumentacją opisującą
                    zastosowane zabezpieczenia w tym procesie,
                </li>
                <li>
                    10.5.4 ogólny opis zastosowanych zabezpieczeń technicznych i organizacyjnych.
                </li>
            </ul>
        </dd>
        <dt>10.6</dt>
        <dd>
            Rejestry czynności przetwarzania, tj RCP i RCP Procesora, wskazane w pkt 10.2 i 10.5 mają charakter
            dokumentów wewnętrznych, nie podlegają ujawnieniu osobom trzecim i udostępniane będą jedynie organowi
            nadzorczemu na jego wyraźne żądanie.
        </dd>
        <dt>10.7</dt>
        <dd>
            Użytkownik upoważnia Operatora do wydawania osobom biorącym udział w przetwarzaniu danych osobowych, o
            których mowa w pkt. 9.2, imiennych upoważnień do przetwarzania danych osobowych.
        </dd>
        <dt>10.8</dt>
        <dd>
            Operator zobowiązuje się do przetwarzania danych osobowych z zachowaniem środków bezpieczeństwa wymaganych
            przez ustawę o ochronie danych osobowych. Operator Serwisu nie ponosi odpowiedzialność za bezprawne
            przechowywanie danych przesłanych do Serwisu przez Użytkownika.
        </dd>
        <dt>10.9</dt>
        <dd>
            Operator umożliwia wprowadzanie zmian oraz całkowite usunięcie danych w przypadku wysłania prośby o
            zaprzestanie przetwarzania danych. Prośbę taką można przesłać na adres e-mail Operatora. Użytkownik może
            usunąć konto i jednocześnie wszystkie dane w Ustawieniach Ogólnych serwisu korzystając z opcji "Usuń konto".
            Operator, zgodnie z Rozporządzeniem może pomóc również, w miarę możliwości, w wywiązaniu się z obowiązków
            wskazanych w art. 32-36 Rozporządzenia.
        </dd>
        <dt>10.10</dt>
        <dd>
            Rejestracja w Serwisie oznacza zgodę Użytkownika na przetwarzanie zebranych danych w celach statystycznych i
            marketingowych prowadzonych przez Operatora. Na podany adres poczty elektronicznej Operator przesyła w
            szczególności informacje odnośnie systemu, ofert specjalnych i promocji Operatora. Zgoda na otrzymywanie
            informacji handlowych może być w każdej chwili cofnięta.
        </dd>
    </dl>

    <h2 class="section-title">11. Zmiana Regulaminu</h2>
    <dl class="definition-list">
        <dt>11.1</dt>
        <dd>
            Operator zastrzega sobie prawo do możliwości zmiany ninejszego Regulaminu.
        </dd>
        <dt>11.2</dt>
        <dd>
            O zamiarze zmiany Regulaminu Operator zawiadamia Użytkownika udostępniając treść nowego Regulaminu na
            internetowych stronach Serwisu.
        </dd>
    </dl>
    
</div>