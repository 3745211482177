import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[exchangeRate]'
})
export class ExchangeRateDirective {
  
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    const input = this.el.nativeElement;
    let value = input.value;

    // Zamiana przecinka na kropkę
    value = value.replace(',', '.');

    // Zapobieganie rozpoczęciu od kropki (zamiana ".1234" na "0.1234")
    if (value.startsWith('.')) {
      value = '0' + value;
    }

    // Usunięcie wszystkich niedozwolonych znaków (poza liczbami i jedną kropką)
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(value)) {
      value = value.replace(/[^0-9.]/g, '');
    }

    // Ograniczenie do jednej kropki
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1]; // Zachowaj tylko pierwszą część
    }

    // Ograniczenie do 5 miejsc po kropce
    if (parts[1] && parts[1].length > 5) {
      value = parts[0] + '.' + parts[1].slice(0, 5);
    }

    // Zaktualizowanie wartości inputa
    input.value = value;
  }

  // Zapobieganie wprowadzaniu niedozwolonych znaków
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const allowedChars = /[0-9,.]/;

    // Zablokowanie innych znaków niż liczby, przecinek i kropka
    if (!allowedChars.test(event.key)) {
      event.preventDefault();
    }
  }
}
