import { Injectable } from "@angular/core";
import { BehaviorSubject, finalize, take, tap } from "rxjs";
import { API_HOST, settings } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ApiService } from "./api.service";
import { SettingsService } from "./settings.service";
import { PanelService } from "./panel.service";
import { DialogService } from "./dialog.service";
import { SynchronizationService } from "./synchronization.service";
import { DbService } from "./db.service";
import { ToastService } from "./toast.service";
import { APIResponse } from "fakturnia-shared";
import { NotificationData } from "fakturnia-shared";
import { UserSettings } from "fakturnia-shared";
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthUtils } from "shared/utils/auth.utils";

declare let gtag: Function

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this._isLoggedIn$.asObservable();


  public readonly ACCESS_TOKEN = settings.accessTokenKey;
  public readonly SESSION_ID = settings.sessionIdKey;
  public readonly REFRESH_TOKEN = settings.refreshTokenKey;

  private readonly _deviceInfo = this._deviceService.getDeviceInfo()
  private readonly _deviceId = localStorage.getItem('device_id') == null ? AuthUtils.generateDeviceId() : localStorage.getItem('device_id');
  private readonly _browser = `${this._deviceInfo.browser} v${this._deviceInfo.browser_version}`;
  private readonly _deviceName = `${this._deviceInfo.os} ${this._deviceService.getDeviceInfo().os_version}`;
  private readonly _platform = this._deviceService.deviceType;

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _dbService:DbService,
    private _apiService:ApiService,
    private _settingsService:SettingsService,
    private _panelService:PanelService,
    private _dialogService:DialogService,
    private _synchronizationService:SynchronizationService,
    private _toastService:ToastService,
    private _deviceService: DeviceDetectorService
  ) {
    this._isLoggedIn$.next(!!this.accessToken);
  }

  get accessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }
  get sessionId() {
    return localStorage.getItem(this.SESSION_ID);
  }
  get refreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  isLoggedIn() {
    return this._isLoggedIn$.value;
  }

  loginOrRegister(action:string, emailAddress: string | any, password: string | any, redirect: boolean = true) {

    const toReturn = new BehaviorSubject<any>(null)
    this._http.post(API_HOST + `auth/${action}`, { 
      emailAddress, 
      password,
      deviceId: this._deviceId, 
      browser: this._browser, 
      deviceName: this._deviceName, 
      platform: this._platform
    })
    .pipe(take(1))
    .pipe(finalize(()=> {
      toReturn.next(true)
    }))
    .subscribe({
        next: (response:APIResponse) => {

          if(response.success == false && action === 'login') {
            this._toastService.warning("Adres e-mail lub hasło jest nieprawidłowe.")
            return
          }

          if(response.success == false && action === 'register') {
            this._toastService.warning(response.message)
            return
          }

          if(response.success == true && action === 'login') {

            if(typeof response.data.id == 'undefined') { this.logout({muted: true}) }

            this._isLoggedIn$.next(true);

            localStorage.setItem(this.ACCESS_TOKEN, response.data.accessToken);
            localStorage.setItem(this.REFRESH_TOKEN, response.data.refreshToken);
            localStorage.setItem(this.SESSION_ID, response.data.sessionId);

            this._dbService.setDatabase(response.data.id);
            this._apiService.getUserSettings().pipe(take(1)).subscribe({

              next: (settingsResponse:APIResponse) => {

                if(settingsResponse.success == true) {
                  this._settingsService.updateUserSettings(<UserSettings>settingsResponse.data);
                  if(
                    settingsResponse.data.user.hasCompletedRegistration == false || 
                    typeof settingsResponse.data.user.hasCompletedRegistration == 'undefined') 
                    {
                        this._dialogService.showSetUpCompanyDialog()
                        .pipe(take(1))  
                        .subscribe()
                    }
                } else {
                  this._toastService.warning("Nie udało się pobrać ustawień konta.")
                  return
                }
                
              }

            })

            this._synchronizationService.synchronize();


            this._toastService.showToast(new NotificationData({
              title: 'Zalogowano!',
              status: 'info',
              message: 'Witaj, życzymy przyjemnej pracy!',
            }))

            if(redirect == true) this._router.navigate(['konto']);
          }

          else if (response.success == true && action === 'register')
          {
            this._toastService.showToast(new NotificationData({
              title: 'Sukces',
              status: 'success',
              message: 'Konto zostało utworzone.',
            }))

            gtag('event', 'conversion_event_signup_1', {})
            this.loginOrRegister('login',emailAddress, password, redirect)
          }
        }
      }
    )

    return toReturn.asObservable()
  }

  getRefreshToken() {
    return this._http.post(API_HOST + 'auth/refresh-token', { refreshToken: this.refreshToken }).pipe(
      tap(
        (response: APIResponse) => {
          localStorage.setItem(this.ACCESS_TOKEN, response.data.accessToken);
          localStorage.setItem(this.REFRESH_TOKEN, response.data.refreshToken);
          localStorage.setItem(this.SESSION_ID, response.data.sessionId);
        }
      )
    );
  }

  changePassword(data) {
    return this._http.post(API_HOST + 'auth/change-password', { currentPassword: data.currentPassword, newPassword: data.newPassword });
  }
  changeEmailAddress(data) {
    return this._http.post(API_HOST + 'auth/change-email-address', { password: data.password, emailAddress: data.emailAddress });
  }
  restoreAccount(data) {
    return this._http.post(API_HOST + 'auth/restore-account', data);
  }
  setNewPassword(data) {
    return this._http.post(API_HOST + 'auth/set-new-password', data);
  }

  logout({ muted = false}) {

    this._http.post(API_HOST + `auth/logout`, {
      deviceId: this._deviceId
    })
    .pipe(finalize(()=> {
      this._isLoggedIn$.next(false);
      localStorage.removeItem(this.ACCESS_TOKEN)
      localStorage.removeItem('role')
      localStorage.removeItem(this.REFRESH_TOKEN)
  
      if(muted == false) {
        this._toastService.showToast(new NotificationData({
          title: 'Do zobaczenia',
          status: 'info',
          message: 'Zostałeś prawidłowo wylogowany z aplikacji.',
        }))
      }
      
      this._router.navigateByUrl('/logowanie');
      this._panelService.close();
    }))
    .pipe(take(1))
    .subscribe({
      next: (response: APIResponse) => {

      }
    })

   
  }

  /**
   * Clear local storage and set logged status as logged out
   * That method is not calling any logout action because session is broken and it could 
   * result not expected results such as errors
   */
  clearSession() {
    localStorage.clear()
    this._isLoggedIn$.next(false)
  }


}