import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { InvoiceTableComponent } from 'shared/components/invoice-table/invoice-table.component';

@Component({
  standalone: true,
  selector: 'app-select-invoice-dialog',
  imports: [CommonModule, MatDialogModule, InvoiceTableComponent, MatButtonModule, MatIconModule ],
  templateUrl: './select-invoice-dialog.component.html',
  styleUrls: ['./select-invoice-dialog.component.scss']
})
export class SelectInvoiceDialogComponent {

  filters:any[] = []
  selectedInvoicesIds = []
  showTools = false
  selection: 'single' | 'multiple' = 'multiple'
  constructor(
    private _dialog: MatDialogRef<SelectInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (typeof data == 'undefined') return
    
    if(typeof data.selectedInvoicesIds != 'undefined') {
      this.selectedInvoicesIds = data.selectedInvoicesIds
    }

    if(typeof data.showToolsButtons != 'undefined') {
      this.showTools = data.showToolsButtons
    }

    if(typeof data.selection != 'undefined') {
      this.selection = data.selection
    }

    if(typeof data.filters != 'undefined') {
      this.filters = data.filters
    }
  }

  onSelectInvoice(e) {
    this.selectedInvoicesIds = e
  }

  selectInvoices() {
    this._dialog.close({
      invoices: this.selectedInvoicesIds
    })
  }
}
