<mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened class="drawer">
        <h1 class="p-4 pb-2">Administracja</h1>
        <mat-selection-list #tab hideSingleSelectionIndicator="true" multiple="false">
            <mat-list-option value="pulpit" routerLink="pulpit">
                <mat-icon matListItemIcon>dashboard</mat-icon>
                <div matListItemTitle>Pulpit</div>
                <div matListItemLine>Panel administracyjny</div>
            </mat-list-option>
            <mat-list-option value="users" routerLink="users">
                <mat-icon matListItemIcon>people</mat-icon>
                <div matListItemTitle>Użytkownicy</div>
                <div matListItemLine>Zarządzaj użytkownikami</div>
            </mat-list-option>
            <mat-list-option value="blog" routerLink="blog">
                <mat-icon matListItemIcon>post_add</mat-icon>
                <div matListItemTitle>Blog</div>
                <div matListItemLine>Zarządzaj blogiem</div>
            </mat-list-option>
            <mat-list-option value="announcements" routerLink="announcements">
                <mat-icon matListItemIcon>announcement</mat-icon>
                <div matListItemTitle>Ogłoszenia systemowe</div>
                <div matListItemLine>Poinformuj użytkowników </div>
            </mat-list-option>
            <mat-list-option value="backup" routerLink="backup">
                <mat-icon matListItemIcon>backup</mat-icon>
                <div matListItemTitle>Backup</div>
                <div matListItemLine>Kopia zapasowa </div>
            </mat-list-option>
        </mat-selection-list>

    </mat-drawer>
    <mat-drawer-content class="p-4" style="min-height: 600px;">
        <router-outlet></router-outlet>
        <!-- <div class="row" *ngIf="tab.selectedOptions.selected[0].value == 'pulpit'">
            
        </div>
        <div class="row" *ngIf="tab.selectedOptions.selected[0].value == 'blog'">
            <h2>Posty</h2>
        </div> -->
    </mat-drawer-content>
</mat-drawer-container>