<h1 style="display:none;">Bezpłatny program do wystawiania faktur przez przeglądarkę. Fakturnia.pl to narzędzie, które ułatwi Ci generowania dokumentów, usprawniając Twoją pracę.</h1>

<section>
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">

        <div *ngIf="post != null" class="max-w-200 w-full text-center mx-auto" >
            <div class="text-3xl sm:text-4xl md:text-5xl lg:text-7xl mb-8 font-bold">{{post?.title}}</div>
            <div class="text-lg sm:text-xl lg:text-2xl font-medium block md:flex items-center justify-center">
                <div class="flex items-center mx-2">
                    <mat-icon [svgIcon]="'tabler_outline:calendar-month'" class="mr-2"></mat-icon> 
                    Dodano: <span class="text-secondary ml-2">{{post?.createdAt | date: 'd MMMM yyyy'}}</span>
                </div>

                <div class="flex items-center mx-2">
                    <mat-icon [svgIcon]="'tabler_outline:book'" class="mr-2"></mat-icon> 
                    Czas czytania: <span class="text-secondary ml-2">{{post?.readingTimeInMinutes == 0 ? '< 1min' : post?.readingTimeInMinutes +'min'}}</span>
                </div>
            </div>
        </div>

        <div *ngIf="post == null" class="w-20 mt-12 lg:mt-24 mx-auto">
            <mat-spinner color="primary"></mat-spinner>
        </div>
    </div>
</section>



<section class="w-full">
    <div class="post-bg"
    [ngStyle]="{ 'background-image': post?.img != ''  && post?.img != undefined  ? 'url('+post?.img+')' : 'url(/assets/images/fakturnia.png)' }">
    <div class="bg-blur"></div>

</div>
</section>

<section *ngIf="post != null">
    <div class="max-w-200 w-full mx-auto my-12 lg:my-24 px-8 lg:px-0">
        <div class="tracking-wide">
            <div class="leading-7" [innerHTML]="post?.content"></div>
        </div>
    </div>
</section>

<section>
    <join-us></join-us>
</section>