import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppData } from 'fakturnia-shared';
import { DatabaseService } from 'shared/services/database.service';
import { Utils } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { DialogService } from 'shared/services/dialog.service';
import { GUSToDbClientConverter } from 'shared/utils/gus-to-db-client-converter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from 'fakturnia-shared';
import { DbClient } from 'fakturnia-shared';

@Component({
  selector: 'company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnChanges  {

  @Input('company') company: Company = new Company({});
  @Input('title') title: string = '';
  @Input('allowAutocomplete') allowAutocomplete: boolean = false;
  @Output('onCompanyChange') onCompanyChange = new EventEmitter<Company>();

  companyToggleableFields = AppData.CompanyToggleableFields;
  @Input('isSearchEnabled') isSearchEnabled = true;
  visibleFields: any = new Set<string>();

  constructor(
    private databaseService: DatabaseService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private snackbar: MatSnackBar
  ) {
  }

  ngOnDestroy(): void {
  }

  
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['company']) {
      if (changes['company'].currentValue == null) this.company = new Company({});

      if (Utils.isDefined(changes, "company.currentValue.fieldsToShow")) {
        this.visibleFields = new Set(changes['company'].currentValue.fieldsToShow);
      }
    }
  }


  searchInGUS(company: 'seller' | 'buyer', nip) {

    if(typeof nip === 'undefined' || nip.length == 0) {
      this.snackbar.open("Wprowadź prawidłowy numer NIP", undefined, { duration: 3000 })
      return;
    }
    this.apiService.searchInGUS(nip).subscribe({
      next: (data: any) => {

          if (typeof data.success === 'undefined' || typeof data.result === 'undefined' || data.result == null || typeof data.code !== 'undefined') {
            this.snackbar.open("Nie znaleziono firmy o tym identyfikatorze.", undefined, { duration: 3000 })
            return;
          }

          
          this.dialogService.showSearchGUSResultsDialog({
            companies: GUSToDbClientConverter.convert([data.result])
          }).subscribe({
            next: (data: any) => {
              if (typeof data != 'undefined' && typeof data.company !== 'undefined' && data.company != null) {
                this.setCompany(data.company)
              }
            }
          })
      },
      error: (error) => {
        this.snackbar.open("Nie znaleziono firmy o tym identyfikatorze.", undefined, { duration: 3000 })
      }
    })
  }


  toggleFieldVisibility(field: any, event: Event) {
    event.stopPropagation();
    if (!this.companyToggleableFields.has(field)) return;

    this.visibleFields.has(field) ? this.visibleFields.delete(field) : this.visibleFields.add(field)

    this.company.set('fieldsToShow', Array.from(this.visibleFields))
    // trigger.openMenu()
  }

  // Autocomplete
  options: any = [];
  filterClients($event) {
    const value = $event.target.value.toLowerCase();
    this.options = Object.entries(this.databaseService.data.value.clients)
      .map(x => x[1]).filter((x: any) => {

        return x.name.toLowerCase().includes(value) && !x.isDeleted
      });
  }

  setCompany(client: DbClient) {
    this.company = new Company(client);
    this.onCompanyChange.emit(this.company);
  }
  // End of autocomplete

  updateCompany(field, value)
 {
  this.company.set(field,value)
  this.onCompanyChange.emit(this.company);

 }
}
