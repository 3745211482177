<mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <h4 *ngIf="mode == 'create'">Dodaj konto bankowe</h4>
        <h4 *ngIf="mode == 'update'">Aktualizacja konta bankowego</h4>
      </div>
      <div class="col-12 mt-2">
        <div class="form-floating">
          <input formControlName="bank" type="text" class="form-control text-left" id="bank" placeholder="Bank" />
          <label for="bank">Bank lub wyświetlana nazwa</label>
        </div>
      </div>
      <div class="col-12 mt-2">
        <div class="input-group mb-3">
          <div class="input-group-text">
            <i class="ti ti-building-bank"></i>
          </div>
          <div class="form-floating">
            <input formControlName="accountNumber" bankAccountFormat type="text" class="form-control text-left"
              id="accountNumber" placeholder="Numer konta" />
            <label for="accountNumber">Numer konta bankowego</label>
          </div>
        </div>
      </div>

      <div class="col-6 mt-3">
        <a *ngIf="form.controls['_id'].value != null" class="cursor-pointer color-red me-auto md"
          (click)="showDeleteConfirmation()">Usuń <i class="ti ti-trash"></i> </a>
      </div>

      <div class="col-6 mt-3">
        <button *ngIf="mode == 'create'" [disabled]="form.invalid" class="f-button ms-auto md" (click)="create()">Dodaj
          konto</button>
        <button *ngIf="mode == 'update'" [disabled]="form.invalid" class="f-button ms-auto md" (click)="update()">Zapisz
          zmiany</button>
      </div>
    </div>
  </form>
</mat-dialog-content>