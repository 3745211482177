<h1 style="display:none;">Bezpłatny program do wystawiania faktur przez przeglądarkę. Fakturnia.pl to narzędzie, które
    ułatwi Ci generowania dokumentów, usprawniając Twoją pracę.</h1>
<section>
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
        <div class="max-w-200 w-full text-center mx-auto">
            <div class="text-3xl sm:text-4xl md:text-5xl lg:text-7xl mb-8 font-bold">Blog</div>
            <div class="text-lg sm:text-xl lg:text-2xl font-medium">
                Zobacz co nowego słychać na naszym blogu
            </div>
        </div>

        <div *ngIf="posts == null" class="w-20 mt-12 lg:mt-24 mx-auto">
            <mat-spinner color="primary"></mat-spinner>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-12 lg:mt-24 place-content-center max-w-80 sm:max-w-180 lg:max-w-320 mx-auto ">
            <div class="max-w-80" *ngFor="let post of posts">
                <div class="bg-white dark:bg-slate-800 shadow-lg rounded-lg overflow-hidden h-120 min-h-120 max-h-120 cursor-pointer"
                    [routerLink]="'/blog/'+post?.url">
                    <div class="w-full h-48 bg-cover bg-center"
                        [ngStyle]="{'background-image': 'url(' + post?.img + ')'}"></div>
                    <div class="p-6 pb-1">
                        <h2 class="text-xl font-semibold text-gray-800 dark:text-slate-50 h-16">{{ post?.title }}</h2>
                        <p class="text-gray-600 dark:text-slate-200 mt-4 h-24 ">
                            {{ post?.shortContent }}
                        </p>

                        <div class="mt-8">
                            <a [href]="'/blog/'+post?.url"
                                class="text-indigo-600 hover:text-indigo-800 font-semibold">Czytaj</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-44 mx-auto mt-12" *ngIf="posts?.length > 0">
            <pagination class="flex items-center" [currentPage]="currentPage" [totalPages]="allPages"
                (onPageChange)="onPageChange($event)">
            </pagination>
        </div>
    </div>
</section>
