<div class="flex justify-between">

    <div>
        <h5>
            Pobierz wiele
        </h5>
        <div class="text-gray-600 dark:text-gray-300 mb-2">
            Pobierz wystawione faktury z wybranego miesiąca
        </div>
    </div>
  

    <button mat-icon-button [matDialogClose]="undefined">
        <mat-icon class="text-secondary"  [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>

</div>
<div class="w-full">

    <div class="grid grid-rows-1 md:grid-cols-2 md:gap-x-6">
        <div>
            <mat-form-field class="w-full">
                <mat-label>Okres</mat-label>
                <mat-select [(ngModel)]="selectedRange">
                    <mat-option [value]="option" *ngFor="let option of rangeOptions">{{option.month}}
                        {{option.year}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field class="w-full">
                <mat-label>Typ</mat-label>
                <mat-select [(ngModel)]="selectedType">
                    <mat-option value="">Wszystkie typy</mat-option>
                    <mat-option value="allRecorded">Wszystkie bez proform</mat-option>
                    <mat-option [value]="type" *ngFor="let type of invoiceTypes">{{type.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
</div>


<mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="print()">Pobierz</button>
    <button mat-button (click)="close()">Zamknij</button>
</mat-dialog-actions>