import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { HtmlUtils } from 'fakturnia-shared';
import { Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'select-control',
  templateUrl: './select-control.component.html',
  styleUrls: ['./select-control.component.scss']
})
export class SelectControlComponent implements OnChanges, AfterViewInit, OnDestroy {

  subscriptions: Subscription[] = [];
  

  @Input('fieldId') fieldId = 'select';
  @Input('label') label = '';
  @Input('value') value = '';
  @Input('displayProperty') displayProperty = '';
  @Input('valueProperty') valueProperty = 'id';
  @Input('options') options = []
  @Input('allowSearch') allowSearch = false
  @Input('disabled') disabled = false

  displayValue = 'Wybierz opcję';
  @Output('onSelect') onSelect = new EventEmitter()
  isOpen = false

  constructor(){}

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof changes['value'] != 'undefined') {
      let option = this.options.find(x=>x[this.valueProperty] == changes['value'].currentValue)
      if(typeof option != 'undefined') this.displayValue = option[this.displayProperty]
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(fromEvent(window,'mousedown').subscribe({
      next: (e:any) => {
        if(this.disabled) { 
          this.isOpen = false
          return
        }
        const isChild = HtmlUtils.isChildOfAnyParent(e,this.fieldId)

        if(!isChild) this.isOpen = false
        else this.isOpen = true
      }
    }))
  }

  select(option)
  {
    if(this.disabled) return
    this.onSelect.emit(option[this.valueProperty])
    this.isOpen = false
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

   
}
