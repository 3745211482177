<form [formGroup]="form" style="max-width:500px">
    <div class="row">
        <div class="col-12 mt-2">
            <h4>Sprzedawca</h4>
            <p>Uzupełniaj automatycznie pole sprzedawcy</p>
        </div>
        <div class="col-12 mt-2 col-md-6 pe-md-2">
           Uzupełnianie danych pola sprzedawcy
        </div>
        <div class="col-12 mt-2 col-md-6">
            <select class="form-select" >
                <option value="1">Pobieraj dane z konta</option>
                <option value="2">Własne ustawienia</option>
              </select>
        </div>
        <div>
            <div class="col-12 mt-2">
                <h5>Własne ustawienia</h5>
            </div>
            <div class="col-12 mt-2 col-md-6 pe-md-2">
                Typ konta
            </div>
            <div class="col-12 mt-2 col-md-6">
                <mat-radio-group color="primary" formControlName="type" aria-label="Typ sprzedawcy">
                    <mat-radio-button value="company">Firma</mat-radio-button>
                    <mat-radio-button value="person">Osoba fizyczna</mat-radio-button>
                </mat-radio-group>
    <!--               
                <div class="form-check form-check-inline">
                    <input class="form-check-input" name="seller-type" type="radio" id="company"
                        formControlName="type"
                        value="company">
                    <label class="form-check-label" for="company">Firma</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" name="seller-type" type="radio" id="person"
                        formControlName="type"
                        value="person">
                    <label class="form-check-label" for="person">Osoba fizyczna</label>
                </div> -->
            </div>
            <div class="col-12 mt-2">
                <div class="form-floating">
                    <input type="text" class="form-control text-left" id="name" placeholder="Nazwa firmy"
                    formControlName="name">
                    <label for="name">Nazwa firmy</label>
                </div>
            </div>
    
            <div class="col-12 mt-2 col-md-4 pe-md-2">
                <div class="form-floating">
                    <input type="text" class="form-control text-left" id="firstName" placeholder="Imię"
                    formControlName="firstName">
                    <label for="firstName">Imię</label>
                </div>
            </div>
            <div class="col-12 mt-2 col-md-8">
                <div class="form-floating">
                    <input type="text" class="form-control text-left" id="lastName" placeholder="Nazwisko"
                    formControlName="lastName">
                    <label for="lastName">Nazwisko</label>
                </div>
            </div>
     
            <div class="col-12 mt-2 col-md-6 pe-md-2 ">
                <div class="input-group">
                    <div class="form-floating">
                        <input placeholder="Wprowadź NIP" type="text" class="form-control text-left"
                        formControlName="nip" />
                        <label for="companyName">NIP</label>
                    </div>
                </div>
            </div>
    
            <div class="col-12 mt-2 col-md-6">
                <div class="form-floating">
                    <input name="regon" placeholder="Wprowadź REGON" type="text" class="form-control text-left"
                    formControlName="regon" />
                    <label for="regon">REGON</label>
                </div>
            </div>
      
            <div class="col-12 mt-2 col-md-6 pe-md-2 ">
                <div class="input-group">
                    <div class="form-floating">
                        <input placeholder="Wprowadź BDO" type="text" class="form-control text-left"
                        formControlName="bdo" />
                        <label for="companyName">BDO</label>
                    </div>
                </div>
            </div>
    
            <div class="col-12 mt-2 col-md-6">
                <div class="form-floating">
                    <input name="krs" placeholder="Wprowadź KRS" type="text" class="form-control text-left"
                    formControlName="krs" />
                    <label for="krs">KRS</label>
                </div>
            </div>
    
            <div class="col-12 mt-2 col-md-6 pe-md-2">
                <div class="form-floating">
                    <input placeholder="Wprowadź numer dowodu" type="text" class="form-control text-left"
                    formControlName="idNumber" />
                    <label for="idNumber">Numer dowodu</label>
                </div>
            </div>
    
            <div class="col-12 mt-2 col-md-6">
                <div class="form-floating">
                    <input placeholder="Wprowadź PESEL" type="text" class="form-control text-left"
                    formControlName="pesel"/>
                    <label for="pesel">PESEL</label>
                </div>
            </div>
    
            <div class="col-12 mt-2">
                <div class="form-floating">
                    <input placeholder="Ulica i numer" type="text" class="form-control text-left"
                    formControlName="street" />
                    <label for="street">Ulica i numer</label>
                </div>
            </div>
    
            <div class="col-12 mt-2 col-md-4 pe-md-2">
                <div class="form-floating">
                    <input placeholder="Kod pocztowy" type="text" class="form-control text-left"
                    formControlName="postalCode"/>
                    <label for="postalCode">Kod pocztowy</label>
                </div>
            </div>
            
            <div class="col-12 mt-2 col-md-8">
                <div class="form-floating">
                    <input placeholder="Miejscowość" type="text" class="form-control text-left"
                    formControlName="city" />
                    <label for="city">Miejscowość</label>
                </div>
            </div>
           
    
            <div class="col-12 mt-2">
                <div class="form-floating">
                    <input placeholder="Kraj" type="text" class="form-control text-left"
                    formControlName="country" />
                    <label for="country">Kraj</label>
                </div>
            </div>
    
            <div class="col-12 mt-3">
                <button [disabled]="form.invalid" class="f-button ms-auto md" (click)="saveChanges()">Zapisz zmiany</button>
            </div>
        </div>
       
    </div>
</form>