<h1 style="display:none;">Faktura VAT - Co to jest? Bezpłatny program do wystawiania faktur przez przeglądarkę Fakturnia.pl</h1>
<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
        <div class="grid grid-cols-2 gap-8">
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto tracking-wide">
                <h2>
                    <strong>Faktura VAT</strong> - Co to jest?<br />
                    
                </h2>
                <p class="mt-2">
                    Faktura VAT to dokument potwierdzający zawarcie transakcji kupna lub sprzedaży. Jej wystawienie jest wymagane dla firm, które są podatnikami VAT, gdy drugą stroną transakcji jest inna firma. Istotne jest, aby faktura była wystawiona w dwóch egzemplarzach i może przyjąć formę papierową lub elektroniczną
                </p>

                <h2 class="mt-5">
                    Co powinna zawierać <strong>Faktura VAT</strong><br />
                    
                </h2>
                <div class="mt-2">
                    Dane umieszczone na fakturze nie zostały wybrane przypadkowo, lecz są regulowane przez <strong>artykuł 106e ust. 1 ustawy o VAT</strong>. Zgodnie z aktualnymi przepisami, prawidłowo wystawiona faktura VAT musi zawierać następujące elementy:

                    <ul class="mt-5 list-disc">
                        <li>Datę wystawienia</li>
                        <li>Kolejny numer nadany w ramach serii, <strong>identyfikujący fakturę</strong></li>
                        <li>Imiona i nazwiska lub nazwy oraz adresy podatnika i nabywcy towarów lub usług</li>
                        <li><strong>Numery identyfikacji podatkowej</strong> zwany prościej jako 'NIP', zarówno sprzedawcy, jak i nabywcy towarów lub usług</li>
                        <li>Datę dokonania dostawy towarów, wykonania usługi lub datę otrzymania zapłaty (jeśli różni się od daty wystawienia faktury)</li>
                        <li>Nazwę (rodzaj) towaru lub usługi, oraz rabat - jeżeli został przyznany</li>
                        <li>Miary i ilości dostarczonych towarów lub zakres wykonanych usług</li>
                        <li>Cena jednostkowa <strong> netto</strong></li>
                        <li>Wartość sprzedaży <strong>netto</strong></li>
                        <li>Stawkę podatku lub stawkę podatku od wartości dodanej w przypadku korzystania z procedur szczególnych</li>
                        <li>Sumę wartości sprzedaży netto <strong>z podziałem na poszczególne stawki</strong> podatku oraz sprzedaż zwolnioną od podatku</li>
                        <li>Kwotę podatku od sumy wartości sprzedaży netto z podziałem na poszczególne stawki podatku</li>
                        <li>Kwotę należności ogółem</li>
                    </ul>
    
                    <div class="max-w-120 w-full mx-auto">
                        <h4 class="text-4xl mt-4">Ciekawostka</h4>
                        <div class="p-4 mb-4 rounded-lg bg-primary text-white border-l-4 dark:border-primary-300 border-primary-900">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <i class="ti ti-question-mark text-white text-3xl"></i>
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-xl font-medium">Czy wiesz, że:</h3>
                                    <div class="mt-2 text-base">
                                        Według art. 106e ust. 1 ustawy o VAT, faktura <strong>nie wymaga umieszczenia</strong> kwoty do zapłaty słownie. Mimo to zdecydowana większość producentów oprogramowania nadal daje taką możliwość swoim użytkownikom.
                                    </div>
        
                                </div>
        
                            </div>
                        </div>
                    </div>

                </div>
               
            </div>
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
                <picture>
                    <img class="w-full" src="/assets/images/faktura_vat_fakturnia_pl.png" alt="faktura vat wygenerowana w programie do faktur fakturnia.pl">
                </picture>
                <a [routerLink]="'/konto/dokumenty/wystaw'">Wystaw taki dokument w naszym programie <i class="ti ti-arrow-narrow-right ms-2"></i></a>
            </div>
        </div>
    </div>
</section>


<read-more [path]="'/funkcjonalnosci-aplikacji/faktura-koncowa'" [text]="'Faktura końcowa'"></read-more>
