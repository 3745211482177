import { Component, Input, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'shared/services/auth.service';
import { emailValidator } from 'shared/validators/email.validator';
import { matchFieldsValidator } from 'shared/validators/match-fields.validator';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  @Input('redirect') redirect = true

  registerForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, emailValidator()]),
    emailAddressRepeat: new FormControl('', [Validators.required, emailValidator()]),
    password: new FormControl('',[ Validators.required, Validators.minLength(8)]),
    rules: new FormControl(true, [Validators.requiredTrue])
  }, { validators: matchFieldsValidator('emailAddress', 'emailAddressRepeat')  })
   
  showPassword = true;
  constructor(
    private authService: AuthService,
    ) {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  register() {

    if(!this.registerForm.valid) return;
    
    this.authService.loginOrRegister(
      "register",
      this.registerForm.controls.emailAddress.value,
      this.registerForm.controls.password.value,
      this.redirect
    )
    
  }
}
