import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DbProduct } from 'fakturnia-shared';
import { Invoice } from 'fakturnia-shared';
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { Product } from 'fakturnia-shared';
import { Settings } from 'fakturnia-shared';
import { FakturaVAT } from 'fakturnia-shared';
import { Subscription, take } from 'rxjs';
import { AppData } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { DatabaseService } from 'shared/services/database.service';
import { DialogService } from 'shared/services/dialog.service';
import { DATA_INJECTION_TOKEN, PanelService } from 'shared/services/panel.service';
import { SettingsService } from 'shared/services/settings.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { ToastService } from 'shared/services/toast.service';
import { Utils } from 'fakturnia-shared';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnDestroy {

  subscriptions: Subscription[] = [];
  mode: 'create' | 'update' = 'create'
  appearance: 'outline' | 'fill' = "outline";

  calculationProduct = new Product(
    {
      _id: '',
      name: '',
      gtu: '',
      unitOfMeasure:'szt.',
      quantity:1,
      netPrice:0,
      vatRate: 23,
      parent: new Invoice(new FakturaVAT)
    }
  )

  gtu = AppData.GTU
  measurementUnits = AppData.MeasurementUnits;
  vatRates = AppData.VatRates;
  productForm = new FormGroup({
    _id: new FormControl<string | null>(null),
    name: new FormControl('', [Validators.required]),
    gtu: new FormControl(''),
    unitOfMeasure: new FormControl('szt.'),
    quantity: new FormControl(1),
    netPrice:   new FormControl(0),
    netValue: new FormControl(0),
    grossPrice: new FormControl(0),
    grossValue: new FormControl(0),
    amountOfVat: new FormControl(0),
    vatRate: new FormControl<string | number>(23),
  })

  constructor(
    private panelService:PanelService,
    private apiService:ApiService,
    private databaseService:DatabaseService,
    private _synchronizationService:SynchronizationService,
    private dialogService:DialogService,
    private settingsService:SettingsService,
    private _toastService:ToastService,
    @Inject(DATA_INJECTION_TOKEN) public data: any,
  )
  {
    if(typeof data.mode == 'undefined') throw new Error("Invalid mode. Provide view type.")
    
    this.subscriptions.push(this.settingsService.getUserSettings().subscribe(
      {
        next: (data) => {
          if(data)  {
            if(!Utils.isNullOrEmpty(data.settings) && data.settings instanceof Settings) {
              this.vatRates = AppData.VatRates.filter(rate=> (<Settings>data.settings).vatRates.includes(rate.value));
            }
          }
        }
      }
    ))
    this.subscriptions.push(this.productForm.controls['quantity'].valueChanges.subscribe(
      (data:any)=> {
        this.calculationProduct.setQuantity(data);
        this.countAll()
      }
    ))


    this.subscriptions.push(this.productForm.controls['vatRate'].valueChanges.subscribe(
      (data:any)=> {
        this.calculationProduct.setTax(data);
        this.countAll()
      }
    ))

    this.mode = data.mode;
    if(this.mode == 'update') { 
      this.productForm.controls['_id'].addValidators(Validators.required)
      this.productForm.controls['_id'].updateValueAndValidity()

      if(typeof data.product != 'undefined') this.loadForm(data.product);
    }

    else if(this.mode == 'create')
    {
      this.productForm.controls['_id'].removeValidators(Validators.required)
      this.productForm.controls['_id'].updateValueAndValidity()
    }

   
  }

  checkVatRates()
  {
    if(Utils.isNullOrEmpty(this.productForm.controls['vatRate'].value)) return;
    if(!this.vatRates.find(x=>x.value == this.productForm.controls['vatRate'].value)){
      const vatRate = AppData.VatRates.find(x=>x.value == this.productForm.controls['vatRate'].value)
      if(typeof vatRate != 'undefined') this.vatRates.push(vatRate)
    } 
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
  
  countAll()
  {
    this.productForm.controls['netPrice'].setValue(this.calculationProduct.netPrice,{emitEvent:false});
    this.productForm.controls['netValue'].setValue(this.calculationProduct.netValue,{emitEvent:false});
    this.productForm.controls['grossPrice'].setValue(this.calculationProduct.netPrice + parseFloat((this.calculationProduct.amountOfVat / this.calculationProduct.quantity).toFixed(2)),{emitEvent:false});
    this.productForm.controls['grossValue'].setValue(this.calculationProduct.grossValue,{emitEvent:false});
    this.productForm.controls['amountOfVat'].setValue(this.calculationProduct.amountOfVat,{emitEvent:false});
  }

  loadForm(product:DbProduct)
  {
    this.productForm.controls['_id'].setValue(product._id)
    this.productForm.controls['name'].setValue(product.name, { emitEvent: false})
    this.productForm.controls['gtu'].setValue(product.gtu, { emitEvent: false})
    this.productForm.controls['unitOfMeasure'].setValue(product.unitOfMeasure, { emitEvent: false})
    this.productForm.controls['quantity'].setValue(product.quantity, { emitEvent: false})
    this.productForm.controls['netPrice'].setValue(Number(product.netPrice?.toFixed(2)), { emitEvent: false})
    this.productForm.controls['netValue'].setValue(Number(product.netValue?.toFixed(2)), { emitEvent: false})
    this.productForm.controls['grossValue'].setValue(Number(product.grossValue?.toFixed(2)), { emitEvent: false})
    this.productForm.controls['amountOfVat'].setValue(Number(product.amountOfVat?.toFixed(2)), { emitEvent: false})
    this.productForm.controls['vatRate'].setValue(Number(product.vatRate), { emitEvent: false})

    this.checkVatRates();
  }

  deleteProduct()
  {
    if(this.mode == 'update')
    {
      this.subscriptions.push(this.dialogService.confirmDialog({
        title: "Usuwanie produktu",
        message: `Potwierdzasz usunięcie produktu: ${this.productForm.controls['name'].value}`,
        type: "danger",
        confirmText: "Usuń"
      }).subscribe(
        {
          next: (data)=>{

            if(data == true)
            {
              this.subscriptions.push(this.apiService.deleteProduct(this.productForm.controls['_id'].value).subscribe({
                next: (response:APIResponse)=>{
                  this.databaseService.processProducts([response.data])
                  this._synchronizationService.synchronize()
                  this._toastService.showToast(new NotificationData({
                    title: 'Informacja',
                    status: 'info',
                    message: 'Produkt został usunięty.',
                  }))
                  this.close();

                },
                error: (err)=>{
                  this._toastService.showToast(new NotificationData({
                    title: 'Wystąpił błąd',
                    status: 'warning',
                    message: 'Nie udało się usunąć tego produktu.',
                  }))
                }
              }))
          }}
        }
      ))
    }
  }
  saveProduct()
  {
    if(!this.productForm.valid) { return}

    const requestData = cloneDeep(this.productForm.value)
    if(!requestData) return
    // Convert to 2 decimal numbers
    requestData.netPrice    = Number(requestData?.netPrice?.toFixed(2))
    requestData.netValue    = Number(requestData?.netValue?.toFixed(2))
    requestData.grossPrice  = Number(requestData?.grossPrice?.toFixed(2))
    requestData.grossValue  = Number(requestData?.grossValue?.toFixed(2))
    requestData.amountOfVat = Number(requestData?.amountOfVat?.toFixed(2))
    
    this.apiService.saveProduct(this.productForm.value).pipe(take(1)).subscribe(
      {
        next: (response:APIResponse) => {
          this.databaseService.processProducts([response.data])
          this._synchronizationService.synchronize()
          this.close();
          this._toastService.showToast(new NotificationData({
            title: 'Informacja',
            status: 'info',
            message: 'Dane produktu zostały zapisane.',
          }))
        },
        error: (err) => {
          this._toastService.showToast(new NotificationData({
            title: 'Wystąpił błąd',
            status: 'warning',
            message: 'Nie udało się zapisać danych produktu.',
          }))
        }
      }
    )

  }
  close()
  {
    this.panelService.close();
  }
}
