<div class="w-full max-w-320 mx-auto p-4 lg:p-8">

  <div class="mb-2 sm:mb-0 flex justify-end space-x-2">

    <button mat-flat-button color="primary" [routerLink]="'/konto/dokumenty/wystaw'">
      <mat-icon [svgIcon]="'tabler_outline:plus'"></mat-icon>
      <span class="ml-2">Wystaw fakturę</span>
    </button>

    <button (click)="showPrintManyDialog()" mat-flat-button
      matTooltip="Pobierz lub drukuj faktury z wybranego miesiąca">
      <mat-icon [svgIcon]="'tabler_outline:file-arrow-right'"></mat-icon>
      <span class="ml-2">Pobierz wiele</span>
    </button>

  </div>

  <div>
    <invoice-table *ngIf="showDrafts" class="mb-12 block" title="Szkice" [filters]="draftsFilters"
      [showMarkAsPaidButton]="true" [showIssueSimilarButton]="true" [showAddNewButton]="true">
    </invoice-table>

    <invoice-table class="mb-12 block" title="Wystawione" [filters]="allFilters" [showMarkAsTemplateButton]="true"
      [showIssueSimilarButton]="true" [showAddNewButton]="true">
    </invoice-table>
  </div>
</div>