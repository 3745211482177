<h1 style="display:none;">Bezpłatny program do wystawiania faktur przez przeglądarkę. Fakturnia.pl to narzędzie, które ułatwi Ci generowania dokumentów, usprawniając Twoją pracę.</h1>

<div class="max-w-200 mx-8 md:mx-auto bg-card rounded-xl my-12 lg:my-24 tracking-wide p-8">
    <h2>Polityka prywatności</h2>
            
    <p><strong>§ 1</strong></p>
    <p>Celem Polityki bezpieczeństwa przetwarzania danych osobowych, zwanej dalej „Polityką prywatności” w THERRAX IT SOLUTIONS Jerzy Kołakowski z siedzibą w Ustroniu Morskim, zwanym dalej „Operatorem serwisu”, jest uzyskanie optymalnego i zgodnego z wymogami obowiązujących aktów prawnych, sposobu przetwarzania informacji zawierających dane osobowe.</p>
    
    <p><strong>§ 2</strong></p>
    <p>Polityka bezpieczeństwa została opracowana w oparciu o wymagania zawarte w:</p>
    <ol class="list-decimal">
        <li>Rozporządzeniu Parlamentu Europejskiego i Rady /UE/ 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE /Dz. Urz. UE.L nr 119, str.1/ - dalej zwaną RODO</li>
        <li>Ustawie o ochronie danych osobowych, dalej zwaną Ustawą.</li>
    </ol>
    
    <p><strong>§ 3</strong></p>
    <p>Ochrona danych osobowych realizowana jest poprzez zabezpieczenia fizyczne, organizacyjne, oprogramowanie systemowe, aplikacje oraz użytkowników proporcjonalne i adekwatne do ryzyka naruszenia bezpieczeństwa danych osobowych przetwarzanych w ramach prowadzonej działalności.</p>
    
    <p><strong>§ 4</strong></p>
    <p>Przez użyte w Polityce prywatności określenia należy rozumieć:</p>
    <ol class="list-decimal">
        <li>Administrator danych osobowych – osoba fizyczna lub prawna, organ publiczny, jednostka lub inny podmiot, który samodzielnie lub wspólnie z innymi ustala cele i sposoby przetwarzania danych osobowych, którym w zakresie działaności Serwisu może być Operator Serwisu lub Użytkownik,</li>
        <li>Ustawa – ustawa o ochronie danych osobowych,</li>
        <li>RODO – rozporządzenie Parlamentu Europejskiego i Rady /UE/ 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE /Dz. Urz. UE.L nr 119, str. 1/,</li>
        <li>Dane osobowe – wszelkie informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej,</li>
        <li>Zbiór danych osobowych – uporządkowany zestaw danych osobowych dostępnych według określonych kryteriów,</li>
        <li>Przetwarzanie danych – operacja lub zestaw operacji wykonywanych na danych osobowych w sposób zautomatyzowany lub niezautomatyzowany, takich jak zbieranie, utrwalanie, przechowywanie, opracowywanie, łączenie, przesyłanie, zmienianie, udostępnianie i usuwanie, niszczenie, itd.,</li>
        <li>System informatyczny – zespół współpracujących ze sobą urządzeń, programów, procedur przetwarzania informacji i narzędzi programowych zastosowanych w celu przetwarzania danych osobowych,</li>
        <li>System tradycyjny – zespół procedur organizacyjnych, związanych z mechanicznym przetwarzaniem informacji oraz wyposażenie i środki trwałe wykorzystywane w celu przetwarzania danych osobowych na papierze,</li>
        <li>Zabezpieczenie danych w systemie informatycznym – wdrożenie i eksploatacja stosownych środków technicznych i organizacyjnych zapewniających ochronę danych przed ich nieuprawnionym przetwarzaniem,</li>
        <li>Administrator systemu informatycznego – osoba lub osoby, upoważnione przez administratora danych osobowych do administrowania i zarządzania systemami informatycznymi,</li>
        <li>Odbiorca – osoba fizyczna lub prawna, organ publiczny, jednostka lub inny podmiot, któremu ujawnia się dane osobowe w oparciu m. in. o umowę powierzenia,</li>
        <li>Strona trzecia – ciąg znaków literowych, cyfrowych lub innych, jednoznacznie identyfikujący osobę upoważnioną do przetwarzania danych osobowych w systemie informatycznym,</li>
        <li>Identyfikator użytkownika (login) – ustawa o ochronie danych osobowych,</li>
        <li>Hasło – ciąg znaków literowych, cyfrowych lub innych, przypisany do identyfikatora użytkownika, znany jedynie osobie uprawnionej do pracy w systemie informatycznym.</li>
    </ol>
    
    <p><strong>§ 5</strong></p>
    <ol class="list-decimal">
        <li>
            <p>Utrzymanie bezpieczeństwa przetwarzanych danych osobowych u Operatora Serwisu rozumiane jest jako zapewnienie ich poufności, integralności, rozliczalności oraz dostępności na odpowiednim poziomie. Miarą bezpieczeństwa jest akceptowalna wielkość ryzyka związanego z ochroną danych osobowych.</p>
        </li>
        <li>
            <p>Zastosowane zabezpieczenia mają służyć osiągnięciu powyższych celów i zapewnić:</p>
            <ul>
                <li>a) poufność danych <br/> rozumianą jako właściwość zapewniającą, że dane nie są udostępniane nieupoważnionym osobom</li>
                <li>b) integralność danych – rozumianą jako właściwość zapewniającą, że dane osobowe nie zostały zmienione lub zniszczone w sposób nieautoryzowany</li>
                <li>c) rozliczalność danych – rozumianą jako właściwość zapewniającą, że działania osoby mogą być przypisane w sposób jednoznaczny tylko tej osobie</li>
                <li>d) integralność systemu – rozumianą jako nienaruszalność systemu, niemożność jakiejkolwiek manipulacji, zarówno zamierzonej, jak i przypadkowej</li>
                <li>e) dostępność informacji – rozumianą jako zapewnienie, że osoby upoważnione mają dostęp do informacji i związanych z nią zasobów wtedy, gdy jest to potrzebne</li>
                <li>f) zarządzanie ryzykiem – rozumiane jako proces identyfikowania, kontrolowania i minimalizowania lub eliminowania ryzyka dotyczącego bezpieczeństwa, które może dotyczyć systemów informacyjnych służących do przetwarzania danych osobowych.</li>
            </ul>
        </li>
        <li><p>Operator Serwisu przetwarza dane osobowe Użytkowników i ich kontrahentów w zakresie w jakim pozostaje Administratorem tych danych lub jedynie ich Procesorem.</p></li>
        <li><p>Informacje te są przetwarzane w postaci informatycznej.</p></li>
        <li><p>Polityka prywatności zawiera uregulowania dotyczące wprowadzonych zabezpieczeń technicznych i organizacyjnych zapewniających ochronę przetwarzanych danych osobowych.</p></li>
        <li>
            <p>Innymi dokumentami regulującymi ochronę danych osobowych u Operatora Serwisu są:</p>
            <ul>
                <li>a) ewidencja osób upoważnionych do przetwarzania danych osobowych,</li>
                <li>b) rejestr czynności przetwarzania danych osobowych,</li>
                <li>c) Regulamin serwisu fakturnia.pl.</li>
            </ul>
        </li>
    </ol>

    <p><strong>§ 6</strong></p>
    <ol class="list-decimal">
        <li>
            <p>Politykę bezpieczeństwa stosuje się w szczególności do:</p>
            <ul>
                <li>a) danych osobowych przetwarzanych w serwisie fakturnia.pl,</li>
                <li>b) wszystkich informacji dotyczących danych,</li>
                <li>c) odbiorców danych osobowych, którym przekazano dane osobowe do przetwarzania w oparciu o umowy powierzenia,</li>
                <li>d) informacji dotyczących zabezpieczenia danych osobowych, w tym w szczególności nazw kont i haseł w systemach przetwarzania danych osobowych,</li>
                <li>e) rejestru osób trzecich /np. pracownicy/ mających upoważnienia administratora danych osobowych do przetwarzania danych osobowych,</li>
                <li>f) innych dokumentów zawierających dane osobowe.</li>
            </ul>
        </li>
    </ol>

    <p><strong>§ 7</strong></p>
    <ol class="list-decimal">
        <li>
            <p>Zakresy ochrony danych osobowych określone przez Politykę prywatności oraz inne z nią związane dokumenty mają zastosowanie do:</p>
            <ul>
                <li>a) wszystkich istniejących, wdrażanych obecnie lub w przyszłości systemów informatycznych oraz papierowych, w których przetwarzane są dane osobowe podlegające ochronie,</li>
                <li>b) wszystkich lokalizacji – budynków i pomieszczeń, w których są lub będą przetwarzane informacje podlegające ochronie,</li>
                <li>c) wszystkich pracowników, stażystów i innych osób mających dostęp do informacji podlegających ochronie.</li>
            </ul>
        </li>
        <li>
            <p>Do stosowania zasad określonych przez Politykę prywatności oraz inne z nią związane dokumenty zobowiązani są wszystkie osoby uczestniczące w procesie wykonywania usług przez Serwis oraz inne osoby mające dostęp do danych osobowych podlegających ochronie.</p>
        </li>
    </ol>

    <p><strong>§ 8</strong></p>
    <ol class="list-decimal">
        <li>
            <p>Operatorem Serwisu <a href="https://www.fakturnia.pl">www.fakturnia.pl</a> jest firma THERRAX IT SOLUTIONS Jerzy Kołakowski z siedzibą w Ustroniu Morskim, ul. Spokojna 3/4, 78-111 Ustronie Morskie, wpisana po numerem NIP 6711852031, zwana dalej Operatorem.</p>
        </li>
        <li>
            <p>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w następujący sposób:</p>
            <ul>
                <li>a) Poprzez dobrowolnie wprowadzone w formularzach informacje</li>
                <li>b) Poprzez zapisywanie w urządzeniach końcowych pliki cookie (tzw. „ciasteczka”).</li>
                <li>c) Poprzez zapisywanie technicznych logów na poziomie serwera www.</li>
                <li>d) Poprzez zapisywanie logów dokumentujących procesy rejestracji usług.</li>
                <li>e) Poprzez zapisywanie logów dokumentujących sposób korzystania z Panelu Klienta.</li>
            </ul>
        </li>
    </ol>

    <p><strong>§ 9</strong></p>
    <ol class="list-decimal">
        <li>
            <p>Serwis zbiera informacje podane dobrowolnie przez użytkownika.</p>
        </li>
        <li>
            <p>Serwis może zapisać ponadto informacje o parametrach połączenia (oznaczenie czasu, adres IP)</p>
        </li>
        <li>
            <p>Serwis może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.</p>
        </li>
        <li>
            <p>Dane podane w formularzu nie są udostępniane podmiotom trzecim inaczej, niż za zgodą użytkownika.</p>
        </li>
        <li>
            <p>Dane podane w formularzu mogą stanowić zbiór potencjalnych klientów, zarejestrowany przez Operatora Serwisu w RCP.</p>
        </li>
        <li>
            <p>Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego.</p>
        </li>
        <li>
            <p>Dane podane w formularzach mogą być przekazane podmiotom technicznie realizującym niektóre usługi – w szczególności dotyczy to przekazywania informacji dla usługi pobierania numerów NIP (Centralna Ewidencja I Informacja o Działalności Gospodarczej j.b.r – CEIDG), lub też podmiotów, z którymi Operator Serwisu w tym zakresie współpracuje.</p>
        </li>
        <li>
            <p>Proces przekazywania danych podmiotom wskazanym w pkt 7 jest elementem zbioru prowadzonego w ramach RCP Procesor, o którym stanowi pkt 10.5 Regulaminu.</p>
        </li>
    </ol>

    <p><strong>§ 10</strong></p>
    <ol class="list-decimal">
        <li>Serwis korzysta z plików cookies.</li>
        <li>
            <p>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>
        </li>
        <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</li>
        <li>
            <p>Pliki cookies wykorzystywane są w następujących celach:</p>
            <ul>
                <li>a) tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości,</li>
                <li>b) utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła,</li>
                <li>c) określania profilu użytkownika w celu wyświetlania mu dopasowanych materiałów w sieciach reklamowych, w szczególności sieci Google.</li>
            </ul>
        </li>
        <li>
            <p>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</p>
        </li>
        <li>
            <p>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</p>
        </li>
        <li>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</li>
        <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów.</li>
        <li>
            <p>Zalecamy przeczytanie polityki ochrony prywatności tych firm, aby poznać zasady korzystania z plików cookie wykorzystywane w statystykach: Polityka ochrony prywatności Google Analytics.</p>
            <p>Pliki cookie mogą być wykorzystane przez sieci reklamowe, w szczególności sieć Google, do wyświetlenia reklam dopasowanych do sposobu, w jaki użytkownik korzysta z Serwisu. W tym celu mogą zachować informację o ścieżce nawigacji użytkownika lub czasie pozostawania na danej stronie.</p>
        </li>
    </ol>

    <p><strong>§ 11</strong></p>
    <ol class="list-decimal">
        <li>Informacje o niektórych zachowaniach użytkowników podlegają logowaniu w warstwie serwerowej. Dane te są wykorzystywane wyłącznie w celu administrowania serwisem oraz w celu zapewnienia jak najbardziej sprawnej obsługi świadczonych usług.</li>
        <li>
            <p>Przeglądane zasoby identyfikowane są poprzez adresy URL. Ponadto zapisowi mogą podlegać:</p>
            <ul>
                <li>a) czas nadejścia zapytania,</li>
                <li>b) czas wysłania odpowiedzi,</li>
                <li>c) nazwę stacji klienta – identyfikacja realizowana przez protokół HTTP,</li>
                <li>d) informacje o błędach jakie nastąpiły przy realizacji transakcji HTTP,</li>
                <li>e) adres URL strony poprzednio odwiedzanej przez użytkownika (referer link) – w przypadku gdy przejście do Serwisu nastąpiło przez odnośnik,</li>
                <li>f) informacje o przeglądarce użytkownika,</li>
                <li>g) informacje o adresie IP.</li>
            </ul>
        </li>
        <li>Dane powyższe nie są kojarzone z konkretnymi osobami przeglądającymi strony.</li>
        <li>Dane powyższe nie są wykorzystywane jedynie dla celów administrowania serwerem.</li>
    </ol>

    <p><strong>§ 12</strong></p>
    <ol class="list-decimal">
        <li>Logi panelu zapisują informacje powiązane z identyfikatorem użytkownika.</li>
        <li>
            <p>Zapisaniu mogą podlegać informacje takie same, jak w wypadku logów serwera, a także dodatkowe informacje, charakterystyczne dla danego procesu rejestracji lub obsługi panelu, jak na przykład informacje o wywoływanych funkcjach czy też wybieranych parametrach.</p>
        </li>
        <li>Zapisaniu wraz z powyższymi podlegać mogą wszelkie dane wprowadzone przez użytkowników w toku korzystania z panelu.</li>
        <li>Logi są wykorzystywane w celach statystycznych oraz diagnostycznych, a także w procesach obsługi Klientów, korzystających z Serwisu.</li>
    </ol>
   
    <p><strong>§ 13</strong></p>
    <ol class="list-decimal">
        <li>
            <p>Dane podlegają udostępnieniu podmiotom zewnętrznym wyłącznie w granicach prawnie dozwolonych. Proces przetwarzania danych osobowych, w tym udostępniania podmiotom zewnętrznym odnotowywany jest w rejestrze czynności przetwarzania (RCP), o którym stanowi Regulamin.</p>
        </li>
        <li>Dane umożliwiające identyfikację osoby fizycznej są udostępniane wyłączenie za zgodą tej osoby.</li>
        <li>Operator może mieć obowiązek udzielania informacji zebranych przez Serwis upoważnionym organom na podstawie zgodnych z prawem żądań w zakresie wynikającym z żądania.</li>
    </ol>

    <p><strong>§ 14</strong></p>
    <ol class="list-decimal">
        <li>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.</li>
    </ol>
</div>
