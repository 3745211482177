import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { UserSettings } from "fakturnia-shared";
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { SettingsService } from 'shared/services/settings.service';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.scss']
})
export class SignaturesComponent implements OnDestroy {

  subscriptions: Subscription[] = [];
  signatures = new FormControl(true,[Validators.required]);
  constructor(
    private apiService:ApiService,
    private settingsService:SettingsService,
    private _toastService:ToastService
  ) {
    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data: UserSettings)=> {
        this.signatures.setValue(data.settings.autocomplete.signatures, { emitEvent: false})
      }
    }))
    this.subscriptions.push(this.signatures.valueChanges.subscribe(
      (data)=> {
      this.toggleSignatures(data)
    }))
  }

  toggleSignatures(checked) {
    this.apiService.toggleSignatures(checked)
    .pipe(take(1))
    .subscribe({
      next: (data:APIResponse)=>{
        if(data.success) {
          this._toastService.showToast(new NotificationData({
            title: 'Informacja',
            status: checked ? 'success' : 'info',
            message: checked ? 'Włączono podpisy.' : 'Wyłączono podpisy.',
          }))
          this.settingsService.refreshSettings()
        } else this._toastService.warning(data.message)
      }
    })
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
}

