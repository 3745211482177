import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { Subscription, tap } from 'rxjs';
import { PaginationComponent } from 'shared/components/pagination/pagination.component';
import { ApiService } from 'shared/services/api.service';

@Component({
  standalone: true,
  selector: 'app-manage-blog',
  imports: [CommonModule, RouterLink, MatDividerModule, PaginationComponent],
  templateUrl: './manage-blog.component.html',
  styleUrls: ['./manage-blog.component.scss']
})
export class ManageBlogComponent implements OnInit, OnDestroy {
  


  

  posts = [];
  subscriptions:Subscription[] = [];

  // Pagination
  defaultPageSize = 10;
  allPages = 0;
  currentPage = 1;

  count = 0;

  constructor(
    private apiService:ApiService
  )
  {

  }
  ngOnInit(): void {
    this.browsePosts();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  browsePosts()
  {
    const sub = this.apiService.browsePostsAsAdmin(this.currentPage).subscribe(
      {
        next: (data:any)=> {
          if(data != null)
          {
            this.count = data.count;
            this.allPages = data.totalPages;
            this.currentPage = data.currentPage;
            this.posts = data.items;
          }
          sub.unsubscribe();
          
        },
        error: (data) => {
          sub.unsubscribe();
        }
      }
    );

  }

  onPageChange(page)
  {
    this.currentPage = page;
    this.browsePosts();
  }

  updatePagination()
  {
    this.allPages = Math.ceil(this.count / this.defaultPageSize);
    this.currentPage = 1;
  }

  search(event)
  {
    console.log(event)
  }
}
