import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, finalize, take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { AuthService } from 'shared/services/auth.service';
import { ToastService } from 'shared/services/toast.service';
import { Utils } from 'fakturnia-shared';
import { emailValidator } from 'shared/validators/email.validator';
import { matchFieldsValidator } from 'shared/validators/match-fields.validator';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent  implements OnDestroy{
 
  private _subscriptions: Subscription[] = [];

  restoreForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, emailValidator()]),
  })

  showPassword = false
  showRepeatPassword = false
  
  newPasswordForm = new FormGroup({
    password: new FormControl('' ,[ Validators.required, Validators.minLength(8)]),
    repeatPassword: new FormControl('', [ Validators.required, Validators.minLength(8)]),
  },{ validators: matchFieldsValidator('password', 'repeatPassword')  })

  public step: 'restore-account' | 'set-new-password' = 'restore-account'
  public isProcessing = false
  private _token = null
  private _emailAddress = null

  constructor(
    private _route:ActivatedRoute,
    private _authService:AuthService,
    private _toastService:ToastService,
    private _router:Router
    ) {
      this._subscriptions.push(this._route.params.subscribe(
        (data:any)=> {
          if(data != null)
          {
            if(!Utils.isNullOrEmpty(data.token) && !Utils.isNullOrEmpty(data.emailAddress))
            {
              if(data.id == 'new') return
              this._token = data.token
              this._emailAddress = data.emailAddress
              this.step = 'set-new-password'
             
            }
          }
        }
      ))
  }

  restore() {
    if(this.restoreForm.invalid) return
    this.isProcessing = true
    this._authService.restoreAccount(this.restoreForm.value)
    .pipe(finalize(()=> {
      this.isProcessing = false
    }))
    .pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        if(response.success) {
          this._toastService.showToast(new NotificationData({
            type: 'success',
            title: 'Wysłano link z przypomnieniem',
            message: "Jeśli istnieje konto powiązane z danym adresem e-mail, wkrótce otrzymasz wiadomość na swoją skrzynkę pocztową zawierającą instrukcje pozwalające na odzyskanie dostępu do konta.",
            timeToClose: 0,
            sound: true
          }))

          this.restoreForm.reset()
        }
      }
    })
  }

  setNewPassword() {
    if(this._token == null) return
    if(this._emailAddress == null) return
    if(this.newPasswordForm.invalid) return
    this._authService.setNewPassword({
      token: this._token,
      emailAddress: this._emailAddress,
      password: this.newPasswordForm.controls.password.value
    })
    .pipe(finalize(()=> {
      this.isProcessing = false
    }))
    .pipe(take(1)).subscribe({
      next: (response:APIResponse) => {


        if(response.success) {

          this.newPasswordForm.reset()
          
          this._router.navigateByUrl('/logowanie')

          this._toastService.showToast(new NotificationData({
            status: 'success',
            title: 'Hasło zmienione',
            message: "Zaloguj się na swoje konto, przy pomocy nowego hasła.",
            timeToClose: 0,
            sound: true
          }))
          return
        }

        this._toastService.showToast(new NotificationData({
          status: 'warning',
          title: 'Token wygasł.',
          message: "Nie udało się przetworzyć Twojego żądania.",
          timeToClose: 0,
          sound: true
        }))
       
      }
    })
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
