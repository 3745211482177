<div class="full-size-mobile">

    <ng-container *ngIf="activeStep == 1">
      
      <div class="p-3">
        <h2>Cześć 👋!</h2><br/>
        <div class="text-gray-600 dark:text-gray-300 ">
          Cieszymy się, że zdecydowałeś się założyć swoje konto.<br/>
          Podaj nam swój numer NIP, a my przygotujemy dla Ciebie aplikację.
        </div>
    

          <mat-form-field class="w-full my-4" subscriptSizing="dynamic">
            <mat-label>NIP Twojej firmy</mat-label>
            <input #nipControl [formControl]="firstForm.controls.nip" numericOnly matInput placeholder="Podaj NIP swojej firmy" value="">
            <mat-error *ngIf="firstForm.controls.nip.hasError('required')">Pole jest wymagane</mat-error>
            <mat-error *ngIf="firstForm.controls.nip.hasError('minlength')">Min. 9 znaków.</mat-error>
            <mat-error *ngIf="firstForm.controls.nip.hasError('notfound')">Nie znaleziono firmy o podanym numerze NIP</mat-error>
          </mat-form-field>
        
          <br />

          <div class="text-center">
            <button  mat-flat-button color="primary" (click)="search()">
              <span *ngIf="!loading">Wyszukaj automatycznie dane firmy</span>
              <span *ngIf="loading">Szukamy danych Twojej firmy</span>
              <mat-icon class="icn-spinner" *ngIf="loading">cached</mat-icon>
            </button>
           
            <div class="text-center my-2">
              lub
            </div>
  
            <button mat-button color="primary" [disabled]="loading" (click)=" activeStep = 2">
              Wypełnij dane firmy ręcznie
            </button>
          </div>
          


      </div>
    </ng-container>

    <ng-container *ngIf="activeStep == 2">
      <div class="h5 text-center text-color-black">Dokończ rejestrację</div>

      <mat-stepper orientation="vertical" linear="true" (selectionChange)="onStepSelect($event)" [selectedIndex]="selectedStepIndex">
        <mat-step [stepControl]="firstForm">
          <ng-template matStepLabel>Dane firmy</ng-template>
          <ng-template matStepperIcon>
            <mat-icon>call_end</mat-icon>
          </ng-template>
          <form [formGroup]="firstForm">
            <p class="mt-2 mb-4" *ngIf="dataLoadedFromGUS">Dane Twojej firmy pobraliśmy z bazy GUS, jeżeli są nieaktualne popraw je.</p>
            <div class="grid grid-cols-12 gap-3">
              <div class="col-span-12">

                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>NIP Twojej firmy</mat-label>
                  <input type="text" formControlName="nip" numericOnly placeholder="Podaj NIP swojej firmy" matInput>
                  <mat-error *ngIf="firstForm.controls.nip.hasError('required')">Pole jest wymagane</mat-error>
                  <mat-error *ngIf="firstForm.controls.nip.hasError('minlength')">Min. 9 znaków</mat-error>
                  <mat-error *ngIf="firstForm.controls.nip.hasError('notfound')">Nie znaleziono firmy o podanym numerze NIP</mat-error>
                
                  <button matSuffix mat-flat-button color="primary" (click)="search()">
                    <mat-icon class="icn-spinner" *ngIf="loading">cached</mat-icon>
                    Szukaj
                  </button>
                </mat-form-field>
              </div>

              <!-- <div class="col-span-12 sm:col-span-4">
                <div class="f-button auto-width search-button big mb-4 mb-sm-0" (click)="search()">
                  <span *ngIf="!loading">Szukaj</span>
                  <mat-icon class="icn-spinner" *ngIf="loading">cached</mat-icon>
                </div>
              </div> -->

              <div class="col-span-12">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Nazwa firmy</mat-label>
                  <input type="text" formControlName="name" placeholder="Podaj nazwę swojej firmy" matInput>
                  <mat-error *ngIf="firstForm.controls.name.hasError('required')">Pole jest wymagane</mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-12 sm:col-span-6">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Imię</mat-label>
                  <input type="text" formControlName="firstName" placeholder="Podaj imię" matInput>
                  <mat-error *ngIf="firstForm.controls.firstName.hasError('required')">Pole jest wymagane</mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-12 sm:col-span-6">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Nazwisko</mat-label>
                  <input type="text" formControlName="lastName" placeholder="Podaj nazwisko" matInput>
                  <mat-error *ngIf="firstForm.controls.lastName.hasError('required')">Pole jest wymagane</mat-error>
                </mat-form-field>
              </div>

            </div>
           
          </form>

          <button class="mt-4" mat-flat-button color="primary" matStepperNext>Dalej</button>

        </mat-step>
        <mat-step [stepControl]="secondForm" label="Dane kontaktowe">
          <!-- <ng-template matStepLabel>Dane kontaktowe</ng-template> -->
          <form [formGroup]="secondForm">
            <p class="mt-2 mb-4">
              Zwiększ bezpieczeństwo swojego konta i uzupełnij swój numer telefonu.
            </p> 

            <div class="grid grid-cols-12 gap-3">
              <div class="col-span-12">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-icon matPrefix>phone</mat-icon>
                  <mat-label>Numer telefonu</mat-label>
                  <input type="text" phoneNumber formControlName="phoneNumber" placeholder="Podaj swój numer telefonu" matInput>
                  <mat-error *ngIf="secondForm.controls.phoneNumber.hasError('required')">Pole jest wymagane</mat-error>
                  <mat-error *ngIf="secondForm.controls.phoneNumber.hasError('minlength')">Min. 9 znaków</mat-error>
                </mat-form-field>
              </div>
            </div>
           
            <p class="mt-2 mb-4">
              Zweryfikuj dane kontaktowe, jeżeli są nieaktualne, popraw je.
            </p>
            
            <div class="grid grid-cols-12 gap-3">

              <div class="col-span-12">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Ulica i numer</mat-label>
                  <input type="text" formControlName="street" placeholder="Podaj ulicę i numer" matInput>
                  <mat-error *ngIf="secondForm.controls.street.hasError('required')">Pole jest wymagane</mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-12 sm:col-span-5">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Kod pocztowy</mat-label>
                  <input type="text" formControlName="postalCode" placeholder="Podaj kod pocztowy" matInput>
                  <mat-error *ngIf="secondForm.controls.postalCode.hasError('required')">Pole jest wymagane</mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-12 sm:col-span-7">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Nazwa miejscowości</mat-label>
                  <input type="text" formControlName="city" placeholder="Podaj nazwę miejscowości" matInput>
                  <mat-error *ngIf="secondForm.controls.city.hasError('required')">Pole jest wymagane</mat-error>
                </mat-form-field>
              </div>

            </div>

          </form>
          <button class="mt-4" mat-flat-button color="primary" matStepperNext>Dalej</button>
        </mat-step>
        <mat-step [stepControl]="thridForm" label="Konto bankowe"  state="chat">
          <!-- <ng-template matStepLabel>Konto bankowe</ng-template> -->
          <form [formGroup]="thirdForm">
            <p class="mt-2 mb-5" *ngIf="dataLoadedFromGUS">
              Wybierz swój domyślny numer konta, który będzie wyświetlał się automatycznie dla płatności przelewami. Dane pobrane zostały z Ministerstwa Finansów na podstawie wprowadzonego NIP-u. Domyślny numer konta możesz zmienić w każdej chwili.
            </p>
            <p class="mt-2 mb-5" *ngIf="accountNumbers.length == 0">
              Nie znaleźliśmy numerów kont powiązanych z tym numerem NIP, dodaj numer konta później.
            </p>
            <div class="row">
              <mat-radio-group formControlName="bankAccountNumber" color="primary">
                <mat-radio-button [value]="b.accountNumber" *ngFor="let b of accountNumbers" class="d-block w-100">
                  <div class="row p-0 m-0">
                    <div class="col-12 m-0 p-0">{{b.bank}}: {{b.accountNumber}}</div>
                  
                  </div>
                </mat-radio-button>
                <mat-radio-button value="0" class="block w-full mt-4">
                Dodam później
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </form>        
        </mat-step>
      </mat-stepper>
    </ng-container>
</div>

<mat-dialog-actions *ngIf="activeStep == 2 && selectedStepIndex == 2" style="border-top:1px solid #d2d2d2" class="justify-content-end">
  <button class="f-button md" (click)="save()">Zakończ konfigurację</button>
</mat-dialog-actions>

