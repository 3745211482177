import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Updated regex to allow '-' and '_' in the local part of the email address
    const emailRegex = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const valid = emailRegex.test(control.value);

    return valid ? null : { 'invalidEmail': { value: control.value } };
  };
}