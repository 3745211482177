<div class="container p-4 pt-0">
    <mat-form-field class="w-100">
        <mat-label>Tytuł</mat-label>
        <input type="text" matInput [formControl]="form.controls.title">
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>Treść</mat-label>
        <textarea matInput [formControl]="form.controls.description"></textarea>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>Aktywność</mat-label>
        <mat-select [formControl]="form.controls.isActive">
            <mat-option [value]="true">Aktywne</mat-option>
            <mat-option [value]="false">Nieaktywne</mat-option>
        </mat-select>
    </mat-form-field>
    <button *ngIf="mode == 'update'" (click)="save()" class="f-button md">Zapisz zmiany</button>
    <button *ngIf="mode == 'create'" (click)="create()" class="f-button md">Dodaj</button>
    <button *ngIf="mode == 'update'" (click)="remove()" mat-button color="warn">Usuń</button>
</div>