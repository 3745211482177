import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIResponse, Invoice, NotificationData, Utils } from 'fakturnia-shared';
import { take } from 'rxjs';
import { DocumentsApiService } from 'shared/services/api/documents.api.service';
import { ClientsService } from 'shared/services/clients.service';
import { SnackbarService } from 'shared/services/snackbar.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'app-send-invoice-by-email-dialog',
  templateUrl: './send-invoice-by-email-dialog.component.html',
  styleUrls: ['./send-invoice-by-email-dialog.component.scss']
})
export class SendInvoiceByEmailDialogComponent {

  public invoice: Invoice | null = null

  receivers: Set<string> = new Set<string>()
  availableReceivers: Set<string> = new Set<string>()

  newReceiver = new FormControl<string>('')
  sendCopyToUser = new FormControl<boolean>(false)
  constructor(
    private _documentsApiService:DocumentsApiService,
    private _dialog: MatDialogRef<SendInvoiceByEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _synchronizationService:SynchronizationService,
    private _clientsService:ClientsService,
    private _snackbarService:SnackbarService,
    private _toastService:ToastService
  ) {

    if(Utils.isDefined(data,"invoice")) {
      this.invoice = data.invoice
    }

    if(!this.invoice) return

    this._addReceiverEmailByClientId(this.invoice.buyer._id)
    
  }

  public toggleReceiver(event: MatCheckboxChange, emailAddress: string) {
    if (event.checked) {
        if (this.receivers.size == 3) {
            this._snackbarService.showSnackBar("Osiągnięto limit odbiorców.", 'error');
            // Reset the checkbox state
            event.source.checked = false;
        } else {
            this.receivers.add(emailAddress);
        }
    } else {
        this.receivers.delete(emailAddress);
    }
}

  public addReceiver() {
    const emailAddress = this.newReceiver.value
    // Validate email address
    const isValid = this._validateEmailAddress(emailAddress)
    if(!emailAddress || !isValid) {
      this._snackbarService.showSnackBar("Nieprawidłowy adres e-mail",'error')
      return
    } 

    if(this.receivers.size == 3) {
      this._snackbarService.showSnackBar("Osiągnięto limit odbiorców.",'error')
      return
    } 

    this.availableReceivers.add(emailAddress)
    this.receivers.add(emailAddress)

    this.newReceiver.reset()

  }

  private _validateEmailAddress(emailAddress):boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(emailAddress);   
  }

  private _addReceiverEmailByClientId(id) {
    const client = this._clientsService.getById(id)
    if(!client) return
    if(Utils.isNullOrEmpty(client.email)) return
    const isValid = this._validateEmailAddress(client.email)
    if(!isValid) return
    // Validate email address
    this.availableReceivers.add(client.email)
  }

  public sendEmail() {

    if(!this.invoice) return

    this._documentsApiService.sendDocumentByEmail(
      this.invoice._id,
      Array.from(this.receivers),
      this.sendCopyToUser.value
    )
    .pipe(take(1))
    .subscribe({
      next: (response:APIResponse) => {

        if(response.success != true) {
          return
        }

        this._toastService.showToast(new NotificationData({
          icon: 'ti-mail-share',
          status: 'success',
          title: 'Sukces',
          message: 'Wiadomość została wysłana.',
          timeToClose: 4000,
          sound: true
        }))
        
        this._synchronizationService.synchronize('After sending email')
        this.close()
      }
    })
    
  }

  close() {
    this._dialog.close()
  }
}
