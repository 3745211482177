import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { DbDocument } from "fakturnia-shared";
import { Subscription } from "rxjs";
import { InvoiceTableComponent } from "shared/components/invoice-table/invoice-table.component";
import { PaginationComponent } from "shared/components/pagination/pagination.component";
import { PrintManyDialogComponent } from "shared/dialogs/print-many-dialog/print-many-dialog.component";
import { DataSynchronizerService } from "shared/services/data-synchronization.service";
import { DocumentsService } from "shared/services/documents.service";

@Component({
  standalone: true,
  selector: 'app-documents',
  imports: [CommonModule, InvoiceTableComponent, RouterLink, MatIconModule, MatTooltipModule, MatButtonModule, PaginationComponent],
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

  private _subscriptions:Subscription[] = []

  showDrafts = false

  draftsFilters = [
    (document:DbDocument) => {return (!document.isDeleted && !document.isPaid)} 
  ]

  allFilters = [
    (document:DbDocument) => {return (!document.isDeleted && document.isPaid)} 
  ]

  constructor(
    private _documentsService:DocumentsService,
    private _dialog:MatDialog,
    private _dataSynchronizerService:DataSynchronizerService
  ) {
    
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  ngOnInit(): void {
    this._checkDrafts()

    this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
      next: (data)=>{
        if(data == null) return
        if(data.table == 'documents') this._checkDrafts()
      }
    }))
  }

  // Check if drafts should be visible
  private _checkDrafts() {
    const pagination = this._documentsService.browseDocuments({
      currentPage: 1,
      defaultPageSize: 1,
      filters: this.draftsFilters,
      activeFilters: [],
    })

    this.showDrafts = pagination.resultsCount > 0
  }

  showPrintManyDialog() {

    this._dialog.open(PrintManyDialogComponent, {
      width: '500px'
    })
    
  }

}
