import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export function matchFieldsValidator(field1: string, field2: string): ValidatorFn {
  return (formGroup: FormGroup): { [key: string]: any } | null => {
    const control1 = formGroup.get(field1);
    const control2 = formGroup.get(field2);

    if (control1 && control2 && control1.value !== control2.value) {
      control2.setErrors({'matchFields': true})
      return { 'matchFields': true };
    }

    if(control2) control2.setErrors(null)
    return null
  };
}