import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { UserSettings } from "fakturnia-shared";
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { SettingsService } from 'shared/services/settings.service';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'seller-form-fields',
  templateUrl: './seller-form-fields.component.html',
  styleUrls: ['./seller-form-fields.component.scss']
})
export class SellerFormFieldsComponent implements OnDestroy {

  subscriptions: Subscription[] = [];
  form = new FormGroup({
    
    type: new FormControl('company'),
    name: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    
    nip: new FormControl(''),
    regon: new FormControl(''),
    bdo: new FormControl(''),
    krs: new FormControl(''),
    idNumber: new FormControl(''),
    pesel: new FormControl(''),

    street: new FormControl(''),
    postalCode: new FormControl(''),
    city: new FormControl(''),
    country: new FormControl(''),
  })
  
  constructor(
    private apiService:ApiService,
    private settingsService:SettingsService,
    private _toastService:ToastService
  ) {
    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data: UserSettings)=> {
        this.form.controls.type.setValue(data.settings.autocomplete.seller.type, { emitEvent: false})
        this.form.controls.name.setValue(data.settings.autocomplete.seller.name, { emitEvent: false})
        this.form.controls.firstName.setValue(data.settings.autocomplete.seller.firstName, { emitEvent: false})
        this.form.controls.lastName.setValue(data.settings.autocomplete.seller.lastName, { emitEvent: false})
        
        this.form.controls.nip.setValue(data.settings.autocomplete.seller.nip, { emitEvent: false})
        this.form.controls.regon.setValue(data.settings.autocomplete.seller.regon, { emitEvent: false})
        this.form.controls.bdo.setValue(data.settings.autocomplete.seller.bdo, { emitEvent: false})
        this.form.controls.krs.setValue(data.settings.autocomplete.seller.krs, { emitEvent: false})
        this.form.controls.idNumber.setValue(data.settings.autocomplete.seller.idNumber, { emitEvent: false})
        this.form.controls.pesel.setValue(data.settings.autocomplete.seller.pesel, { emitEvent: false})
        
        this.form.controls.street.setValue(data.settings.autocomplete.seller.street, { emitEvent: false})
        this.form.controls.postalCode.setValue(data.settings.autocomplete.seller.postalCode, { emitEvent: false})
        this.form.controls.city.setValue(data.settings.autocomplete.seller.city, { emitEvent: false})
        this.form.controls.country.setValue(data.settings.autocomplete.seller.country, { emitEvent: false})
      }
    }))
    
  }

  saveChanges() {
    if(this.form.invalid) return
    this.apiService.updateAutomaticSellerFormField(this.form.value)
    .pipe(take(1))
    .subscribe({
      next: (data:APIResponse)=>{
        if(data.success) {
          this._toastService.showToast(new NotificationData({
            title: 'Sukces',
            status: 'info',
            message: 'Ustawienia zostały zapisane.',
          }))
          this.settingsService.refreshSettings()
        }
      }
    })
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
}

