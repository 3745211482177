<div class="flex flex-col w-full min-h-full max-h-full">
 <!-- Header -->
 <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 dark:bg-gray-900 border-b">
  <div class="text-2xl font-medium">
    <span *ngIf="mode == 'create'">Dodaj konto bankowe</span>
    <span *ngIf="mode == 'update'">Aktualizacja konta bankowego</span>
  </div>

  <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="dark:text-white" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
  </button>

</div>
 <!-- Content -->
 <div class="flex flex-auto overflow-hidden">
  <div class="flex flex-col flex-auto sm:mb-18 overflow-y-scroll sm:overflow-y-auto p-6">

    <div class="grid grid-cols-12 gap-3">

      <div class="col-span-12">
        <mat-form-field class="w-full" subscriptSizing="dynamic">
          <mat-label>Bank lub wyświetlana nazwa</mat-label>
          <input [formControl]="form.controls.bank" matInput type="text"/>
        </mat-form-field>
      </div>

      <div class="col-span-12" >
        <mat-form-field class="w-full" subscriptSizing="dynamic">
          <mat-label>Numer konta bankowego</mat-label>
          <input [formControl]="form.controls.accountNumber" matInput type="text"/>
        </mat-form-field>
      </div>

    </div>
 
</div>
</div>

<!-- Footer -->
<div  class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t z-10 bg-gray-50 dark:bg-gray-900">
  <div class="flex items-end">
      <div class="ml-auto">
        <button mat-flat-button color="primary" *ngIf="mode == 'create'" [disabled]="form.invalid"  (click)="create()">
          Dodaj konto
        </button>
        <button mat-flat-button color="primary" *ngIf="mode == 'update'" [disabled]="form.invalid" (click)="update()">
          Zapisz zmiany
        </button>

          <button mat-button *ngIf="mode == 'update'" (click)="showDeleteConfirmation()" color="warn" class="ml-2">
            Usuń
          </button>

          <button mat-flat-button (click)="close()" class="ml-2">Zamknij</button>
      </div>
  </div>
</div>

</div>
