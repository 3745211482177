import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { API_HOST } from "environments/environment"

@Injectable({
    providedIn: 'root'
})
export class BankAccountsApiService {

    constructor(private http: HttpClient) { }

    public createBankAccount(data:any) {
        return this.http.post(API_HOST + 'bank-accounts', data)
    }

    public updateBankAccount(data:any) {
        return this.http.put(API_HOST + `bank-accounts/${data._id}`, data)
    }
    
    public setDefaultBankAccount(id:string | undefined) {
        return this.http.patch(API_HOST + `bank-accounts/${id}/default`, {})
    }

    public deleteBankAccount(id:string) {
        return this.http.delete(API_HOST + `bank-accounts/${id}`)
    }
}