import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent {
  title = 'Ładowanie'
  content = 'Trwa aktualizacja aplikacji do najnowszej wersji.'
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(!data) return
    if(data.title) this.title = data.title
    if(data.content) this.content = data.content
  }
}
