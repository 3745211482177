<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fakturnia-vertical-navigation class="light bg-white print:hidden" [mode]="'over'" [name]="'mainNavigation'"
        [navigation]="navigation" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fakturniaVerticalNavigationContentHeader>
            <a [routerLink]="'/'">
                <!-- Logo -->
                <div class="flex items-center pt-6 px-8">
                    <img class="w-24 mx-auto" src="assets/images/logo-a.png" alt="Logo image"><br />
                </div>
                <div class="text-center w-full pb-6">
                    Fakturnia.pl
                </div>
            </a>
        </ng-container>
    </fakturnia-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">

        <ng-container *ngIf="!isScreenSmall">
            <ng-container fakturniaVerticalNavigationContentHeader>
                <div class="flex items-center pt-6 px-8">
                    <a [routerLink]="'/'">
                        <img class="w-24 mx-auto" src="assets/images/logo-a.png" alt="Logo image"><br />
                    </a>
                </div>
            </ng-container>
            <!-- Horizontal navigation -->
            <fakturnia-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation">
            </fakturnia-horizontal-navigation>
        </ng-container>

        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
        </ng-container>


        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">

            <button *ngIf="isLoggedIn" mat-icon-button [routerLink]="'/kontakt'" matTooltip="Pomoc">
                <mat-icon [svgIcon]="'tabler_outline:lifebuoy'"></mat-icon>
            </button>

            <fakturnia-fullscreen *ngIf="isLoggedIn" class="hidden md:block"></fakturnia-fullscreen>
            <user *ngIf="isLoggedIn"></user>

            <ng-container *ngIf="!isLoggedIn">
                <button color="primary" mat-button [routerLink]="'/logowanie'">
                    Logowanie
                </button>

                <button mat-flat-button color="primary" [routerLink]="'/rejestracja'">
                    Zarejestruj się
                    <mat-icon iconPositionEnd class="mr-6" [svgIcon]="'tabler_outline:chevron-right'"></mat-icon>
                </button>
            </ng-container>
        </div>

        <mat-progress-bar class="absolute bottom-0 -mx-6 px-0" *ngIf="isSynchronizing"
            mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full  overflow-x-hidden">
        <!-- *ngIf="true" layout changes won't be registered without *ngIf=true! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <main-footer *ngIf="!isLoggedIn"></main-footer>

</div>