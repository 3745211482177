import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DATA_INJECTION_TOKEN, PanelService } from 'shared/services/panel.service';
import { Subscription, take } from 'rxjs';
import { ApiService } from 'shared/services/api.service';
import { DialogService } from 'shared/services/dialog.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';
import { APIResponse, Utils } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { ToastService } from 'shared/services/toast.service';
import { DbClient } from 'fakturnia-shared';
import { ClientsApiService } from 'shared/services/api/clients.api.service';
import { cloneDeep } from 'lodash'

@Component({
  selector: 'client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  mode: 'create' | 'update' = 'create'
  appearance: 'outline' | 'fill' = "outline";

  clientForm = this.formGroup.group({
    _id: [''],
    name: ['', Validators.required],
    type: ['company', Validators.required],
    firstName: '',
    lastName: '',
    nip: '',
    regon: '',
    krs: '',
    bdo: '',
    pesel: '',
    idNumber: '',
    street: '',
    postalCode: '',
    city: '',
    country: '',
    phoneNumber: '',
    fax: '',
    email: '',
    www: '',
    description: ''
  })

  constructor(
    private panelService: PanelService,
    private formGroup: FormBuilder,
    private apiService: ApiService,
    private _clientsApiService: ClientsApiService,
    private _dataSynchronizerService: DataSynchronizerService,
    private _synchronizationService: SynchronizationService,
    private dialogService: DialogService,
    private _toastService: ToastService,
    @Inject(DATA_INJECTION_TOKEN) public data: any,
  ) {
    if (typeof data.mode == 'undefined') throw new Error("Invalid mode. Provide view type.")

    this.mode = data.mode;
    if (this.mode == 'update') {
      this.clientForm.controls._id.addValidators(Validators.required)
      this.clientForm.controls._id.updateValueAndValidity()

      if (typeof data.client != 'undefined') this.loadForm(data.client);
    }

    else if (this.mode == 'create') {
      this.clientForm.controls._id.removeValidators(Validators.required)
      this.clientForm.controls._id.updateValueAndValidity()
    }

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  loadForm(company: DbClient) {
    if (!company) return
    if (typeof company == 'undefined') return

    Object.keys(company).forEach(key => {
      if(typeof this.clientForm.controls[key] == 'undefined') return
      this.clientForm.controls[key].setValue(company[key])
    })
    
  }

  createClient() {
    if (!this.clientForm.valid) { return }

    const requestData = cloneDeep(this.clientForm.value)
    this._clientsApiService.create(requestData)
    .pipe(take(1))
    .subscribe({
        next: (response:APIResponse) => this._processResponse(response)
    })

  }

  updateClient() {
    if (!this.clientForm.valid) { return }

    const requestData = cloneDeep(this.clientForm.value)
    const clientId = requestData._id

    if(Utils.isNullOrEmpty(clientId)) return
    delete requestData._id
    this._clientsApiService.update(clientId, requestData)
    .pipe(take(1))
    .subscribe({
        next: (response:APIResponse) => this._processResponse(response)
    })

  }



  private _processResponse = (response: APIResponse) => {
    if (response.success != true) {
      this._toastService.showToast(new NotificationData({
        title: 'Wystąpił błąd',
        status: 'warning',
        message: response.message,
      }))
      return
    }

    this._dataSynchronizerService.next('clients', [response.data])
    this._synchronizationService.synchronize()
    this.close();
    this._toastService.showToast(new NotificationData({
      title: 'Informacja',
      status: 'success',
      message: response.message,
    }))
  }

  close() {
    this.panelService.close();
  }
}
