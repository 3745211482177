import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserSettings } from "fakturnia-shared";
import { ApiService } from 'shared/services/api.service';
import { AuthService } from 'shared/services/auth.service';
import { SettingsService } from 'shared/services/settings.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnDestroy {

  subscriptions: Subscription[] = [];

  loginForm = this.formBuilder.group({
    emailAddress: ['', Validators.required],
    password: ['', Validators.required]
  })

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private settingsService: SettingsService,
    private router: Router,
    private formBuilder:FormBuilder
    ) {

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  login() {

    if(!this.loginForm.valid) return;
   
    this.authService.loginOrRegister(
      'login',
      this.loginForm.controls.emailAddress.value,
      this.loginForm.controls.password.value
    )
    
    
  }
}
