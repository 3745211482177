import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { Utils } from 'fakturnia-shared';

@Component({
  selector: 'app-remote-login',
  templateUrl: './remote-login.component.html',
  styleUrls: ['./remote-login.component.scss']
})
export class RemoteLoginComponent implements OnDestroy {

  private _subscriptions:Subscription[] = []
  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    private _apiService:ApiService
    ) {
    this._subscriptions.push(this._route.params.subscribe(
      (data:any)=> {
        if(data != null)
        {
          if(Utils.isNullOrEmpty(data.oneTimeToken))
          {
            console.log("invalid_token")
            this._router.navigateByUrl('/')
          }

          const oneTimeToken = data.oneTimeToken

          this._login(oneTimeToken)
        }
      }
    ))
  }

  private _login(oneTimeToken) {


    this._apiService.getOneTimeToken(oneTimeToken)
    .pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        if(response.success) {
          console.log("REFT: ", response.data.refreshToken)
          console.log("ACCT: ", response.data.accessToken)
          localStorage.setItem('access_token', response.data.accessToken);
          localStorage.setItem('refresh_token', response.data.refreshToken);
      
          location.href = ""
          location.reload()
        }
      }
    })
    
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
