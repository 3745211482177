import { Component } from '@angular/core';
import { take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { BackupApiService } from 'shared/services/api/backup.api.service';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss']
})
export class BackupComponent {

  selectedFile: File | any = null;

  constructor(
    private _apiService:ApiService, 
    private _backupService:BackupApiService
  ) {}

  getBackup() {
    this._apiService.getBackup().pipe(take(1)).subscribe({
      next: (response: APIResponse) => {
        if(response.success) {
          const json = JSON.stringify(response.data, null, 2);
          const blob = new Blob([json], { type: 'application/json' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `fakturnia_${new Date().toISOString()}_database.json`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      }
    })
  }

  uploadBackup() {
    this._backupService.uploadBackup(this.selectedFile).pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        console.log(response)
      }
    })
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }
}
