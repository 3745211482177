<div class="invoice-header">{{title}}</div>

<div class="d-flex w-100 align-items-end">
  <div>
    <mat-radio-group color="primary" aria-label="Select an option" [value]="company.type"
      (change)="updateCompany('type',$event.value)">
      <mat-radio-button value="company">Firma</mat-radio-button>
      <mat-radio-button value="person">Osoba fizyczna</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="ms-auto">
    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" matTooltip="Pokaż / ukryj pola">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button [role]="'menuitemcheckbox'" mat-menu-item *ngFor="let field of companyToggleableFields"
        (click)="toggleFieldVisibility(field,$event)">
        <mat-icon *ngIf="visibleFields.has(field)" color="primary">check_box</mat-icon>
        <mat-icon *ngIf="!visibleFields.has(field)">check_box_outline_blank</mat-icon>
        Dodaj pole {{field}}
      </button>
    </mat-menu>
  </div>
</div>

<div class="row mb-3">
  <div class="col-12 col-md-12 col-lg-12 col-xl-12 pe-md-2 mt-2">
    <div class="input-group">
      <div class="form-floating" [ngClass]="{ 'blue': isSearchEnabled}">
        <input placeholder="Wprowadź NIP" type="text" class="form-control text-left" [value]="company.nip"
          (change)="updateCompany('nip',$event.target.value)" />
        <label for="companyName">NIP</label>
      </div>
      <div matTooltip="Pobierz dane firmy z bazy GUS" class="input-group-text cursor-pointer gus-search-button"
        *ngIf="isSearchEnabled" (click)="searchInGUS('seller', company.nip)">
        <div class="d-flex" style="font-size:13px;">
          <div>
            Sprawdź <br /> w GUS
          </div>
          <div class="d-flex justify-items-center align-content-center p-2" style="flex-wrap: wrap;">
            <span class="material-symbols-outlined " style="font-size: 25px;">
              search
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="company.type == 'company'">
  <div class="col-12">
    <mat-form-field appearance="outline" class="white-background w-100" subscriptSizing="dynamic">
      <mat-label>Nazwa firmy</mat-label>
      <input matInput class="text-left" [value]="company.name" (change)="updateCompany('name',$event.target.value)"
        [matAutocomplete]="auto" (input)="filterClients($event)">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option.name" (click)="setCompany(option, 'DbClient')">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>

<div class="row" *ngIf="company.type == 'person'">
  <div class="col-12 col-md-4 pe-md-2">
    <div class="form-floating">
      <input type="text" class="form-control text-left" id="firstName" placeholder="Imię" [value]="company.firstName"
        (change)="updateCompany('firstName',$event.target.value)">
      <label for="companyName">Imię</label>
    </div>
  </div>
  <div class="col-12 col-md-8">
    <div class="form-floating">
      <input type="text" class="form-control text-left" id="lastName" placeholder="Nazwisko" [value]="company.lastName"
        (change)="updateCompany('lastName',$event.target.value)">
      <label for="lastName">Nazwisko</label>
    </div>
  </div>
</div>

<div class="row">


  <div class="col-12 col-md-12 col-lg-6 pe-md-2 mt-2" *ngIf="visibleFields.has('PESEL')">
    <div class="form-floating">
      <input placeholder="Wprowadź PESEL" type="text" class="form-control text-left" [value]="company.pesel"
        (change)="updateCompany('pesel',$event.target.value)" />
      <label for="companyName">PESEL:</label>
    </div>
  </div>

  <div class="col-12 col-md-12 col-lg-6 pe-md-2 mt-2" *ngIf="visibleFields.has('numer dowodu')">
    <div class="form-floating">
      <input placeholder="Wprowadź numer dowodu" type="text" class="form-control text-left" [value]="company.idNumber"
        (change)="updateCompany('idNumber',$event.target.value)" />
      <label for="companyName">Numer dowodu:</label>
    </div>
  </div>

  <div class="col-12 col-md-12 col-lg-6 pe-md-2 mt-2" *ngIf="visibleFields.has('REGON')">
    <div class="form-floating">
      <input placeholder="Wprowadź REGON" type="text" class="form-control text-left" [value]="company.regon"
        (change)="updateCompany('regon',$event.target.value)" />
      <label for="companyName">REGON:</label>
    </div>
  </div>

  <div class="col-12 col-md-12 col-lg-6 pe-md-2 mt-2" *ngIf="visibleFields.has('BDO')">
    <div class="form-floating">
      <input placeholder="Wprowadź BDO" type="text" class="form-control text-left" [value]="company.bdo"
        (change)="updateCompany('bdo',$event.target.value)" />
      <label for="companyName">BDO:</label>
    </div>
  </div>

  <div class="col-12 col-md-12 col-lg-6 pe-md-2 mt-2" *ngIf="visibleFields.has('KRS')">
    <div class="form-floating">
      <input placeholder="Wprowadź KRS" type="text" class="form-control text-left" [value]="company.krs"
        (change)="updateCompany('krs',$event.target.value)" />
      <label for="companyName">KRS:</label>
    </div>
  </div>
</div>

<div class="row my-2">
  <div class="col-12">
    <div class="form-floating">
      <input placeholder="Ulica i numer" type="text" class="form-control text-left" [value]="company.street"
        (change)="updateCompany('street',$event.target.value)" />
      <label for="companyName">Ulica i numer</label>
    </div>
  </div>
</div>

<div class="row my-2">
  <div class="col-12 col-md-4 pe-md-2">
    <div class="form-floating">
      <input placeholder="Kod pocztowy" type="text" class="form-control text-left" [value]="company.postalCode"
        (change)="updateCompany('postalCode',$event.target.value)" />
      <label for="postalCode">Kod pocztowy</label>
    </div>
  </div>
  <div class="col-12 col-md-8 mt-2 mt-md-0">
    <div class="form-floating">
      <input placeholder="Miejscowość" type="text" class="form-control text-left" [value]="company.city"
        (change)="updateCompany('city',$event.target.value)" />
      <label for="companyName">Miejscowość</label>
    </div>
  </div>
</div>

<div class="row my-2" *ngIf="visibleFields.has('kraj')">
  <div class="col-12 col-md-4 pe-md-2">
    <div class="form-floating">
      <input placeholder="Kraj" type="text" class="form-control text-left" [value]="company.country"
        (change)="updateCompany('country',$event.target.value)" />
      <label for="postalCode">Kraj</label>
    </div>
  </div>
</div>

<div class="row my-2" *ngIf="visibleFields.has('telefon / fax')">
  <div class="col-12 col-md-6 pe-md-2">
    <div class="form-floating">
      <input placeholder="Numer telefonu" type="text" class="form-control text-left" [value]="company.phoneNumber"
        (change)="updateCompany('phoneNumber',$event.target.value)" />
      <label for="phoneNumber">Numer telefonu</label>
    </div>
  </div>
  <div class="col-12 col-md-6 mt-2 mt-md-0">
    <div class="form-floating">
      <input placeholder="FAX" type="text" class="form-control text-left" [value]="company.fax"
        (change)="updateCompany('fax',$event.target.value)" />
      <label for="companyName">FAX</label>
    </div>
  </div>
</div>



<div class="row my-2" *ngIf="visibleFields.has('email / www')">
  <div class="col-12 col-md-6 pe-md-2">
    <div class="form-floating">
      <input placeholder="Adres e-mail" type="text" class="form-control text-left" [value]="company.email"
        (change)="updateCompany('email',$event.target.value)" />
      <label for="email">Adres e-mail</label>
    </div>
  </div>
  <div class="col-12 col-md-6 mt-2 mt-md-0">
    <div class="form-floating">
      <input placeholder="Strona WWW" type="text" class="form-control text-left" [value]="company.www"
        (change)="updateCompany('www',$event.target.value)" />
      <label for="www">Strona WWW</label>
    </div>
  </div>
</div>

<div class="row my-2" *ngIf="visibleFields.has('pole opisowe')">
  <div class="col-12">
    <div class="form-floating">
      <input placeholder="Pole opisowe" type="text" class="form-control text-left" [value]="company.description"
        (change)="updateCompany('description',$event.target.value)" />
      <label for="postalCode">Pole opisowe</label>
    </div>
  </div>
</div>