<nav class="navbar-fixed bg-white dark:bg-slate-950" >
   
    <div class="navbar-container border-bottom mx-auto" >
        <div class="navbar-brand">
            <div class="d-none d-lg-flex align-tems-center">
                <ul class="navbar-nav flex-row align-items-center ms-auto">
                    <li>
                        <a routerLink="/"><img src="./assets/images/logo-a.png" style="height:65px; width:65px;"
                                alt=""></a>

                    </li>
                    <li class="me-4 brand-name">
                        <a routerLink="/" class="menu-link fs-13">FAKTURNIA.PL</a>
                    </li>
                </ul>
            </div>
            <div class="menu-toggle d-lg-none cursor-pointer" (click)="menuExpanded = true">
                <i class="h3 ti ti-menu-2"></i>
            </div>
        </div>
        <div class="navbar-nav-right d-flex align-items-center">
            <ul class="navbar-nav flex-row align-items-center">
                <ng-container *ngIf="!isAuthenticated">
                    <li class="me-3" >
                        <a routerLink="/pomoc" routerLinkActive="active" class="menu-link">Pomoc</a>
                    </li>
                    <li>
                        <a routerLink="/kontakt" routerLinkActive="active" class="menu-link">Kontakt</a>
                    </li>
                </ng-container>

                <ng-container *ngIf="isAuthenticated">
                    <li *ngIf="isAdmin" class="me-3">
                        <a class="menu-link" matTooltip="Panel administracyjny"  routerLink="/admin/pulpit">
                            <i class="ti ti-shield-bolt"></i> Admin
                        </a>
                    </li>
                    <li class="me-3" matTooltip="Synchronizuj" [ngClass]="{'rotate primary': isSynchronizing}" >
                        <a class="menu-link cursor-pointer" (click)="synchronize()" >
                            <i  [ngClass]="{'primary': isSynchronizing}" class="ti ti-refresh  "></i>
                        </a>
                    </li>
                    <li class="me-3">
                        <a routerLink="/konto/ustawienia" matTooltip="Ustawienia" routerLinkActive="active" class="menu-link">
                            <i class="ti ti-settings"></i>
                        </a>
                    </li>
                    <li>
                        <a class="menu-link" matTooltip="Wyloguj się" (click)="logout()">
                            <i class="ti ti-power rotate"></i>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <div class="d-flex navbar-container mx-auto pages-container py-2">
        <div class="align-tems-center">
            <ul class="navbar-nav flex-row align-items-center ms-auto">

                <ng-container *ngIf="!isAuthenticated">
                    <li class="me-3 d-none d-lg-flex">
                        <a routerLink="/o-aplikacji" routerLinkActive="active" class="menu-link">O aplikacji</a>
                    </li>
                    <li class="me-3 dropdown d-none d-lg-flex">
                        <a routerLink="/funkcjonalnosci-aplikacji" routerLinkActive="active" class="menu-link">
                            Funkcjonalności
                            <i class="ti ti-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu"> 
                            <li><a routerLink="/funkcjonalnosci-aplikacji/podglad-faktury" routerLinkActive="active">Podgląd faktury</a></li>
                            <li><a routerLink="/funkcjonalnosci-aplikacji/faktura-vat" routerLinkActive="active">Faktura VAT</a></li> 
                            <li><a routerLink="/funkcjonalnosci-aplikacji/faktura-koncowa" routerLinkActive="active">Faktura końcowa</a></li> 
                            <li><a routerLink="/funkcjonalnosci-aplikacji/faktura-korygujaca" routerLinkActive="active">Faktura korygujaca</a></li> 
                            <li><a routerLink="/funkcjonalnosci-aplikacji/faktura-vat-marza" routerLinkActive="active">Faktura VAT marża</a></li>
                           
                        </ul>
                    </li>
                    <li class="me-3 d-none d-lg-flex">
                        <a routerLink="/blog" routerLinkActive="active" class="menu-link">Blog</a>
                    </li>
                </ng-container>
               
                <ng-container *ngIf="isAuthenticated">
                    <li class="me-3 d-none d-lg-flex" >
                        <a routerLink="/pomoc" routerLinkActive="active" class="menu-link">Pomoc</a>
                    </li>
                    <li class="me-3 d-none d-lg-flex">
                        <a routerLink="/kontakt" routerLinkActive="active" class="menu-link">Kontakt</a>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="ms-auto align-items-center">
            <ul class="navbar-nav flex-row align-items-center ms-auto">
                <ng-container *ngIf="isAuthenticated">
                    
                    <li class="me-3">
                        <a routerLink="/konto/pulpit" routerLinkActive="active" class="menu-link">
                            Pulpit
                        </a>
                    </li>
                    <li class="me-3">
                        <a routerLink="/konto/dokumenty" routerLinkActive="active" class="menu-link">
                            Dokumenty
                        </a>
                    </li>
                    <li class="me-3">
                        <a routerLink="/konto/klienci" routerLinkActive="active" class="menu-link">
                            Klienci
                        </a>
                    </li>
                    <li class="me-3">
                        <a routerLink="/konto/produkty" routerLinkActive="active" class="menu-link">
                            Produkty
                        </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="!isAuthenticated">
                    <li class="me-3">
                        <a routerLink="/logowanie" class="menu-link">
                            <i class="ti ti-lock me-2"></i>
                            Logowanie</a>
                    </li>
                    <li>
                        <a routerLink="/rejestracja">
                            <div class="f-button text-base lg:text-lg">
                                Dołącz do nas <i class="ti ti-arrow-narrow-right ms-2"></i>
                            </div>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <mat-progress-bar class="p-absolute" *ngIf="isSynchronizing" mode="indeterminate"></mat-progress-bar>
</nav>

<nav class="navbar-mobile-fixed" [ngClass]="menuExpanded ? 'menu-expanded' : 'd-block'">

    <ul class="navbar-nav flex-row align-items-center ms-auto ">
        <li class="ms-3">
            <a routerLink="/"><img src="./assets/images/logo-a.png" style="height:65px; width:65px;" alt=""></a>

        </li>
        <li class="mx-auto brand-name">
            <a routerLink="/" class="menu-link fs-13">FAKTURNIA.PL</a>
        </li>
        <li (click)="menuExpanded = false" class="ms-auto me-3 h4 cursor-pointer"><i class="ti ti-x"></i></li>

    </ul>

    <div class="h-100 d-flex flex-column mt-4 overflow-auto">
        <ul class="mobile-menu">

            <ng-container *ngIf="!isAuthenticated">
                <li class="menu-item mb-2">

                    <div class="cursor-pointer h4 text-center mx-3">
                        Masz już konto?
                    </div>
                </li>
                <li class="menu-item mb-2">
                    <a routerLink="/logowanie" class="login-link">
                        <div class="cursor-pointer text-center mx-3">
                            <i class="ti ti-lock ms-2"></i>
                            Logowanie
                        </div>
                    </a>
                </li>
    
                <li class="menu-item mb-2">
                    <a class="normal" routerLink="/rejestracja">
                        <div class="f-button cursor-pointer text-center mx-4">
                            Utwórz konto
                            <i class="ti ti-arrow-narrow-right ms-2"></i>
                        </div>
                    </a>
                </li>
            </ng-container>
            

            <li class="menu-item mt-3 mb-2">
                <div class="mx-3 menu-header-text">
                    MENU
                </div>
            </li>

            <li class="menu-item" >
                <a routerLink="/blog" class="menu-link">
                    Blog
                    <div class="d-flex ms-auto"><i class="mt-1 ti ti-chevron-right"></i></div>
                </a>
            </li>

            <li class="menu-item" >
                <a routerLink="/o-aplikacji" class="menu-link">
                    O aplikacji
                    <div class="d-flex ms-auto"><i class="mt-1 ti ti-chevron-right"></i></div>
                </a>
            </li>

            <li class="menu-item" >
                <a routerLink="/funkcjonalnosci-aplikacji" class="menu-link">
                    Funkcjonalności
                    <div class="d-flex ms-auto"><i class="mt-1 ti ti-chevron-right"></i></div>
                </a>
            </li>

            

            <li class="menu-item mt-3 mb-2">
                <div class="mx-3 menu-header-text">
                    PRZYDATNE
                </div>
            </li>

            <li class="menu-item" >
                <a routerLink="/pomoc" class="menu-link">
                    Pomoc
                    <div class="d-flex ms-auto"><i class="mt-1 ti ti-chevron-right"></i></div>
                </a>
            </li>

            <li class="menu-item" >
                <a routerLink="/kontakt" class="menu-link">
                    Kontakt
                    <div class="d-flex ms-auto"><i class="mt-1 ti ti-chevron-right"></i></div>
                </a>
            </li>


            <li class="menu-item mt-3 mb-2">
                <div class="mx-3 menu-header-text">
                    WIĘCEJ
                </div>
            </li>

            <li class="menu-item">
                <a routerLink="/regulamin-aplikacji" class="menu-link">
                    Regulamin
                    <div class="d-flex ms-auto"><i class="mt-1 ti ti-chevron-right"></i></div>
                </a>
            </li>
            <li class="menu-item">
                <a routerLink="/polityka-prywatnosci" class="menu-link">
                    Polityka prywatności
                    <div class="d-flex ms-auto"><i class="mt-1 ti ti-chevron-right"></i></div>
                </a>
            </li>
        </ul>
    </div>



</nav>


<div (click)="menuExpanded = !menuExpanded" class="layout-overlay" [ngClass]="menuExpanded ? 'd-block' : 'd-none'">

</div>
