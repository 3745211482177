import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[phoneNumber]'
})
export class PhoneNumberDirective {

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event: any): void {
      const inputValue: string = this.el.nativeElement.value;
  
      // Replace consecutive spaces with a single space
      const sanitizedValue = inputValue.replace(/\s{2,}/g, ' ');
  
      // Ensure there's at most one '+' at the start
      const plusCount = (sanitizedValue.match(/\+/g) || []).length;
      const finalValue = plusCount <= 1 ? sanitizedValue : sanitizedValue.substring(0, 1) + sanitizedValue.substring(1).replace(/\+/g, '');
  
      // Update the input value
      this.el.nativeElement.value = finalValue;
    }
  
    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
      const key = event.key;
  
      // Allow numbers, spaces, '+', and navigation keys
      const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '+', 'Tab', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
  
      if (!allowedKeys.includes(key)) {
        event.preventDefault();
      }
    }
}