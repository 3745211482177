import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'app-register-or-login-dialog',
  templateUrl: './register-or-login-dialog.component.html',
  styleUrls: ['./register-or-login-dialog.component.scss']
})
export class RegisterOrLoginDialogComponent implements OnDestroy {

  subscriptions:Subscription[] = [];
  action: 'save' | 'download' | 'preview'
  alreadyRegistered = false;
  constructor(
    private dialog: MatDialogRef<RegisterOrLoginDialogComponent>,
    private authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    
      if(typeof data !== 'undefined') {
        if(typeof data.action !== 'undefined') this.action = data.action
      }
      
      this.subscriptions.push(this.authService.isLoggedIn$.subscribe({
        next: (data)=> {
          if(data == true) {
            this.close(true)
          }
        }
      }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  close(result:boolean = false) {
    this.dialog.close({
      result: result
    })
  }
  
}
