import { Component, Input } from '@angular/core';

@Component({
  selector: 'activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss']
})
export class ActivityLogsComponent {

  @Input() activityLogs = []
  
}
