import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { UserSettings } from "fakturnia-shared";
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { SettingsService } from 'shared/services/settings.service';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'automatic-numbering',
  templateUrl: './automatic-numbering.component.html',
  styleUrls: ['./automatic-numbering.component.scss']
})
export class AutomaticNumberingComponent implements OnDestroy {

  subscriptions: Subscription[] = [];
  documentNumbering = new FormControl(true,[Validators.required]);
  constructor(
    private apiService:ApiService,
    private settingsService:SettingsService,
    private _toastService:ToastService
  ) {

    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data: UserSettings)=> {
        this.documentNumbering.setValue(data.settings.autocomplete.documentNumbering, { emitEvent: false})
      }
    }))

    this.subscriptions.push(this.documentNumbering.valueChanges.subscribe(
      (data)=> {
      this.toggleAutomaticNumbering(data)
    }))

  }

  toggleAutomaticNumbering(checked) {
    this.apiService.toggleAutomaticNumbering(checked)
    .pipe(take(1))
    .subscribe({
      next: (data:APIResponse)=>{
        if(data.success) {
          this._toastService.showToast(new NotificationData({
            title: 'Informacja',
            status: checked ? 'success' : 'info',
            message: checked ? 'Włączono autonumerowanie.' : 'Wyłączono autonumerowanie.',
          }))
          this.settingsService.refreshSettings()
        } else this._toastService.warning(data.message)
      }
    })
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
