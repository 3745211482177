import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Utils } from 'fakturnia-shared';

@Component({
  selector: 'update-user-personal-informations',
  templateUrl: './update-user-personal-informations.component.html',
  styleUrls: ['./update-user-personal-informations.component.scss']
})
export class UpdateUserPersonalInformationsComponent implements OnChanges {

  @Input('user') user
  @Input('isEditable') isEditable = false

  form:FormGroup = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    emailAddress: new FormControl(''),
    phoneNumber: new FormControl(''),
    pesel: new FormControl(''),
    idNumber: new FormControl(''),
  })

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Utils.isDefined(changes,'user.currentValue')) {
      this._updateForm(this.user)
    }
    if(Utils.isDefined(changes,'isEditable.currentValue')) {
      if(this.isEditable) this.form.enable()
      else this.form.disable()
    }
  }
  private _updateForm(user) {
    this.form.controls['firstName'].setValue(user.firstName)
    this.form.controls['lastName'].setValue(user.lastName)
    this.form.controls['emailAddress'].setValue(user.emailAddress)
    this.form.controls['phoneNumber'].setValue(user.phoneNumber)
    this.form.controls['pesel'].setValue(user.pesel)
    this.form.controls['idNumber'].setValue(user.idNumber)
  }

  saveChanges() {

  }
}
