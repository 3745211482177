<form  style="max-width:500px;">
<div class="row">
    <div class="col-12">
        <h4>Numerowanie dokumentów</h4>
        <p>Automatycznie ustawiaj numery kolejnych dokumentów</p>
        <mat-checkbox color="primary" [formControl]="documentNumbering">
            {{documentNumbering.value ? "Włączone" : "Wyłączone"}}
        </mat-checkbox>
    </div>
</div>
</form>
<!-- [checked]="settings.defaults.documentNumbering"
(change)="settings.defaults.documentNumbering = $event.source.checked; update();" -->