<div class="min-h-screen max-w-320 mx-auto py-8 px-4 sm:px-8 sm:py-16">
    <div class="grid grid-cols-2">

        <div class="col-span-2 md:col-span-1 max-w-100 w-full mx-auto">

            <h3 class="mb-6">Rejestracja</h3>
            <!-- <p>Wprowadź swój adres e-mail oraz ustaw hasło do konta.</p> -->

            <register></register>

            <button class="my-8" color="primary" mat-button [routerLink]="'/logowanie'">
                Mam już konto
                <mat-icon iconPositionEnd>chevron_right</mat-icon>
            </button>

        </div>

        <div class="col-span-2 md:col-span-1 md:mt-12">
            <div class="max-w-120 mx-auto">
                <h4 class="text-4xl mt-4">Witamy na pokładzie!</h4>
                <div class="p-4 pb-1 mb-4 rounded-lg bg-primary text-white border-l-4 dark:border-primary-300 border-primary-900">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <i class="ti ti-shield-check-filled text-white text-3xl"></i>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-xl font-medium text-primar">Cieszymy się, że do nas dołączysz</h3>
                        <div class="mt-2 text-base text-white">
                          <ul role="list" class="list-disc pl-5 pt-2 space-y-1">
                            <li>Upewnij się, że podałeś <b>prawidłowy</b> adres e-mail.</li>
                            <li>Zabezpiecz swoje konto <b>bezpiecznym</b> hasłem!</li>
                            <li>Hasło powinno zawierać minimum <b>8 znaków</b>.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>

    </div>
</div>