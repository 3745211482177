import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  standalone: true,
  selector: 'company-name',
  imports: [CommonModule],
  templateUrl: './company-name.component.html',
  styleUrls: ['./company-name.component.scss']
})
export class CompanyNameComponent implements OnChanges {

  @Input('company') company;

  companyName = 'Brak danych';

  constructor()
  {
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['company']) this.setCompanyName()
  }

  setCompanyName()
  {
    if(typeof this.company == 'undefined') return;
    if(this.company.type == 'company') { this.companyName = this.company.name}
    else if(this.company.type == 'person') { this.companyName = `${this.company.firstName} ${this.company.lastName}`}
  }
}
