import { ComponentPortal, Portal } from "@angular/cdk/portal";
import { Injectable, InjectionToken, Injector, OnDestroy } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { Subject, Subscription } from "rxjs";
import { ClientComponent } from "../components/client/client.component";
import { ProductComponent } from "../components/product/product.component";

@Injectable({
    providedIn: 'root'
})

export class PanelService implements OnDestroy {

    subscriptions: Subscription[] = [];
    panel: MatDrawer;
    private activePortal$ = new Subject<Portal<any>>();
    private hasActivePortal$ = new Subject<boolean>();

    readonly hasPortal = this.hasActivePortal$.asObservable();
    readonly portal$ = this.activePortal$.asObservable();

    constructor() { }

    /** Opens the panel with optionally a portal to be set. */
    async open(portal?: Portal<any>) {

       
        const p = new Promise(async (resolve) => {
            this.hasActivePortal$.next(false);
            if (this.panel.opened) { resolve(true); return; }

            await this.panel.open()
            this.hasActivePortal$.next(true);

            resolve(true)
        })

        p.then((res) => {
            if (portal) {
                this.setPortal(portal)
            }
        })

    }

    async openClientPanel(mode, client)
    {
        await this.open(new ComponentPortal<ClientComponent>(
            ClientComponent,
              null,
              Injector.create({
                providers: [{ provide: DATA_INJECTION_TOKEN, useValue: { 
                    mode: mode,
                    client: client
                    // roomId: reservation.id_pokoju,
                    // startDate: reservation.przyjazd,
                    // endDate: reservation.wyjazd,
                    // reservation: reservation
                } }],
              })
          ))
    }
    async openProductPanel(mode, product)
    {
        await this.open(new ComponentPortal<ProductComponent>(
            ProductComponent,
              null,
              Injector.create({
                providers: [{ provide: DATA_INJECTION_TOKEN, useValue: { 
                    mode: mode,
                    product: product
                    // roomId: reservation.id_pokoju,
                    // startDate: reservation.przyjazd,
                    // endDate: reservation.wyjazd,
                    // reservation: reservation
                } }],
              })
          ))
    }
    currentPortal;
    async setPortal(portal) {
        this.currentPortal = portal;
        this.activePortal$.next(this.currentPortal);
        this.hasActivePortal$.next(true);
    }

    setPanel(panel)
    {
      this.panel = panel;
      this.subscriptions.forEach(sub=>sub.unsubscribe())
    }

    /** Closes the panel. */
    close() {
        this.hasActivePortal$.next(false);
        return this.panel.close();

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
export const DATA_INJECTION_TOKEN = new InjectionToken<string>('DATA_INJECTION_TOKEN');
