
<div class="min-h-screen max-w-320 mx-auto py-8 px-4 sm:px-8 sm:py-16">
    <div class="grid grid-cols-2">
        <div class="col-span-2 md:col-span-1 max-w-100 w-full mx-auto">
            <div *ngIf="step == 'restore-account'">
                <h3 class="text-4xl font-medium">Odzyskiwanie konta</h3>
            </div>
            <div *ngIf="step == 'set-new-password'">
                <h3  class="text-4xl font-medium">Ustaw nowe hasło</h3>
                <p class="mb-3">Zabezpiecz konto silnym hasłem.</p>
            </div>

            <div class="bg-white dark:bg-slate-800 w-full mx-auto p-8 rounded-xl">
                <div *ngIf="step == 'restore-account'">

                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matIconPrefix [svgIcon]="'tabler_outline:mail'"></mat-icon>
                        <mat-label>Adres e-mail konta</mat-label>
                        <input type="email" matInput lowercaseEmail [formControl]="restoreForm.controls.emailAddress"  placeholder="Podaj adres e-mail">
                            <mat-error *ngIf="restoreForm.controls.emailAddress.hasError('required')">Adres e-mail jest wymagany.</mat-error>
                            <mat-error *ngIf="restoreForm.controls.emailAddress.hasError('invalidEmail')">Nieprawidłowy format adresu e-mail.</mat-error>
                    </mat-form-field>
    
                    <button [disabled]="isProcessing" mat-flat-button color="primary" class="w-full mt-4" (click)="restore()">
                        Odzyskaj dostęp
                    </button>
                </div>
    
                <div *ngIf="step == 'set-new-password'">
                      
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matIconPrefix [svgIcon]="'tabler_outline:lock'"></mat-icon>
                            <mat-label>Nowe hasło</mat-label>
                            <input [type]="showPassword ? 'text' : 'password'" matInput  [formControl]="newPasswordForm.controls.password"  placeholder="Podaj nowe hasło">
                            <button mat-icon-button matSuffix (click)="showPassword = !showPassword">
                                <mat-icon >{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="newPasswordForm.controls.password.hasError('required')">Pole wymagane</mat-error>
                            <mat-error *ngIf="newPasswordForm.controls.password.hasError('minlength')">Hasło musi zawierać minimum 8 znaków.</mat-error>
                              
                        </mat-form-field> 
                       
                      
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-icon matIconPrefix [svgIcon]="'tabler_outline:lock'"></mat-icon>
                            <mat-label>Powtórz nowe hasło</mat-label>
                            <input [type]="showRepeatPassword ? 'text' : 'password'" matInput [formControl]="newPasswordForm.controls.repeatPassword"  placeholder="Powtórz nowe hasło">
                            <button mat-icon-button matSuffix (click)="showRepeatPassword = !showRepeatPassword">
                                <mat-icon >{{showRepeatPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="newPasswordForm.controls.repeatPassword.hasError('matchFields')">Hasła nie są takie same.</mat-error>
                        </mat-form-field>
                       
    
                    <button [disabled]="isProcessing"  mat-flat-button color="primary" class="w-full mt-4" (click)="setNewPassword()">
                        Ustaw nowe hasło
                    </button>
                </div>
            </div>
            
            <button class="my-8" color="primary" mat-button [routerLink]="'/logowanie'">
                Logowanie
                <mat-icon iconPositionEnd>chevron_right</mat-icon>
            </button>
        </div>

        <div class="col-span-2 md:col-span-1 md:mt-6">
            <div class="max-w-120 w-full mx-auto">
                <h4 class="text-4xl mt-4">Wskazówki</h4>
                <div class="p-4 rounded-lg bg-primary text-white border-l-4 dark:border-primary-300 border-primary-900">
                    <div class="flex">
                        <!-- <div class="flex-shrink-0">
                            <i class="ti ti-shield-check-filled text-white text-3xl"></i>
                        </div> -->
                        <div class="ml-3">
                            <!-- <h3 class="text-xl font-medium">Załóż bezpłatne konto w <b>28 sekund</b>.</h3> -->
                            <div class="text-base">
                                <ul role="list" class="list-disc pl-5 space-y-1" *ngIf="step == 'restore-account'">
                                    <li>Wprowadź adres e-mail, który jest przypisany do Twojego konta.</li>
                                    <li>Jeżeli wprowadziłeś prawidłowy adres e-mail. Na wskazany adres zostanie wysłany jednorazowy link.</li>
                                    <li>Kliknij link w mailu i wprowadź swoje nowe hasło do konta.</li>
                                </ul>
                                <ul role="list" class="list-disc pl-5 space-y-1" *ngIf="step == 'set-new-password'">
                                    <li>Zabezpiecz swoje konto bezpiecznym hasłem!</li>
                                    <li>Hasło powinno zawierać minimum <b>8 znaków</b>.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
