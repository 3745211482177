
<div class="therrax-select reversed" >
    <div [matMenuTriggerFor]="menu" *ngIf="labels.length > 0" class="label">
        <div>{{label}}<i class="ti ti-chevron-down ms-1"></i></div>
        
    </div>
    <div *ngIf="labels.length == 0" class="label" (click)="picker.show()">
        {{label}}
    </div>
    <div class="value" (click)="picker.show()"> 
        <input  #picker="bsDatepicker"  
        type="text" 
        class="cursor-pointer"
        [bsValue]="dateAsDateObject" 
        (bsValueChange)="inputChange($event)" 
        placeholder="dd-mm-yyyy"  
        bsDatepicker 
        readonly
        [bsConfig]="{
            containerClass:'date-picker-theme',
            returnFocusToInput:true, 
            dateInputFormat: 'DD-MM-YYYY', 
            todayButtonLabel: 'Dzisiaj', 
            showTodayButton: true, 
            readOnly:true, 
            todayPosition: 'center', 
            showWeekNumbers: false  
        }" />
    </div>
    <div class="icon" (click)="picker.show()">
        <i class="ti ti-calendar-event"></i>
    </div>
</div>

<mat-menu #menu="matMenu">
    <button class="menu-sm" mat-menu-item *ngFor="let l of labels" (click)="selectLabel(l)">
      <span>{{l}}</span>
    </button>
   
  </mat-menu>
