<div class="bg-white dark:bg-slate-800 w-full mx-auto p-8 rounded-xl">
    
    <mat-form-field [subscriptSizing]="'dynamic'" floatLabel="always" class="w-full">
        <mat-icon matIconPrefix [svgIcon]="'tabler_outline:mail'"></mat-icon>
        <mat-label>Adres e-mail</mat-label>
        <input type="email" lowercaseEmail matInput [formControl]="loginForm.controls.emailAddress" placeholder="Adres e-mail">
        <mat-error *ngIf="loginForm.controls.emailAddress.touched && loginForm.controls.emailAddress?.errors?.invalidEmail">Nieprawidłowy format adresu e-mail.</mat-error>
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'" floatLabel="always" class="w-full mt-4">
        <mat-icon matIconPrefix [svgIcon]="'tabler_outline:lock'"></mat-icon>
        <mat-label>Hasło</mat-label>
        <input (keyup.enter)="login()" [type]="showPassword ? 'text' : 'password'" matInput [formControl]="loginForm.controls.password" placeholder="Hasło">
        <button mat-icon-button matSuffix (click)="showPassword = !showPassword">
            <mat-icon >{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.['minlength']">Hasło musi mieć minimum 8 znaków.</mat-error>
    </mat-form-field>

    <button mat-flat-button color="primary" class="mt-6 w-full" (click)="login()">
        Zaloguj się
    </button>
</div>
   

