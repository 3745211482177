<!-- <div [id]="elementId" class="therrax-select">
    <div class="label" (click)="control.focus();">Nazwa firmy</div>
    <div class="value">
      <input #control [formControl]="nameControl" placeholder="Podaj nazwę firmy" type="text" class="blank">
    </div>
    <div class="icon" (click)="control.focus()"><i class="ti ti-chevron-down"></i></div>
    <div class="hints" *ngIf="isOpen">
      <div class="button text-color-blue ps-3 fs-13" (click)="showAddClientDialog()">
        <i class="ti ti-plus"></i> Dodaj nowego klienta
      </div> 
      <div class="button ps-3 fs-13" *ngIf="options.length == 0 && nameControl?.value?.length > 0">
        <i class="ti ti-user-search"></i> Nie znaleziono klienta
      </div> 
      <div [id]="elementId" class="results">
        <div class="item p-2 d-inline-flex w-100" *ngFor="let client of options" (click)="selectClient(client)">
          <div class="d-flex pt-1 px-2"><i class="ti ti-user text-color-blue"></i></div>
          <div class="item-data w-100">
            <div class="item-name">
                <div *ngIf="client?.type == 'company'">
                    {{client?.name}}
                </div>
                <div *ngIf="client?.type == 'person'">
                    {{client?.firstName}} {{client?.lastName}}
                </div>
            </div>
            <div class="d-flex w-100 mt-2">
              <div>
                {{client?.street?.length > 0 ? client?.street + ',' : ''}} {{client?.postalCode}} {{client?.city}}
              </div>
              
              <div class="ms-auto" *ngIf="client?.nip?.length > 0">
                NIP: {{client?.nip}}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</div> -->

<mat-form-field class="w-full" subscriptSizing="dynamic">
  <mat-label class="flex justify-between" (click)="$event.preventDefault()">
    {{label}}  
    <small *ngIf="nameControl.value?.length > 0" (click)="edit()" class="cursor-pointer mx-2 text-gray-600 dark:text-gray-300">edytuj</small>
  </mat-label>
  <mat-icon matPrefix class="cursor-pointer" *ngIf="nameControl.value?.length == 0" (click)="showSelectClientDialog()" [svgIcon]="'tabler_outline:user-search'"></mat-icon>
  <mat-icon matPrefix class="cursor-pointer" *ngIf="nameControl.value?.length > 0" (click)="showSelectClientDialog()" [svgIcon]="'tabler_outline:user-check'"></mat-icon>
  <input type="text" class="cursor-pointer" [formControl]="nameControl" readonly matInput (click)="showSelectClientDialog()" />
  <mat-icon matSuffix class="cursor-pointer" *ngIf="nameControl.value?.length > 0" (click)="showSelectClientDialog()" [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
  <!-- <mat-select [formControl]="valueControl" placeholder="Wybierz nabywcę lub dodaj nowego">

    <div class="px-3 mt-2">
      <mat-form-field class="fuse-mat-dense w-full">
        <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"
          (click)="$event.stopPropagation(); searchText?.focus()"></mat-icon>
        <input matInput #searchText [formControl]="nameControl" placeholder="Szukaj w bazie" type="text" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
          (keyup)="1;" />
          <mat-icon matSuffix [svgIcon]="'heroicons_solid:x-mark'"
          (click)="nameControl.reset()"></mat-icon>
      </mat-form-field>
    </div>

    <mat-option (click)="showAddClientDialog()">
      <mat-icon [svgIcon]="'tabler_outline:plus'"></mat-icon>  Dodaj nowego klienta
    </mat-option>
    <mat-option [value]="client._id" *ngFor="let client of options" (click)="selectClient(client)">
      <div *ngIf="client?.type == 'company'">
        {{client?.name}}
      </div>
      <div *ngIf="client?.type == 'person'">
          {{client?.firstName}} {{client?.lastName}}
      </div>
    </mat-option>
  </mat-select> -->
</mat-form-field>