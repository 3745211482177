<div class="bg-card rounded-xl shadow-lg">
    <ng-container *ngFor="let element of data; let i = index;">
        <div class="invoice-card-header grid grid-cols-2 px-4 py-4 sm:px-6 sm:py-6" *ngIf="i == 0">
            <div>
                <div class="header-text">Lista przychodów</div>
                <div class="current-month">Ten miesiąc</div>    
            </div>
            <div class="text-end">
                <div class="header-text">{{selectedFieldId.text}}</div>
                <div class="current-month-value">
                    <div *ngFor="let sum of element.summary">
                        {{toDecimal(sum.summary)}} <span class="text-sm">{{sum.currency}}</span>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="element.invoices.length > 0">
            <div class="grid grid-cols-2 gap-6 border-t-4 rounded-xl px-4 sm:px-6 py-4 cursor-pointer" *ngIf="i > 0" (click)="element.toggleExpand()">
                <div class="month-name">
                    {{element.monthName}} {{element.year}} <span class="text-sm ml-2 text-gray-400">({{element.invoices.length}})</span>
                </div>
                <div class="month-value text-end">
                    <div *ngFor="let sum of element.summary">
                        {{toDecimal(sum.summary)}} <span class="text-sm text-gray-300">{{sum.currency}}</span>
                    </div>
                </div>
            </div>
            <!-- Hide months without data -->
            <ng-container *ngIf="element.expanded">
                <div class="invoice-card-table-header row" *ngIf="element.invoices.length > 0">
                    <div class="col-7">
                        Dokument
                    </div>
                    <div class="col-5 text-end">
                        {{selectedFieldId.text}}
                    </div>
                </div>
                <div [routerLink]="'/konto/dokumenty/' + invoice._id + '/podglad'" class="invoice-card-table-row row border-t rounded-xl" *ngFor="let invoice of element.invoices">
                    <div class="col-7">
                        <div class="invoice-buyer" *ngIf="!invoice.buyer">
                            Brak nabywcy
                        </div>
                        <div class="invoice-buyer" *ngIf="invoice.buyer.type == 'company'">
                            {{invoice.buyer.name}}
                        </div>
                        <div class="invoice-buyer" *ngIf="invoice.buyer.type == 'person'">
                            {{invoice.buyer.firstName}}  {{invoice.buyer.lastName}}
                        </div>
                        <div class="invoice-name">
                            {{invoice.invoiceName}}
                        </div>
                    </div>
                    <div class="col-5 text-end">
                       {{toDecimal(invoice[selectedFieldId.value])}} <span class="text-sm text-gray-300">{{invoice.currency}}</span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        
        
    </ng-container>
    
</div>