<div class="flex">
    <div *ngIf="data.showIcon" class="p-2">
        <div class="icon-container" [ngClass]="data?.type">
            <mat-icon *ngIf="data?.type == 'danger'">warning_amber</mat-icon>
            <mat-icon *ngIf="data?.type == 'warning' || data?.type == 'error'">error</mat-icon>
            <mat-icon *ngIf="data?.type == 'info'">notifications_none</mat-icon>
            <mat-icon *ngIf="data?.type == 'edit'">edit</mat-icon>
            <mat-icon *ngIf="data?.type == 'success'">check_circle</mat-icon>
        </div>
    </div>
    <div class="p-2 w-100">
        <div class="confirm-header">
            {{data?.title}}
            <button class="float-end close-btn" (click)="close(false)" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <p class="text-secondary confirm-message mt-3" [innerHTML]="data?.message">
        </p>
        <div *ngIf="data?.inputs?.length > 0">
            <form [formGroup]="form">
                <div *ngFor="let input of data?.inputs">
                    <div [ngSwitch]="input.type">
                        <div *ngSwitchCase="'checkbox'">
                            <mat-checkbox [formControlName]="input.name">{{input.description}}</mat-checkbox>
                        </div>
                        <div *ngSwitchCase="'text'">
                            <mat-form-field class="w-100">
                                <mat-label>{{input.description}}</mat-label>
                                <input [formControlName]="input.name" matInput [placeholder]="input.description"
                                    [value]="input.value">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<mat-dialog-actions [align]="'end'">
    <button mat-stroked-button *ngIf="data?.cancelText != null" (click)="close(false)"
        class="mc-button">{{data?.cancelText}}</button>
    <button mat-flat-button *ngIf="data?.confirmText != null" (click)="close(true)" [disabled]="!form.valid"
        [color]="(data?.type == 'warning' || data?.type == 'error' || data?.type == 'danger') ? 'warn' : 'primary'"
        class="mc-button">{{data?.confirmText}}</button>
</mat-dialog-actions>