import { AfterViewInit, Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { AppData } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { AuthService } from 'shared/services/auth.service';
import { DatabaseService } from 'shared/services/database.service';
import { SettingsService } from 'shared/services/settings.service';
import { Utils } from 'fakturnia-shared';
import { debounce } from 'lodash';
import { ToastService } from 'shared/services/toast.service';
import { NotificationData } from 'fakturnia-shared';
import { Settings } from 'fakturnia-shared';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements AfterViewInit, OnDestroy {

  subscriptions:Subscription[] = [];
  settings:Settings = new Settings();

 

  vatRates = AppData.VatRates;
  userVatRates: Set<string> = new Set();

  @ViewChild('settingsNav', { static: true }) private settingsNav: MatSidenav;
  mode: 'side' | 'over' = 'side';
  switchModeFromPx = 768;

  widthObserver;
  widthObserverElement;
  isMobile = false;
  shouldBeOpened = true;

  constructor(
    private _apiService:ApiService,
    private _settingsService:SettingsService,
    private _toastService:ToastService,
    private _authService:AuthService,
    private _zone: NgZone
    )
  {

    this.toggle = debounce(this.toggle,100)
   
    this.subscriptions.push(this._settingsService.getUserSettings().subscribe({
      next: (data)=> {
        if(Utils.isDefined(data,"settings"))  this.settings = <Settings>data.settings;
        this.userVatRates = new Set(this.settings.vatRates);
      }
    }))
  }

  ngAfterViewInit(): void {
    this.widthObserverElement = document.getElementById("sidenav-observer");
    this.widthObserver = new ResizeObserver(entries => {
      this._zone.run(() => {

        // this.isMobile = true
        if (entries[0].contentRect.width >= this.switchModeFromPx) this.isMobile = false
        else if (entries[0].contentRect.width < this.switchModeFromPx) this.isMobile = true
        
        if (entries[0].contentRect.width >= this.switchModeFromPx) this.mode = 'side';
        else if (entries[0].contentRect.width < this.switchModeFromPx) this.mode = 'over';

        this.toggle()
      });
    });
    this.widthObserver.observe(this.widthObserverElement);
  }

  toggle()
  {
    if(this.mode == 'over' || !this.settingsNav.opened)
    {
      this.settingsNav.toggle();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }



  update()
  {
    this._apiService.saveSettings(this.settings).subscribe({
      next: (data:any)=> {
        this._toastService.showToast(new NotificationData({
          title: 'Sukces',
          status: 'success',
          message: 'Zapisano ustawienia',
        }))
        if(Utils.isDefined(data,"settings")) this._settingsService.updateSettings(data.settings)
      }
    })
  }

  

}
