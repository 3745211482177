import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { catchError, of } from "rxjs";
import { ToastService } from "../services/toast.service";
import { SnackbarService } from "../services/snackbar.service";
import { settings } from "environments/environment";
import { AuthService } from "../services/auth.service";
import { NotificationData } from "fakturnia-shared";
import { APIResponse } from "fakturnia-shared";
import { ErrorUtils } from "../utils/error-utils";
import { DialogService } from "../services/dialog.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private readonly _toastService: ToastService = inject(ToastService)
  private readonly _snackbarService: SnackbarService = inject(SnackbarService)
  private readonly _authService: AuthService = inject(AuthService)
  private readonly _dialogService: DialogService = inject(DialogService)


  intercept(request: HttpRequest<any>, next: HttpHandler | any): any {
    return next.handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 500 || response.status == 400 || response.status == 404) {
            this._toastService.showToast(new NotificationData({
              title: 'Wystąpił błąd.',
              message: 'Nie udało się prawidłowo przetworzyć Twojego żądania. Zgłoś problem administratorowi.',
              status: 'danger',
              timeToClose: 0
            }))
          }

          else if (response.status === 401) {
            this._authService.logout({ muted: true })
          }

          else if (response.status === 422) {
            const errors:any = ErrorUtils.extractErrors(response.error)
            
            this._dialogService.showFormErrorsDialog(errors)
            this._toastService.showToast(new NotificationData({
              title: 'Formularz zawiera błędy',
              message: 'Popraw błędy w formularzu.',
              status: 'danger',
              timeToClose: 5000
            }))
          }

          // Synchronization needs error handling
          next.handle(response)
          return of(request)
        })
      )
  }
}


export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true
}




