import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DataSynchronizerService } from "./data-synchronization.service";
import { DbService } from "./db.service";
import { DbDocumentObject } from "./documents.service";
import { ActivityLogModelTypes, AppDb, DbActivityLog } from "fakturnia-shared";
import { DbProduct } from "fakturnia-shared";

@Injectable({
    providedIn: 'root'
})
export class ActivityLogsService implements OnDestroy {

 
    private _subscriptions:Subscription[] = [];
    private _activityLogs = new BehaviorSubject<DbActivityLogObject>({});
    currentActivityLogs = this._activityLogs.asObservable();

    private _db:AppDb
    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }

    constructor(
        private _dbService:DbService,
        private _dataSynchronizerService:DataSynchronizerService
        )
    {
            this._subscriptions.push(this._dbService.getDatabase().subscribe({
                next: (data)=> {
                    this._db = data
                }
            }))

            this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {
                    if(!data) return
                    if(data.table != 'activityLogs') return
    
                    // Process documents
                    // data.value = this._processDocuments(data.value)
                    // Update
                    data.value.forEach((activityLog: DbActivityLog) => {
                        this._activityLogs.value[<string>activityLog._id] = activityLog
                    });
    
                    // Filter by status
                    // Not a good idea
                    // const filtered: DbDocumentObject = Object.entries(this._documents.value)
                    //     .filter(([_, doc]) => doc.isDeleted === false)
                    //     .reduce((acc, [key, value]) => {
                    //         acc[key] = value;
                    //         return acc;
                    //     }, {} as DbDocumentObject);
    
                    // Push changes
                    this._activityLogs.next(this._activityLogs.value)
    
                    this._dataSynchronizerService.received()
                }
            }))
    }

    getInvoiceActivityLogs(invoiceId) {
        return Object.values(this._activityLogs.value).filter(x=>x.refId == invoiceId && x.modelType == ActivityLogModelTypes.DOCUMENT).sort((a:DbActivityLog,b:DbActivityLog) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    }

    getAll(): Observable<any> {
        if(!this._db) return of(null)
        const s1 = new Date().getTime();
        const loader = this._db.transaction("r", this._db.activityLogs, () => {
            return this._db.activityLogs.orderBy('createdAt').reverse().toArray();
        }).then((result: DbActivityLog[]) => {
            const object: DbActivityLogObject = {};
            result.forEach(activityLog => {
                object[<string>activityLog._id] = activityLog;
            });
            return object;
        });

        return forkJoin([from(loader)]).pipe(
            map(([activityLogs]:any) => {
                // Update your subjects
                this._activityLogs.next(activityLogs);
                return { activityLogs };
            }),
            tap(() => console.log(`[Activity Logs Service]: Built in ${new Date().getTime() - s1}ms.`))
        );
    }
}
export interface DbActivityLogObject {
    [key: string]: DbActivityLog;
}
