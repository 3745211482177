import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { SettingsService } from 'shared/services/settings.service';
import { ToastService } from 'shared/services/toast.service';
import { Utils } from 'fakturnia-shared';
import { GUSToDbClientConverter } from 'shared/utils/gus-to-db-client-converter';

@Component({
  selector: 'app-set-up-company-dialog',
  templateUrl: './set-up-company-dialog.component.html',
  styleUrls: ['./set-up-company-dialog.component.scss']
})
export class SetUpCompanyDialogComponent implements OnDestroy, AfterViewInit {

  subscriptions: Subscription[] = [];
  loading = false
  activeStep = 1

  accountNumbers:any = []
  selectedStepIndex = 0;
  firstForm = new UntypedFormGroup({
    nip: new UntypedFormControl('', [Validators.required, Validators.minLength(9)]),
    regon: new UntypedFormControl(''),
    name: new UntypedFormControl('', Validators.required),
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
  })

  secondForm = new UntypedFormGroup({
    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(9)]),
    street: new UntypedFormControl('', Validators.required),
    postalCode: new UntypedFormControl('', Validators.required),
    city: new UntypedFormControl('', Validators.required),
  })

  thirdForm = new UntypedFormGroup({
    bankAccountNumber: new UntypedFormControl('0', Validators.required),
  })

  dataLoadedFromGUS = false
  constructor(
    private apiService:ApiService,  
    private settingsService:SettingsService,  
    private dialogRef:MatDialogRef<SetUpCompanyDialogComponent>,
    private _toastService:ToastService,
    private _router:Router
    )
  {
  }


  ngAfterViewInit(): void {
  }

  touchAll(form) {
    Object.keys(form.controls).forEach(key=> {
      form.controls[key].markAsTouched();
    })
  }

  nextStep() {

    if(this.selectedStepIndex == 0 && this.firstForm.invalid) { this.touchAll(this.firstForm); return;}
    if(this.selectedStepIndex == 1 && this.secondForm.invalid) {  this.touchAll(this.secondForm); return;}
    this.selectedStepIndex++;
    if(this.selectedStepIndex > 2) this.selectedStepIndex = 2
  }

  onStepSelect(e){
    this.selectedStepIndex = e.selectedIndex
  }

  search() {
    if(this.loading) return
    if(this.firstForm.controls['nip']?.errors?.['length'] > 0)  return

    this.loading = true;

    this.subscriptions.push(this.apiService.searchInGUS(this.firstForm.controls['nip'].value)
    .pipe(take(1))
    .subscribe({
      next: (response:APIResponse) => {

        if(!response.data) {
          this.firstForm.controls['nip'].setErrors({
            notfound: true
          })
          this.loading = false
          return
        }

        if(typeof response.data.Nip != 'undefined') {

          this.dataLoadedFromGUS = true
          const company = GUSToDbClientConverter.convertOne(response.data)

          this.firstForm.controls['name'].setValue(company.name)
          this.firstForm.controls['regon'].setValue(company.regon)
          this.secondForm.controls['street'].setValue(company.street)
          this.secondForm.controls['postalCode'].setValue(company.postalCode)
          this.secondForm.controls['city'].setValue(company.city)
          this.activeStep = 2


          this.apiService.searchInMF(company.nip).pipe(take(1)).subscribe({
            next: (response:any)=> {
              if(typeof response.data.accountNumbers != 'undefined') {
                this.accountNumbers = response.data.accountNumbers;
                this.accountNumbers.forEach((number:any,index:number) => {
                    const bank:any = Utils.getBankByDigits(number)
                    let bankName = "Konto bankowe"
                    if(typeof bank.error == 'undefined') bankName = bank.bank
                    this.accountNumbers[index] = { bank: bankName, accountNumber: Utils.formatBankAccount(number)}
                });
                if(this.accountNumbers.length > 0) {
                  this.thirdForm.controls['bankAccountNumber'].setValue(this.accountNumbers[0].accountNumber)
                }
              }
              

              if(typeof response.data.name != 'undefined') {
                let result = Utils.extractNameInfo(response.data.name)
                if(result.firstName.length > 0) this.firstForm.controls['firstName'].setValue(result.firstName)
                if(result.lastName.length > 0) this.firstForm.controls['lastName'].setValue(result.lastName)
              }
            }
          })
        }


      },
      error: (err) => {
        this.loading = false
        this.activeStep = 1
      },
      complete: ()=> {
        this.loading = false
      }
    }))
  }

  close() {
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  save() {
    if(this.firstForm.invalid || this.secondForm.invalid || this.thirdForm.invalid) {
      this._toastService.showToast(new NotificationData({
        title: 'Wystąpił błąd',
        status: 'warning',
        message: 'Formularz zawiera błędy, popraw go i spróbuj ponownie.',
      }))
      return
    }

    const body = {
      firstName: this.firstForm.value.firstName,
      lastName: this.firstForm.value.lastName,
      name: this.firstForm.value.name,
      nip: this.firstForm.value.nip,
      regon: this.firstForm.value.regon,
      phoneNumber: this.secondForm.value.phoneNumber,
      street: this.secondForm.value.street,
      postalCode: this.secondForm.value.postalCode,
      city: this.secondForm.value.city,
      defaultBankAccount: this.thirdForm.value.bankAccountNumber,
      bankAccounts: this.accountNumbers
    }

    this.apiService.completeRegister(body)
    .pipe(take(1))
    .subscribe({
      next: (data:any)=> {
        if(data.success) {
          this._toastService.showToast(new NotificationData({
            title: 'Konfiguracja konta',
            status: 'success',
            message: 'Konto zostało prawidłowo skonfigurowane.',
          }))
          this.settingsService.refreshSettings()
          this.close()
        } else {
          this._toastService.showToast(new NotificationData({
            title: 'Wystąpił błąd',
            status: 'warning',
            message: 'Formularz zawiera błędy, popraw go i spróbuj ponownie.',
          }))
        } 
      }      
    })
  }
}
