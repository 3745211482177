import { Component } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { User } from 'fakturnia-shared';
import { UserSettings } from "fakturnia-shared";
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { BankAccountsApiService } from 'shared/services/api/bank-accounts.api.service';
import { DialogService } from 'shared/services/dialog.service';
import { SettingsService } from 'shared/services/settings.service';
import { IBankAccount } from 'fakturnia-shared';

@Component({
  selector: 'bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss']
})
export class BankAccountsComponent {

  subscriptions:Subscription[] = [];
  user:User
  bankAccounts:IBankAccount[] = []
  constructor(
    private _bankAccountAPIService:BankAccountsApiService,
    private settingsService:SettingsService,
    private dialogService:DialogService
  )
  {

    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data:UserSettings)=> {
        this.user = data.user
        this.bankAccounts = data.bankAccounts
      }
    }))

  }
  
  showAddOrUpdateBankAccount(bankAccount?:IBankAccount | undefined)
  {
    this.dialogService.showAddOrUpdateBankAccount(bankAccount)
  }

  setAsDefaultBankAccount(bankAccount:IBankAccount) {
    this._bankAccountAPIService.setDefaultBankAccount(bankAccount._id)
    .pipe(take(1))
    .subscribe({
      next: (data:APIResponse)=> {
        if(data.success == true) this.settingsService.refreshSettings()
      }
    })
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
