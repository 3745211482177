<h1 style="display:none;">Faktura końcowa - Co to jest? Bezpłatny program do wystawiania faktur przez przeglądarkę Fakturnia.pl</h1>

<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
        <div class="grid grid-cols-2 gap-8">
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto tracking-wide">
                <h2>
                    <strong>Faktura końcowa</strong> - Co to jest?<br />

                </h2>
                <p class="mt-2">
                    Czasem w biznesie zdarza się, że realizacja zlecenia wymaga przedpłat, co prowadzi do wystawiania
                    faktur zaliczkowych. Na zakończenie takiej transakcji wystawiana jest faktura końcowa, która dla
                    wielu przedsiębiorców może być trudna do zrozumienia. Jeśli również masz wątpliwości co do tego,
                    czym jest faktura końcowa i kiedy należy ją wystawić, możesz skorzystać z naszych wskazówek. Dzięki
                    nim dowiesz się, jakie informacje powinna zawierać ta faktura oraz jak efektywnie z niej korzystać.
                </p>

                <h2 class="mt-5">
                    Faktura końcowa - <strong>kiedy ją wystawić?</strong><br />

                </h2>
                <div class="mt-2">
                    W uproszczeniu, faktura końcowa jest dokumentem finalizującym transakcję handlową, która była
                    rozłożona w czasie, i dokonuje ostatecznego rozliczenia. Pojawia się, gdy nabywca towaru lub usługi
                    wpłaca część kwoty przed dostawą lub wykonaniem usługi. Faktura końcowa występuje w dwóch
                    sytuacjach, zależnych od terminów realizacji umowy:
                    <br />
                    <br />
                    <strong>1.</strong> Gdy zaliczka i dostawa są realizowane w tym samym okresie rozliczeniowym, kiedy
                    to zaliczka nie wymaga osobnej dokumentacji. W takiej sytuacji termin wystawienia faktury
                    zaliczkowej pokrywa się z terminem faktury końcowej. Zaliczkę uwzględnia się na fakturze końcowej, a
                    podatek VAT oblicza się od całkowitej kwoty zamówienia.
                    <br />
                    <br />
                    <strong>2.</strong> Gdy zaliczka i dostawa są realizowane w różnych okresach rozliczeniowych,
                    wówczas podatnik musi wystawić fakturę końcową po dostarczeniu towaru lub wykonaniu usługi. Faktura
                    ta powinna zawierać ustaloną cenę pomniejszoną o wpłacone zaliczki, wraz z wykazem numerów faktur
                    zaliczkowych dotyczących rozliczenia.
                </div>
               
            </div>
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
                <picture>
                    <img class="w-full" src="/assets/images/faktura_koncowa_fakturnia_pl.png"
                        alt="faktura końcowa wygenerowana w programie do faktur fakturnia.pl ">
                </picture>
                <a [routerLink]="'/konto/dokumenty/wystaw'">Wystaw taki dokument w naszym programie <i class="ti ti-arrow-narrow-right ms-2"></i></a>
            </div>
        </div>
    </div>
</section>

<read-more [path]="'/funkcjonalnosci-aplikacji/faktura-korygujaca'" [text]="'Faktura korygująca'"></read-more>