import { Invoice } from 'fakturnia-shared';
import { create } from 'xmlbuilder2';

export class JPKVatGenerator {
    public static create(invoices: Invoice[]) {
        const xml = create({
            version: '1.0',
            encoding: 'UTF-8'
        }).ele('tns:JPK', {
            'xmlns:etd': 'http://crd.gov.pl/xml/schematy/dziedzinowe/mf/2016/01/25/eD/DefinicjeTypy/',
            'xmlns:tns': 'http://jpk.mf.gov.pl/wzor/2017/11/13/1113/',
            'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance'
        });

        this._createHeader(xml,{});
        this._createSubject(xml);
        
        invoices.forEach(invoice=> {
            this._createSalesRow(xml, invoice);
        })
        this._createSalesControl(xml);

        const content = xml.end({ prettyPrint: true });

        this._downloadXmlFile(content)

    }

    private static _downloadXmlFile(xmlContent: string): void {
        const blob = new Blob([xmlContent], { type: 'text/xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'jpk_vat.xml';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }

    private static _createHeader(xml: any, headerData): void {
        xml.ele('tns:Naglowek')
            .ele('tns:KodFormularza', { kodSystemowy: 'JPK_VAT (3)', wersjaSchemy: '1-1' })
            .txt('JPK_VAT')
            .up()
            .ele('tns:WariantFormularza')
            .txt('3')
            .up()
            .ele('tns:CelZlozenia')
            .txt('0')
            .up()
            .ele('tns:DataWytworzeniaJPK')
            .txt('2024-03-15T21:53:43Z')
            .up()
            .ele('tns:DataOd')
            .txt('2024-02-01')
            .up()
            .ele('tns:DataDo')
            .txt('2024-02-29')
            .up()
            .ele('tns:NazwaSystemu')
            .txt('fakturnia.pl');
    }

    private static _createSubject(xml: any): void {
        xml.ele('tns:Podmiot1')
            .ele('tns:NIP')
            .txt('6711063989')
            .up()
            .ele('tns:PelnaNazwa')
            .txt('OW Gacek Iwona Kołakowska');
    }

    private static _createSalesRow(xml: any, invoice: Invoice): void {

        const K_19 = invoice.netSum.toString()
        const K_20 = invoice.vatSum.toString()
        xml.ele('tns:SprzedazWiersz')
            .ele('tns:LpSprzedazy')
            .txt('1')
            .up()
            .ele('tns:NrKontrahenta')
            .txt(invoice.buyer.nip)
            .up()
            .ele('tns:NazwaKontrahenta')
            .txt('Jurek, jesteś?')
            .up()
            .ele('tns:AdresKontrahenta')
            .up()
            .ele('tns:DowodSprzedazy')
            .txt(invoice.invoiceName)
            .up()
            .ele('tns:DataWystawienia')
            .txt(invoice.issueDateHeader.value)
            .up()
            .ele('tns:DataSprzedazy')
            .txt(invoice.subHeader.value)
            .up()
            .ele('tns:K_19')
            .txt(K_19)
            .up()
            .ele('tns:K_20')
            .txt(K_20);
    }

    private static _createSalesControl(xml: any): void {
        xml.ele('tns:SprzedazCtrl')
            .ele('tns:LiczbaWierszySprzedazy')
            .txt('1')
            .up()
            .ele('tns:PodatekNalezny')
            .txt('818.64');
    }
}

export default JPKVatGenerator;
