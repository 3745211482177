    <div style="position:absolute; top:10px; right: 10px;">
        <button mat-button (click)="close()">Zamknij <mat-icon>close</mat-icon></button>
    </div>
    <ngx-extended-pdf-viewer *ngIf="previewBase64"
    [base64Src]="previewBase64"
    backgroundColor="#d8d8d8"
    [height]="'auto'"
    [showToolbar]="false"
    [showSidebarButton]="false"
    [showFindButton]="false"
    [showPagingButtons]="false"
    [showDrawEditor]="false"
    [showStampEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="true"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showPrintButton]="false"
    [showDownloadButton]="false"
    [showSecondaryToolbarButton]="false"
    [showRotateButton]="undefined"
    [showHandToolButton]="true"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [showPropertiesButton]="false"
    [zoom]="zoom"
    >
    </ngx-extended-pdf-viewer>

