<div class="relative w-full z-49 border-t bg-card dark:bg-transparent print:hidden">
    
    <div class="max-w-280 mx-auto px-8">
        <a class="w-24 link" [routerLink]="'/'">
            <!-- Logo -->
            <div class="flex items-center pt-6 px-6 pb-4">
                <img class="w-28" src="assets/images/logo-a.png" alt="Logo image">
                <div class="hidden sm:block text-2xl font-bold ml-6 ">
                    Fakturnia.pl <br/>
                    <span class="text-md font-light">Bezpłatny program do zarządzania dokumentami</span>
                </div>
            </div>
        </a>
    </div>
    

    <div class="max-w-280 mx-auto grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-6 place-items-center px-8">
        
       

        <mat-accordion class="w-full h-full" multi>
            <mat-expansion-panel  [expanded]="true" class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title class="border-b pb-2">Masz pytania?</mat-panel-title>
              </mat-expansion-panel-header>
              <a class="block link"[routerLink]="'/kontakt'">Kontakt z nami</a>
              <a class="block link"href="https://pomoc.fakturnia.pl/inne/czesto-zadawane-pytania" target="_blank">Często zadawane pytania</a>
              <a class="block link"href="https://pomoc.fakturnia.pl" target="_blank">Materiały pomocnicze</a>
              <a class="block link"[routerLink]="'/blog'">Blog</a>  
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="w-full h-full" multi>
            <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title class="border-b pb-2">Strony</mat-panel-title>
              </mat-expansion-panel-header>
              <a class="block link" [routerLink]="'/funkcjonalnosci-aplikacji'">O nas</a>
              <a class="block link" [routerLink]="'/logowanie'">Logowanie</a>
              <a class="block link" [routerLink]="'/rejestracja'">Rejestracja</a>
              <a class="block link" [routerLink]="'/przypomnij-haslo'">Przypomnienie hasła</a>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="w-full h-full" multi>
            <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title class="border-b pb-2">Rozwiązania</mat-panel-title>
              </mat-expansion-panel-header>
              <a class="block link" [routerLink]="'/funkcjonalnosci-aplikacji'">Funkcjonalności aplikacji</a>
              <a class="block link" [routerLink]="'/funkcjonalnosci-aplikacji/podglad-faktury'">Podgląd faktury</a>
              <a class="block link" [routerLink]="'/funkcjonalnosci-aplikacji/faktura-vat'">Faktura VAT</a>
              <a class="block link" [routerLink]="'/funkcjonalnosci-aplikacji/faktura-koncowa'">Faktura końcowa</a>
              <a class="block link" [routerLink]="'/funkcjonalnosci-aplikacji/faktura-vat-marza'">Faktura VAT marża</a>
              <a class="block link" [routerLink]="'/funkcjonalnosci-aplikacji/faktura-korygujaca'">Faktura korygująca</a>
            </mat-expansion-panel>
        </mat-accordion>

    </div>

    <div class="max-w-280 mx-auto py-4 text-base flex items-center justify-between border-t px-8 flex-wrap-reverse">

        <div class="flex-none text-xs py-2">© {{currentYear}} Fakturnia.pl <span class="ml-4">v{{config.version}}</span> </div>
        <!-- <div class="flex-auto flex justify-center"><a [routerLink]="'/regulamin'">Regulamin</a></div> -->
        <div class="flex-auto flex justify-end space-x-4">
            <a [routerLink]="'/regulamin'">Regulamin</a>
            <a [routerLink]="'/polityka-prywatnosci'">Polityka prywatnosci</a>
        </div>
        <div></div>
        
    </div>
</div>