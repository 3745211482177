import { Component } from '@angular/core';

@Component({
  selector: 'app-functionalities',
  templateUrl: './functionalities.component.html',
  styleUrls: ['./functionalities.component.scss']
})
export class FunctionalitiesComponent {

  documents = [
    'Faktura VAT', 
    'Faktura zaliczkowa', 
    'Faktura końcowa',
    'Faktura korygująca',
    'Faktura VAT marża',
    'Faktura proforma',
    'Faktura proforma bez VAT'
  ]
}
