<section class="container">
    <div class="w-full lg:w-140 mx-auto">
        <div class="text-center bg-white dark:bg-slate-800 my-12 py-8 rounded-xl">
            <h2 class="mb-5">
                Nie masz jeszcze <b>konta</b>?
                <div class="h5">dołącz do grona zadowolonych użytkowników</div>
            </h2>
            <a [routerLink]="'/rejestracja'">
                <div class="f-button my5-2 mx-auto w-full">
                    Dołącz do nas
                    <i class="ti ti-arrow-narrow-right ms-2"></i>
                </div>
            </a>
        </div>
    </div>
</section>