<div class="px-3">
    <div class="row">
        <div class="col-12 col-sm-8 pt-3 pl-4">
            <h2 *ngIf="mode == 'create'">Dodaj klienta</h2>
            <h2 *ngIf="mode == 'update'">Edycja klienta</h2>
        </div>
        <div class="col-12 col-sm-4">
            <span class="float-end mt-2">
                <!-- <button mat-icon-button matTooltip="Ustawienia">
                    <mat-icon>settings</mat-icon>
                </button> -->
                <button (click)="close()" mat-icon-button matTooltip="Zamknij okno">
                    <mat-icon>close</mat-icon>
                </button>
            </span>
        </div>
    </div>

</div>
<mat-divider class="my-3"></mat-divider>
<div class="px-3">
    <form [formGroup]="clientForm">
        <div class="row mb-2">
            <div class="col-12">
                <h5>Informacje podstawowe</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Typ klienta</mat-label>
                    <mat-select formControlName="type" >
                        <mat-option value="company">Firma</mat-option>
                        <mat-option value="person">Osoba fizyczna</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-9">
                <mat-form-field class="w-100" [appearance]="appearance">
                    <mat-label>Nazwa firmy</mat-label>
                    <input matInput type="text" formControlName="name" />
                </mat-form-field>
            </div>
            <div class="col-5">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Imie</mat-label>
                    <input matInput type="text" formControlName="firstName" />
                </mat-form-field>
            </div>
            <div class="col-7">
                <mat-form-field class="w-100" [appearance]="appearance">
                    <mat-label>Nazwisko</mat-label>
                    <input matInput type="text" formControlName="lastName" />
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <h5>Dane firmy / osobowe</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>NIP</mat-label>
                    <input matInput type="text" formControlName="nip" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Pesel</mat-label>
                    <input matInput type="text" formControlName="pesel" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Numer dowodu</mat-label>
                    <input matInput type="text" formControlName="idNumber" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>REGON</mat-label>
                    <input matInput type="text" formControlName="regon" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>KRS</mat-label>
                    <input matInput type="text" formControlName="krs" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>BDO</mat-label>
                    <input matInput type="text" formControlName="bdo" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Pole opisowe</mat-label>
                    <input matInput type="text" formControlName="description" />
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <h5>Adres</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Ulica</mat-label>
                    <input matInput type="text" formControlName="street" />
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Kod pocztowy</mat-label>
                    <input matInput type="text" formControlName="postalCode" />
                </mat-form-field>
            </div>
            <div class="col-5">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Miasto</mat-label>
                    <input matInput type="text" formControlName="city" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Kraj</mat-label>
                    <input matInput type="text" formControlName="country" />
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <h5>Dane kontaktowe</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Telefon</mat-label>
                    <input matInput type="text" formControlName="phoneNumber" />
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Fax</mat-label>
                    <input matInput type="text" formControlName="fax" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Adres e-mail</mat-label>
                    <input matInput type="text" formControlName="email" />
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field [appearance]="appearance" class="w-100">
                    <mat-label>Strona internetowa www</mat-label>
                    <input matInput type="text" formControlName="www" />
                </mat-form-field>
            </div>
        </div>
      
        <div class="row">
            <div class="col-12">
                
                <button class="me-2" *ngIf="mode == 'update'" color="warn" mat-button (click)="deleteClient()">
                    <span>Usuń</span>
                  </button>

                <button *ngIf="mode == 'create'" [disabled]="!clientForm.valid" color="primary" mat-flat-button (click)="createClient()">
                    <span >Dodaj klienta</span> 
                </button>
                
                <button *ngIf="mode == 'update'" [disabled]="!clientForm.valid" color="primary" mat-flat-button (click)="updateClient()">
                    <span >Zapisz zmiany</span> 
                </button>
            </div>
        </div>
    </form>
</div>