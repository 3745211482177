<div class="flex items-center justify-between">

    <div class="text-2xl">
        Dostosuj dokument
    </div>

    <button mat-icon-button [matDialogClose]="undefined">
        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>

</div>

<div class="grid grid-cols-6 gap-3 scrollable pr-3">
    <div class="col-span-6 md:col-span-3">
        <h6>Ogólne</h6>

        <div *ngFor="let field of generalToggleableFields">
            <mat-checkbox [matTooltip]="generalFields[field]?.informations" [checked]="generalFieldsToShow.has(field)"
                (change)="toggleFieldVisibility('general',field,$event)" color="primary">
                {{generalFields[field].text}}
            </mat-checkbox>
        </div>
    </div>

    <div class="col-span-6 md:col-span-3">
        <!-- <h6>Waluta</h6> -->
        <mat-form-field class="w-full" subscriptSizing="dynamic">
            <mat-label>Waluta</mat-label>
            <mat-select [(ngModel)]="currency">
                <mat-option *ngFor="let cur of currencies" [value]="cur">{{cur}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="saveChanges()">Zapisz</button>
    <button mat-flat-button (click)="close()">Zamknij</button>
</mat-dialog-actions>