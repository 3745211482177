<form [formGroup]="form">
    <div class="row">
        <div class="col-12">
            <h4>Dane personalne</h4>
        </div>
        <div class="col-12 pe-md-1 col-md-6 mt-2">
            <div class="form-floating">
                <input formControlName="firstName" type="text" class="form-control text-left" id="firstName"
                    placeholder="Imię" />
                <label for="firstName">Imię</label>
            </div>
        </div>
        <div class="col-12 ps-md-1 col-md-6 mt-2">
            <div class="form-floating">
                <input formControlName="lastName" type="text" class="form-control text-left" id="lastName"
                    placeholder="Nazwisko" />
                <label for="lastName">Nazwisko</label>
            </div>
        </div>
        <div class="col-12 mt-2">
            <div class="form-floating">
                <input formControlName="emailAddress" type="text" class="form-control text-left" id="emailAddress"
                    placeholder="Adres e-mail" />
                <label for="emailAddress">Adres e-mail</label>
            </div>
        </div>

        <div class="col-12 mt-2">
            <div class="form-floating">
                <input formControlName="phoneNumber" type="text" class="form-control text-left" id="phoneNumber"
                    placeholder="Numer telefonu" />
                <label for="phoneNumber">Numer telefonu</label>
            </div>
        </div>
        <div class="col-12 pe-md-1 col-md-6 mt-2">
            <div class="form-floating">
                <input formControlName="pesel" type="text" class="form-control text-left" id="pesel"
                    placeholder="PESEL" />
                <label for="pesel">PESEL</label>
            </div>
        </div>
        <div class="col-12 ps-md-1 col-md-6 mt-2">
            <div class="form-floating">
                <input formControlName="idNumber" type="text" class="form-control text-left" id="idNumber"
                    placeholder="Numer dowodu" />
                <label for="idNumber">Numer dowodu</label>
            </div>
        </div>
        <div class="col-12 mt-3">
            <button [disabled]="form.invalid || !isEditable" class="f-button ms-auto md" (click)="saveChanges()">
                Zapisz zmiany
            </button>
        </div>
    </div>

</form>