import { Injectable } from "@angular/core";
import { Utils } from 'fakturnia-shared';
import { DocumentsService } from "./documents.service";
import { Invoice } from "fakturnia-shared";

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {
    constructor(private _documentsService:DocumentsService)
    {

    }

    public getById(invoiceId): Invoice | null
    {
        const document = this._documentsService.getById(invoiceId)
        if (!document || typeof document == 'undefined') {
          console.warn(`[InvoicesService]: Invoice with _id: ${invoiceId} not found.`)
          return null
        }
    
        let invoice = new Invoice().fromDbDocument(document)

        if(invoice.prepaymentIds.length > 0) {
            invoice.prepayments = []
            invoice.prepaymentIds.forEach(prepaymentId => {
                const prepayment = this._documentsService.getById(prepaymentId)
                if(prepayment) invoice.addPrepayment(prepayment)
            })
        }

        if(!Utils.isNullOrEmpty(invoice.correctedInvoiceId)) {
        invoice.correctedInvoice = null
        const correctedInvoice = this._documentsService.getById(invoice.correctedInvoiceId)
        if(correctedInvoice) invoice.addCorrectedInvoice(correctedInvoice, false)
        }

        invoice.count()
        return invoice
    }
}