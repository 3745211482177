import { NgxSeo } from "./modules/ngx-seo/ngx-seo";

const SITE_NAME = ' - Fakturnia.pl'
const BASIC_KEYWORDS = ',fakturowanie online, wystawianie faktur, faktury, aplikacja do faktur, program, program do faktur'

export class  SEOData {
    
    public static DEFAULT_TITLE = "Bezpłatny program do wystawiania faktur online" + SITE_NAME
    public static BLOG: NgxSeo = {
      title: 'Blog - Bezpłatny program do wystawiania faktur online' + SITE_NAME,
      meta: {
        description: 'Zapoznaj się z naszymi najnowszymi wpisami na blogu.',
        keywords: 'blog, darmowy program do wystawiania faktur online, faktury .pdf, bezpłaty, program do faktur, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };
   
    public static WELCOME: NgxSeo = {
      title: 'Bezpłatny program do wystawiania faktur online' + SITE_NAME,
      meta: {
        description: 'Nasz program to idealne rozwiązanie dla małych firm, które potrzebują intuicyjnego i bezpłatnego narzędzia do wystawiania faktur przez przeglądarkę.',
        keywords: 'Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static HELP: NgxSeo = {
      title: 'Potrzebujesz pomocy w obsłudze naszego programu do wystawiania faktur?' + SITE_NAME,
      meta: {
        description: 'Nie możesz znaleźć interesującej Cię funkcjonalności w naszym programie do generowania faktur?',
        keywords: 'Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static ABOUT: NgxSeo = {
      title: 'Pomagamy przedsiębiorcom w tworzeniu dokumentów .PDF' + SITE_NAME,
      meta: {
        description: 'Nasz program ułatwia proces tworzenia faktur, dając możliwość podglądu dokumentu już w trakcie jego tworzenia.',
        keywords: 'Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static ISSUE: NgxSeo = {
      title: 'Wystaw nowy dokument' + SITE_NAME,
      meta: {
        description: 'Utwórz nowy dokument i wydrukuj go w formacie .pdf bez żadnych ograniczeń ilościowych.',
        keywords: 'Darmowy program do wystawiania faktur online,faktury .pdf, program do faktur, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static FUNCTIONALITIES: NgxSeo = {
      title: 'Funkcjonalności aplikacji do fakturowania' + SITE_NAME,
      meta: {
        description: 'Generowanie faktur w formacie.pdf. Własna baza klientów. Własna baza produktów. Konfiguracja formularzy.',
        keywords: 'Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, baza klientów, baza produktów, generowanie faktur.pdf, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static INVOICE_PREVIEW: NgxSeo = {
      title: 'Podgląd faktury podczas edycji' + SITE_NAME,
      meta: {
        description: 'Tworzenie faktur staje się jeszcze przyjaźniejsze, gdy widzimy jak wygląda dokument, który tworzymy.',
        keywords: 'Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, baza klientów, baza produktów, generowanie faktur.pdf, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static FAKTURA_VAT: NgxSeo = {
      title: 'Faktura VAT - co to jest?' + SITE_NAME,
      meta: {
        description: 'Faktura jest kluczowym dokumentem, który potwierdza przeprowadzenie transakcji podlegającej opodatkowaniu VAT. Może być ona wygenerowana w postaci papierowej lub elektronicznej. Zgodnie z ogólną zasadą, fakturę należy wystawić najpóźniej do 15. dnia miesiąca następującego po miesiącu, w którym miała miejsce dostawa towaru lub wykonanie usługi.',
        keywords: 'Faktura VAT, co to jest, Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, baza klientów, baza produktów, generowanie faktur.pdf, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static FAKTURA_KONCOWA: NgxSeo = {
      title: 'Faktura końcowa - co to jest?' + SITE_NAME,
      meta: {
        description: 'Faktura końcowa jest dokumentem handlowym wydawanym na zakończenie procesu realizacji umowy bądź transakcji handlowej. Stanowi ona ostateczne podsumowanie wszystkich etapów sprzedaży lub wykonania usługi, zawierając informacje o wszelkich wcześniejszych fakturach częściowych lub zaliczkowych.',
        keywords: 'Faktura końcowa, co to jest, Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, baza klientów, baza produktów, generowanie faktur.pdf, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static FAKTURA_KORYGUJACA: NgxSeo = {
      title: 'Faktura korygująca - co to jest?' + SITE_NAME,
      meta: {
        description: 'Faktura korygująca stanowi specjalny dokument księgowy, który jest wystawiany przez sprzedawcę w szczególnych sytuacjach, takich jak zmniejszenie lub zwiększenie podstawy opodatkowania, zwrot towarów lub udzielenie rabatu. W zależności od powodu wystawienia korekty, istnieją różne procedury postępowania z nią.',
        keywords: 'Faktura korygująca, co to jest,Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, baza klientów, baza produktów, generowanie faktur.pdf, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static FAKTURA_VAT_MARZA: NgxSeo = {
      title: 'Faktura VAT marża - co to jest?' + SITE_NAME,
      meta: {
        description: 'Marża w podatku VAT, zgodnie z przepisami, to różnica między kwotą, którą płaci nabywca towarów lub usług, a ceną zakupu, pomniejszoną o podatek.',
        keywords: 'Faktura VAT marża, Darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, baza klientów, baza produktów, generowanie faktur.pdf, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

    public static CONTACT: NgxSeo = {
      title: 'Kontakt - Bezpłatny program do wystawiania faktur online' + SITE_NAME,
      meta: {
        description: 'Jeśli masz jakieś pytania lub potrzebujesz pomocy w korzystaniu z naszego programu do wystawiania faktur, skontaktuj się z nami! Nasz zespół jest gotowy, by Ci pomóc i odpowiedzieć na wszystkie Twoje pytania.',
        keywords: 'kontakt, pomoc, support, zgłoszenia, faktury .pdf' + BASIC_KEYWORDS
      },
    };

    public static LOGIN: NgxSeo = {
      title: 'Logowanie - Bezpłatny program do wystawiania faktur online' + SITE_NAME,
      meta: {
        description: 'Zaloguj się do swojego konta w naszym programie do wystawiania faktur online',
        keywords: 'logowanie, konto, pomoc, support, darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców ' + BASIC_KEYWORDS
      },
    };

    public static REGISTER: NgxSeo = {
      title: 'Rejestracja - Bezpłatny program do wystawiania faktur online' + SITE_NAME,
      meta: {
        description: 'Zarejestruj się w 28 sekund i korzystaj całkowicie za darmo z wielu dodatkowych funkcji naszego programu do wystawiania faktur online!',
        keywords: 'rejestracja, konto, nowe konto, kontakt, pomoc, support, darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };
   
    public static FORGOT_PASSWORD: NgxSeo = {
      title: 'Przypomnienie hasła - Bezpłatny program do wystawiania faktur online' + SITE_NAME,
      meta: {
        description: 'Straciłeś dostęp do swojego konta? Odzyskaj dostęp za pomocą adresu e-mail.',
        keywords: 'przypomnienie hasła, odzyskiwanie konta, bezpłatny program do faktur, konto, nowe konto, kontakt, pomoc, support, darmowy program do wystawiania faktur online, faktury .pdf, program do faktur, darmowe narzędzie do wystawiania faktur, faktury online, narzędzie dla małych firm, intuicyjne narzędzie do faktur, bezpłatne faktury przez przeglądarkę, narzędzie dla przedsiębiorców, prosty sposób na faktury, darmowe rozwiązanie dla małych firm, online fakturacja, faktury dla przedsiębiorców' + BASIC_KEYWORDS
      },
    };

}