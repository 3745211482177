<div class="row" style="max-width:500px;">
    <div class="col-12">
      <h4>Zmiana adresu e-mail</h4>

      <form [formGroup]="form">
        
        <div class="form-floating mt-2">
          <input formControlName="emailAddress" lowercaseEmail placeholder="Nowy adres-email" type="text" autocomplete="off"
            class="form-control text-left" />
          <label for="emailAddress">Nowy adres e-mail</label>
        </div>
        <div class="form-floating mt-2">
            <input formControlName="emailAddressRepeat" lowercaseEmail  placeholder="Powtórz nowy adres e-mail" type="text" autocomplete="off"
              class="form-control text-left" />
            <label for="emailAddressRepeat">Powtórz nowy adres e-mail</label>
          </div>
        <div class="form-floating mt-2">
          <input formControlName="password" placeholder="Potwierdź zmiany hasłem" autocomplete="off"
            type="password" class="form-control text-left" />
          <label for="password">Hasło</label>
        </div>

        <div class="color-red" *ngIf="form.hasError('matchFields')">
          Powtórzony adres e-mail jest inny.
        </div>

      </form>


      <button [disabled]="!form.valid" class="f-button ms-auto md mt-2"
        (click)="changeEmailAddress()">Zmień email</button>

    </div>
  </div>
