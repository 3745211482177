import { Component, Input } from '@angular/core';
import { APIResponse } from 'fakturnia-shared';
import { Observable, map, take } from 'rxjs';
import { ApiService } from 'shared/services/api.service';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'remote-login',
  templateUrl: './remote-login.component.html',
  styleUrls: ['./remote-login.component.scss']
})
export class RemoteLoginComponent {
  @Input('user') user
  loginUrl: string | null = null
  constructor(
    private _apiService: ApiService,
    private _toastService:ToastService
    ) {

  }

  public remoteLogin() {
    this._getRemoteAccessUrl().subscribe((url: string | null) => {
      if (url) {
        this.loginUrl = url;
      } 
    });
  }
  
  private _getRemoteAccessUrl(): Observable<string | null> {
    const url = 'http://localhost:4200/remote-login/';
    return this._apiService.createOneTimeToken(this.user._id)
      .pipe(
        take(1),
        map((response: APIResponse) => {
          if (response.success) {
            return `${url}${response.data.oneTimeTokenId}`;
          } else {
            this._toastService.warning('Nie udało się pobrać tokenu.');
            return null;
          }
        })
      );
  }
}
