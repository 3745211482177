import { Component, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DatabaseService } from 'shared/services/database.service';
import { PanelService } from 'shared/services/panel.service';
import { ApiService } from 'shared/services/api.service';
import { DialogService } from 'shared/services/dialog.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { ProductsService } from 'shared/services/products.service';
import { NotificationData } from 'fakturnia-shared';
import { ToastService } from 'shared/services/toast.service';
import { APIResponse } from 'fakturnia-shared';
import { DbProduct } from 'fakturnia-shared';
import { Utils } from 'fakturnia-shared';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from 'shared/components/pagination/pagination.component';
import { MatCheckboxModule } from '@angular/material/checkbox';


@Component({
  standalone: true,
  selector: 'app-products',
  imports: [CommonModule, MatIconModule, MatButtonModule, MatCheckboxModule, MatInputModule, ReactiveFormsModule, PaginationComponent],
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnDestroy{
  subscriptions: Subscription[] = [];

  activeFilters = new Set<any>();
  bsActiveFilters = new BehaviorSubject<any>(this.activeFilters);
  currentActiveFilters = this.bsActiveFilters.asObservable();

  searchResults:any;
  products: DbProduct[] = [];

  // Pagination
  defaultPageSize = 10;
  allPages = 0;
  currentPage = 1;

  count = 0;

  selected = new Set<string>();
  constructor (
    private _productsService:ProductsService,
    private _synchronizationService:SynchronizationService,
    private panelService:PanelService,
    private apiService:ApiService,
    private _toastService:ToastService,
    private dialogService:DialogService,
  )
  {
    // this.browseProducts = debounce(this.browseProducts,10)
    this.subscriptions.push(this.currentActiveFilters.subscribe({
      next:  (data)=>
        {
          this.activeFilters = data;
          
          setTimeout( async ()=>{
            this.browseProducts();
          })
        }
      }
    ))

    this.subscriptions.push(this._productsService.currentProducts.subscribe(
      {
        next: (data)=> {
          this.products = Object.values(data)
          this.browseProducts();
          this.updatePagination();
        }
      }
    ))
  }

  search(value)
  {
    this.currentPage = 1;
    this.updateFilterSet('searchText',value)
    this.browseProducts();
  }

  time;
  results:any = [];
  resultsCount = 0;
  searchResultsCount = 0;


  browseProducts()
  {
    let t1 = new Date().getTime();

    let fromIndex = (this.currentPage * this.defaultPageSize) - this.defaultPageSize;
    let toIndex = fromIndex + (this.defaultPageSize - 1);

    this.results = this.products
    .filter((product:DbProduct)=>!product.isDeleted)
    .sort((a:any,b:any)=>  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    this.count = this.results.length;
    this.activeFilters.forEach((element:any) => {

      let key = element[0];
      let value = element[1];

      let filterValue = value;
      if(typeof filterValue == 'string') { filterValue = filterValue.toLowerCase()}
      
      if(key == 'searchText')
      {
        this.results = this.results.filter(
          (product:DbProduct) => 
          {
            let found = false; 
            let name = '';
            
            
            if(Utils.isNullOrEmpty(product)) return false;
            if (!Utils.isNullOrEmpty(product['name'])) { name = product['name'].toLowerCase(); }
       
            if (name.includes(filterValue)) { found = true }
           
            return found;
          })
      }
    })

    this.resultsCount =  this.results.length;
    this.searchResults = this.results;
    this.searchResultsCount = this.searchResults.length;

    if(toIndex > this.searchResultsCount-1)
    {
      toIndex = this.searchResultsCount-1;
    }

    this.products = Utils.getObjectsInRange( this.results,fromIndex,toIndex);
    this.time = new Date().getTime() - t1;

    this.allPages = Math.ceil(this.results.length / this.defaultPageSize);


  }

  openProductPanel(mode, product = null)
  {
    this.panelService.openProductPanel(mode, product)
  }

  onPageChange(page)
  {
    this.currentPage = page;
    this.browseProducts();
  }

  updatePagination()
  {
    this.allPages = Math.ceil(this.products.filter((product:DbProduct)=>!product.isDeleted).length / this.defaultPageSize);
    this.currentPage = 1;
  }

  private updateFilterSet(key, value) {
    if (!value || value == '') {
      // Jeśli wartość jest pustym ciągiem lub białym znakiem, usuń filtr z zestawu
      for (let filter of this.activeFilters) {
        if (filter[0] === key) {
          this.activeFilters.delete(filter);
          this.searchResults = this.products.filter((product:DbProduct)=>!product.isDeleted)
          this.bsActiveFilters.next(this.activeFilters)
          return;
        }
      }
      return;
    }
    // Sprawdzenie, czy dany klucz już istnieje w secie
    for (let filter of this.activeFilters) {
      if (filter[0] === key) {
        // Jeśli klucz istnieje, zaktualizuj wartość filtra
        // Jezeli nowa wartość filtra jest inna od obecnego
        if(filter[1] != value) {
          // Jezeli jest stringiem
          if(typeof filter[1] == 'string')
          {
            // Zwieksz zakres tylko jezeli wartosc jest mniejsza
            //sprawdz czy jest mniejsza (zwieksz zakres)
            if(filter[1].length > value.length)
            {
              this.searchResults = this.products.filter((product:DbProduct)=>!product.isDeleted)
            }

            filter[1] = value;
          }
          // Jezeli jest pozostałym typem
          else {
            filter[1] = value;
            this.searchResults = this.products.filter((product:DbProduct)=>!product.isDeleted)
          }
        } 

        
        this.bsActiveFilters.next(this.activeFilters)
        return;
      }
    }
    // Jeśli klucz nie istnieje, dodaj nowy filtr
    this.activeFilters.add([key, value]);
    this.bsActiveFilters.next(this.activeFilters)
  }

  selectAll(e:any)
  {
    if(e.checked == true) {
      this.products.forEach((product:DbProduct)=>{
        if(typeof product._id !== 'undefined')  this.selected.add(<string>product._id)
      })
    }
    else this.selected = new Set()
  }

  toggleSelect(id)
  {
    if(this.selected.has(id)) this.selected.delete(id)
    else this.selected.add(id)
  }

  deleteSelected()
  {

    if(typeof this.selected === 'undefined') return;
    if(this.selected.size === 0) return;
    
      this.subscriptions.push(this.dialogService.confirmDialog({
        title: "Usuwanie zaznaczonych",
        message: `Chcesz usunąć zaznaczone elementy? Wybranych produktów: ${this.selected.size}`,
        type: "danger",
        confirmText: "Usuń"
      }).subscribe(
        {
          next: (data)=>{

            if(data == true)
            {
              this.subscriptions.push(this.apiService.deleteProducts(Array.from(this.selected)).subscribe({
                next: (response:APIResponse)=>{
                  // this.databaseService.processDocuments([data])
                  this._synchronizationService.synchronize()
                  this._toastService.showToast(new NotificationData({
                    title: 'Sukces',
                    status: 'info',
                    message: 'Operacja usuwania się powiodła.',
                  }))
                  this.selected = new Set()
                },
                error: (err)=>{
                  this._toastService.showToast(new NotificationData({
                    title: 'Wystąpił błąd',
                    status: 'danger',
                    message: 'Operacja usuwania się nie powiodła.',
                  }))
                }
              }))
            }
          },
          error: (err)=> {

          }
        }
      ))
    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
}

