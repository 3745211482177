<form  style="max-width:500px;">
    <div class="row mt-2">
        <div class="col-12">
            <h4>Miejsce wystawienia</h4>
            <p>Ustaw domyślne miejsce wystawienia</p>
        </div>
    </div>
    <div class="row my-2">
        <div class="col-12">
            <div class="form-floating">
                <input type="text" class="form-control text-left" id="issuePlace"
                    placeholder="Miejsce wystawienia" [formControl]="control">
                <label for="issuePlace">Miejsce wystawienia</label>
            </div>
        </div>
        <div class="col-12 mt-3">
            <button [disabled]="control.invalid" class="f-button ms-auto md" (click)="saveChanges()">Zapisz zmiany</button>
        </div>
    </div>
</form>
