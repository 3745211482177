import { Component, ElementRef, ViewChild } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent {

  documentDefaultWidth = 794
  @ViewChild('previewElement', { static: true }) previewElement: ElementRef;
  originalWidth: number = this.documentDefaultWidth; // Assuming original width is 800px

  currentWidth = this.documentDefaultWidth

  constructor( ) { }

  zoomIn() {
    if(this.currentWidth >= this.documentDefaultWidth) return
    this.currentWidth = this.currentWidth + 100
    this.scaleToWidth(this.currentWidth); // Scale to width of 1000px
  }

  
  zoomOut() {
    if(this.currentWidth <= 100) return
    this.currentWidth = this.currentWidth - 100
    this.scaleToWidth(this.currentWidth); // Scale to width of 600px
  }

  scaleToWidth(width: number) {
    const scaleFactor = width / this.originalWidth;
    const previewElement = this.previewElement.nativeElement;
    if(previewElement) previewElement.style.transform = `scale(${scaleFactor})`;
  }



  generatePdf() {
    const previewElement = this.previewElement.nativeElement;
    if (!previewElement) return;

    // Create a copy of the HTML content in memory
    const clonedElement = previewElement.cloneNode(true);

    // Scale the copy to 1
    clonedElement.style.transform = 'scale(1)';

    // Append the copy to the document body (to ensure correct rendering)
    document.body.appendChild(clonedElement);

    // Use html2canvas to capture the cloned element
    html2canvas(clonedElement).then((canvas) => {
      // Remove the cloned element from the document body
      document.body.removeChild(clonedElement);

      // Continue with existing code to generate PDF
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jspdf.jsPDF();
      const imgWidth = 210; // A4 width in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('generated-pdf.pdf');
    });
}
}
