<div class="my-3">
    Zakres dat<br />
    <mat-form-field class="w-full">
        <!-- <mat-label>Enter a date range</mat-label> -->
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [ngModel]="startDate" placeholder="Od" (dateChange)="rangeChanged('start', $event)">
            <input matEndDate [ngModel]="endDate" placeholder="Do" (dateChange)="rangeChanged('end', $event)">
        </mat-date-range-input>
        <!-- <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

    </mat-form-field>
</div>
<div style="text-align:center">

    <button mat-button (click)="convert()">Convert</button>

    <apx-chart *ngIf="loaded" 
        [series]="newDocumentsAndUsersMonthlyChart?.series"
        [chart]="newDocumentsAndUsersMonthlyChart?.chart"
        [xaxis]="newDocumentsAndUsersMonthlyChart?.xaxis"
        [stroke]="newDocumentsAndUsersMonthlyChart?.stroke"
        [tooltip]="newDocumentsAndUsersMonthlyChart?.tooltip"
        [dataLabels]="newDocumentsAndUsersMonthlyChart?.dataLabels">
    </apx-chart>

    <apx-chart *ngIf="loaded" [chart]="newDocumentsAndUsersChart?.chart" [colors]="newDocumentsAndUsersChart?.colors"
        [fill]="newDocumentsAndUsersChart?.fill" [dataLabels]="newDocumentsAndUsersChart.dataLabels"
        [series]="newDocumentsAndUsersChart?.series" [stroke]="newDocumentsAndUsersChart?.stroke"
        [tooltip]="newDocumentsAndUsersChart?.tooltip" [xaxis]="newDocumentsAndUsersChart?.xaxis" [yaxis]="newDocumentsAndUsersChart?.yaxis">
    </apx-chart>

</div>
