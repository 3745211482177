<div id="sidenav-observer" class="container-xxxl">
    <mat-drawer-container class="example-container" style="min-height: 600px;">
        <mat-drawer #settingsNav mode="side" opened class="drawer" [mode]="mode">
            <h1 class="p-4 pb-2">Ustawienia</h1>
            <mat-selection-list #tab hideSingleSelectionIndicator="true" multiple="false">
                <!-- <mat-list-option value="general" selected="true">
                <mat-icon matListItemIcon>settings</mat-icon>
                <div matListItemTitle>Ogólne</div>
                <div matListItemLine>Ustawienia ogólne</div>
            </mat-list-option>
            <mat-divider></mat-divider> -->
            <mat-list-option value="account" selected="true" (click)="toggle()">
                <mat-icon matListItemIcon>account_circle</mat-icon>
                <div matListItemTitle>Moje konto</div>
                <div matListItemLine>Dane Twojego konta</div>
            </mat-list-option>
            <mat-divider></mat-divider>
                <mat-list-option value="autocomplete" (click)="toggle()">
                    <mat-icon matListItemIcon>auto_fix_high</mat-icon>
                    <div matListItemTitle>Autouzupełnianie</div>
                    <div matListItemLine>Automatycznie wypełniaj pola</div>
                </mat-list-option>
                <mat-divider></mat-divider>
                <mat-list-option value="vatRates" (click)="toggle()">
                    <mat-icon matListItemIcon>tune</mat-icon>
                    <div matListItemTitle>Stawki VAT</div>
                    <div matListItemLine>Domyślnie stawki VAT</div>
                </mat-list-option>
                <mat-divider></mat-divider>
                <mat-list-option value="security" (click)="toggle()">
                    <mat-icon matListItemIcon>lock</mat-icon>
                    <div matListItemTitle>Bezpieczeństwo</div>
                    <div matListItemLine>Zmień hasło do konta</div>
                </mat-list-option>
            </mat-selection-list>

        </mat-drawer>
        <mat-drawer-content class="p-4">
            <button mat-button class="d-block d-md-none" (click)="toggle()">
                <h2>&#8249; Ustawienia</h2>
            </button>
            <ng-container *ngIf="tab.selectedOptions.selected[0].value == 'account'">
                <personal-informations></personal-informations>
                <mat-divider class="my-4 max-width-500px"></mat-divider>
                <company-data></company-data>
                <mat-divider class="my-4 max-width-500px"></mat-divider>
                <bank-accounts class="d-block"></bank-accounts>
            </ng-container>
           
            <div class="row" *ngIf="tab.selectedOptions.selected[0].value == 'autocomplete'">
                <automatic-numbering></automatic-numbering>
                <mat-divider class="my-4 max-width-500px"></mat-divider>
                <place-of-issue></place-of-issue>
                <mat-divider class="my-4 max-width-500px"></mat-divider>
                <signatures></signatures>
                <!-- <mat-divider class="my-2"></mat-divider>
                <seller-form-fields></seller-form-fields> -->
            </div>
          
            <div class="row" *ngIf="tab.selectedOptions.selected[0].value == 'security'">
                <change-password></change-password>
                <mat-divider class="my-4 max-width-500px"></mat-divider>
                <change-email-address></change-email-address>
               
            </div>

            <div class="row" *ngIf="tab.selectedOptions.selected[0].value == 'vatRates'">
                <default-vat-rates></default-vat-rates>


            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>