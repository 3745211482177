import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { FakturniaHorizontalNavigationComponent } from '@fakturnia/components/navigation/horizontal/horizontal.component';
import { FakturniaNavigationItem } from '@fakturnia/components/navigation/navigation.types';
import { NavigationService } from 'shared/services/navigation.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FakturniaFullscreenComponent } from '@fakturnia/components/fullscreen/fullscreen.component';
import { UserComponent } from '@fakturnia/components/user/user.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { AuthService } from 'shared/services/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MediaWatcherService } from 'shared/services/media-watcher.service';
import { FakturniaNavigationService, FakturniaVerticalNavigationComponent } from '@fakturnia/components/navigation';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MainFooterComponent } from 'shared/layout/footers/main-footer/main-footer.component';

@Component({
  selector: 'modern-layout',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MainFooterComponent, RouterOutlet, MatTooltipModule, FakturniaVerticalNavigationComponent, FakturniaHorizontalNavigationComponent, UserComponent, MatProgressBarModule, FakturniaFullscreenComponent, RouterModule, MatIconModule],
  templateUrl: './modern-layout.component.html',
  styleUrl: './modern-layout.component.scss'
})
export class ModernLayoutComponent implements OnInit, OnDestroy {

  private _subscriptions:Subscription[] = []
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public isScreenSmall: boolean;
  public navigation: FakturniaNavigationItem[] = []
  public isSynchronizing = true
  public isLoggedIn = false



  constructor(
    private _navigationService:NavigationService,
    private _synchronizationService:SynchronizationService,
    private _authService: AuthService,
    private _mediaWatcherService:MediaWatcherService,
    private _fakturniaNavigationService:FakturniaNavigationService
    ) {
    
    this._subscriptions.push(
      this._navigationService.currentNavigation.subscribe({
        next: (navigation) => {
          this.navigation = navigation
        }
      })
    )

    this._subscriptions.push(this._authService.isLoggedIn$.subscribe({
      next: (data)=> {
        this.isLoggedIn = data
      }
    }))

    this._subscriptions.push(this._synchronizationService.currentSynchronizationStatus.subscribe({
      next: (data)=> {
        if(!data.isSynchronizing) {
          setTimeout(()=> {
            this.isSynchronizing = false
          },1500)
        } else this.isSynchronizing = true
      }
    }))
  }

   /**
     * Toggle navigation
     *
     * @param name
     */
   toggleNavigation(name: string): void
   {
       // Get the navigation
       const navigation = this._fakturniaNavigationService.getComponent<FakturniaVerticalNavigationComponent>(name);

       if ( navigation )
       {
           // Toggle the opened status
           navigation.toggle();
       }
   }

  ngOnInit(): void {
     // Subscribe to media changes
     this._mediaWatcherService.onMediaChange$
     .pipe(takeUntil(this._unsubscribeAll))
     .subscribe(({matchingAliases}) =>
     {
         // Check if the screen is small
         this.isScreenSmall = !matchingAliases.includes('md');
     });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())

     // Unsubscribe from all subscriptions
     this._unsubscribeAll.next(null);
     this._unsubscribeAll.complete();
  }
}
