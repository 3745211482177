import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[lowercaseEmail]'
})
export class LowercaseEmailDirective {

  private isUpdating = false;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngControl: NgControl
  ) { }

  @HostListener('input', ['$event']) onInput(event: any): void {
    if (this.isUpdating) {
      return;
    }
    this.isUpdating = true;
    const inputValue: string = event.target.value;
    const lowercaseValue: string = inputValue.toLowerCase();
    // const validEmailValue: string = this.removeInvalidChars(lowercaseValue);
    this.renderer.setProperty(this.el.nativeElement, 'value', lowercaseValue);

    // Update the form control value
    this.ngControl?.control?.setValue(lowercaseValue);

    // Emit an input event to ensure Angular forms recognize the changes
    const inputEvent = new Event('input', { bubbles: true });
    this.el.nativeElement.dispatchEvent(inputEvent);
    this.isUpdating = false;
  }
}