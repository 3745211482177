import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { InvoicePreviewDialogComponent } from "../dialogs/invoice-preview-dialog/invoice-preview-dialog.component";
import { BehaviorSubject } from "rxjs";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})
export class InvoicePreviewService implements OnDestroy {

    private _preview = new BehaviorSubject<any>(null);
    currentPreview = this._preview.asObservable();

    constructor(
        private _dialog:MatDialog
    ) {

    }
    
    next(docDefinition:any) {
        pdfMake.createPdf(<any>docDefinition).getBase64(base64 => {
            this._preview.next(base64)
        })
    }

    showPreview() {

        this._dialog.open(InvoicePreviewDialogComponent, {
          width: '100%',
          height: '90%'
        })
    }

    ngOnDestroy(): void {
        
    }
    
}
