<mat-dialog-content>

    <div class="h5 text-color-black">Formularz zawiera błędy</div>
    <div>
        Popraw następujace błędy w formularzu:
    </div>

    <div class="pt-4">
    
        <div *ngFor="let fieldError of errors">
            <!-- {{fieldError.field}}: <br/> -->
            <div class="text-color-black" *ngFor="let error of fieldError.errors">
                - {{error}}
            </div>
        </div>

    <br />

    <button class="f-button inversed ms-auto md" (click)="close()">Zamknij</button>

    </div>
</mat-dialog-content>