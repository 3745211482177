import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import { registerLocaleData } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { plLocale } from 'ngx-bootstrap/locale';
import { Utils } from 'fakturnia-shared';
import { AuthService } from 'shared/services/auth.service';
import { SettingsService } from 'shared/services/settings.service';
import { Subscription, filter, map, mergeMap } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { PanelService } from 'shared/services/panel.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { settings } from 'environments/environment';
import { Meta } from '@angular/platform-browser';
import { UserSettings } from "fakturnia-shared";
import localePl from '@angular/common/locales/pl';
defineLocale('pl', plLocale);
registerLocaleData(localePl, 'pl');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  shouldBeOpened = true;
  isMobile = false;

  isAuthenticated = false;
  userSettings:UserSettings = new UserSettings();

  isSynchronizing = false;

  mode: 'side' | 'over' = 'side';
  @ViewChild('rightPanel', { static: true }) private rightPanel: MatSidenav;

  widthObserver;
  widthObserverElement;

  isAdmin = false;

  config = settings
  constructor(
    private authService:AuthService,
    private settingsService:SettingsService,
    public panelService: PanelService,
    private zone: NgZone,
    private _router:Router,
    private renderer: Renderer2,
    private route:ActivatedRoute,
    private _metaService: Meta
    
  ) 
  {

    // Check screen size
    this.mode = 'side'
    this.isMobile = true
    if (window.innerWidth > 960) this.isMobile = false
    if(this.isMobile) { this.shouldBeOpened = false;} else { this.shouldBeOpened = true;}
    if (window.innerWidth < 1300) this.mode = 'over'

    this._subscriptions.push(this.authService.isLoggedIn$.subscribe({
      next: (data)=> { 
        this.isAuthenticated = data;
        if(this.isAuthenticated) {
          if(localStorage.getItem('role') == 'administrator') this.isAdmin = true;
          else this.isAdmin = false;
        }
      }
    }))

    this._subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data:UserSettings)=> { 
        this.userSettings = data;
        if(Utils.isDefined(data,"user.role"))
        {
          localStorage.setItem('role', data.user.role || 'user');
          if(data.user.role == 'administrator') this.isAdmin = true;
          else this.isAdmin = false;
        }
      }
    }))

    // Subscribe to route data changes
    this._subscriptions.push(this.route.data.subscribe((data:any) => {
      // Check if 'footer' is defined in route data and set showFooter accordingly
      if(typeof data.footer == 'undefined') this.showFooter = true;
      else if(typeof data.footer != 'undefined') this.showFooter = data.footer;
    }));
    
  }
  ngAfterViewInit(): void {
    this.widthObserverElement = document.getElementById("sidenav-observer");
    this.widthObserver = new ResizeObserver(entries => {
      this.zone.run(() => {

        // this.isMobile = true
        if (entries[0].contentRect.width >= 960) this.isMobile = false
        else if (entries[0].contentRect.width < 960) this.isMobile = true
        
        if (entries[0].contentRect.width >= 1300) this.mode = 'side';
        else if (entries[0].contentRect.width < 1300) this.mode = 'over';

        if(this.isMobile) this.shouldBeOpened = false

      });
    });
    // this.widthObserver.observe(this.widthObserverElement);
  }
  showFooter = true;
  ngOnInit(): void {
    this.panelService.setPanel(this.rightPanel); 

    this._subscriptions.push(this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe((data:any) => {
      if(typeof data.footer != 'undefined')
      {
        this.showFooter = data.footer;
      }
      else {
        this.showFooter = true
      }
    }
    ))

    this._subscriptions.push(this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Pobierz aktualny URL
      const currentUrl = window.location.href;
      // Ustaw kanoniczny link
      const existingCanonicalTag = this._metaService.getTag('name="canonical"');
      if (!existingCanonicalTag) {
        // Ustaw kanoniczny link, jeśli nie istnieje jeszcze
      this._setCanonicalLink(currentUrl);
      }
    }))
  }
 
  private _setCanonicalLink(url: string) {
    // Ustaw kanoniczny link w nagłówku
    const link: HTMLLinkElement = this.renderer.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    // Dodaj kanoniczny link do nagłówka strony
    this.renderer.appendChild(document.head, link);
  }
  
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

}
