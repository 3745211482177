<h1 style="display:none;">Faktura korygująca - Co to jest? Bezpłatny program do wystawiania faktur przez przeglądarkę Fakturnia.pl</h1>
<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
        <div class="grid grid-cols-2 gap-8">
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto tracking-wide">
                <h2>
                    <strong>Faktura korygująca</strong> - Co to jest?<br />

                </h2>
                <p class="mt-2">
                    Faktura korygująca stanowi istotny dokument księgowy, który jest wystawiany przez sprzedawcę w
                    konkretnych sytuacjach, takich jak zmniejszenie lub zwiększenie podstawy opodatkowania, zwrot
                    towarów lub udzielenie rabatu. W zależności od przyczyny korekty, procedury związane z nią mogą się
                    różnić. Wprowadzenie tzw. pakietu Slim VAT od 2021 roku precyzuje sposób postępowania z fakturami
                    korygującymi w przypadku korekt dodatnich oraz zmienia zasady księgowania faktur korygujących
                    odjętych. Zapoznaj się z informacjami na temat sposobu ujmowania faktur korygujących wystawianych
                    przez sprzedawcę w ewidencjach
                </p>

                <h2 class="mt-5">
                    Faktura korygująca - <strong>jakie dane powinna zawierać?</strong><br />
                </h2>
                <div class="mt-2">
                    Zgodnie z artykułem 106j ust. 1 ustawy o VAT, istnieją określone sytuacje, które obligują podatnika
                    do wystawienia faktury korygującej. Te sytuacje można podzielić na trzy grupy:
                    <br />
                    <br />
                    <strong>1.</strong>Korekty zmniejszające podstawę opodatkowania<br />
                    <strong>2.</strong>Korekty zwiększające podstawę opodatkowania<br />
                    <strong>3.</strong>Korekty stwierdzające inne pomyłki<br />
                    <br />
                    <br />
                    Każda z tych grup wymaga specyficznego podejścia do wystawienia faktury korygującej, zgodnie z
                    przepisami prawa VAT.
                </div>

                <div class="mt-5">
                    <h5>
                        Dokładny zakres danych wymaganych do umieszczenia na fakturze korygującej jest określony w art.
                        106j ust. 2 ustawy.
                        <br />
                    </h5>
                    <ul class="mt-2 list-disc">
                        <li>Szczegóły zawarte w oryginalnej fakturze, objęte korektą, zgodnie z wymaganiami art. 106e
                            ust. 1 pkt 1-5, takie jak data wystawienia, numer faktury, dane nabywcy i sprzedawcy wraz z
                            ich numerami identyfikacyjnymi dla celów podatkowych.</li>
                        <li>Kolejny numer nadany w ramach serii, <strong>identyfikujący fakturę</strong></li>
                        <li>W przypadku faktur ustrukturyzowanych - Numer identyfikacyjny w Krajowym Systemie e-Faktur
                            faktury, do której odnosi się korekta</li>
                        <li>W przypadku korekty wpływającej na podstawę opodatkowania lub kwotę podatku, należy podać
                            kwotę korekty z podziałem na poszczególne stawki podatku i sprzedaż zwolnioną.</li>
                        <li>Jeśli zmiana nie wpływa na podstawę opodatkowania, należy poprawić zawartość dotkniętych
                            pozycji</li>
                    </ul>
                </div>

                <div class="mt-2">
                    <h5>Faktura korygująca może także zawierać:</h5>
                    <ul class="mt-2 list-disc">
                        <li>Oznaczenie "FAKTURA KORYGUJĄCA" lub "KOREKTA".</li>
                        <li>Opis przyczyny korekty</li>
                    </ul>
                </div>
            </div>
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto tracking-wide">
                <picture>
                    <img class="img-fluid" src="/assets/images/faktura_korygujaca_fakturnia_pl.png"
                        alt="faktura korygująca wygenerowana w programie do faktur fakturnia.pl">
                </picture>

                <a [routerLink]="'/konto/dokumenty/wystaw'">Wystaw taki dokument w naszym programie <i class="ti ti-arrow-narrow-right ms-2"></i>
                </a>
            </div>
        </div>
    </div>
</section>       

<join-us></join-us>