import { Component } from '@angular/core';
import { PanelService } from 'shared/services/panel.service';

@Component({
  selector: 'right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent {
  constructor(public panelService:PanelService){}
}
