import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountComponent } from './account/account.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from 'shared/services/auth.service';
import { ApiService } from 'shared/services/api.service';
import { AuthInterceptorProvider } from 'shared/interceptors/auth.interceptor';
import { AppInitService } from 'shared/services/app-init.service';
import { SettingsService } from 'shared/services/settings.service';
import { CommonModule } from '@angular/common';
import { DocumentsService } from 'shared/services/documents.service';

// Material Modules
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import { ContactComponent } from './pages/contact/contact.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { RightPanelComponent } from '../shared/components/right-panel/right-panel.component';
import {PortalModule} from '@angular/cdk/portal';
import { ClientComponent } from '../shared/components/client/client.component';
import { PanelService } from 'shared/services/panel.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ProductComponent } from 'shared/components/product/product.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ConfirmDialogComponent } from 'shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogService } from 'shared/services/dialog.service';
import { IsAuthenticatedGuard } from 'shared/guards/is-authenticated.guard';
import { IsNotAuthenticatedGuard } from 'shared/guards/is-not-authenticated.guard';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { CompanyFormComponent } from 'shared/components/company-form/company-form.component';
import { BlogComponent } from './pages/blog/blog.component';
import { AdminComponent } from './admin/admin.component';
import { ManagePostComponent } from './admin/manage-blog/manage-post/manage-post.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { QuillModule } from 'ngx-quill';
import { PostComponent } from './pages/blog/post/post.component';
import { MatDividerModule } from '@angular/material/divider';
import { SearchGusCompanyDialogComponent } from 'shared/dialogs/search-gus-company-dialog/search-gus-company-dialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { RegisterOrLoginDialogComponent } from 'shared/dialogs/register-or-login-dialog/register-or-login-dialog.component';
import { FunctionalitiesComponent } from './pages/functionalities/functionalities.component';
import { AboutAppComponent } from './pages/about-app/about-app.component';
import { RulesComponent } from './pages/rules/rules.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SelectControlComponent } from 'shared/components/select-control/select-control.component';
import { AddClientDialogComponent } from 'shared/dialogs/add-client-dialog/add-client-dialog.component';
import { SetUpCompanyDialogComponent } from 'shared/dialogs/set-up-company-dialog/set-up-company-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import { NumericOnlyDirective } from 'shared/directives/number-only.directive';
import { PhoneNumberDirective } from 'shared/directives/phone-number.directive';
import { AddOrUpdateBankAccountDialogComponent } from 'shared/dialogs/add-or-update-bank-account-dialog/add-or-update-bank-account-dialog.component';
import { BankAccountFormatDirective } from 'shared/directives/bank-accont-format.directive';
import { ToggleFormFieldsDialogComponent } from 'shared/dialogs/toggle-form-fields-dialog/toggle-form-fields-dialog.component';
import { TherraxInputComponent } from 'shared/components/therrax-input/therrax-input.component';
import { CreateOrUpdateClientDialogComponent } from 'shared/dialogs/create-or-update-client-dialog/create-or-update-client-dialog.component';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';
import { ClientsService } from 'shared/services/clients.service';
import { InvoicePreviewService } from 'shared/services/invoice-preview.service';
import { ProductsService } from 'shared/services/products.service';
import { InvoicePreviewDialogComponent } from 'shared/dialogs/invoice-preview-dialog/invoice-preview-dialog.component';
import { MatProgressBarModule}  from '@angular/material/progress-bar';
import { ToastComponent } from 'shared/components/toast/toast.component';
import { ToastService } from 'shared/services/toast.service';
import { ErrorInterceptorProvider } from 'shared/interceptors/error.interceptor';
import { LoadingDialogComponent } from 'shared/dialogs/loading-dialog/loading-dialog.component';
import { TestingComponent } from './account/testing/testing.component';
import { AutoResizeDirective } from 'shared/directives/auto-resize.directive';
import { InvoicesService } from 'shared/services/invoices.service';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MATERIAL_SANITY_CHECKS, MatNativeDateModule } from '@angular/material/core';
import { UserComponent } from './admin/users/user/user.component';
import { UpdateUserPersonalInformationsComponent } from './admin/users/user/update-user-personal-informations/update-user-personal-informations.component';
import {MatTabsModule} from '@angular/material/tabs';
import { UpdateCompanyDataComponent } from './admin/users/user/update-user-company-data/update-company-user-data.component';
import { UserToolsComponent } from './admin/users/user/user-tools/user-tools.component';
import { RemoteLoginComponent } from './admin/users/user/user-tools/remote-login/remote-login.component';
import { AnnouncementsComponent } from './admin/announcements/announcements.component';
import { CreateOrUpdateAnnouncementComponent } from './admin/announcements/create-or-update-announcement/create-or-update-announcement.component';
import { AnnouncementsListComponent } from 'shared/components/announcements/announcements-list.component';
import { BackupComponent } from './admin/backup/backup.component';
import { InvoicePreviewComponent } from './pages/functionalities/invoice-preview/invoice-preview.component';
import { FakturaVatComponent } from './pages/functionalities/faktura-vat/faktura-vat.component';
import { FakturaKoncowaComponent } from './pages/functionalities/faktura-koncowa/faktura-koncowa.component';
import { FakturaKorygujacaComponent } from './pages/functionalities/faktura-korygujaca/faktura-korygujaca.component';
import { ReadMoreComponent } from 'shared/components/read-more/read-more.component';
import { JoinUsComponent } from 'shared/components/join-us/join-us.component';
import { FakturaVatMarzaComponent } from './pages/functionalities/faktura-vat-marza/faktura-vat-marza.component';
import { BankAccountsApiService } from 'shared/services/api/bank-accounts.api.service';
import { SnackbarService } from 'shared/services/snackbar.service';
import { PrintManyDialogComponent } from 'shared/dialogs/print-many-dialog/print-many-dialog.component';
import { PreviewComponent } from './account/documents/preview/preview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DashboardComponent } from './account/dashboard/dashboard.component';
import { InvoiceCardComponent } from 'shared/components/invoice-card/invoice-card.component';
import { BackupApiService } from 'shared/services/api/backup.api.service';
import { ClientsApiService } from 'shared/services/api/clients.api.service';
import { DocumentsApiService } from 'shared/services/api/documents.api.service';
import { SendInvoiceByEmailDialogComponent } from 'shared/dialogs/send-invoice-by-email-dialog/send-invoice-by-email-dialog.component';
import { DisplayFormErrorsDialogComponent } from 'shared/dialogs/display-form-errors-dialog/display-form-errors-dialog.component';
import { ActivityLogsComponent } from 'shared/components/activity-logs/activity-logs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSeoModule } from 'shared/modules/ngx-seo/ngx-seo.module';
import { provideIcons } from 'shared/providers/icons.provider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExchangeRateDirective } from 'shared/directives/exchange-rate.directive';
import { FAKTURNIA_APP_CONFIG } from '@fakturnia/services/config/config.contants';
import { RefreshTokenInterceptorProvider } from 'shared/providers/refresh-token.provider';

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.initializeApp();
  }
}

const APIServices = [
  ApiService,
  BankAccountsApiService,
  BackupApiService,
  ClientsApiService,
  DocumentsApiService
]

const Services = [
  AppInitService,
  AuthService,
  SettingsService,
  SynchronizationService,
  DataSynchronizerService,
  PanelService,
  ClientsService,
  DocumentsService,
  DialogService,
  InvoicePreviewService,
  ProductsService,
  ToastService,
  SnackbarService,
  InvoicesService
]

const Directives = [
  NumericOnlyDirective,
  PhoneNumberDirective,
  BankAccountFormatDirective,
  AutoResizeDirective,
  ExchangeRateDirective
]

const MatModules = [
  MatToolbarModule,
  MatTooltipModule,
  MatIconModule,
  MatDividerModule,
  MatButtonModule,
  MatMenuModule,
  MatListModule,
  MatChipsModule,
  MatSidenavModule,
  PortalModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSnackBarModule,
  MatStepperModule,
  MatProgressBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTabsModule,
  MatProgressSpinnerModule
]

const Dialogs = [
  ConfirmDialogComponent,
  RegisterOrLoginDialogComponent,
  AddClientDialogComponent,
  SearchGusCompanyDialogComponent,
  ToggleFormFieldsDialogComponent,
  CreateOrUpdateClientDialogComponent,
  InvoicePreviewDialogComponent,
  LoadingDialogComponent,
  PrintManyDialogComponent,
  SendInvoiceByEmailDialogComponent,
  DisplayFormErrorsDialogComponent
]


const SharedComponents = [
  ToastComponent,
  AnnouncementsListComponent,
  ReadMoreComponent,
  JoinUsComponent,
  ActivityLogsComponent
]

@NgModule({ declarations: [
        Directives,
        Dialogs,
        SharedComponents,
        AppComponent,
        AccountComponent,
        ContactComponent,
        RightPanelComponent,
        ClientComponent,
        ProductComponent,
        CompanyFormComponent,
        BlogComponent,
        AdminComponent,
        ManagePostComponent,
        DashboardComponent,
        AdminDashboardComponent,
        PostComponent,
        FunctionalitiesComponent,
        AboutAppComponent,
        RulesComponent,
        PrivacyPolicyComponent,
        SelectControlComponent,
        SetUpCompanyDialogComponent,
        AddOrUpdateBankAccountDialogComponent,
        TherraxInputComponent,
        TestingComponent,
        // PreviewComponent,
        UserComponent,
        UpdateUserPersonalInformationsComponent,
        UpdateCompanyDataComponent,
        UserToolsComponent,
        RemoteLoginComponent,
        AnnouncementsComponent,
        CreateOrUpdateAnnouncementComponent,
        BackupComponent,
        InvoicePreviewComponent,
        FakturaVatComponent,
        FakturaKoncowaComponent,
        FakturaKorygujacaComponent,
        FakturaVatMarzaComponent,
        PreviewComponent,
        InvoiceCardComponent,
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        NgxExtendedPdfViewerModule,
        QuillModule.forRoot(),
        NgxSeoModule.forRoot({
            changeTitle: (title) => title,
            preserve: false,
            listenToRouteEvents: true,
        }),
        MatModules], providers: [
        Services,
        APIServices,
        AuthInterceptorProvider,
        ErrorInterceptorProvider,
        RefreshTokenInterceptorProvider,
        IsAuthenticatedGuard,
        IsNotAuthenticatedGuard,
        {
            provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
        {
          // Disable 'theme' sanity check
          provide : MATERIAL_SANITY_CHECKS,
          useValue: {
              doctype: true,
              theme  : false,
              version: true,
          },
      },
      {
          // Use the 'fill' appearance on Angular Material form fields by default
          provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: {
              appearance: 'fill',
          },
      },
      {
        provide : FAKTURNIA_APP_CONFIG,
        useValue: {
          layout: 'modern',
          scheme: 'light',
          screens: {
              sm: '600px',
              md: '960px',
              lg: '1280px',
              xl: '1440px',
          },
          theme: 'theme-default',
          themes: [
              {
                  id: 'theme-default',
                  name: 'Default',
              },
          ],
      },
    },
      { provide: LOCALE_ID, useValue: 'pl' },
      provideIcons()
    ] })
export class AppModule { }
