import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription, firstValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppDb } from "fakturnia-shared";

@Injectable({
    providedIn: 'root'
})

export class DatabaseService implements OnDestroy {

    syncSub: Subscription = Subscription.EMPTY;
    subscriptions: Subscription[] = [];

    private synchronizationStatus = new BehaviorSubject<any>({ isSynchronizing: false, hasSuccessfulSynchronize: false, changeOccured: false, currentPage: 0, allPages: 0 });
    currentSynchronizationStatus = this.synchronizationStatus.asObservable();

    private database = new BehaviorSubject<AppDb | null>(null);
    currentDatabase = this.database.asObservable();

    db: AppDb;

    data = new BehaviorSubject<any>({
        loaded: false,
        products: {}
    })
    currentData = this.data.asObservable();
    
    isSynchronizing = false;

    constructor(
        private http: HttpClient
    ) {
        this.subscriptions.push(this.currentDatabase.subscribe(
            (data) => {
            if (data != null) {
                // Set correct database
                this.db = data;

                // Clear memory data
                this.data.next({
                    products: {}
                })


            }
        }))
    }

    clearMemory() {
        this.data.next(
            {
                loaded: false,
                documents: {},
                clients: {},
                products: {}
            }
        )
    }
    

    updateInMemoryData(table,values)
    {
      
      
        if (table == 'products') {
            this.processProducts(values)
        }
        this.data.next(this.data.value);
    }



    processProducts(products)
    {
        let count = 0;
        products.forEach(product => {
            this.data.value.products[product._id] = product;
            count += 1;
        })
        return count
    }

    
    destroyDatabase()
    {
        this.db.delete();
    }
    
    private processError(err: any) {
        console.log(err);
    }

    private async setLastSyncDate(last_sync: string) {

        console.warn(`Saving last synchronization date time: ${this.db.name}-last_sync ${last_sync}`);
        await this.db.synchronizations.where("id").equals(1).modify({ last_sync: last_sync });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}