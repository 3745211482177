import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from 'fakturnia-shared';
import { SnackbarService } from 'shared/services/snackbar.service';

@Component({
  selector: 'app-display-form-errors-dialog',
  templateUrl: './display-form-errors-dialog.component.html',
  styleUrls: ['./display-form-errors-dialog.component.scss']
})
export class DisplayFormErrorsDialogComponent {

  public errors: { field: string, errors: string[] }[] = []

  constructor(
    private _dialog: MatDialogRef<DisplayFormErrorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackbarService:SnackbarService
  ) {

    if(Utils.isDefined(data,"errors")) {
      this.errors = data.errors
      return
    }

    this.close()
  }

  close() {
    this._dialog.close()
  }
  
}
