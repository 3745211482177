import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'input[autoResize], textarea[autoResize]'
})
export class AutoResizeDirective {

  constructor(private element: ElementRef) {}

  @HostListener('input')
  onInput() {
    this.adjustElementHeight();
  }

  ngAfterViewInit() {
    // Call adjustElementHeight initially to set the height based on initial content
    this.adjustElementHeight();
  }

  private adjustElementHeight() {
    const nativeElement = this.element.nativeElement;
    nativeElement.style.height = 'auto';
    nativeElement.style.height = nativeElement.scrollHeight + 'px';
  }

}