<div class="w-full md:w-90 min-h-90 p-4">

  <div class="flex items-center justify-between">
    <div class="text-2xl">
      Dodaj nowego klienta
    </div>
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="text-secondary"  [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>
  </div>
 
  <div class="text-gray-600 dark:text-gray-300 md:text-center">
    <ng-container *ngIf="countryCode == 'PL'">
      Uzupełnij NIP klienta, a pobierzemy jego dane z
      <span matTooltip="CEIDG to Centralna Ewidencja i Informacja o Działalności Gospodarczej" class="border-bottom-dotted">CEiDG / Regon</span>
    </ng-container>
    <ng-container *ngIf="countryCode != 'PL'">
      Uzupełnij NIP klienta, a pobierzemy jego dane z 
      <span matTooltip="VIES - VAT Information Exchange System jest to system, który pozwala weryfikować numery podatników w Unii Europejskiej" class="border-bottom-dotted">VIES</span>
    </ng-container>
  </div>

  <mat-form-field class="w-full my-4" subscriptSizing="dynamic">
    <mat-label>NIP </mat-label>
    <span [matMenuTriggerFor]="countryCodesMenu" class="cursor-pointer flex items-center mr-2" matTextPrefix>{{countryCode}} <mat-icon class="text-gray-200 dark:text-gray-300 p-1" [svgIcon]="'tabler_outline:chevron-down'"></mat-icon></span>
    <input [formControl]="nipControl" matInput placeholder="Podaj NIP klienta" value=""/>
    <mat-error *ngIf="nipControl.hasError('required')">Pole jest wymagane</mat-error>
    <mat-error *ngIf="nipControl.hasError('minlength')">Nieprawidłowy numer NIP</mat-error>
    <mat-error *ngIf="nipControl.hasError('notfound')">Nie znaleziono firmy o podanym numerze NIP</mat-error>
    <mat-error *ngIf="nipControl.hasError('notactive')">Nie znaleziono firmy o podanym numerze NIP</mat-error>

    <mat-menu #countryCodesMenu class="max-h-80 w-80 -ml-3 mt-2">
      <div class="text-sm font-medium mx-4">Wybierz kraj klienta</div>
      <menu class="flex items-center border-t" mat-menu-item *ngFor="let cc of countryCodes" (click)="selectCountryCode(cc.id)">{{cc.name}} <small class="ml-2 text-gray-600 dark:text-gray-300">({{cc.id}})</small></menu>
    </mat-menu>

  </mat-form-field>

  <br />

  <button mat-flat-button color="primary" class="w-full" [disabled]="loading" (click)="search()">
    <mat-icon class="icn-spinner" *ngIf="loading">cached</mat-icon>
    Wyszukaj automatycznie dane klienta
  </button>
  <div class="text-center my-2">
    lub
  </div>
  <button mat-button color="primary" class="w-full" [disabled]="loading" (click)="showCreateOrUpdateClientDialog(null)">
    <mat-icon class="icn-spinner" *ngIf="loading">cached</mat-icon>
    Wprowadź dane ręcznie
  </button>

</div>