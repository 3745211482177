<div class="container p-4">
    <h4>
        {{user?.firstName}} {{user?.lastName}}
        <button mat-button (click)="isEditable = !isEditable" [color]="isEditable ? 'warn' : 'primary'"> {{isEditable ? 'Wyłącz edycję' : 'Włącz edycję'}}</button>
    </h4>
    
    <mat-tab-group mat-align-tabs="start" animationDuration="0ms">
        <mat-tab>
            <ng-template mat-tab-label>
                Informacje
            </ng-template>
            <ng-template matTabContent>
                <div class="p-4" style="max-width:500px">
                    <update-user-personal-informations [user]="user" [isEditable]="isEditable"></update-user-personal-informations>
                    <update-user-company-data [company]="user.company" [isEditable]="isEditable"></update-user-company-data>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Dokumenty
            </ng-template>
            <ng-template matTabContent>
                <user-documents [user]="user"></user-documents>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Produkty
            </ng-template>
            <ng-template matTabContent>
                <div class="p-4" style="max-width:500px">
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Klienci
            </ng-template>
            <ng-template matTabContent>
                <div class="p-4" style="max-width:500px">
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Ustawienia
            </ng-template>
            <ng-template matTabContent>
                <div class="p-4" style="max-width:500px">
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Dodatkowe opcje
            </ng-template>
            <ng-template matTabContent>
                <div class="p-4" style="max-width:500px">
                    Konto utworzone: {{user?.createdAt}} <br/>
                    <user-tools [user]="user"></user-tools>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>