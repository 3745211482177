import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {MatChipInputEvent} from '@angular/material/chips';
import { ApiService } from 'shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'app-manage-post',
  templateUrl: './manage-post.component.html',
  styleUrls: ['./manage-post.component.scss']
})
export class ManagePostComponent implements OnDestroy {

  mode: 'create' | 'update' = 'create'
  subscriptions:Subscription[] = [];

  addOrUpdateForm = this.formBuilder.group({
    _id: new FormControl(''),
    title: new FormControl(''),
    url: new FormControl(''),
    img: new FormControl(''),
    readingTimeInMinutes: new FormControl(1),
    shortContent: new FormControl(''),
    content: new FormControl(''),
    isActive: new FormControl(true),
    keywords: new FormControl<any>([]),

  })

  post = {_id: ''};

  htmlstring = '';
  constructor(
    private formBuilder:FormBuilder,
    private apiService:ApiService,
    private route:ActivatedRoute,
    private _toastService:ToastService
  )
  {
    this.subscriptions.push(this.route.params.subscribe(
      (data:any)=> {
        if(data != null)
        {
          if(!Utils.isNullOrEmpty(data.id))
          {
            this.getPost(data.id);
          }
        }
      }
    ))
  }

  getPost(url)
  {
    const sub = this.apiService.getPostAsAdmin(url).subscribe(
      {
        next: (data:any)=> {
          if(data != null)
          {
            this.addOrUpdateForm.controls._id.setValue(data._id)
            this.addOrUpdateForm.controls.title.setValue(data.title)
            this.addOrUpdateForm.controls.url.setValue(data.url)
            this.addOrUpdateForm.controls.readingTimeInMinutes.setValue(data.readingTimeInMinutes)
            this.addOrUpdateForm.controls.img.setValue(data.img)
            this.addOrUpdateForm.controls.content.setValue(data.content)
            this.addOrUpdateForm.controls.keywords.setValue(data.keywords)
            this.addOrUpdateForm.controls.isActive.setValue(data.isActive)

            this.addOrUpdateForm.updateValueAndValidity();
            this.mode = 'update'
          }
          
          sub.unsubscribe();
        },
        error: (data)=> {
          sub.unsubscribe();
        }
      }
    )
  }

  savePost()
  {
    if(this.addOrUpdateForm.valid)
    {
      let post:any = this.addOrUpdateForm.value;

      if(this.mode == 'create') delete post._id
      post.content = this.htmlstring
      this.subscriptions.push(this.apiService.savePost(this.addOrUpdateForm.value).subscribe(
        {
          next: (data:any)=> {
            this._toastService.showToast(new NotificationData({
              title: 'Sukces',
              status: 'success',
              message: 'Post został zapisany.',
            }))
            if(this.mode == 'create')
            {
              this.addOrUpdateForm.controls._id.setValue(data._id);
              this.mode = 'update';
            }
            
          },
          error: (error)=>{
            this._toastService.showToast(new NotificationData({
              title: 'Wystąpił błąd',
              status: 'success',
              message: 'Nie udało się zapisać posta.',
            }))
          }
        }
      ));
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  onContentChanged(event)
  {
    this.htmlstring = event.html;
    
    const shortContent = Utils.shortenText(event.text,120);
    this.addOrUpdateForm.controls.shortContent.setValue(shortContent);

    const readingTimeInMinutes = Math.floor(event.text.length / 300);
    this.addOrUpdateForm.controls.readingTimeInMinutes.setValue(readingTimeInMinutes);

  }

  handleFileChange(event: any) {
    const file = event.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const base64String = reader.result as string;
        this.addOrUpdateForm.controls.img.setValue(base64String);
      };
  
      reader.readAsDataURL(file);
    }
  }

  removeKeyword(keyword: string) {
    keyword = keyword.toString();
    if(this.addOrUpdateForm.controls.keywords.value == null) return;
    const index:any = this.addOrUpdateForm.controls.keywords.value.indexOf(keyword);
    if (index >= 0) {
      this.addOrUpdateForm.controls.keywords.value.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      const current = this.addOrUpdateForm.controls.keywords.value;
      current?.push(value);
      this.addOrUpdateForm.controls.keywords.setValue(current);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

}
