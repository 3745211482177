import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogService } from 'shared/services/dialog.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ClientsService, PaginatedDbClients } from 'shared/services/clients.service';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Utils } from 'fakturnia-shared';

@Component({
  standalone: true,
  selector: 'app-select-client-dialog',
  imports: [CommonModule, MatFormFieldModule, MatDialogModule, MatInputModule, MatButtonModule, MatIconModule, ReactiveFormsModule],
  templateUrl: './select-client-dialog.component.html',
  styleUrl: './select-client-dialog.component.scss'
})
export class SelectClientDialogComponent implements OnDestroy {

  private _subscriptions: Subscription[] = [];

  public searchControl = new FormControl('')
  public options: any = [];

  constructor(
    private _dialogService:DialogService,
    private _clientsService:ClientsService,
    private _dialogRef: MatDialogRef<SelectClientDialogComponent>
    ) {

      // Load default last added companies.
      const pagination:PaginatedDbClients = this._clientsService.browseClients({ currentPage: 1, pageSize: 30, activeFilters: [], filters: [(client) => client.isDeleted == false]})
      this.options = pagination.results

      if(pagination.resultsCount == 0) {
        this.showAddClientDialog()
      }
      this._subscriptions.push(this.searchControl.valueChanges.subscribe({
        next: (data) => {
          if(Utils.isNullOrEmpty(data)) {
            const pagination:PaginatedDbClients = this._clientsService.browseClients({ currentPage: 1, pageSize: 30, activeFilters: [], filters: [(client)=> client.isDeleted == false]})
            this.options = pagination.results
            return
          }
          this._filterClients(data)
        }
      }))

  }

  public showAddClientDialog() {
    this._subscriptions.push(
      this._dialogService.showAddClientDialog()
        .subscribe({
          next: (response: any) => {

            if (!response) return
            if (typeof response.success == 'undefined') return
            if (!response.success) return

            this.selectClient(response.data)
          }
    }))
  }

  public selectClient(client) {
    this._dialogRef.close({
      client: client
    })
  }

  private _filterClients(value) {
    this.options = this._clientsService.filterClients(value)
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

}
