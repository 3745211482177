<div class="relative flex flex-col w-full md:max-w-120 md:w-120 md:h-160 md:max-h-160 min-h-full max-h-full">

  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-gray-50  border-b dark:border-gray-800 dark:bg-gray-900">
    <div class="text-2xl font-medium">
      Wyszukaj klienta
    </div>

    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="dark:text-white" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>

  </div>

  <div>
    <!-- Form Field -->
    <mat-form-field class="fuse-mat-dense w-full my-2 px-4" subscriptSizing="dynamic">
      <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"
        (click)="$event.stopPropagation(); searchText?.focus()"></mat-icon>
      <input [autocomplete]="'off'" matInput #searchText [formControl]="searchControl"
        placeholder="Wprowadź NIP lub nazwę firmy" type="text" (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()" (keyup)="1;" />
      <mat-icon *ngIf="searchControl.value?.length > 0" matSuffix [svgIcon]="'heroicons_solid:x-mark'"
        (click)="searchControl.reset()"></mat-icon>
      <mat-hint *ngIf="searchControl.value?.length > 0">
        Wyniki wyszukiwania dla frazy: {{searchControl.value}}
      </mat-hint>
    </mat-form-field>
  </div>

  <!-- Content -->
  <div class="flex flex-auto overflow-hidden mb-14">
    <div class="flex flex-col flex-auto overflow-y-scroll p-6 pt-2">
      <div class="text-center tracking-wide my-2 text-md"
        *ngIf="options.length == 0 && searchControl.value?.length > 0">
        Nie znaleziono klienta dla szukanej frazy w Twojej bazie. Dodaj nowego klienta szukając go w Bazie GUS lub
        VIES.<br />
        <button mat-flat-button color="primary" class="mt-4" (click)="showAddClientDialog()">
          <mat-icon class="mr-2" [svgIcon]="'tabler_outline:plus'"></mat-icon>
          Dodaj nowego klienta
        </button>
      </div>
      <div *ngFor="let client of options" class="border-b py-2 cursor-pointer" (click)="selectClient(client)">
        <div *ngIf="client?.type == 'company'">
          {{client?.name}}
        </div>
        <div *ngIf="client?.type == 'person'">
          {{client?.firstName}} {{client?.lastName}}
        </div>
        <div class="flex justify-between">
          <div class="text-sm text-gray-600 dark:text-gray-200">
            {{client.street}}, {{client.postalCode}} {{client.city}}
          </div>
          <div class="text-sm text-gray-600 dark:text-gray-200">
            NIP: {{client.nip}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div *ngIf="options.length > 0 || searchControl.value?.length == 0"
    class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-gray-900">
    <div class="flex items-end">
      <div class="ml-auto">
        <button mat-flat-button color="primary" (click)="showAddClientDialog()">
          <mat-icon class="mr-2" [svgIcon]="'tabler_outline:plus'"></mat-icon>
          Dodaj nowego klienta
        </button>
        <button mat-button (click)="close()" class="ml-2">Zamknij</button>
      </div>
    </div>
  </div>
</div>