<mat-dialog-content style="background-color: #f0f2f4; height: 100%;">
    <div class="d-flex">
        <!-- <div class="p-2 flex-shrink-1">
            <div class="icon-container info">
                <mat-icon>gpp_good</mat-icon>
            </div>
        </div> -->
        <div class="p-2 w-100">
            <div class="confirm-header">
                <div class="row">
                    <div class="col-9">
                        <h4>  
                            <!-- <mat-icon>gpp_good</mat-icon>  -->
                            Szybka rejestracja
                        </h4>
                    </div>
                    <div class="col-3">
                        <button class="float-end close-btn" (click)="close()" mat-icon-button>
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p *ngIf="action == 'save'">
                            Dołącz do nas, aby móc zapisywać swoje <b>dokumenty</b>. <br/>
                            Wprowadź swój adres e-mail oraz hasło lub <b>zaloguj się</b> na już istniejące konto.
                        </p>
                        <!-- <p *ngIf="action == 'preview'">
                            To dobry moment, żeby zapisać dokument, aby to zrobić zarejestruj się lub zaloguj na istniejące konto.
                        </p>
                        <p *ngIf="action == 'download'">
                            Rejestracja nie jest wymagana, natomiast daje dużo dodatkowych korzyści.
                        </p> -->
                    </div>
                </div>
            </div>
            <br/>
            <mat-radio-group color="primary" [(ngModel)]="alreadyRegistered">
                <mat-radio-button [value]="false">Nie mam konta</mat-radio-button>
                <mat-radio-button [value]="true">Mam już konto</mat-radio-button>

              </mat-radio-group>
              
            <div class="mt-4">
                <register *ngIf="alreadyRegistered == false" redirect="false"></register>
                <login *ngIf="alreadyRegistered == true" redirect="false"></login>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="bg-gray-50 text-end" [align]="'end'">
    <button mat-button color="primary" *ngIf="action == 'preview'" (click)="close(true)">
        <mat-icon>plagiarism</mat-icon> Podejrzyj bez rejestracji
    </button>
    <button mat-button color="primary" *ngIf="action == 'download'" (click)="close(true)" >
        <mat-icon>picture_as_pdf</mat-icon> Pobierz bez rejestracji
    </button>
</mat-dialog-actions>