import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent {
    @Input() showLabel: boolean = true
    @Input() label: string = '';
    @Input() type: 'select' | 'input' = 'select';
    @Input() options: any[] = [];
    @Input() toggleClass: string = '';
    @Input() placeholder: string = 'Brak';
    @Output() selectionChange = new EventEmitter<any>();
  
    @Input() selectedOption = '';
    isOpen: boolean = false;
  
    toggleDropdown() {
      if(this.options.length == 0) return
      this.isOpen = !this.isOpen;
    }
  
    selectOption(option: any) {
      this.selectedOption = option;
      this.selectionChange.emit(option);
      this.isOpen = false;
    }
  }