import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-search-gus-company-dialog',
  templateUrl: './search-gus-company-dialog.component.html',
  styleUrls: ['./search-gus-company-dialog.component.scss']
})
export class SearchGusCompanyDialogComponent {

  companies = [];
  constructor(
    private dialog: MatDialogRef<SearchGusCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (typeof data != 'undefined') {
        if(typeof data.companies != 'undefined') {
          this.companies = data.companies
        }
      }
  }

  close(action) {
    this.dialog.close({
      action: action,
      company: null
    })
  } 

  selectCompany(company) {
    this.dialog.close({
      action: true,
      company: company
    }) 
  }
}
