import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppData } from 'fakturnia-shared';

@Component({
  selector: 'app-toggle-form-fields-dialog',
  templateUrl: './toggle-form-fields-dialog.component.html',
  styleUrls: ['./toggle-form-fields-dialog.component.scss']
})
export class ToggleFormFieldsDialogComponent {

  currency = 'PLN'
  currencies = AppData.Currencies

  generalFields = {
    issuePlace: {
      text: 'Edytuj pole: miejsce wystawienia',
      informations: 'Miejsce wystawienia zawsze wyświetla się na dokumencie. Jeżeli ta opcja jest zaznaczona masz możliwość edycji tego pola w formularzu.'
    },
    hasReceiver: {
      text: 'Dodaj odbiorcę',
    },
    hasComments: {
      text: 'Dodaj pole na uwagi',
    },
    hasDiscount: {
      text: 'Dodaj pole rabat',
    },
    // hasGTU: {
    //   text: 'Dodaj GTU do pozycji',
    // },
    hasAdditionalPaymentDate: {
      text: 'Dodaj datę płatności',
    },
    hasPaymentDescription: {
      text: 'Dodaj opis płatności',
    },
    signatures: {
      text: 'Dodaj podpisy osób upoważnionych',
    }
  }

  generalToggleableFields = Object.keys(this.generalFields)
  
  generalFieldsToShow = new Set()
 
  
  additionalDateHeaders = AppData.AdditionalDateHeaders
  constructor(
    private dialog: MatDialogRef<ToggleFormFieldsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (typeof data != 'undefined') {
        
        if(typeof data.currency != 'undefined') {
          this.currency = data.currency
        }

        

        if(typeof data.generalFieldsToShow != 'undefined') {
          this.generalFieldsToShow = new Set(data.generalFieldsToShow)
        }
      }
  }


  close() {
    this.dialog.close({
      success: false,
      data: null
    })
  } 

  saveChanges() {

    this.dialog.close({
      success: true,
      data: {
        currency: this.currency,
        generalFieldsToShow: Array.from(this.generalFieldsToShow),

      }
    })
    
  }
  toggleFieldVisibility(type: 'seller' | 'buyer' | 'receiver' | 'general' , field: any) {

    if( type === 'general') {
      this.generalFieldsToShow.has(field) ? this.generalFieldsToShow.delete(field) : this.generalFieldsToShow.add(field)
    }
  }
}
