<div class="row">
    <div class="col-2">
        <button (click)="generatePdf()">pdf</button><br/>
        <button (click)="zoomIn()">zoomIn</button><br/>
        <button (click)="zoomOut()">zoomOut</button>
    </div>
    <!-- <div class="col-10">
        <div style="background-color:#2a2a2e;  padding:30px;">
            <div #previewElement [style.width.px]="documentDefaultWidth" style="min-height:1200px; transform-origin: top; background-color:white;border:1px solid black; margin: 10px auto 10px auto; padding:53px">
                <preview></preview>
            </div>
        </div>
    </div> -->
</div>