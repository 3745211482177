<div class="p-5">
    <ol class="timeline-container">
        <li *ngFor="let log of activityLogs; let last = last" class="timeline-entry">
        <div class="bg-card" *ngIf="!last" style="position:absolute; left:5px; top:5px; height: 100%; width:2px;"></div>
          <div class="timeline-marker bg-card">
            <i class="ti" [ngClass]="{
              'ti-plus': log.logType == 'CREATE',
              'ti-trash': log.logType == 'DELETE',
              'ti-mail-share': log.logType == 'SENT_BY_EMAIL',
              'ti-printer': log.logType == 'PRINT',
              'ti-download': log.logType == 'DOWNLOAD',
              'ti-pencil': log.logType == 'UPDATE'
            }"></i>
          </div>
          <div class="timeline-content">
            <div class="timeline-header">
              <div class="timeline-title">
                <span *ngIf="log.logType == 'CREATE'">Utworzenie dokumentu</span>
                <span *ngIf="log.logType == 'DELETE'">Usunięcie dokumentu</span>
                <span *ngIf="log.logType == 'PRINT'">Wydrukowanie dokumentu</span>
                <span *ngIf="log.logType == 'DOWNLOAD'">Pobranie dokumentu</span>
                <span *ngIf="log.logType == 'SENT_BY_EMAIL'">Wysłano na adres e-mail</span>
                <span *ngIf="log.logType == 'UPDATE'">Aktualizacja</span>
              </div>
                <div class="timeline-time">
                  <span>{{log.createdAt | date: 'dd MMMM YYYY'}}</span> <span class="ml-2">{{log.createdAt | date: 'HH:mm'}}</span>
                </div>
            </div>
            <div class="changes-container bg-card" *ngIf="log.logType == 'SENT_BY_EMAIL'">
                <div class="activity-log" *ngFor="let data of log.data">
                    <div class="change-field">
                        {{data.success ? 'Wysłano do:' : 'Nie udało się wysłać do:' }} {{data.receiver}}
                    </div>
                </div>
            </div>
            <div class="changes-container bg-card" *ngIf="log.logType == 'UPDATE'">
              <div class="activity-log" *ngFor="let change of log.data">
                <div class="change-field">
                  <span class="activity-display-name me-2">{{ change.displayName }}<span class="ms-2" *ngIf="change.extra">{{change.extra}}</span>:</span>
                  <span class="field-value" *ngIf="change.old != null">{{change.old}}</span>
                  <span class="mx-2 fw-bold" *ngIf="change.old != null && change.new != null && change.old != ''">na</span>
                  <span class="field-value" *ngIf="change.new != null">{{change.new == '' ? 'Brak' : change.new}}</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ol>
</div>

  