<form [formGroup]="form" style="max-width:500px;">
    <div class="row">
      <div class="col-12">
        <h4>Dane firmy</h4>
        <p>Dane Twojej firmy</p>
      </div>
      <div class="col-12 mt-2">
        <div class="form-floating">
          <select class="form-select" formControlName="type" aria-label="Typ">
            <option selected>Wybierz typ</option>
            <option value="company">Firma (działalność gospodarcza)</option>
            <option value="person">Osoba fizyczna</option>
          </select>
          <label for="type">Typ firmy</label>
        </div>
      </div>
      <div class="col-12 mt-2">
        <div class="form-floating">
          <input formControlName="name" type="text" class="form-control text-left" placeholder="Nazwa firmy" />
          <label for="name">Nazwa firmy</label>
        </div>
      </div>
      
      <div class="col-12 col-md-6 pe-md-1 mt-2">
        <div class="form-floating">
          <input formControlName="nip" type="text" class="form-control text-left" placeholder="NIP" />
          <label for="nip">NIP</label>
        </div>
      </div>
      <div class="col-12 col-md-6 ps-md-1 mt-2">
        <div class="form-floating">
          <input formControlName="regon" type="text" class="form-control text-left" placeholder="REGON" />
          <label for="regon">REGON</label>
        </div>
      </div>

      <div class="col-12 mt-2">
        <div class="form-floating">
          <input formControlName="street" type="text" class="form-control text-left" placeholder="Ulica i numer" />
          <label for="street">Ulica i numer</label>
        </div>
      </div>
      <div class="col-12 col-md-5 pe-md-1 mt-2">
        <div class="form-floating">
          <input formControlName="postalCode" type="text" class="form-control text-left" id="postalCode" placeholder="Kod pocztowy" />
          <label for="postalCode">Kod pocztowy</label>
        </div>
      </div>
      <div class="col-12 col-md-7 ps-md-1 mt-2">
        <div class="form-floating">
          <input formControlName="city" type="text" class="form-control text-left" id="city" placeholder="Miejscowość" />
          <label for="city">Miejscowość</label>
        </div>
      </div>
      
      <div class="col-12">
        <div class="h6 mt-4">Dodatkowe informacje</div>
      </div>
      <div class="col-12 pe-md-1 col-md-6 mt-2">
        <div class="form-floating">
          <input formControlName="bdo" type="text" class="form-control text-left" id="bdo" placeholder="BDO" />
          <label for="bdo">BDO</label>
        </div>
      </div>

      <div class="col-12 ps-md-1 col-md-6 mt-2">
        <div class="form-floating">
          <input formControlName="krs" type="text" class="form-control text-left" id="krs" placeholder="KRS" />
          <label for="krs">KRS</label>
        </div>
      </div>

      <div class="col-12 pe-md-1 col-md-6 mt-2">
        <div class="form-floating">
          <input formControlName="www" type="text" class="form-control text-left" id="www" placeholder="BDO" />
          <label for="bdo">Strona WWW</label>
        </div>
      </div>

      <div class="col-12 ps-md-1 col-md-6 mt-2">
        <div class="form-floating">
          <input formControlName="fax" type="text" class="form-control text-left" id="fax" placeholder="KRS" />
          <label for="krs">Fax</label>
        </div>
      </div>

      <div class="col-12 pe-md-1 col-md-6 mt-2">
        <div class="form-floating">
          <input formControlName="description" type="text" class="form-control text-left" id="description" placeholder="Pole opisowe" />
          <label for="description">Pole opisowe</label>
        </div>
      </div>

      <div class="col-12 mt-3">
          <button [disabled]="form.invalid" class="f-button ms-auto md" (click)="saveChanges()">Zapisz zmiany</button>
      </div>
    </div>
  </form>
  