import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { ApiService } from 'shared/services/api.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];
  posts:any = null;
  // Pagination
  defaultPageSize = 10;
  allPages = 0;
  currentPage = 1;

 count = 0;
  constructor(
    private _apiService:ApiService
  ) {

  }

  ngOnInit(): void {
    this.browsePosts();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }


  browsePosts() {
    this._apiService.browsePosts(this.currentPage)
    .pipe(take(1))
    .subscribe({
        next: (data: any) => {
          if (data == null) return

          this.count = data.count;
          this.allPages = data.totalPages;
          this.currentPage = data.currentPage;
          this.posts = data.items;
        }
      }
    );
  }

  onPageChange(page:number) {
    this.currentPage = page
    if(this.currentPage > this.allPages) this.currentPage = 1

    this.browsePosts()
  }
}
