import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { Utils } from 'fakturnia-shared';
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronization.service";
import { AppDb } from "fakturnia-shared";
import { DbDocument } from "fakturnia-shared";
import { Invoice } from "fakturnia-shared";

@Injectable({
    providedIn: 'root'
})
export class DocumentsService implements OnDestroy {

    private _subscriptions:Subscription[] = [];

    private _documents = new BehaviorSubject<DbDocumentObject>({});
    currentDocuments = this._documents.asObservable();

    private _db:AppDb

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }

    constructor(
        private _dbService:DbService,
        private _dataSynchronizerService:DataSynchronizerService
        )
    {
        this._subscriptions.push(this._dbService.getDatabase().subscribe({
            next: (data)=> {
                this._db = data
            }
        }))

        this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
            next: (data) => {
                if(!data) return
                if(data.table != 'documents') return

                // Update
                data.value.forEach((document: DbDocument) => {
                    this._documents.value[document._id] = document
                });

                // Filter by status
                // Not a good idea
                // const filtered: DbDocumentObject = Object.entries(this._documents.value)
                //     .filter(([_, doc]) => doc.isDeleted === false)
                //     .reduce((acc, [key, value]) => {
                //         acc[key] = value;
                //         return acc;
                //     }, {} as DbDocumentObject);

                // Push changes
                this._documents.next(this._documents.value)

                this._dataSynchronizerService.received()
            }
        }))
    }

    getDocumentsByMonthAndYear(docTypes: string[], month, year):DbDocument[] {
        return Object.entries(this._documents.value).map((x: [any, DbDocument]) => x[1]).filter(x => docTypes.includes(x.documentType) && !x.isDeleted && x.isPaid &&new Date(x.issueDate).getMonth() == month && new Date(x.issueDate).getFullYear() == year)
    }

    getNextDocumentNumber(invoice:Invoice):{ nextNumber: number, currentMonth: number, currentYear:number} {
        let nextNumber = 1;
        const currentMonth = !Utils.isNullOrEmpty(invoice.issueDateHeader.value) ? new Date(invoice.issueDateHeader.value).getMonth() : new Date().getMonth();
        const currentYear = !Utils.isNullOrEmpty(invoice.issueDateHeader.value) ? new Date(invoice.issueDateHeader.value).getFullYear() : new Date().getFullYear();
        const documents = Object.entries(this._documents.value).map((x: [any, DbDocument]) => x[1]).filter(x => x.documentType == invoice.documentType && !x.isDeleted && new Date(x.issueDate).getMonth() == currentMonth && new Date(x.issueDate).getFullYear() == currentYear).length
    
        if (documents > 0) {
          nextNumber = documents + 1;
        }

        return {
            nextNumber: nextNumber,
            currentMonth: currentMonth,
            currentYear: currentYear
        }
    }

    getPrepayments() {
        return Object.values(this._documents.value)
        .filter((invoice:DbDocument)=> invoice.documentType == 'FakturaZaliczka')
    }

    getById(invoiceId):DbDocument | null {
        const invoice = this._documents.value[invoiceId]

        if(!invoice)  {
            console.warn(`Invoice with id: ${invoiceId} not found.`)
            return null
        }
        else return invoice
    }
    
    browseDocuments({currentPage, defaultPageSize, activeFilters, filters}): PaginatedDbDocuments {
        
          let t1 = new Date().getTime();
      
          let fromIndex = (currentPage * defaultPageSize) - defaultPageSize;
          let toIndex = fromIndex + (defaultPageSize - 1);
      
          let results:any = Object.entries(this._documents.value)
          .map(x=> x[1])

          if (filters.length > 0) {
            filters.forEach(filter=> {
                results = results.filter(filter)
            })
          }

          results.sort((a:any,b:any)=>  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          
          activeFilters.forEach((element:any) => {
      
            let key = element[0];
            let value = element[1];
      
            let filterValue = value;
            if(typeof filterValue == 'string') { filterValue = filterValue.toLowerCase()}
            
            if(key == 'searchText')
            {
              results = results.filter(
                (document:DbDocument) => 
                {
                  let found = false; 
                  let name = '';
                  let documentNumber = document.documentNumber.toLowerCase();
                  let firstName = '';
                  let lastName = '';
                  let nazwiskoImie = '';
                  let imieNazwisko = '';
                  let nip = '';
                  let grossSum = document.grossSum.toString()
                  let grossSumFixed = document.grossSum.toFixed(2)
                    
      
                  if (documentNumber.includes(filterValue)) { found = true; return found; }
      
                  const buyer = document.buyer;
                  if(Utils.isNullOrEmpty(buyer)) return false;
                  if (Utils.isDefined(buyer,"name")) { name = buyer['name'].toLowerCase(); }
                  if (Utils.isDefined(buyer, "firstName")) { firstName = buyer['firstName'].toLowerCase(); }
                  if (Utils.isDefined(buyer, "lastName")) { lastName = buyer['lastName'].toLowerCase(); }
                  if (Utils.isDefined(buyer, "nip")) { nip = buyer['nip'].toLowerCase(); }
             
                  if (
                    firstName.length > 0 && lastName.length > 0
                  ) {
                    imieNazwisko = `${firstName} ${lastName}`
                    nazwiskoImie = `${lastName} ${firstName}`
                  }
      
                  if (name.includes(filterValue)) { found = true }
                  else if (firstName.includes(filterValue)) { found = true }
                  else if (imieNazwisko.includes(filterValue)) { found = true }
                  else if (nazwiskoImie.includes(filterValue)) { found = true }
                  else if (nip.includes(filterValue)) { found = true }
                  else if (grossSum.includes(filterValue)) { found = true }
                  else if (grossSumFixed.includes(filterValue)) { found = true }
      
                  return found;
                })
            }
          })
        
          
      
          if(toIndex > results.length-1)
          {
            toIndex = results.length-1;
          }
      
          const paginationResult:PaginatedDbDocuments = {
            time:new Date().getTime() - t1,
            allPages: Math.ceil(results.length / defaultPageSize),
            resultsCount: results.length,
            results: Utils.getObjectsInRange(results, fromIndex,toIndex),
          } 
          
          return paginationResult
      
      
    }

    getAll(): Observable<any> {
        if(!this._db) return of(null)
        const s1 = new Date().getTime();
        const documentLoader = this._db.transaction("r", this._db.documents, () => {
            return this._db.documents.orderBy('createdAt').reverse().toArray();
        }).then((result: DbDocument[]) => {
            const object: DbDocumentObject = {};
            result.forEach(doc => {
                object[doc._id] = doc;
            });
            return object;
        });

        return forkJoin([from(documentLoader)]).pipe(
            map(([documents]) => {
                // Update your subjects
                this._documents.next(documents);
                return { documents };
            }),
            tap(() => console.log(`[Documents Service]: Built in ${new Date().getTime() - s1}ms.`))
        );
    }

}

export interface DbDocumentObject {
    [key: number]: DbDocument;
}

export interface PaginatedDbDocuments {
    results: DbDocument[]
    resultsCount: number
    time: number
    allPages: number
}