<!-- <div id="sidenav-observer">
  <top-bar-menu></top-bar-menu>

  <mat-drawer-container>
    <mat-drawer-content>

      <router-outlet></router-outlet>
      <footer *ngIf="showFooter == true" class="bg-white dark:bg-slate-800 footer pb-4">
        
        <div class="grid grid-cols-8 gap-6 max-w-280 px-4 sm:px-12 mx-auto py-4">
          <div class="col-span-8 md:col-span-2 logo-box">

          </div>
         
          <div class="col-span-8 sm:col-span-4 md:col-span-2 flex justify-start items-center">
            <ul class="mt-4 normal">
              <ng-container *ngIf="isAuthenticated">
                <li>
                  <a [routerLink]="'/blog'" class="h6">Blog &#8250;</a>
                </li>
                <li>
                  <a [routerLink]="'/pomoc'" class="h6">Pomoc &#8250;</a>
                </li>
                <li>
                  <a [routerLink]="'/kontakt'" class="h6">Kontakt &#8250;</a>
                </li>
              </ng-container>
              <ng-container *ngIf="!isAuthenticated">
                <li>
                  <a [routerLink]="'/logowanie'" class="h6">Logowanie &#8250;</a>
                </li>
                <li>
                  <a [routerLink]="'/rejestracja'" class="h6">Rejestracja &#8250;</a>
                </li>
                <li>
                  <a [routerLink]="'/kontakt'" class="h6">Kontakt &#8250;</a>
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="col-span-8 sm:col-span-4 md:col-span-2 flex justify-start items-center">
            <ul class="mt-4 normal">
              <ng-container *ngIf="!isAuthenticated">
                <li>
                  <a [routerLink]="'/o-aplikacji'" class="h6">O aplikacji &#8250;</a>
                </li>
                <li>
                  <a [routerLink]="'/funkcjonalnosci-aplikacji'" class="h6">Funkcjonalności &#8250;</a>
                </li>
                <li>
                  <a [routerLink]="'/pomoc'" class="h6">Pomoc &#8250;</a>
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="col-span-8 sm:col-span-4 md:col-span-2 flex justify-start items-center">
            <div>
              <b>Potrzebujesz wsparcia?</b>
              <br/> 
              <a [href]="'mailto:' + config.emailAddress">{{config.emailAddress}}</a>
            </div>
          </div>
        </div>

        <div class="bg-white rounded-xl grid grid-cols-9 gap-6 max-w-200 px-4 sm:px-12 mx-auto items-center border-t border-gray-100 dark:border-gray-900 py-2 my-4">
          <div class="col-span-8 sm:col-span-3">
            <img src="/assets/images/logo_gus_pl.svg" alt="" class="max-w-64 sm:mx-auto">
          </div>
          <div class="col-span-8 sm:col-span-3">
            <img src="/assets/images/PL_pl.png" alt="" class="max-w-50 max-h-24 sm:mx-auto " >
          </div>
          <div class="col-span-8 sm:col-span-3">
            <img src="/assets/images/mf_transparent.png" alt="" class="max-w-50 max-h-24 sm:mx-auto ">
          </div>
        </div>

        <div class="grid grid-cols-4 gap-6 max-w-280 px-4 sm:px-12 mx-auto items-center border-t border-gray-100 dark:border-gray-900 py-4">
          <div class="col-span-4 sm:col-span-2">
            <small>
              Wszelkie prawa zastrzeżone
            </small>
            <small class="ml-4">v{{config.version}}</small>
          </div>
          <div class="col-span-4 sm:col-span-2 flex justify-end">
            <div>
              <a [routerLink]="'/regulamin'" class="h6">Regulamin</a>
              <a [routerLink]="'/polityka-prywatnosci'" class="h6 mx-5">Polityka prywatności</a>
            </div>
          </div>
        </div>

      </footer>
    </mat-drawer-content>
    <mat-drawer #rightPanel [mode]="mode" class="right-drawer" position="end" opened="false">
      <right-panel></right-panel>
    </mat-drawer>
  </mat-drawer-container>
</div> -->
<router-outlet></router-outlet>
<div id="notifications-container"></div>
<!-- </mat-sidenav-content>
</mat-sidenav-container> -->
