import { APIResponse } from "fakturnia-shared";

export class ErrorUtils {
    private static _errors: any = []
    public static extractErrors(errorResponse: APIResponse | any) {
        this._errors = [];

        for (const error of errorResponse.errors) {
            const pathPrefix = error.property;

            if (error.children && error.children.length > 0) {
                this._parseChildren(error.children, pathPrefix);
            }

            if(error.constraints) {
                const constraints = Object.values(error.constraints);
                this._errors.push({ field: pathPrefix, errors: constraints });
            }
        }

        return this._errors;
    }

    private static _parseChildren(children: Array<ErrorChild>, pathPrefix: string) {
        for (const child of children) {
            const currentPath = `${pathPrefix}.${child.property}`;
            if (child.constraints) {
                const constraints = Object.values(child.constraints);
                this._errors.push({ field: currentPath, errors: constraints });
            }
            if (child.children && child.children.length > 0) {
                this._parseChildren(child.children, currentPath);
            }
        }
    }
}


type ErrorChild = {
    target: any;
    value: any;
    property: string;
    children: Array<ErrorChild>;
    constraints?: { [key: string]: string };
};