import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, take } from "rxjs";
import { ApiService } from "./api.service";
import { UserSettings, Settings, AppData, APIResponse, Utils } from "fakturnia-shared";
import { ToastService } from "./toast.service";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private _userSettings:any = new BehaviorSubject<UserSettings | any>(new UserSettings());
    constructor(
        private apiService:ApiService,
        private _toastService:ToastService
    ) { }

    refreshSettings() {
        this.apiService.getUserSettings()
        .pipe(take(1))
        .subscribe({
            next: (response:APIResponse) => {

                if(response.success == false) { 
                    this._toastService.warning(response.message)
                    return
                }
                
                if(response.success == true) {
                    this.updateUserSettings(response.data);
                }
                
            }
            
        })
    }
    getUserSettings(): Observable<UserSettings> {
        if(this._userSettings.value.settings instanceof Settings && this._userSettings.value.settings.vatRates.length == 0) this._userSettings.value.settings.vatRates = AppData.DefaultVatRates.map(v=>v.value)
        return this._userSettings;
    }

    updateUserSettings(userSettings:UserSettings)
    {
        // if(Utils.isNullOrEmpty(userSettings.settings)) userSettings.settings = new Settings();
        // if(!Utils.isNullOrEmpty(userSettings.settings)) userSettings.settings = Utils.parseJSONToClass(<string>userSettings.settings, Settings) || new Settings();
        this._userSettings.next(userSettings);
    }

    updateSettings(settings:string | Settings)
    {
        if(!Utils.isNullOrEmpty(settings)) settings = Utils.parseJSONToClass(<string>settings, Settings) || new Settings();
        this._userSettings.value.settings = <Settings>settings;
    }
}