import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { AuthService } from 'shared/services/auth.service';
import { DialogService } from 'shared/services/dialog.service';
import { ToastService } from 'shared/services/toast.service';
import { matchFieldsValidator } from 'shared/validators/match-fields.validator';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  form = new FormGroup({
    currentPassword: new FormControl('',[Validators.required]),
    newPassword: new FormControl('',[Validators.required, Validators.minLength(8)]),
    passwordRepeat: new FormControl('',[Validators.required, Validators.minLength(8)]),
  },  
  { validators: matchFieldsValidator('newPassword', 'passwordRepeat')  })


  constructor(
    private _authService:AuthService, 
    private _dialogService:DialogService,
    private _toastService:ToastService) {

  }

  changePassword()
  {
    if(!this.form.valid) return;

    this._dialogService.confirmDialog({
      title: "Potwierdzenie zmiany hasła",
      confirmText: "Zmień hasło",
      message: "Czy na pewno chcesz zmienić hasło do konta?",
      type: 'warning'
    }).subscribe({
      next: (data) => {
        if(data == true) {
          this._authService.changePassword(this.form.value).subscribe({
            next: (data:APIResponse)=> {
      
              if(data.success == false) {
                this._toastService.warning(data.message)
                return
              }
      
              else if(data.success == true) {
                this._toastService.showToast(new NotificationData({
                  title: 'Informacja',
                  status: 'info',
                  message: 'Hasło zostało zmienione',
                }))
      
                this.form.reset()
              }
             
            }
          })
        }
      }
    })
    
  }
}
