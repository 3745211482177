import { Component, OnDestroy } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { User } from 'fakturnia-shared';
import { SettingsService } from 'shared/services/settings.service';
import { UserSettings } from "fakturnia-shared";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'shared/services/api.service';
import { NotificationData } from 'fakturnia-shared';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'personal-informations',
  templateUrl: './personal-informations.component.html',
  styleUrls: ['./personal-informations.component.scss']
})
export class PersonalInformationsComponent implements OnDestroy {

  subscriptions:Subscription[] = [];
  user:User

  form = new UntypedFormGroup({
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    emailAddress: new UntypedFormControl('', Validators.required),
    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(9)]),
    pesel: new UntypedFormControl(''),
    idNumber: new UntypedFormControl(''),
  })

  constructor(
    private settingsService:SettingsService,
    private apiService:ApiService,
    private _toastService:ToastService
  )
  {

    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data:UserSettings)=> {
        this.user = data.user

        this.form.controls['firstName'].setValue(this.user.firstName)
        this.form.controls['lastName'].setValue(this.user.lastName)
        this.form.controls['emailAddress'].setValue(this.user.emailAddress)
        this.form.controls['phoneNumber'].setValue(this.user.phoneNumber)
        this.form.controls['pesel'].setValue(this.user.pesel)
        this.form.controls['idNumber'].setValue(this.user.idNumber)

        this.form.controls['emailAddress'].disable()
      }
    }))

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  saveChanges()
  {
    if(this.form.invalid) return

    let data = this.form.value;
    delete data.emailAddress;
    this.apiService.updatePersonalInformations(data)
    .pipe(take(1))
    .subscribe({
      next: (data:any)=> {
        if(data.success) {
          this._toastService.showToast(new NotificationData({
            title: 'Informacja',
            status: 'info',
            message: 'Dane zostały zapisane.',
          }))
          this.settingsService.refreshSettings()
        } else {
          this._toastService.showToast(new NotificationData({
            title: 'Wystąpił błąd',
            status: 'warning',
            message: 'Formularz zawiera błędy, popraw go i spróbuj ponownie.',
          }))
        } 
      },
      error: (err)=> {
        this._toastService.showToast(new NotificationData({
          title: 'Wystąpił błąd',
          status: 'danger',
          message: 'Nie udało się zaktualizować danych. Spróbuj ponownie później.',
        }))
      }
    })
  }
}
