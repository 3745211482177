import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Utils } from 'fakturnia-shared';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnChanges {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input('label') label:string;
  @Input('labels') labels:string[] = [];
  @Input('date') date:string 
  dateAsDateObject:Date | null = null;
  @Output() change = new EventEmitter();
  @Output() onSelectLabel = new EventEmitter();

  constructor(private cdr:ChangeDetectorRef)
  {
    
  }

  selectLabel(label) {
    this.label = label
    this.onSelectLabel.emit(label)
  }

  showMenu() {
    if(this.labels.length == 0) return
    this.trigger.openMenu();
  }
  ngOnChanges(changes: SimpleChanges): void {

    if(Utils.isNullOrEmpty(this.label) && this.labels.length > 0) {
      this.selectLabel(this.labels[0])
    }

    // if(changes['date'] == null) return
    // if(typeof changes['date'] == 'undefined') return
    // if(typeof changes['date'].currentValue == 'undefined') return

    if(this.date == null || typeof this.date == 'undefined' || Utils.isNullOrEmpty(this.date)) this.date = new Date().toISOString().slice(0,10)
    setTimeout(() => {
      this.dateAsDateObject = new Date(this.date);
      this.cdr.detectChanges();
    }, 10)

   
  }
  
  inputChange(value:Date)
  {
    setTimeout(()=>{
      let isValid = true;
      try {
        if(isNaN(value.getTime())) { return;}
      }
      catch(ex)
      {
        isValid = false;
      }
      
      if(isValid) { 
        this.change.emit(value);
        this.cdr.detectChanges();
      }
    },0)
  }

}
