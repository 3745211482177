import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subscription, take } from "rxjs";
import { DbClient, Invoice, Utils } from 'fakturnia-shared';
import { ConfirmDialogComponent, ConfirmDialogData } from "../dialogs/confirm-dialog/confirm-dialog.component";
import { SearchGusCompanyDialogComponent } from "../dialogs/search-gus-company-dialog/search-gus-company-dialog.component";
import { RegisterOrLoginDialogComponent } from "../dialogs/register-or-login-dialog/register-or-login-dialog.component";
import { SetUpCompanyDialogComponent } from "../dialogs/set-up-company-dialog/set-up-company-dialog.component";
import { AddOrUpdateBankAccountDialogComponent } from "../dialogs/add-or-update-bank-account-dialog/add-or-update-bank-account-dialog.component";
import { ToggleFormFieldsDialogComponent } from "../dialogs/toggle-form-fields-dialog/toggle-form-fields-dialog.component";
import { CreateOrUpdateClientDialogComponent } from "../dialogs/create-or-update-client-dialog/create-or-update-client-dialog.component";
import { SelectInvoiceDialogComponent } from "../dialogs/select-invoice-dialog/select-invoice-dialog.component";
import { ApiService } from "./api.service";
import { SynchronizationService } from "./synchronization.service";
import { SnackbarService } from "./snackbar.service";
import { APIResponse } from "fakturnia-shared";
import { IBankAccount } from "fakturnia-shared";
import { SendInvoiceByEmailDialogComponent } from "../dialogs/send-invoice-by-email-dialog/send-invoice-by-email-dialog.component";
import { DisplayFormErrorsDialogComponent } from "../dialogs/display-form-errors-dialog/display-form-errors-dialog.component";
import { DocumentsApiService } from "./api/documents.api.service";
import { AddClientDialogComponent } from "shared/dialogs/add-client-dialog/add-client-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class DialogService implements OnDestroy {
  private _subscriptions: Subscription[] = [];
  constructor(
    private dialog: MatDialog,
    private _apiService:ApiService,
    private _synchronizationService:SynchronizationService,
    private _snackbarService:SnackbarService,
    private _documentsApiService:DocumentsApiService
  ) {

  }

  confirmDialog(data: ConfirmDialogData): Observable<boolean | null> {
    let toReturn = new BehaviorSubject<boolean | null>(null);

    this._subscriptions.push(this.dialog.open(ConfirmDialogComponent, {
      data,
      width: '512px'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            if (!Utils.isDefined(data, "action")) { toReturn.next(false); return; }

            if (data.action == true) {
              toReturn.next(true)
            }
            else if (data.action == false) {
              toReturn.next(false)
            }
          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }

  confirmMarkAsPaidOrTemplateDialog(isPaid, selectedDocs, title:string | null = null, message:string | null = null) {
    let toReturn = new BehaviorSubject<boolean | null>(null);

    

    this.confirmDialog({
      title: title ? title : "Oznaczanie dokumentów",
      message:  message ? message : `Chcesz oznaczyć wybrane dokumenty? Ilość wybranych dokumentów: ${selectedDocs.length}`,
      type: "info",
      confirmText: "Oznacz"
    })
    .subscribe({
      next: (data) => {

        if(data != true) return

        this._documentsApiService.markDocumentsAsPaidOrTemplate(selectedDocs, isPaid)
        .pipe(take(1))
        .subscribe({
          next: (response:APIResponse) => {

            if(!response.success) {
              this._snackbarService.showSnackBar('Operacja się nie powiodła.','error')
              toReturn.next(false)
              return
            }

            this._synchronizationService.synchronize()
            this._snackbarService.showSnackBar("Operacja przebiegła pomyślnie.","success")
            toReturn.next(true)
          }

        })
      }
    })

    return toReturn.asObservable()
  }

  confirmDeleteInvoiceDialog(invoice:Invoice) {

    let toReturn = new BehaviorSubject<boolean | null>(null);

    this.confirmDialog({
      title: "Usuwanie dokumentu",
      message: `Czy na pewno chcesz usunąć dokument ${invoice.invoiceName}?`,
      type: "danger",
      confirmText: "Usuń"
    })
    .subscribe({
        next: (data) => {

          if(data != true) return

          this._documentsApiService.delete(invoice._id)
          .pipe(take(1))
          .subscribe({
            next: (response:APIResponse) => {

              if(!response.success) {
                this._snackbarService.showSnackBar('Nie udało się usunąć tego dokumentu.','success')
                toReturn.next(false)
                return
              }

              this._synchronizationService.synchronize()
              this._snackbarService.showSnackBar('Dokument został usunięty','error')

              toReturn.next(true)

            }
          })
        }
      }
    )

    return toReturn.asObservable()
  }

  confirmDeleteInvoicesDialog(invoices, title:string | null = null, message:string | null = null) {

    let toReturn = new BehaviorSubject<boolean | null>(null);

    this.confirmDialog({
      title: title ? title : "Usuwanie dokumentów",
      message:  message ? message : `Chcesz usunąć zaznaczone elementy? Ilość usuwanych dokumentów: ${invoices.length}`,
      type: "danger",
      confirmText: "Usuń"
    })
    .subscribe({
        next: (data) => {

          if(data != true) return

          this._apiService.deleteDocuments(invoices)
          .pipe(take(1))
          .subscribe({
            next: (response:APIResponse) => {

              if(!response.success) {
                this._snackbarService.showSnackBar('Operacja usuwania się nie powiodła.','error')
                toReturn.next(false)
                return
              }

              this._synchronizationService.synchronize()
              this._snackbarService.showSnackBar('Operacja usuwania się powiodła.','success')
              toReturn.next(true)

            }
          })
        }
      }
    )

    return toReturn.asObservable()
  }

  public showSendInvoiceByEmailDialog(invoice:Invoice){

    this.dialog.open(SendInvoiceByEmailDialogComponent, {
      data: { invoice: invoice},
      width: '600px',
      panelClass: 'full-size-mobile'
    })

  }

  public showFormErrorsDialog(errors:{field: string, errors: string[]}[]){

    this.dialog.open(DisplayFormErrorsDialogComponent, {
      data: { errors: errors},
      width: '500px'
    })

  }

    public showSelectInvoiceDialog({filters, selection = 'multiple'}: { filters:Function[], selection: 'single' | 'multiple'}): Observable<boolean | null | any> {
    let toReturn = new BehaviorSubject<boolean | null>(null);

    this._subscriptions.push(this.dialog.open(SelectInvoiceDialogComponent, {
      data: { filters: filters, selection: selection},
      panelClass: 'select-invoice-dialog',
      width: '900px'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {
            if(!data) { 
              toReturn.next(false)
              return
            }
            else if(data.invoices)  toReturn.next(data.invoices)

          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }

  showRegisterOrLoginDialog(data?: any): Observable<boolean | null> {
    let toReturn = new BehaviorSubject<boolean | null>(null);

    this._subscriptions.push(this.dialog.open(RegisterOrLoginDialogComponent, {
      data,
      panelClass: 'therrax-dialog',
      // width: '512px'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            toReturn.next(data.result)

          },
          error: (err) => {
            toReturn.next(false)
          }
        }
      ))

    return toReturn.asObservable();
  }

  showAddClientDialog(): Observable<boolean | null> {
    let toReturn = new BehaviorSubject<any>({
      success: null,
      data: null
    });

    this._subscriptions.push(this.dialog.open(AddClientDialogComponent, {
      panelClass: 'full-size-mobile'
    })
      .afterClosed()
      .subscribe(
        {
          next: (response) => {

            if(!response || typeof response.success == 'undefined') {
              toReturn.next({
                success: false,
                data: null
              })
              return
            }

            if(response.success) {
              toReturn.next({
                success: true,
                data: response.data
              })
              return
            }

            toReturn.next({
              success: false,
              data: null
            })
          },
          error: (err) => {
            toReturn.next({
              success: false,
              data: null
            })
          }
        }
      ))

    return toReturn.asObservable();
  }

  showAddOrUpdateBankAccount(bankAccount:IBankAccount | undefined): Observable<boolean | null> {
    let toReturn = new BehaviorSubject<any>({
      success: null,
      data: null
    });

    this._subscriptions.push(this.dialog.open(AddOrUpdateBankAccountDialogComponent, {
      width: '512px',
      data: bankAccount
    })
      .afterClosed()
      .subscribe(
        {
          next: (response) => {

            if(!response || typeof response.success == 'undefined') {
              toReturn.next({
                success: false,
                data: null
              })
              return
            }

            if(response.success) {
              toReturn.next({
                success: true,
                data: response.data
              })
              return
            }

            toReturn.next({
              success: false,
              data: null
            })
          },
          error: (err) => {
            toReturn.next({
              success: false,
              data: null
            })
          }
        }
      ))

    return toReturn.asObservable();
  }

  showSetUpCompanyDialog(): Observable<boolean | null> {
    let toReturn = new BehaviorSubject<any>({
      success: null,
      data: null
    });

    this._subscriptions.push(this.dialog.open(SetUpCompanyDialogComponent, {
      width: '650px',
      disableClose: true,
      panelClass: 'full-size-mobile'
    })
      .afterClosed()
      .subscribe(
        {
          next: (response) => {

            if(!response || typeof response.success == 'undefined') {
              toReturn.next({
                success: false,
                data: null
              })
              return
            }

            if(response.success) {
              toReturn.next({
                success: true,
                data: response.data
              })
              return
            }

            toReturn.next({
              success: false,
              data: null
            })
          },
          error: (err) => {
            toReturn.next({
              success: false,
              data: null
            })
          }
        }
      ))

    return toReturn.asObservable();
  }

  showSearchGUSResultsDialog(data: any): Observable<boolean | null> {
    let toReturn = new BehaviorSubject<any>({
      action: null,
      company: null
    });

    this._subscriptions.push(this.dialog.open(SearchGusCompanyDialogComponent, {
      data,
      width: '512px'
    })
      .afterClosed()
      .subscribe(
        {
          next: (data) => {

            if (!Utils.isDefined(data, "action")) {
              toReturn.next({
                action: false,
                company: null
              })
              return
            }

            if (data.action == true) {
              toReturn.next({
                action: true,
                company: data.company
              })
            }
            else if (data.action == false) {
              toReturn.next({
                action: false,
                company: null
              })
            }
          },
          error: (err) => {
            toReturn.next({
              action: false,
              company: null
            })
          }
        }
      ))

    return toReturn.asObservable();
  }

  showToggleFormFieldsDialog({
    currency,
     generalFieldsToShow
  }): Observable<any> {
    let toReturn = new BehaviorSubject<any>({
      success: null,
      data: null
    });

    this._subscriptions.push(this.dialog.open(ToggleFormFieldsDialogComponent, {
      width: '650px',
      panelClass: 'full-size-mobile',
      data: {
        currency: currency,
        generalFieldsToShow: generalFieldsToShow
      }
    })
      .afterClosed()
      .subscribe(
        {
          next: (response) => {

            if(!response || typeof response.success == 'undefined') {
              toReturn.next({
                success: false,
                data: null
              })
              return
            }

            if(response.success) {
              toReturn.next({
                success: true,
                data: response.data
              })
              return
            }

            toReturn.next({
              success: false,
              data: null
            })
          },
          error: (err) => {
            toReturn.next({
              success: false,
              data: null
            })
          }
        }
      ))

    return toReturn.asObservable();
  }

  showCreateOrUpdateClientDialog(client: DbClient, clientType?: 'seller' | 'buyer' | 'receiver',): Observable<any> {
    let toReturn = new BehaviorSubject<any>({
      success: null,
      client: null
    });

    this._subscriptions.push(this.dialog.open(CreateOrUpdateClientDialogComponent, {
      width: '650px',
      panelClass: 'full-size-mobile',
      data: {
        client: client,
        clientType: clientType,
      }
    })
      .afterClosed()
      .subscribe(
        {
          next: (response) => {

            if(!response || typeof response === 'undefined' || typeof response.success == 'undefined') {
              toReturn.next({
                success: false,
                client: null
              })
              return
            }

            if(response.success) {
              toReturn.next(response)
              return
            }

            toReturn.next({
              success: false,
              client: null
            })
          },
          error: (err) => {
            toReturn.next({
              success: false,
              client: null
            })
          }
        }
      ))

    return toReturn.asObservable();
  }

  ngOnDestroy(): void {
    this.clear_subscriptions();
  }

  clear_subscriptions() {
    // Print subs to delete
    // console.log(this._subscriptions.length);
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this._subscriptions = [];
  }
}