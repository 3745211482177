import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModernLayoutComponent } from './layouts/modern-layout/modern-layout.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, ModernLayoutComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  layout = 'modern'

}
