<div class="px-6 pb-6 pt-5">

    <div class="grid grid-cols-6 gap-3 max-w-2xl">

        <div class="col-span-6">
            <h6 class="text-secondary">Podstawowe informacje</h6>
        </div>

        <div class="col-span-6">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Nazwa towaru lub usługi</mat-label>
                <input matInput [formControl]="form.controls.name">
                <mat-error *ngIf="form.controls['name'].hasError('required')">Pole jest wymagane</mat-error>
            </mat-form-field>
        </div>

        <div class="col-span-6 sm:col-span-3">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Jednostka miary</mat-label>
                <mat-select [formControl]="form.controls.unitOfMeasure">
                    <mat-option *ngFor="let measurementUnit of measurementUnits" [value]="measurementUnit.value">{{measurementUnit?.viewValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-span-6 sm:col-span-3">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Domyślna ilość</mat-label>
                <input matInput quantity [formControl]="form.controls.quantity">
                <div matTextSuffix>{{form.controls.unitOfMeasure.value}}</div>
                <mat-error *ngIf="form.controls['quantity'].hasError('required')">Pole jest wymagane</mat-error>
            </mat-form-field>
        </div>

        <div class="col-span-6 sm:col-span-2">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Cena netto</mat-label>
                <input matInput decimalFormatter [formControl]="form.controls.netPrice">
                <div matTextSuffix>{{form.controls.currency.value}}</div>
                <mat-error *ngIf="form.controls['netPrice'].hasError('required')">Pole jest wymagane</mat-error>
            </mat-form-field>
        </div>

        <div class="col-span-6 sm:col-span-2">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Stawka VAT</mat-label>
                <mat-select [formControl]="form.controls.vatRate">
                    <mat-option *ngFor="let rate of vatRates" [value]="rate.value">{{rate?.viewValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-span-6 sm:col-span-2">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Wartość brutto</mat-label>
                <input matInput decimalFormatter [formControl]="form.controls.grossValue">
                <div matTextSuffix>{{form.controls.currency.value}}</div>
                <mat-error *ngIf="form.controls['grossValue'].hasError('required')">Pole jest wymagane</mat-error>
            </mat-form-field>
        </div>

        <div class="col-span-6 sm:col-span-2">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Waluta</mat-label>
                <mat-select [formControl]="form.controls.currency">
                    <mat-option *ngFor="let currency of currencies" [value]="currency.id">{{currency.id}} - {{currency.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-span-6 sm:col-span-2">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Podatek</mat-label>
                <input matInput readonly decimalFormatter [formControl]="form.controls.amountOfVat">
                <div matTextSuffix>{{form.controls.currency.value}}</div>
            </mat-form-field>
        </div>

        <div class="col-span-6 flex justify-end">
            <button *ngIf="mode == 'UPDATE'" [disabled]="form.invalid || isProcessing" (click)="saveChanges()" mat-flat-button color="primary">Zapisz zmiany</button>
            <button *ngIf="mode == 'CREATE'" [disabled]="form.invalid || isProcessing" (click)="create()" mat-flat-button color="primary">Dodaj produkt</button>
        </div>
    </div>
</div>