import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription, debounceTime, take } from 'rxjs';
import { UserSettings } from "fakturnia-shared";
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { SettingsService } from 'shared/services/settings.service';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'place-of-issue',
  templateUrl: './place-of-issue.component.html',
  styleUrls: ['./place-of-issue.component.scss']
})
export class PlaceOfIssueComponent implements OnDestroy {

  subscriptions: Subscription[] = [];
  control = new FormControl('',[Validators.required]);
  constructor(
    private apiService:ApiService,
    private settingsService:SettingsService,
    private _toastService:ToastService
  ) {
    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data: UserSettings)=> {
        this.control.setValue(data.settings.autocomplete.issuePlace, { emitEvent: false})
      }
    }))
  }

  saveChanges() {
    this.apiService.updateIssuePlace(this.control.value)
    .pipe(take(1))
    .subscribe({
      next: (data:APIResponse)=>{
        if(data.success) {
          this._toastService.showToast(new NotificationData({
            title: 'Informacja',
            status: 'info',
            message: 'Ustawienia zostały zapisane.',
          }))
          this.settingsService.refreshSettings()
        }
      }
    })
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
