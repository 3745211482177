    <div class="row" style="max-width:500px;">
      <div class="col-12">
        <h4>Zmiana hasła</h4>
        <p>Nowe hasło powinno zawierać minimum 8 znaków.</p>

        <form [formGroup]="form">
          <div class="form-floating mt-2">
            <input formControlName="currentPassword" autocomplete="new-password" placeholder="Aktualne hasło"
              type="password" class="form-control text-left" />
            <label for="currentPassword">Aktualne hasło</label>
          </div>
          <div class="form-floating mt-2">
            <input formControlName="newPassword" autocomplete="new-password" placeholder="Nowe hasło" type="password"
              class="form-control text-left" />
            <label for="password">Nowe hasło</label>
          </div>
          <div class="form-floating mt-2">
            <input formControlName="passwordRepeat" autocomplete="new-password" placeholder="Powtórz nowe hasło"
              type="password" class="form-control text-left" />
            <label for="passwordRepeat">Powtórz nowe hasło</label>
          </div>
          <div class="color-red" *ngIf="form.hasError('matchFields')">
            Powtórzone hasło jest inne.
          </div>
        </form>


        <button [disabled]="!form.valid" class="f-button ms-auto md mt-2"
          (click)="changePassword()">Ustaw nowe hasło</button>

      </div>
    </div>
