import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { ApiService } from "./api.service";
import { SettingsService } from "./settings.service";
import { take } from "rxjs";
import { DialogService } from "./dialog.service";
import { DbService } from "./db.service";
import { SynchronizationService } from "./synchronization.service";
import { ToastService } from "./toast.service";
import { APIResponse } from "fakturnia-shared";

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private _authService: AuthService,
    private _apiService: ApiService,
    private _settingsService: SettingsService,
    private _synchronizationService:SynchronizationService, 
    private _dbService:DbService, 
    private _dialogService:DialogService,
    private _toastService:ToastService
  ) 
  {

  }

  initializeApp() {
    return new Promise<void>((resolve, reject) => {

      if (!this._authService.isLoggedIn()) {
        resolve();
        return;
      }

      this._apiService.getUserSettings().subscribe({

        next: (response:APIResponse) => {

          console.warn("Intialize platform");

          if(response.success == false) { 
            resolve();

            setTimeout(()=> {
              this._toastService.warning(response.message)
              this._authService.logout({muted: true})
            },3000)
            
            return
          }

          // Set user role
          localStorage.setItem('role',response.data.user.role);
          // Update user settings
          this._settingsService.updateUserSettings(response.data);
          // Set user database
          this._dbService.setDatabase(response.data.user.userId);
          // Synchronize app
          this._synchronizationService.synchronize('App initializator')

          if(response.data.user.hasCompletedRegistration == false || 
            typeof response.data.user.hasCompletedRegistration == 'undefined') {
                this._dialogService.showSetUpCompanyDialog()
                .pipe(take(1))
                  .subscribe()
            }
          resolve();
        },
        error: (err)=> {
          resolve()
        }

      });
    })
  }
}
