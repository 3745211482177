import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"

@Injectable({
    providedIn: 'root'
})
export class DataSynchronizerService {

    private _data = new BehaviorSubject<any>(null)
    currentData = this._data.asObservable()

    next(table: 'documents' | 'clients' | 'products' | 'activityLogs', value) {
        this._data.next({
            table: table,
            value: value
        })
    }

    received() {
        this._data.next(null)
    }
}