import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { APIResponse } from 'fakturnia-shared';
import { take } from 'rxjs';
import { ApiService } from 'shared/services/api.service';
import { BankAccountsApiService } from 'shared/services/api/bank-accounts.api.service';
import { DialogService } from 'shared/services/dialog.service';
import { SettingsService } from 'shared/services/settings.service';
import { SnackbarService } from 'shared/services/snackbar.service';
import { Utils } from 'fakturnia-shared';

@Component({
  selector: 'app-add-or-update-bank-account-dialog',
  templateUrl: './add-or-update-bank-account-dialog.component.html',
  styleUrls: ['./add-or-update-bank-account-dialog.component.scss']
})
export class AddOrUpdateBankAccountDialogComponent {

  mode: 'create' | 'update' = 'create'

  form = new UntypedFormGroup({
    _id: new UntypedFormControl(null),
    bank: new UntypedFormControl('',[Validators.required]),
    accountNumber: new UntypedFormControl('',[Validators.required]),
  })

  constructor(
    private dialogRef:MatDialogRef<AddOrUpdateBankAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService:DialogService,
    private _bankAccountAPIService:BankAccountsApiService,
    private settingsService:SettingsService,
    private _snackbarService:SnackbarService
  )
  {
    if (typeof data != 'undefined') {
      this.form.controls['_id'].setValue(this.data._id)
      this.form.controls['bank'].setValue(this.data.bank)
      this.form.controls['accountNumber'].setValue(this.data.accountNumber)
    
      if(Utils.isDefined(data, "_id")) {
        this.mode = 'update'
      } 

    }
  }

  create() {

    this._bankAccountAPIService.createBankAccount(this.form.value).pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        this.settingsService.refreshSettings()
        this.close()
      }
    })

  }

  update() {

    this._bankAccountAPIService.updateBankAccount(this.form.value).pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        this.settingsService.refreshSettings()
        this.close()
      }
    })
 
  }

  showDeleteConfirmation()
  {
    this.dialogService.confirmDialog({
      confirmText: "Tak, usuń.",
      cancelText: "Anuluj",
      message: "Czy na pewno chcesz usunąć ten numer konta?",
      title: "Potwierdź usunięcie.",
      type: 'danger'
    })
    .subscribe({
      next: (data)=> {
        if(data == true) {
          this._bankAccountAPIService.deleteBankAccount(this.form.controls['_id'].value).pipe(take(1)).subscribe({
            next: (response:APIResponse) => {

              if(!response.success) return

              this.settingsService.refreshSettings()
              this._snackbarService.showSnackBar(response.message,'success')
              this.close()

            }
          })
        }
      }
    })
  }

  close() {
    this.dialogRef.close()
  }

}
