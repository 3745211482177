
  <div class="px-3">
    <div class="row">
      <div class="col-12 col-sm-8 pt-3 pl-4">
        <h2 *ngIf="mode == 'create'">Dodaj produkt</h2>
        <h2 *ngIf="mode == 'update'">Edycja produktu</h2>
      </div>
      <div class="col-12 col-sm-4">
        <span class="float-end mt-2">
          <!-- <button mat-icon-button matTooltip="Ustawienia">
          <mat-icon>settings</mat-icon>
        </button> -->
          <button (click)="close()" mat-icon-button matTooltip="Zamknij okno">
            <mat-icon>close</mat-icon>
          </button>
        </span>
      </div>
    </div>

  </div>
  <mat-divider class="my-3"></mat-divider>
  <div class="px-3">
    <form [formGroup]="productForm">
      <div class="row mb-2">
        <div class="col-12">
          <h5>Informacje podstawowe</h5>
        </div>
      </div>
      <div class="row">

        <div class="col-9">
          <mat-form-field class="w-100" [appearance]="appearance">
            <mat-label>Nazwa produktu</mat-label>
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field [appearance]="appearance" class="w-100">
            <mat-label>Jednostka miary</mat-label>
            <mat-select formControlName="unitOfMeasure">
              <mat-option *ngFor="let measurementUnit of measurementUnits" [value]="measurementUnit.value">
                {{measurementUnit?.viewValue}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <mat-form-field class="w-100" [appearance]="appearance">
            <mat-label>Domyślna ilość</mat-label>
            <input matInput type="text" formControlName="quantity" />
          </mat-form-field>
        </div>
        <div class="col-12 col-md-3">
          <mat-form-field class="w-100" [appearance]="appearance">
            <mat-label>Cena netto</mat-label>
            <input matInput decimalFormatter type="text" formControlName="netPrice" (change)="this.calculationProduct.setNetPrice($event.target.value); this.countAll()" />
            <mat-hint>* za sztukę</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-3">
          <mat-form-field class="w-100" [appearance]="appearance">
            <mat-label>Stawka VAT</mat-label>
            <mat-select formControlName="vatRate">
              <mat-option *ngFor="let rate of vatRates" [value]="rate.value">
                {{rate?.viewValue}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-3">
          <mat-form-field class="w-100" [appearance]="appearance">
            <mat-label>Wartość brutto </mat-label>
            <input matInput type="text" decimalFormatter formControlName="grossValue" (change)="this.calculationProduct.setGrossValue($event.target.value); this.countAll()"/>
            <mat-hint>Razem</mat-hint>
          </mat-form-field>
        </div>
       
        <mat-divider class="my-2"></mat-divider>
        <div class="row">
          <div class="col-12">
            Wartość netto: {{productForm.controls?.['netValue']?.value?.toFixed(2)}}<br/>
            Razem VAT: {{productForm.controls?.['amountOfVat']?.value?.toFixed(2)}}<br/>
            Cena brutto za sztukę: {{productForm?.controls['grossPrice']?.value?.toFixed(2)}}<br/>
          </div>
        </div>
      </div>

    

      <div class="row mb-2">

        <mat-divider class="my-2"></mat-divider>

        <div class="col-12">
          <h5>Informacje zaawansowane</h5>
        </div>
        <div class="col-12 col-md-3">
          <mat-form-field class="w-100" [appearance]="appearance">
            <mat-label>GTU</mat-label>
            <mat-select formControlName="gtu" panelClass="w-250px">
              <mat-option *ngFor="let element of gtu" [value]="element.id">
                {{element.id}} <br/>
                <small>{{element?.text}}</small>
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">

          <button class="me-2" *ngIf="mode == 'update'" color="warn" mat-button (click)="deleteProduct()">
            <span>Usuń</span>
          </button>

          <button [disabled]="!productForm.valid" color="primary" mat-flat-button (click)="saveProduct()">
            <span *ngIf="mode == 'create'">Dodaj produkt</span>
            <span *ngIf="mode == 'update'">Zapisz zmiany</span>
          </button>
        </div>
      </div>
    </form>
  </div>
