import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, fromEvent, take } from 'rxjs';
import { UserSettings } from "fakturnia-shared";
import { AuthService } from 'shared/services/auth.service';
import { DatabaseService } from 'shared/services/database.service';
import { DialogService } from 'shared/services/dialog.service';
import { SettingsService } from 'shared/services/settings.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { Utils } from 'fakturnia-shared';

@Component({
  selector: 'top-bar-menu',
  templateUrl: './top-bar-menu.component.html',
  styleUrls: ['./top-bar-menu.component.scss']
})
export class TopBarMenuComponent implements OnDestroy {

  private _subscriptions: Subscription[] = [];

  menuExpanded = false
  scrollMoved = false
  isAuthenticated = false;

  isSynchronizing = true;
  isAdmin = false
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  constructor(
    private _synchronizationService:SynchronizationService,
    private _route:Router,
    private _authService:AuthService,
    private _dialogService:DialogService,
    private _databaseService:DatabaseService,
    private _settingsService:SettingsService
  )
  {
    this._subscriptions.push(this._synchronizationService.currentSynchronizationStatus.subscribe({
      next: (data)=> {
        if(!data.isSynchronizing) {
          setTimeout(()=> {
            this.isSynchronizing = false
          },1500)
        } else this.isSynchronizing = true
      }
    }))

    this._subscriptions.push(this._authService.isLoggedIn$.subscribe({
      next: (data)=> {
        this.isAuthenticated = data

        if(this.isAuthenticated) {
          this._settingsService.getUserSettings().pipe(take(1)).subscribe({
            next: (userSettings:UserSettings) => {
              if(Utils.isDefined(userSettings, "user.role")) this.isAdmin = userSettings.user.role == 'administrator' ? true : false
            }
          })
        }
      }
    }))
    
    this._subscriptions.push(this._route.events.subscribe({
      next: (data)=> {
        if(data instanceof NavigationEnd) {
          this.menuExpanded = false
        }
      }
    }))

    this._subscriptions.push(fromEvent(window, 'scroll').subscribe({
      next: (data) => {
        var scrollPosition = window.scrollY || document.documentElement.scrollTop;

        if (scrollPosition > 1) {
          this.scrollMoved = true
        } else {
          this.scrollMoved = false
        }
      }
      
    }))
  }

  synchronize()
  {
    this._synchronizationService.synchronize();
  }

  logout()
  {
    this._dialogService.confirmDialog({
      confirmText: "Tak, wyczyść historię.",
      message: `Jeżeli logowałeś się na innym komputerze niż swój własny (np. u znajomego, w kafejce), ze względów bezpieczeństwa powinieneś wyczyścić historię. Jeżeli logowałeś się na prywatnym urządzeniu nie ma takiej potrzeby. Czy chcesz wyczyścić historię?`,
      title: 'Bezpieczne wylogowanie',
      showIcon: true,
      cancelText: "Nie",
      type: "info"
    }).subscribe({
      next: (data) => {
        if(data != null && data == true)
        {
          // TODO
          // Remove in memory data and database
          this._authService.logout({muted:false});
        }
        else if(data == false)
        {
          this._authService.logout({muted:false});
          // TODO
          // Remove just in memory data
        }
      }
    })

  }


  
}
