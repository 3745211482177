import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[numericOnly]'
})
export class NumericOnlyDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = this.el.nativeElement;

    // Get input value and replace non-numeric characters with an empty string
    const newValue = inputElement.value.replace(/[^0-9]/g, '');

    // Update the input value with the cleaned numeric value
    this.renderer.setProperty(inputElement, 'value', newValue);
  }
}