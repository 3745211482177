import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Utils } from 'fakturnia-shared';

@Directive({
  selector: '[decimalFormatter]'
})
export class DecimalFormatterDirective {


  private previousValue: string | undefined;

  constructor(private el: ElementRef<HTMLInputElement>, private renderer: Renderer2) {}

  @Input() set ngModel(value: any) {

    if(Utils.isNullOrEmpty(value))  { this.renderer.setProperty(this.el.nativeElement, 'value', ''); return;}
    if(isNaN(value))  { this.renderer.setProperty(this.el.nativeElement, 'value', ''); return;}
    
    const newValue = parseFloat(value).toFixed(2);
    if (newValue !== this.previousValue) {
      this.previousValue = newValue;
      this.renderer.setProperty(this.el.nativeElement, 'value', newValue);
    }
  }

  @HostListener('input', ['$event.target.value']) onInput(value: string) {

    value = value.replace(',', '.');

    // Sprawdź, czy istnieje więcej niż jedna kropka
    if (value.split('.').length > 2) {
      value = value.substr(0, value.lastIndexOf('.'));
    }

    // Znajdź indeks kropki
    const dotIndex = value.indexOf('.');

    // Jeżeli jest kropka, ogranicz ilość cyfr po kropce do dwóch
    if (dotIndex !== -1 && value.length - dotIndex > 3) {
      value = value.substr(0, dotIndex + 3);
    }

    // Ustaw nową sformatowaną wartość w polu tekstowym
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
    
    this.previousValue = value;
  }

  @HostListener('change', ['$event.target.value'])
  onChange(value: string) {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      const formattedValue = parsedValue.toFixed(2);
      this.el.nativeElement.value = formattedValue;
    }
  }



  doCheck(value) {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      const formattedValue = parsedValue.toFixed(2);
      this.el.nativeElement.value = formattedValue;
    }
  }

 
}