<div class="flex items-center justify-between">

  <div class="text-2xl">
    <span *ngIf="clientType != 'seller'">
      {{mode == 'create' ? 'Dodawanie nowego klienta' : 'Edycja danych klienta'}}
    </span>
    <span *ngIf="clientType == 'seller'">Edycja danych sprzedawcy</span>
  </div>
  
  <button mat-icon-button [matDialogClose]="undefined">
    <mat-icon class="text-secondary"  [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
  </button>

</div>

<div class="grid grid-cols-6 gap-3 scrollable pr-3">

  <div class="col-span-6">
    <h6>Podstawowe informacje</h6>
  </div>

  <div class="col-span-6">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>NIP</mat-label>
      <input matInput [formControl]="form.controls.nip">
      <mat-error *ngIf="form.controls['nip'].hasError('required')">Pole jest wymagane</mat-error>
    </mat-form-field>
  </div>

  <div class="col-span-6">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-select [formControl]="form.controls.type">
        <mat-option value="company">Firma (działalność gospodarcza)</mat-option>
        <mat-option value="person">Osoba fizyczna</mat-option>
      </mat-select>
      <mat-label>Typ klienta</mat-label>
    </mat-form-field>
  </div>

  <div class="col-span-6">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Nazwa firmy</mat-label>
      <input matInput [formControl]="form.controls.name">
      <mat-error *ngIf="form.controls['name'].hasError('required')">Pole jest wymagane</mat-error>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Imię</mat-label>
      <input matInput [formControl]="form.controls.firstName">
      <mat-error *ngIf="form.controls['firstName'].hasError('required')">Pole jest wymagane</mat-error>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Nazwisko</mat-label>
      <input matInput [formControl]="form.controls.lastName">
      <mat-error *ngIf="form.controls['lastName'].hasError('required')">Pole jest wymagane</mat-error>
    </mat-form-field>
  </div>

  <div class="col-span-6 mt-5">
    <h6>Adres</h6>
  </div>

  <div class="col-span-6">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Ulica i numer</mat-label>
      <input matInput [formControl]="form.controls.street">
      <mat-error *ngIf="form.controls['street'].hasError('required')">Pole jest wymagane</mat-error>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Kod pocztowy</mat-label>
      <input matInput [formControl]="form.controls.postalCode">
      <mat-error *ngIf="form.controls['postalCode'].hasError('required')">Pole jest wymagane</mat-error>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Miejscowość</mat-label>
      <input matInput [formControl]="form.controls.city">
      <mat-error *ngIf="form.controls['city'].hasError('required')">Pole jest wymagane</mat-error>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        Kraj
        <small *ngIf="!fieldsToShow.has('kraj')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.country">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('kraj') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('kraj')">
        <mat-icon [svgIcon]="fieldsToShow.has('kraj') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 mt-5">
    <h6>Dodatkowe informacje</h6>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        PESEL
        <small *ngIf="!fieldsToShow.has('PESEL')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.pesel" placeholder="Wprowadź PESEL">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('PESEL') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('PESEL')">
        <mat-icon [svgIcon]="fieldsToShow.has('PESEL') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        Numer dowodu
        <small *ngIf="!fieldsToShow.has('numer dowodu')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.idNumber" placeholder="Wprowadź numer dowodu">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('numer dowodu') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('numer dowodu')">
        <mat-icon [svgIcon]="fieldsToShow.has('numer dowodu') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        REGON
        <small *ngIf="!fieldsToShow.has('REGON')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.regon" placeholder="Wprowadź REGON">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('REGON') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('REGON')">
        <mat-icon [svgIcon]="fieldsToShow.has('REGON') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
   <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        BDO
        <small *ngIf="!fieldsToShow.has('BDO')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.bdo" placeholder="Wprowadź BDO">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('BDO') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('BDO')">
        <mat-icon [svgIcon]="fieldsToShow.has('BDO') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        KRS
        <small *ngIf="!fieldsToShow.has('KRS')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.krs" placeholder="Wprowadź KRS">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('KRS') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('KRS')">
        <mat-icon [svgIcon]="fieldsToShow.has('KRS') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        Pole opisowe
        <small *ngIf="!fieldsToShow.has('pole opisowe')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.description" placeholder="Wprowadź opis">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('pole opisowe') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('pole opisowe')">
        <mat-icon [svgIcon]="fieldsToShow.has('pole opisowe') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 mt-5">
    <h6>Dane kontaktowe</h6>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        Numer telefonu
        <small *ngIf="!fieldsToShow.has('telefon')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.phoneNumber" placeholder="Wprowadź numer telefonu">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('telefon') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('telefon')">
        <mat-icon [svgIcon]="fieldsToShow.has('telefon') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        Fax
        <small *ngIf="!fieldsToShow.has('fax')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.fax" placeholder="Wprowadź numer fax">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('fax') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('fax')">
        <mat-icon [svgIcon]="fieldsToShow.has('fax') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
      <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        Adres e-mail
        <small *ngIf="!fieldsToShow.has('email')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.email" placeholder="Wprowadź adres e-mail">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('email') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('email')">
        <mat-icon [svgIcon]="fieldsToShow.has('email') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="col-span-6 md:col-span-3">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label class="flex justify-between">
        Strona WWW
        <small *ngIf="!fieldsToShow.has('www')" class="text-secondary" [matTooltip]="fieldHiddenHint">{{fieldHiddenText}}</small>
      </mat-label>
      <input matInput [formControl]="form.controls.www" placeholder="Wprowadź adres www">
      <button mat-icon-button matPrefix [matTooltip]="fieldsToShow.has('www') ? hideFieldText : showFieldText" (click)="toggleFieldVisibility('www')">
        <mat-icon [svgIcon]="fieldsToShow.has('www') ? iconFieldOn : iconFieldOff" ></mat-icon>
      </button>
    </mat-form-field>
  </div>

</div>  

<mat-dialog-actions align="end">
  <button [disabled]="form.invalid" mat-flat-button color="primary" (click)="saveChanges()">{{ mode == 'create' ? 'Dodaj klienta' : 'Zapisz zmiany'}}</button>
  <button mat-flat-button (click)="close()">Zamknij</button>
</mat-dialog-actions>