import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { AuthService } from 'shared/services/auth.service';
import { DialogService } from 'shared/services/dialog.service';
import { ToastService } from 'shared/services/toast.service';
import { emailValidator } from 'shared/validators/email.validator';
import { matchFieldsValidator } from 'shared/validators/match-fields.validator';

@Component({
  selector: 'change-email-address',
  templateUrl: './change-email-address.component.html',
  styleUrls: ['./change-email-address.component.scss']
})
export class ChangeEmailAddressComponent {

  form = new FormGroup({
    emailAddress: new FormControl('',[Validators.required, emailValidator()]),
    emailAddressRepeat: new FormControl('',[Validators.required, Validators.minLength(8), emailValidator()]),
    password: new FormControl('',[Validators.required, Validators.minLength(8)]),
  }, { validators: matchFieldsValidator('emailAddress', 'emailAddressRepeat')  })

  constructor(
    private _authService:AuthService, 
    private _dialogService:DialogService,
    private _toastService:ToastService) {

  }
  
  changeEmailAddress()
  {
    if(!this.form.valid) return;
    this._dialogService.confirmDialog({
      title: "Potwierdzenie zmiany adres e-mail",
      confirmText: "Zmień adres e-mail",
      message: `Czy na pewno chcesz zmienić adres e-mail do konta na ${this.form.controls.emailAddress.value}?`,
      type: 'warning'
    }).subscribe({
      next: (data) => {
      if(data == true) {
        this._authService.changeEmailAddress(this.form.value).subscribe({
          next: (data:APIResponse)=> {
    
            if(data.success == false) {
              this._toastService.warning(data.message)
              return
            }
    
            else if(data.success == true) {
              this._toastService.showToast(new NotificationData({
                title: 'Informacja',
                status: 'info',
                message: 'Adres e-mail konta został zmieniony.',
              }))
    
              this.form.reset()
            }
          }
        })
      }
    }})
    
  }
}

