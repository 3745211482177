import { Component, OnDestroy } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { debounce } from 'lodash'
import { PaginationComponent } from 'shared/components/pagination/pagination.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-users',
  imports: [CommonModule, PaginationComponent, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule],
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnDestroy {

  private _subscriptions:Subscription[] = []
  searchInputControl = new FormControl()

  pagination = {
    currentPage: 1,
    itemsPerPage: 20,
    count: 0,
    pages: 0
  }

  users:any = []

  constructor(private _apiService:ApiService) {

    this.browseUsers = debounce(this.browseUsers, 100)
    this._subscriptions.push(this.searchInputControl.valueChanges.subscribe({
      next: (value) => {
        this.browseUsers()
      }
    }))

    this.browseUsers()
  }
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  browseUsers() {
    this._apiService.browseUsers({
      searchQuery: this.searchInputControl.value,
      currentPage: this.pagination.currentPage,
      itemsPerPage: this.pagination.itemsPerPage
    }).pipe(take(1))
    .subscribe({
      next: (response:APIResponse) => {
        if(response.success == true) {
          this.users = response.data.users
          this.pagination.pages = response.data.pages
          this.pagination.itemsPerPage = response.data.itemsPerPage
          this.pagination.count = response.data.count
        }
      }
    })
  }

  onPageChange(page) {
    this.pagination.currentPage = page
    this.browseUsers()
  }
}
