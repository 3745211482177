import { Injectable, OnDestroy } from "@angular/core";
import { AuthService } from "./auth.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { FakturniaNavigationItem } from "@fakturnia/components/navigation/navigation.types";

@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {

    private _subscriptions: Subscription[] = []
    private _isLoggedIn: boolean = false

    private _navigation = new BehaviorSubject<FakturniaNavigationItem[]>([]);
    currentNavigation = this._navigation.asObservable();

    constructor(private _authService: AuthService) {

        this._subscriptions.push(
            this._authService.isLoggedIn$.subscribe({
                next: (isLoggedIn: boolean) => {
                    this._isLoggedIn = isLoggedIn

                    this._buildNavigation()
                }
            })
        )

    }

    private _buildNavigation() {

        const navigation: FakturniaNavigationItem[] = []
        if (this._isLoggedIn) {

            // Pulpit
            navigation.push({
                id: 'pulpit',
                title: 'Pulpit',
                type: 'basic',
                icon: 'tabler_outline:home',
                link: '/konto/pulpit'
            })

            // Dokumenty
            navigation.push({
                id: 'dokumenty',
                title: 'Dokumenty',
                type: 'basic',
                icon: 'tabler_outline:folder',
                link: '/konto/dokumenty'
            })

            // Klienci
            navigation.push({
                id: 'klienci',
                title: 'Klienci',
                type: 'basic',
                icon: 'tabler_outline:users',
                link: '/konto/klienci'
            })

            // Produkty
            navigation.push({
                id: 'produkty',
                title: 'Produkty',
                type: 'basic',
                icon: 'tabler_outline:package',
                link: '/konto/produkty'
            })

            // Więcej
            navigation.push({
                id: 'inne',
                title: 'Inne',
                type: 'group',
                icon: 'tabler_outline:dots-vertical',
                children: [
                    {
                        id: 'raporty',
                        title: 'Raporty',
                        type: 'basic',
                        badge: { title: 'Wkrótce dostępne', classes: 'text-primary mx-2'},
                        disabled: true,
                        icon: 'tabler_outline:list-check',
                        link: '/konto/raporty'
                    }
                ]
            })
           
            this._navigation.next(navigation)
            return
        }

        // O aplikacji
        navigation.push({
            id: 'o-aplikacji',
            title: 'O aplikacji',
            type: 'basic',
            icon: 'heroicons_outline:question-mark-circle',
            link: '/o-aplikacji'
        })

        // Funkcjonalności
        navigation.push({
            id: 'funkcjonalnosci',
            title: 'Funkcjonalności',
            type : 'collapsable',
            icon: 'tabler_outline:wand',
            children: [
                {
                    id: 'funkcje',
                    title: 'Funkcjonalności aplikacji',
                    type: 'basic',
                    link: '/funkcjonalnosci-aplikacji',
                },
                {
                    id: 'podglad-faktury',
                    title: 'Podgląd faktury',
                    type: 'basic',
                    link: '/funkcjonalnosci-aplikacji/podglad-faktury'
                },
                {
                    id: 'faktura-vat',
                    title: 'Faktura VAT',
                    type: 'basic',
                    link: '/funkcjonalnosci-aplikacji/faktura-vat'
                },
                {
                    id: 'faktura-koncowa',
                    title: 'Faktura końcowa',
                    type: 'basic',
                    link: '/funkcjonalnosci-aplikacji/faktura-koncowa'
                },
                {
                    id: 'faktura-korygujaca',
                    title: 'Faktura korygująca',
                    type: 'basic',
                    link: '/funkcjonalnosci-aplikacji/faktura-korygujaca'
                },
                {
                    id: 'faktura-vat-marza',
                    title: 'Faktura VAT marża',
                    type: 'basic',
                    link: '/funkcjonalnosci-aplikacji/faktura-vat-marza'
                }
            ]
        })

        // Blog
        navigation.push({
            id: 'blog',
            title: 'Blog',
            type: 'basic',
            icon: 'tabler_outline:book',
            link: 'blog'
        })


        // Kontakt
        navigation.push({
            id: 'kontakt',
            title: 'Kontakt',
            type: 'basic',
            icon: 'tabler_outline:message-dots',
            link: 'kontakt'
        })


        this._navigation.next(navigation)
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }
}