import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DbClient, Utils } from 'fakturnia-shared';
import { Subscription, fromEvent } from 'rxjs';
import { SelectClientDialogComponent } from 'shared/dialogs/select-client-dialog/select-client-dialog.component';
import { ClientsService } from 'shared/services/clients.service';
import { DialogService } from 'shared/services/dialog.service';

@Component({
  selector: 'select-client-control',
  templateUrl: './select-client-control.component.html',
  styleUrls: ['./select-client-control.component.scss']
})
export class SelectClientControlComponent implements OnChanges, OnDestroy {

  private _subscriptions: Subscription[] = [];
  nameControl = new UntypedFormControl('')
  @Input() value = ''
  @Input() label = ''
  @Output('onClientSelect') onClientSelect = new EventEmitter()
  @Output('onEditClick') onEditClick = new EventEmitter()
  isOpen = false

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes['value'] != 'undefined') {
      this.nameControl.setValue(this.value)
    }
  }

  constructor(
    private _clientsService: ClientsService,
    private dialogService: DialogService,
    private _dialog:MatDialog
  ) {
    this._subscriptions.push(this.nameControl.valueChanges.subscribe({
      next: (data) => {
        this.filterClients(data)
      }
    }))

  }

  edit() {
    if(this.nameControl.value?.length == 0) return
    this.onEditClick.emit(true)
  }

  showSelectClientDialog() {
    this._dialog.open(SelectClientDialogComponent,{
      panelClass: 'full-size-mobile',
    })
    .afterClosed()
    .subscribe({
      next: (result) => {
        if(Utils.isDefined(result, "client")) this.selectClient(result.client)
      }
    })
  }

  showAddClientDialog() {
    this._subscriptions.push(
      this.dialogService.showAddClientDialog()
        .subscribe({
          next: (response: any) => {

            if (!response) return
            if (typeof response.success == 'undefined') return
            if (!response.success) return

            
            this.selectClient(response.data)
          }
    }))
  }

  selectClient(client: DbClient) {

    if (client.type == 'company') {
      this.nameControl.setValue(client.name)
    }
    else if (client.type == 'person') {
      this.nameControl.setValue(`${client.firstName} ${client.lastName}`)
    }

    this.onClientSelect.emit(client)
    this.isOpen = false

  }
  
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  // Autocomplete
  options: any = [];
  filterClients(value) {
    this.options = this._clientsService.filterClients(value)
    if (this.options.length > 0) this.isOpen = true
  }
}
