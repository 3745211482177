<section class="container">
    <div class="w-full mx-2 lg:w-140 lg:mx-auto">
        <div class="text-center bg-white dark:bg-slate-800 my-12 py-8 rounded-xl">
            <h2>
                Czytaj <b>dalej</b>
            </h2>
            <a [routerLink]="path">
                <div class="f-button my-2 mx-auto">
                    {{text}}
                    <i class="ti ti-arrow-narrow-right ms-2"></i>
                </div>
            </a>
        </div>
    </div>
</section>