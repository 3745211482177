import { Component } from '@angular/core';

@Component({
  selector: 'app-faktura-korygujaca',
  templateUrl: './faktura-korygujaca.component.html',
  styleUrls: ['./faktura-korygujaca.component.scss']
})
export class FakturaKorygujacaComponent {

}
