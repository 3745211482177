import { Injectable, OnDestroy } from "@angular/core";
import { AppDb } from "fakturnia-shared";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class DbService implements OnDestroy {

    private _subscriptions: Subscription[] = [];

    private _db:any = new BehaviorSubject<AppDb | null>(null);
    currentDb = this._db.asObservable();

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }
  
    getDatabase(): Observable<AppDb> {
        return this._db;
    }

    setDatabase(dbName:string) {
        this._db.next(new AppDb(dbName));
    }
    
    clearData() {

    }
}