<form  style="max-width:500px;">
    <div class="row">
        <div class="col-12">
            <h4>Podpisy</h4>
            <p>Automatycznie dodawaj pola na podpisy osób upoważnionych</p>
            <mat-checkbox color="primary" [formControl]="signatures">
                {{signatures.value ? "Włączone" : "Wyłączone"}}
            </mat-checkbox>
        </div>
    </div>
    </form>