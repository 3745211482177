<form style="max-width:500px;">
    <div class="row">
      <div class="col-12">
        <h4>Konta bankowe</h4>
        <p>Twoje konta bankowe</p>
      </div>
      <div class="col-12 mt-2">
        <div class="mt-2" *ngFor="let bankAccount of bankAccounts">
          <b>{{bankAccount.bank}}</b>  
          <small *ngIf="bankAccount.isDefault == true">(domyślne)</small>
          <a (click)="showAddOrUpdateBankAccount(bankAccount)" class="ms-2 cursor-pointer">
            <small>edytuj <i class="ti ti-edit"></i></small>
          </a>
          <a *ngIf="bankAccount.isDefault == false" (click)="setAsDefaultBankAccount(bankAccount)" class="ms-2 cursor-pointer">
            <small>wybierz jako domyślne <i class="ti ti-star"></i></small>
          </a>
          <!-- <a href="#" class="ms-2 clor-red">usuń <i class="ti ti-trash"></i></a> -->
          <br/>
          {{bankAccount.accountNumber}}
        </div>
        <button (click)="showAddOrUpdateBankAccount()" class="mt-5 f-button md">Dodaj konto</button>
      </div>
    </div>
</form>