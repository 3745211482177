
<div class="relative flex flex-col w-full min-h-full max-h-full">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-gray-50">
        <div class="text-2xl font-medium">
            Szukaj dokument
        </div>
        <button mat-icon-button [matDialogClose]="undefined">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-auto overflow-hidden h-[90vh] max-h-[90vh]">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-y-scroll sm:overflow-y-auto p-0">
            <invoice-table title="Faktury" [addBoxShadow]="false" [filters]="filters" [showTitle]="false" [showTools]="showTools" [showSelectors]="true" [allowNavigation]="false" [selection]="selection" (selectionChange)="onSelectInvoice($event)"></invoice-table>
        </div>
    </div>

    <!-- Footer -->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <button [disabled]="selectedInvoicesIds.length == 0" (click)="selectInvoices()" mat-flat-button color="primary">Dodaj</button>
                <button mat-button (click)="close()" class="ml-2">Zamknij</button>
            </div>
        </div>
    </div>
</div>
