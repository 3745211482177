import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  // @ViewChild('colorPicker') colorPicker: NgxMatColorPickerComponent;
  // colorPickerControls: "default" | "only-alpha" | "no-alpha" = "no-alpha";

  public form: FormGroup = this.formBuilder.group({});
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private readonly formBuilder: FormBuilder,
    private dialog: MatDialogRef<ConfirmDialogComponent>
    ) {
    if (typeof data.showIcon == 'undefined') { data.showIcon = true; }

    if (typeof data.inputs != 'undefined' && data.inputs.length > 0) {
      for (let control of data.inputs) {
        let validators: ValidatorFn[] = [];
        if (control.validators) {
          console.log(control.validators)
          validators = control.validators;
        }
        this.form.addControl(control.name, new FormControl(control.value, validators));
        // if(control.type == 'color') {
        //   this.form.addControl(control.name+'Pckr', new FormControl(control.value, validators));
        //   // this.form.controls[control.name].valueChanges.subscribe(data=>this.form.controls[control.name+'Pckr'].setValue(data))
        // }
      }
    }

  }

  // updateColor(ev) {
  //   this.form.controls.color.setValue(ev)
  //   this.form.controls.colorPckr.setValue(ev)
  // }

  close(action) {
    this.dialog.close({
      action: action,
      form: this.form
    })

  }
}

export interface ConfirmDialogData {
  title: string;
  message: string;
  type: "danger" | "warning" | "info" | "edit" | "success";
  inputs?: any[];
  confirmText: string;
  cancelText?: string;
  showIcon?: boolean;
}