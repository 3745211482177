<div class="w-full md:w-100 min-h-120 flex flex-col max-h-[90vh]"> <!-- Set max height for the dialog -->

    <!-- Header -->
    <div class="flex items-center justify-between px-4">
      <div class="text-2xl">
        Wyszukaj klienta
      </div>
      <button mat-icon-button [matDialogClose]="undefined">
        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
      </button>
    </div>
  
    <!-- Form Field -->
    <mat-form-field class="fuse-mat-dense w-full my-2 px-4" subscriptSizing="dynamic">
      <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"
        (click)="$event.stopPropagation(); searchText?.focus()"></mat-icon>
      <input [autocomplete]="'off'" matInput #searchText [formControl]="searchControl" placeholder="Wprowadź NIP lub nazwę firmy" type="text" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
        (keyup)="1;" />
      <mat-icon *ngIf="searchControl.value?.length > 0" matSuffix [svgIcon]="'heroicons_solid:x-mark'"
        (click)="searchControl.reset()"></mat-icon>
      <mat-hint *ngIf="searchControl.value?.length > 0">
        Wyniki wyszukiwania dla frazy: {{searchControl.value}}
      </mat-hint>
    </mat-form-field>
  
    <!-- Scrollable Content -->
    <div class="flex-1 overflow-y-auto px-4 pt-2 pb-4">
      <div class="text-center tracking-wide my-2 text-md" *ngIf="options.length == 0 && searchControl.value?.length > 0">
        Nie znaleziono klienta dla szukanej frazy w Twojej bazie. Dodaj nowego klienta szukając go w Bazie GUS lub VIES.<br/>
        <button mat-flat-button color="primary" class="mt-4" (click)="showAddClientDialog()">
          <mat-icon class="mr-2" [svgIcon]="'tabler_outline:plus'"></mat-icon>
          Dodaj nowego klienta
        </button>
      </div>
      <div *ngFor="let client of options" class="border-t py-2 cursor-pointer" (click)="selectClient(client)">
        <div *ngIf="client?.type == 'company'">
          {{client?.name}}
        </div>
        <div *ngIf="client?.type == 'person'">
          {{client?.firstName}} {{client?.lastName}}
        </div>
        <div class="flex justify-between">
          <div class="text-sm text-gray-600 dark:text-gray-200">
            {{client.street}}, {{client.postalCode}} {{client.city}}
          </div>
          <div class="text-sm text-gray-600 dark:text-gray-200">
            NIP: {{client.nip}}
          </div>
        </div>
      </div>
    </div>
  
    <!-- Footer -->
    <div *ngIf="options.length > 0 || searchControl.value?.length == 0" class="bg-card w-full flex justify-center py-4">
      <button mat-flat-button color="primary" (click)="showAddClientDialog()">
        <mat-icon class="mr-2" [svgIcon]="'tabler_outline:plus'"></mat-icon>
        Dodaj nowego klienta
      </button>
    </div>
  </div>
  