import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API_HOST } from "environments/environment";
import { Invoice } from "fakturnia-shared";
import { IProduct } from 'fakturnia-shared';
@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private http: HttpClient
    )
    {
        
    }
    // Adm
    convertData() {
        return this.http.get(API_HOST + `admin/test`)
    }
    browseUsers(params) {
        return this.http.post(API_HOST + 'admin/users', params)
    }
    browseUserDocuments(userId, params) {
        return this.http.post(API_HOST + `admin/users/${userId}/documents`, params)
    }
    getUser(userId) {
        return this.http.get(API_HOST + `admin/users/${userId}`)
    }
    // Get backup
    getBackup() {
        return this.http.post(API_HOST + `admin/backup`,{})
    }
    // Announcements
    createAnnouncement(data) {
        return this.http.post(API_HOST + `admin/announcements`, data)
    }
    getAnnouncementsAsAdmin() {
        return this.http.get(API_HOST + `admin/announcements`)
    }
    getAnnouncementAsAdmin(id:string) {
        return this.http.get(API_HOST + `admin/announcements/${id}`)
    }
    updateAnnouncement(announcementId, data) {
        return this.http.put(API_HOST + `admin/announcements/${announcementId}`, data)
    }
    deleteAnnouncement(announcementId) {
        return this.http.delete(API_HOST + `admin/announcements/${announcementId}`)
    }
    getAnnouncements() {
        return this.http.get(API_HOST + `announcements`)
    }
    getAnnouncement(id:string) {
        return this.http.get(API_HOST + `announcements/${id}`)
    }
    // OneTimeToken
    
    createOneTimeToken(remoteUserId) {
        return this.http.post(API_HOST + `admin/one-time-tokens`, {
            remoteUserId: remoteUserId
        })
    }

    getOneTimeToken(tokenId) {
        return this.http.get(API_HOST + `admin/one-time-tokens/${tokenId}`)
    }

    // Statistics
    getNewDocumentsAndUsersChart(startDate, endDate) {
        return this.http.post(API_HOST + 'admin/statistics/new-documents-and-users-chart', {
            startDate: startDate,
            endDate: endDate
        })
    }


    // Account
    
    updatePersonalInformations(data:any) {
        return this.http.post(API_HOST + 'account/update-personal-informations', data)
    }
    updateCompanyData(data:any) {
        return this.http.post(API_HOST + 'account/update-company-data', data)
    }
    completeRegister(data:any) {
        return this.http.post(API_HOST + 'account/complete-register', data)
    }
    // Documents
    searchInGUS(nip:string) {
        return this.http.post(API_HOST + 'documents/search-in-gus', { nip })
    }
    searchInMF(nip:string) {
        return this.http.post(API_HOST + 'documents/search-in-mf', { nip })
    }
    
    deleteDocuments(ids) {
        return this.http.post(API_HOST + 'documents/delete', {
            _ids: ids
        })
    }


   

    // Products
    saveProduct(product) {
        return this.http.post(API_HOST + 'products/save', product)
    }
    deleteProduct(productId) {
        return this.http.post(API_HOST + 'products/delete', {
            _id: productId
        })
    }
    deleteProducts(ids) {
        return this.http.post(API_HOST + 'products/delete-many', {
            _ids: ids
        })
    }

    // Blog
    getPost(url) {
        return this.http.post(API_HOST + 'blog/get-post', { url: url })
    }
    getPostAsAdmin(url) {
        return this.http.post(API_HOST + 'blog/get-post-as-admin', { url: url })
    }
    browsePosts(page) {
        return this.http.post(API_HOST + 'blog/browse-posts', { page: page })
    }
    browsePostsAsAdmin(page) {
        return this.http.post(API_HOST + 'blog/browse-posts-as-admin', { page: page })
    }
    savePost(post) {
        return this.http.post(API_HOST + 'blog/save', post)
    }
    deletePost(postId) {
        return this.http.post(API_HOST + 'blog/delete', {
            _id: postId
        })
    }

    // Settings
    saveSettings(settings) {
        return this.http.post(API_HOST + 'account/settings/save', { settings: JSON.stringify(settings)} )
    }
    getUserSettings() {
        return this.http.post(API_HOST + 'account/settings', { })
    }
    toggleAutomaticNumbering(checked) {
        return this.http.post(API_HOST + 'account/settings/autocomplete/document-numbering', { checked: checked })
    }
    toggleSignatures(checked) {
        return this.http.post(API_HOST + 'account/settings/autocomplete/signatures', { checked: checked })
    }
    updateIssuePlace(issuePlace) {
        return this.http.post(API_HOST + 'account/settings/autocomplete/issue-place', { issuePlace: issuePlace })
    }
    updateAutomaticSellerFormField(data) {
        return this.http.post(API_HOST + 'account/settings/autocomplete/seller-form-fields', data)
    }
    updateVatRates(data) {
        return this.http.post(API_HOST + 'account/settings/vat-rates/update-vat-rates', { vatRates : data})
    }
}