<div class="max-w-400 mx-auto px-4 overflow-y-hidden">

  <!-- Header and buttons -->
  <div class="grid grid-cols-1 md:grid-cols-2 w-full mt-6">

    <div>
      <span class="pl-4">{{invoice._id != null ? 'Edycja dokumentu' : 'Nowa faktura'}}</span><br/>

      <input matTooltip="Numer dokumentu" class="mb-0 blank h3 bg-transparent invoice-number-input"
        type="text" placeholder="Faktura *** **/**/****" [value]="invoice.invoiceName"
        (change)="invoice.set('invoiceName', $event.target.value)" />
      <div *ngIf="invoice?.errors?.invoiceName?.required" class="ps-3 error-text">Pole wymagane</div>
    </div>

    <div class="ml-auto space-x-2">

      <button class="mt-2" color="primary" mat-flat-button *ngIf="!invoice?._id" (click)="createDocument()">
        <mat-icon [svgIcon]="'tabler_outline:plus'"></mat-icon>
        <span class="ml-2">Utwórz dokument</span> 
      </button>

      <button class="mt-2" color="primary" mat-flat-button *ngIf="invoice?._id" (click)="updateDocument()">
        <mat-icon [svgIcon]="'tabler_outline:check'"></mat-icon>
        <span class="ml-2">Zapisz dokument</span> 
      </button>

      <button [matMenuTriggerFor]="menu" class="mt-2" mat-stroked-button >
        <mat-icon [svgIcon]="'tabler_outline:tool'"></mat-icon>
        <span class="ml-2">Dostosuj</span> 
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="showUpdateSellerDialog()">
          <mat-icon [svgIcon]="'tabler_outline:pencil'"></mat-icon>
          Edytuj dane sprzedawcy
        </button>
        <button mat-menu-item (click)="showToggleFormFieldsDialog()">
          <mat-icon [svgIcon]="'tabler_outline:settings'"></mat-icon>
          Dostosuj dokument
        </button>
      </mat-menu>

      <button class="mt-2" color="primary" mat-stroked-button *ngIf="invoice?._id"  [routerLink]="'/konto/dokumenty/'+invoice?._id+'/podglad'" [matTooltip]="'Powrót do widoku opcji'">
        <mat-icon [svgIcon]="'tabler_outline:arrow-right'"></mat-icon>
        <span class="ml-2"> Wróć do opcji</span> 
      </button>

      <button class="lg:hidden mt-2" color="primary" mat-stroked-button   (click)="togglePreview()" [matTooltip]="'Podgląd'">
        <mat-icon [svgIcon]="'tabler_outline:external-link'"></mat-icon>
        <span class="ml-2"> Podgląd</span> 
      </button>

      <!-- <div class="f-button green md ms-2 mt-2" *ngIf="!invoice?._id" (click)="createDocument()"
        [matTooltip]="'Dokument zostanie utworzony i zapisany'">
        <i class="ti ti-plus me-2"></i>
        Utwórz dokument
      </div>

      <div class="f-button md ms-2 mt-2" *ngIf="invoice?._id" (click)="updateDocument()"
        [matTooltip]="'Dokument zostanie zapisany'">
        <i class="ti ti-check me-2"></i>
        Zapisz dokument
      </div> -->

      <!-- <div class="f-button inversed md ms-2 mt-2" *ngIf="invoice?._id"
        [routerLink]="'/konto/dokumenty/'+invoice?._id+'/podglad'" [matTooltip]="'Powrót do widoku opcji'">
        <i class="ti ti-arrow-right me-2"></i>
        Wróć do opcji
      </div> -->

     
    </div>
  </div>

  <!-- Form and preview -->
  <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-4 mt-2">

    <!-- Form -->
    <div class="bg-card col-span-12 lg:col-span-8 w-full p-4 rounded-xl max-w-256">

      <div class="grid grid-cols-1 md:grid-cols-2">

       

        <!-- Left form side -->
        <div>

          <!-- Buyer and document select -->
          <div class="controls-container grid grid-cols-1 gap-y-2">


            <select-client-control label="Nabywca" (onEditClick)="showCreateOrUpdateClientDialog(invoice.buyer, 'buyer')"
              [value]="invoice.buyer.name" (onClientSelect)="updateBuyer($event)">
            </select-client-control>

            <div *ngIf="(invoice.buyer._id == null || invoice.buyer._id == '')" class="mt-1 ms-2 error-text">
              Wybierz nabywcę
            </div>
             
              <ng-container *ngIf="invoice.hasReceiver">
                <select-client-control label="Odbiorca" (onEditClick)="showCreateOrUpdateClientDialog( invoice.receiver, 'receiver')"
                [value]="invoice.receiver.name" (onClientSelect)="updateReceiver($event)">
              </select-client-control>
  
              <div *ngIf="(invoice.receiver._id == null || invoice.receiver._id == '')" class="mt-1 ms-2 error-text">
                Wybierz odbiorcę
              </div>
              </ng-container>
           
              <mat-form-field class="w-full" subscriptSizing="dynamic" [matTooltip]="mode == 'edit' ? 'Nie można zmienić typu edytowanego dokumentu.' : ''">
                <mat-label>Typ dokumentu</mat-label>
                <mat-select [disabled]="mode == 'edit' ? true : false" [value]="invoice.documentType" (valueChange)="switchDocument($event)">
                  <mat-option [value]="documentType.id" *ngFor="let documentType of availableDocs">{{documentType.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              
              <mat-form-field *ngIf="document.config.hasMarginProcedure" class="w-full" subscriptSizing="dynamic">
                <mat-label>Oznaczenie marży</mat-label>
                <mat-select [value]="invoice.marginProcedureId" (valueChange)="setMarginProcedure($event)">
                  <mat-option [value]="procedure.id" *ngFor="let procedure of marginProcedures">{{procedure.text}}</mat-option>
                </mat-select>
              </mat-form-field>

            <!-- <select-control class="d-block mt-2" (onSelect)="switchDocument($event)" fieldId="invoiceType"
              [disabled]="mode == 'edit' ? true : false" label="Typ dokumentu" [value]="invoice.documentType"
              [options]="availableDocs" displayProperty="name" valueProperty="id"></select-control> -->

            <!-- <select-control *ngIf="document.config.hasMarginProcedure" class="d-block mt-2"
              (onSelect)="setMarginProcedure($event)" fieldId="id" label="Oznaczenie marży"
              [value]="invoice.marginProcedureId" [options]="marginProcedures" displayProperty="text"
              valueProperty="id"></select-control> -->
          </div>

          <div class="mt-2 p-1" *ngIf="document.id == 'FakturaKorygujaca'">
            <h6>Korygowany dokument</h6>
            <a class="cursor-pointer fs-14" (click)="showSelectCorrectedInvoiceDialog()">Wybierz dokument <i
                class="ti ti-chevron-right"></i></a>
            <div class="controls-container" *ngIf="invoice.correctedInvoice != null">
              {{invoice.correctedInvoice.invoiceName}}
              <a class="icon-button px-2 cursor-pointer ms-2 fs-14" matTooltip="Kliknij, aby usunąć korygowany dokument"
                (click)="invoice.removeCorrectedInvoice()">
                Usuń <i class="ti ti-x"></i>
              </a>
            </div>
          </div>
      
          <div class="mt-2 p-1" *ngIf="document.id == 'FakturaKoncowa'">
            <h6>Powiązane faktury zaliczkowe <small class="text-muted"
                *ngIf="invoice.prepayments.length > 0">({{invoice.prepayments.length}})</small> </h6>
            <a class="cursor-pointer fs-14" (click)="showSelectPrepaymentDialog()">Dodaj dokument <i
                class="ti ti-chevron-right"></i></a>
            <div class="controls-container" *ngIf="invoice.prepayments.length > 0">
              <div *ngFor="let prepayment of invoice.prepayments; let i = index">
                {{prepayment.invoiceName}}
                <a class="icon-button px-2 cursor-pointer ms-2 fs-14"
                  matTooltip="Kliknij, aby wyłączyć fakturę z tego dokumentu" (click)="invoice.removePrepayment(i)">
                  Usuń <i class="ti ti-x"></i>
                </a>
              </div>
            </div>
          </div>

          <!-- Payments -->
          <div>
            <!-- Płatność -->
            <div class="controls-container grid grid-cols-3 gap-2 mt-3">

              <div class="col-span-3 md:col-span-1">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Status płatności</mat-label>
                  <mat-select [value]="invoice.paymentStatus.value" (valueChange)="invoice.setPaymentStatus($event)">
                    <mat-option [value]="1">Do zapłaty</mat-option>
                    <mat-option [value]="0">Zapłacono</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-span-3 md:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Sposób płatności</mat-label>
                  <mat-select [value]="invoice.paymentMethod.value" (valueChange)="invoice.setPaymentMethod($event)">
                    <mat-option *ngFor="let payment of paymentMethods" [value]="payment.index">{{payment.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-span-3">
                <a class="cursor-pointer mt-2 fs-14" *ngIf="!invoice.bankAccountNumber.show"
                  (click)="invoice.setShowBankAccountNumber(true)">
                  Dodaj numer konta
                </a>
               
                <mat-form-field class="w-full" *ngIf="invoice.bankAccountNumber.show && bankAccounts.length > 0" subscriptSizing="dynamic">
                  <mat-label class="flex justify-between">Numer konta bankowego <small class="text-gray-600 dark:text-gray-300" matTooltip="Kliknij, aby nie wyświetlać numeru konta bankowego na fakturze." (click)="invoice.setShowBankAccountNumber(false)">ukryj</small></mat-label>
                  <mat-select [value]="invoice.bankAccountNumber.value" (valueChange)="invoice.setBankAccountNumber($event)">
                    <mat-option [value]="bankAccount.accountNumber" *ngFor="let bankAccount of bankAccounts">
                      {{bankAccount.bank}}:
                      {{bankAccount.accountNumber}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
  
                <mat-form-field class="w-full" *ngIf="invoice.bankAccountNumber.show && bankAccounts.length == 0" subscriptSizing="dynamic">
                  <mat-label class="flex justify-between">Numer konta bankowego <small class="text-gray-600 dark:text-gray-300" matTooltip="Kliknij, aby nie wyświetlać numeru konta bankowego na fakturze." (click)="invoice.setShowBankAccountNumber(false)">ukryj</small></mat-label>
                  <input matInput bankAccountFormat [value]="invoice.bankAccountNumber.value" (input)="invoice.setBankAccountNumber($event.target.value);"/>
                </mat-form-field>

              </div>
             
              <!-- Vat account number -->
              <div class="col-span-3" *ngIf="document.config.hasVATAccount">

                  <mat-form-field class="w-full" *ngIf="bankAccounts.length > 0" subscriptSizing="dynamic">
                    <mat-label>Konto VAT</mat-label>
                    <mat-select [value]="invoice.vatAccountNumber" (valueChange)="invoice.setVatAccountNumber($event)">
                      <mat-option [value]="bankAccount.accountNumber" *ngFor="let bankAccount of bankAccounts">
                        {{bankAccount.bank}}:
                        {{bankAccount.accountNumber}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="w-full" *ngIf="bankAccounts.length == 0" subscriptSizing="dynamic">
                    <mat-label>Konto VAT</mat-label>
                    <input matInput [value]="invoice.vatAccountNumber" (input)="invoice.setVatAccountNumber($event.target.value);"/>
                  </mat-form-field>

              </div>

              <!-- Payment description -->
              <div class="col-span-3" *ngIf="invoice.hasPaymentDescription">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Opis płatności</mat-label>
                  <textarea matInput cols="30" rows="2" [value]="invoice.paymentDescription" placeholder="Dodaj opis płatności"
                  (change)="invoice.set('paymentDescription', $event.target.value);"></textarea>
                </mat-form-field>
              </div>
              
              <div class="col-span-3" *ngIf="invoice.hasComments">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Uwagi</mat-label>
                  <textarea matInput cols="30" rows="2" [value]="invoice.comments" placeholder="Dodaj uwagi"
                  (change)="invoice.set('comments', $event.target.value);"></textarea>
                </mat-form-field>
              </div>
             

   
           

           

              <!-- <div class="mt-1" *ngIf="document.config.hasVATAccount">

                <b>Konto VAT: </b>
                <br />

                <div class="flex">
                  <select *ngIf="bankAccounts.length > 0" #select
                    class="full-clear-select border-bottom-on-hover cursor-pointer" [value]="invoice.vatAccountNumber"
                    (change)="invoice.setVatAccountNumber($event.target.value)">
                    <option value="">Wybierz konto VAT</option>
                    <option [selected]="bankAccount.accountNumber == invoice.vatAccountNumber"
                      [value]="bankAccount.accountNumber" *ngFor="let bankAccount of bankAccounts">
                      {{bankAccount.bank}}:
                      {{bankAccount.accountNumber}}</option>
                  </select>
                  <input *ngIf="bankAccounts.length == 0" bankAccountFormat class="blank w-auto bank-account-input"
                    type="text" placeholder="Podaj numer konta" [value]="invoice.vatAccountNumber"
                    (input)="invoice.setVatAccountNumber($event.target.value);">

                </div>
              </div> -->

              <!-- <a class="cursor-pointer mt-2 fs-14" *ngIf="!invoice.bankAccountNumber.show"
                (click)="invoice.setShowBankAccountNumber(true)">
                Dodaj numer konta
              </a> -->

            </div>
          </div>

        </div>

        <!-- Right form side -->
        <div class="md:w-80 md:max-w-80 md:ml-auto">
          <div class="controls-container ">

            <div class="grid grid-cols-1 gap-2">

              <!-- Place of issue -->
              <div *ngIf="generalFieldsToShow.has('issuePlace')">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Miejsce wystawienia</mat-label>
                  <input matInput [value]="invoice.placeHeader.value" (change)="invoice.setPlaceHeaderValue($event.target.value);">
                </mat-form-field>
              </div>
             
              <!-- Date of issue -->
              <div>
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label >Data wystawienia</mat-label>
                  <!-- invoice.setIssueDateHeaderValue($event) -->
                  <input matInput [value]="invoice.issueDateHeader.value" readonly (click)="picker1.open()" (dateChange)="invoice.setIssueDateHeaderValue($event.target.value); checkInvoiceName()" [matDatepicker]="picker1">
                  <mat-datepicker-toggle matIconSuffix [for]="picker1">
                    <mat-icon matDatepickerToggleIcon [svgIcon]="'tabler_outline:calendar-event'"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>

              <!-- Sub header date (sale date) -->
              <div>
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label (click)="$event.preventDefault(); $event.stopPropagation()" class="flex items-center cursor-pointer pb-2" [matMenuTriggerFor]="subHeaderMenu">
                    {{invoice.subHeader.text}}
                    <mat-icon  [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                  </mat-label>
                  <input matInput [value]="invoice.subHeader.value" (click)="picker2.open()" (dateChange)="invoice.setSubHeaderValue($event.target.value)" [matDatepicker]="picker2">
                  <mat-datepicker-toggle matIconSuffix [for]="picker2">
                    <mat-icon matDatepickerToggleIcon [svgIcon]="'tabler_outline:calendar-event'"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>


                <mat-menu #subHeaderMenu="matMenu">
                  <div class="px-4 pb-2 text-center text-sm border-b">Wybierz wyświetlaną nazwę</div>
                  <div mat-menu-item (click)="invoice.setSubHeader(header)" *ngFor="let header of subheaderDateHeaders">{{header}}</div>
                </mat-menu>
              </div>

              <!-- Additional payment date -->
              <!-- Fields is toggleable by document settings -->
              <div *ngIf="invoice.additionalPaymentDateHeader.show">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label (click)="$event.preventDefault(); $event.stopPropagation()" class="flex justify-between cursor-pointer" [matMenuTriggerFor]="subHeaderMenu">
                    {{invoice.additionalPaymentDateHeader.text}}
                    <mat-icon [svgIcon]="'tabler_outline:chevron-down'"></mat-icon>
                  </mat-label>
                  <input matInput [value]="invoice.additionalPaymentDateHeader.value" (click)="picker3.open()" (dateChange)="invoice.setAdditionalPaymentDateValue($event.target.value);" [matDatepicker]="picker3">
                  <mat-datepicker-toggle matIconSuffix [for]="picker3">
                    <mat-icon matDatepickerToggleIcon [svgIcon]="'tabler_outline:calendar-event'"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>

                <mat-menu #subHeaderMenu="matMenu">
                  <div class="px-4 pb-2 text-center text-sm border-b">Wybierz wyświetlaną nazwę</div>
                  <button mat-menu-item (click)="invoice.setAdditionalPaymentDateHeader(header)" *ngFor="let header of paymentDateHeaders">{{header}}</button>
                </mat-menu>
              </div>

              <!-- Payment deadline -->
              <div>
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Termin zapłaty</mat-label>
                  <input matInput [value]="invoice.paymentDeadline.value" (click)="picker4.open()" (dateChange)="invoice.setPaymentDeadlineValue($event.target.value);" [matDatepicker]="picker4">
                  <mat-datepicker-toggle matIconSuffix [for]="picker4">
                    <mat-icon matDatepickerToggleIcon [svgIcon]="'tabler_outline:calendar-event'"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
           
            <!-- <therrax-input *ngIf="generalFieldsToShow.has('issuePlace')" class="block mt-2" [icon]="'ti ti-map-pin'"
              label="Miejsce wystawienia" [value]="invoice.placeHeader.value"
              (updateValue)="invoice.setPlaceHeaderValue($event);">
            </therrax-input>

            <date-picker class="block mt-2" label="Data wystawienia" [date]="invoice.issueDateHeader.value"
              (change)="invoice.setIssueDateHeaderValue($event);">
            </date-picker> -->

            <!-- <date-picker class="block mt-2" [label]="this.invoice.subHeader.text" [labels]="subheaderDateHeaders"
              [date]="invoice.subHeader.value" (change)="invoice.setSubHeaderValue($event);"
              (onSelectLabel)="invoice.setSubHeader($event)">
            </date-picker> -->

            <!-- <date-picker *ngIf="invoice.additionalPaymentDateHeader.show" class="block mt-2"
              [label]="invoice.additionalPaymentDateHeader.text" [labels]="paymentDateHeaders"
              [date]="invoice.additionalPaymentDateHeader.value"
              (change)="invoice.setAdditionalPaymentDateValue($event);"
              (onSelectLabel)="invoice.setAdditionalPaymentDateHeader($event)">
            </date-picker> -->

            <!-- <date-picker class="block mt-2" label="Termin zapłaty" [date]="invoice.paymentDeadline.value"
              (change)="invoice.setPaymentDeadlineValue($event);">
            </date-picker> -->

          </div>


        </div>
      </div>



      <div class="mt-2">
        <h6 class="px-2" *ngIf="document.id != 'FakturaKorygujaca'">
          Pozycje
        </h6>
        <h6 class="px-2" *ngIf="document.id == 'FakturaKorygujaca'">
          Po korekcie
        </h6>

        <div class="overflow-x-auto">

          <div class="product-row px-2">
            <div class="field" *ngFor="let header of document.table.viewHeaders" [class]="header.class">
              {{header.text}}
            </div>
          </div>

          <div class="products-bg p-2">
            <div class="product-row pb-2 " *ngFor="let p of invoice.products; let i = index;">

              <ng-container *ngFor="let tableHeader of document.table.viewHeaders">
  
                <ng-container *ngIf="tableHeader.name == 'name'">
                  <div class="field xl" style="display:inline-grid">
                    <input type="text" [value]="p.name" thrx-autocomplete [suggestions]="foundProducts"
                      (onSelect)="p.update($event); checkVatRates()" (change)="p.setName($event.target.value)"
                      (input)="findProducts($event.target.value);" />
  
                    <span *ngIf="p.name == ''" class="mt-1 ms-2 error-text">Wprowadź nazwę</span>
  
                  </div>
                </ng-container>
  
                <ng-container *ngIf="tableHeader.name == 'unitOfMeasure'">
                  <div class="field xs">
                    <select class="blank-select" (change)="p.setUnitOfMeasure($event.target.value)">
                      <option *ngFor="let measurementUnit of measurementUnits"
                        [selected]="p.unitOfMeasure == measurementUnit.value" [value]="measurementUnit.value">
                        {{measurementUnit?.viewValue}}</option>
                    </select>
                  </div>
                </ng-container>
  
                <ng-container *ngIf="tableHeader.name == 'quantity'">
                  <div class="field xs">
                    <input inputmode="numeric" [value]="p.quantity" (change)="p.setQuantity($event.target.value)" />
                  </div>
                </ng-container>
  
                <ng-container *ngIf="tableHeader.name == 'netPrice'">
                  <div class="field md">
                    <input decimalFormatter inputmode="numeric" step=".01" [value]="p.netPrice?.toFixed(2)"
                      (change)="p.setNetPrice($event.target.value);" />
                  </div>
                </ng-container>
  
                <ng-container *ngIf="tableHeader.name == 'netValue'">
                  <div class="field md text-center">
                    {{toDecimal(p.netValue)}}
                  </div>
                </ng-container>
  
                <ng-container *ngIf="tableHeader.name == 'vatRate'">
                  <div class="field xs">
                    <select class="blank-select" (change)="p.setTax($event.target.value)">
                      <option *ngFor="let rate of vatRates" [value]="rate.value" [selected]="p.vatRate == rate.value">
                        {{rate?.viewValue}}</option>
                      <option disabled>___________________________</option>
                      <option disabled>Aby wyświetlać inne stawki </option>
                      <option disabled>przejdz do ustawień konta. </option>
                    </select>
                  </div>
                </ng-container>
  
  
                <ng-container *ngIf="tableHeader.name == 'amountOfVat'">
                  <div class="field md text-center">
                    {{toDecimal(p.amountOfVat)}}
                  </div>
                </ng-container>
  
                <ng-container *ngIf="tableHeader.name == 'grossValue'">
                  <div class="field md">
                    <input decimalFormatter inputmode="numeric" step=".01" [value]="p?.grossValue?.toFixed(2)"
                      (change)="p.setGrossValue($event.target.value)" />
                  </div>
                </ng-container>
  
  
                <ng-container *ngIf="tableHeader.name == 'options'">
                  <div class="field xs">
                    <a (click)="removeProduct(i)" matTooltip="Kliknij, aby usunąć ten produkt z listy"
                      class="cursor-pointer p-2 text-color-blue text-center icon-button">
                      <i class="ti ti-trash-x"></i>
                    </a>
                  </div>
                  <!-- [matMenuTriggerFor]="menu"
                  <mat-menu #menu="matMenu" yPosition="above">
                    <button class="px-1 py-0 m-0" mat-menu-item (click)="removeProduct(i)" >
                      <mat-icon color="danger">delete</mat-icon>
                      Usuń
                    </button>
                  </mat-menu> -->
                </ng-container>
  
  
              </ng-container>
  
              <div class="block product-row p-2" *ngIf="p?._id == null && p?.grossValue > 0">
                Czy chcesz zapisać ten produkt? <span class="text-color-blue" (click)="saveProduct(p)">Zapisz</span>
              </div>
  
              <div *ngIf="invoice.hasGTU || invoice.hasDiscount" class="flex product-row py-2"
                style="border-bottom: 1px solid #eee;">
  
                <!-- <select-dropdown *ngIf="invoice.hasGTU" [label]="'GTU'" class="me-2" [options]="gtu" [selectedOption]="p.gtu" (selectionChange)="p.setGTU($event)"></select-dropdown> -->
                <select-dropdown *ngIf="invoice.hasDiscount" [label]="'Rabat %'" [toggleClass]="'no-arrow'" type="input"
                  class="me-2" [selectedOption]="p.discount"
                  (selectionChange)="p.setDiscount($event)"></select-dropdown>
  
              </div>
  
            </div>
          </div>
          

        </div>
        <a class="h6 text-color-blue w-auto" (click)="addProduct()">
          Dodaj kolejny produkt
        </a>
      </div>

      <!-- <div class="mt-2" *ngIf="invoice.hasComments">
          <small>Uwagi</small>
          <div class="controls-container fs-13">
            <textarea style="border-radius: 10px; border: none; width: 100%; padding: 6px 10px"
              [value]="invoice.comments" placeholder="Dodaj uwagi"
              (change)="invoice.set('comments', $event.target.value);" rows="2"></textarea>
          </div>
      </div> -->

      <div class="mt-2 summary-row">
          <div class="sum-label text-end sum-price-title">
            {{invoice.sum.label}}
            <span class="sum-price">
              {{invoice.sum.value}} <span class="currency">{{invoice.currency}}</span>
              <br />
              <span class="sum-payment-status-text">{{invoice.paymentStatus.text}}</span>
            </span>
          </div>
      </div>
    </div>

    <!-- Preview -->
    <div class="col-span-12 lg:col-span-4 w-full">

      <div class="relative h-200 lg:h-full" [ngClass]="{'full-screen-preview' : showingPreview}">
        
        <div class="preview-backdrop rounded-xl" (click)="togglePreview()"></div>
        <!-- <div class="preview-overlay" (click)="this.showPreview()">
          <div id="show-external">
    
            <i class="ti ti-maximize me-2"></i>
            Kliknij, aby powiększyć
          </div>
        </div> -->
        <!-- <div id="pdfContainer"></div> -->
        <!-- [showRotateCwButton]="false"
        [showRotateCcwButton]="false" -->
        <ngx-extended-pdf-viewer *ngIf="dataUrl" [base64Src]="dataUrl" backgroundColor="#d8d8d8" [height]="'100%'"
          [showToolbar]="false" [showSidebarButton]="false" [showFindButton]="false" [showPagingButtons]="false"
          [showDrawEditor]="false" [showStampEditor]="false" [showTextEditor]="false" [showZoomButtons]="true"
          [showPresentationModeButton]="false" [showOpenFileButton]="false" [showPrintButton]="false"
          [showDownloadButton]="false" [showSecondaryToolbarButton]="false" [showRotateButton]="undefined"
          [showHandToolButton]="true" [showScrollingButton]="false" [showSpreadButton]="false"
          [showPropertiesButton]="false" [zoom]="zoom">
        </ngx-extended-pdf-viewer>
        <div style="position:absolute; top:0;right: 0; padding: 8px;">
          <button class="zoom-control-button" (click)="zoomIn()" [matTooltip]="'Powiększ'"><i class="ti ti-zoom-in"></i></button>
          <button class="zoom-control-button" (click)="zoomOut()" [matTooltip]="'Pomniejsz'"><i class="ti ti-zoom-out"></i></button>
          <button class="zoom-control-button" (click)="togglePreview()" [matTooltip]="showingPreview ? 'Zamknij podgląd' : 'Pełny podgląd'">
            <i *ngIf="!showingPreview" class="ti ti-maximize"></i>
            <i *ngIf="showingPreview" class="ti ti-minimize"></i>
          </button>
        </div>

        <div class="absolute grid place-content-center bottom-4 w-full" *ngIf="showingPreview">
          <button (click)="closePreview()" class="mx-auto w-60" mat-raised-button color="primary">Zamknij podgląd</button>
        </div>
      </div>
    </div>
  </div>
</div>

