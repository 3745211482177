<div class="row" style="max-width: 600px">
    <div class="col-12">
        <h4>Stawki VAT</h4>
        <p>Spersonalizuj swoje stawki VAT, którymi się posługujesz.</p>
    </div>
    
    <div class="col-4 col-md-3" *ngFor="let vatRate of vatRates">
        <mat-checkbox color="primary" (change)="changeVatRate($event,vatRate)"
            [checked]="userVatRates.has(vatRate.value)"></mat-checkbox> {{vatRate.viewValue}}
    </div>
</div>

