import { inject } from "@angular/core";
import { forkJoin } from "rxjs";
import { ActivityLogsService } from "shared/services/activity-logs.service";
import { ClientsService } from "shared/services/clients.service";
import { DatabaseService } from "shared/services/database.service";
import { DocumentsService } from "shared/services/documents.service";
import { ProductsService } from "shared/services/products.service";

export const accountResolver = () => {

    const documentsService = inject(DocumentsService);
    const clientsService = inject(ClientsService);
    const productsService = inject(ProductsService);
    const activityLogsService = inject(ActivityLogsService);
    return forkJoin([
        documentsService.getAll(),
        clientsService.getAll(),
        productsService.getAll(),
        activityLogsService.getAll()
    ]);
}