
<div class="container h-100 p-4 pt-0">
    <h3>Ogłoszenia ({{announcements.length}}) <a routerLink="new">Dodaj</a></h3>
   
    
    <table class="table table-striped">
      <thead class="table-heading">
        <tr>
          <th>#</th>
          <th>Tytuł</th>
          <th>Aktywnosc</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let announcement of announcements; let i = index">
            <tr>
                <td>{{i+1}}</td>
                <td>{{announcement.title}} </td>
                <td>{{announcement.isActive ? 'Aktywne' : 'Nieaktywne'}}</td>
                <td><a [routerLink]="announcement._id">Edytuj</a></td>
            </tr>
        </ng-container>
        
      </tbody>
    </table>
  </div>