<h1 style="display:none;">Bezpłatny program do wystawiania faktur przez przeglądarkę. Fakturnia.pl to narzędzie, które
    ułatwi Ci generowania dokumentów, usprawniając Twoją pracę.</h1>
<section>
    <div class="max-w-360 w-full mx-auto px-8 lg:px-12 py-12 lg:pt-28 lg:pb-12">

        <div class="max-w-200 w-full text-center mx-auto">
            <div class="text-3xl sm:text-4xl md:text-5xl lg:text-7xl mb-8 font-bold">W czym możemy Ci pomóc?</div>
            <div class="text-lg sm:text-xl lg:text-2xl font-medium">
                Z przyjemnością odpowiemy na wszelkie Twoje pytania.<br />
                Zachęcamy też do sprawdzenia naszej bazy <a
                    href="https://pomoc.fakturnia.pl/inne/czesto-zadawane-pytania" target="_blank">najczęściej
                    zadawanych pytań.</a>
            </div>
        </div>

        <div class="grid gap-8 grid-cols-1 md:grid-cols-3 place-content-center place-items-center max-w-100 md:max-w-200 w-full text-center tracking-wide mx-auto my-12 lg:my-24 bg-white dark:bg-slate-800 rounded-xl p-14">

            <div>
                <div class="text-lg sm:text-xl lg:text-3xl font-medium">
                    Kontakt z nami
                </div>
                <br />
                <a [href]="'mailto:' + config.emailAddress">{{config.emailAddress}}</a>
            </div>

            <div class="none md:bg-primary-50 dark:md:bg-slate-900 h-full w-1"></div>

            <div>
                <div class="text-lg sm:text-xl lg:text-3xl font-medium">
                    Fakturnia.pl
                </div>
                <div>
                    Spokojna 3/4,
                    <br /> 78-111 Ustronie Morskie
                </div>
            </div>

        </div>
    </div>

    <div class="max-w-360 w-full mx-auto px-8 lg:px-12 lg:pb-24">

        <div class="max-w-180 tracking-wide mx-auto">
            <div class="text-5xl font-bold text-center">Pomoc</div>
    
            <div class="my-4 lg:mb-8 lg:mt-16">
                <div class="text-4xl my-4">Jak zgłosić problem techniczny</div>
                <div class="text-lg">
                    <p>
                        Zgłoszenia problemów technicznych prosimy niezwłocznie kierować na nasz adres e-mail. Pamiętaj, aby
                        zawrzeć jak najwięcej szczegółów dotyczących Twojego problemu.
                        <br />
                        <br />
                        <span class="font-medium">Wyślij zgłoszenie na: </span><a
                            [href]="'mailto:'+config.emailAddress">{{config.emailAddress}}</a>
                    </p>
                </div>
            </div>
    
            <div class="my-4 lg:mb-8 lg:mt-16">
                <div class="text-4xl my-4">Połączenie z KSeF</div>
                <div class="text-lg">
                    <p>
                        Połączenie z KSeF jest już w trakcie realizacji i nie jest aktualnie dostępne. Bardzo zależy nam,
                        żeby nasi użytkownicy mieli możliwość dodatkowej integracji.<br />
                        <span class="font-medium">Planujemy uruchomić usługę na początku 2025 roku - przed ustawową datą nakazującą korzystanie z KSeF - 1 lutego 2026.</span>
                    </p>
                </div>
            </div>
    
            <div class="my-4 lg:mb-8 lg:mt-16">
                <div class="text-4xl my-4">Baza wiedzy o systemie</div>
                <div class="text-lg">
                    <p>
                        Materiały pomocnicze, instrukcja obsługi i wiele pomocnych treści znajdziesz na naszej stronie pomocy. 
                        <br />
                        <br />
                        Treści znajdziesz pod adresem: <a href="https://pomoc.fakturnia.pl" target="_blank">https://pomoc.fakturnia.pl</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

</section>