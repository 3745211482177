<div class="w-full max-w-320 mx-auto p-4 lg:p-8">

    <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mb-10">

        <div class="md:col-span-2">

            <!-- Announcements -->
            <announcements-list></announcements-list>

            <h2 class="text-3xl font-bold mb-3">Pulpit</h2>
            <invoice-card></invoice-card>

        </div>

        <div class="md:justify-self-center">

            <div class="mb-8">

                <h2 class="text-3xl font-bold mb-3">Szybkie akcje</h2>
                <div class="bg-card shadow-lg rounded-xl p-4 w-64">
                    <button mat-flat-button color="primary" class="w-full" [routerLink]="'/konto/dokumenty/wystaw'">
                        <mat-icon [svgIcon]="'tabler_outline:plus'"></mat-icon>
                        <span class="ml-2">Wystaw fakturę</span>
                    </button>
                    <button (click)="showPrintManyDialog()" mat-stroked-button color="primary" class="w-full mt-4" matTooltip="Pobierz lub drukuj faktury z wybranego miesiąca">
                        <mat-icon  [svgIcon]="'tabler_outline:file-arrow-right'"></mat-icon>
                        <span class="ml-2">Pobierz wiele</span>
                    </button>
                </div>

            </div>
         
            <!-- <a routerLink="/konto/ustawienia">Przjedź do ustawień <i class="ti ti-arrow-right"></i></a> -->


            <!-- Help Box -->
            <div>
                <div class="w-64 h-64 bg-card shadow-lg rounded-xl p-6 flex flex-col justify-between items-center">
                    <mat-icon class="h-12 w-12" color="primary" [svgIcon]="'tabler_outline:lifebuoy'"></mat-icon>
                    <h2 class="text-xl font-semibold mt-4">Wsparcie</h2>
                    <p class="text-secondary text-center">Potrzebujesz pomocy?</p>
                    
                    <a href="mailto:kontakt@fakturnia.pl?subject=Potrzebuje%20pomocy">
                        <button mat-flat-button color="primary" class="w-full" >
                            <mat-icon [svgIcon]="'tabler_outline:message'"></mat-icon>
                            <span class="ml-2">Wyślij wiadomość</span>
                        </button>
                    </a>
                  
                  </div>
            </div>
            

        </div>
    </div>
</div>