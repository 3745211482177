<div class="flex">
    <div class="p-2 flex-shrink-1">
        <div class="icon-container info">
            <mat-icon>person_search</mat-icon>
        </div>
    </div>
    <div class="p-2 w-100">
        <div class="confirm-header">
            <div class="row">
                <div class="col-9">
                    <h5>Baza GUS</h5>
                </div>
                <div class="col-3">
                    <button class="float-end close-btn" (click)="close(false)" mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <br />
        <p *ngIf="companies.length == 0">Nie znaleziono klienta w bazie GUS</p>
        <div class="row" *ngIf="companies.length > 0">
            <ng-container *ngFor="let company of companies; let i = index">
                <div class="col-9">
                    <h6><b>{{company?.name}}</b></h6> <br />
                    <b>NIP:</b> {{company?.nip}} <br />
                    <b>Adres:</b>{{company?.street}}, {{company.zipCode}} {{company.city}}
                    <br />
                    <br />
                    <button color="primary" mat-stroked-button (click)="selectCompany(company)">Wybierz</button>
                </div>

                <div class="col-3">

                </div>
                <hr *ngIf="i+1 != companies.length" class="my-4" />
            </ng-container>
        </div>
    </div>
</div>
<!-- <mat-dialog-actions class="bg-gray-50 text-end" [align]="'end'">
    <button mat-stroked-button (click)="close(false)" class="mc-button">Zamknij</button>
</mat-dialog-actions> -->