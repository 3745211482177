import { DomPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit } from '@angular/core';
import { NotificationData } from 'fakturnia-shared';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  @Input('data') data: NotificationData;
  
  @Input() portalHost: DomPortalOutlet;
  @Input() portal: ComponentPortal<ToastComponent>;

  actions = {};

  constructor() {
  }

  ngOnInit(): void {

  }

  registerAction(action) {
    this.actions[action] = new BehaviorSubject<any>(null)
  }

  invoke(action) {
    this.actions[action].next(true)
  }

  on(action) {
    this.registerAction(action)
    return this.actions[action].asObservable()
  }

  close() {
    if(this.portalHost) this.portalHost.detach();
  }

}
