import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { API_HOST } from "environments/environment"

@Injectable({
    providedIn: 'root'
})
export class BackupApiService {

    constructor(private http: HttpClient) { }

    public uploadBackup(backupFile:any) {
        const formData = new FormData();
        formData.append('file', backupFile, backupFile.name);
        return this.http.post(API_HOST + 'admin/backup/upload', formData)
    }

}