import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_HOST } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ViesApiService {

    constructor(private http: HttpClient) { }

    public validateVatNumber(countryCode: string, vatNumber: string) {
        return this.http.post(API_HOST + 'vies/validate-vat-number', {
            countryCode: countryCode,
            vatNumber: vatNumber
        })
    }
}