import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { UserSettings } from "fakturnia-shared";
import { NotificationData } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { SettingsService } from 'shared/services/settings.service';
import { ToastService } from 'shared/services/toast.service';

@Component({
  selector: 'company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss']
})
export class CompanyDataComponent  implements OnDestroy {

  subscriptions:Subscription[] = [];
  userSettings:UserSettings

  form = new UntypedFormGroup({
    type: new UntypedFormControl('company', Validators.required),
    name: new UntypedFormControl('', Validators.required),
    nip: new UntypedFormControl('', Validators.required),
    regon: new UntypedFormControl(''),
    street: new UntypedFormControl('', Validators.required),
    postalCode: new UntypedFormControl('', Validators.required),
    city: new UntypedFormControl('', Validators.required),
    bdo: new UntypedFormControl(''),
    krs: new UntypedFormControl(''),
    www: new UntypedFormControl(''),
    fax: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
  })

  constructor(
    private settingsService:SettingsService,
    private apiService:ApiService,
    private _toastService:ToastService
  )
  {

    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({
      next: (data:UserSettings)=> {
        this.userSettings = data
       
        this._setCompany()
        this._setAddress()

      }
    }))

  }

  private _setAddress() {
    if(this.userSettings.address == null) return

    this.form.controls['street'].setValue(this.userSettings.address.street)
    this.form.controls['postalCode'].setValue(this.userSettings.address.postalCode)
    this.form.controls['city'].setValue(this.userSettings.address.city)
  }

  private _setCompany() {
    if(this.userSettings.company == null) return

    this.form.controls['type'].setValue(this.userSettings.company.type)
    this.form.controls['name'].setValue(this.userSettings.company.name)
    this.form.controls['nip'].setValue(this.userSettings.company.nip)
    this.form.controls['regon'].setValue(this.userSettings.company.regon)
    this.form.controls['bdo'].setValue(this.userSettings.company.bdo)
    this.form.controls['krs'].setValue(this.userSettings.company.krs)

    this.form.controls['fax'].setValue(this.userSettings.company.fax)
    this.form.controls['www'].setValue(this.userSettings.company.www)
    this.form.controls['description'].setValue(this.userSettings.company.description)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  saveChanges()
  {
    if(this.form.invalid) return

    let data = this.form.value;
    this.apiService.updateCompanyData(data)
    .pipe(take(1))
    .subscribe({
      next: (data:any)=> {
        if(data.success) {
          this._toastService.showToast(new NotificationData({
            title: 'Informacja',
            status: 'info',
            message: 'Dane zostały zapisane.',
          }))
          this.settingsService.refreshSettings()
        } else {
          this._toastService.showToast(new NotificationData({
            title: 'Wystąpił błąd',
            status: 'info',
            message: 'Formularz zawiera błędy, popraw go i spróbuj ponownie.',
          }))
        } 
      },
      error: (err)=> {
        this._toastService.showToast(new NotificationData({
          title: 'Wystąpił błąd',
          status: 'info',
          message: 'Nie udało się zaktualizować danych. Spróbuj ponownie później.',
        }))
      }
    })
  }
}