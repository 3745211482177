<h1 style="display:none;">Podgląd faktury na żytwo podczas tworzenia. Bezpłatny program do wystawiania faktur przez przeglądarkę Fakturnia.pl</h1>

<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
      <div class="grid grid-cols-2 gap-8 place-items-center">
        
        <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto"> 
            <h2>
                Podgląd faktury <strong>na żywo</strong> <br />
                podczas tworzenia dokumentu
            </h2>
            <p class="mt-2">
                Komfort z korzystania naszego programu od samego początku jest dla nas priorytetem. Między innymi dlatego uprościliśmy formularz i jako pierwsi na rynku oferujemy podgląd dokumentu na żywo w czasie edycji.
            </p>
        </div>

        <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
            <picture>
                <img class="max-w-160 lg:max-w-full" src="/assets/images/fakturnia_podglad_faktury.png" alt="Generuj dokumenty .pdf w programie do faktur Fakturnia.pl">
            </picture>
        </div>

    </div>
  </div>
</section>

<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
      <div class="grid grid-cols-2 gap-8 place-items-center">
        
        <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
            <picture>
                <img class="max-w-160 lg:max-w-full" src="/assets/images/podglad_powieksz_fakturnia_pl.png" alt="Generuj dokumenty .pdf w programie do faktur Fakturnia.pl">
            </picture>
        </div>

        <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto"> 
            <h2>
                Odświeżanie <strong>podglądu</strong> <br />
                podczas tworzenia dokumentu
            </h2>
            <p class="mt-2">
                Po każdej edycji widzisz dokładne zmiany jakie wprowadzone zostały w dokumencie. Po naciśnięciu kursorem na podgląd, można go powiększyć.
            </p>
        </div>

       

    </div>
  </div>
</section>

<join-us></join-us>

<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
      <div class="grid grid-cols-2 gap-8 place-items-center">
        
        <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto"> 
            <h2>
                Więcej <strong>nie zawsze</strong> znaczy lepiej <br />
                
            </h2>
            <p class="mt-2">
                Stawiamy na prostotę i intuicyjność, oprogramowanie do dokumentów często jest przepełnione zbędnymi kontrolkami i przytłacza mnogością opcji.
            </p>
        </div>

        <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
            <picture>
                <img class="max-w-160 lg:max-w-full" src="/assets/images/wystaw_fakture_okno_okragle.png" alt="Generuj dokumenty .pdf w programie do faktur Fakturnia.pl">
            </picture>
        </div>

    </div>
  </div>
</section>

<read-more [path]="'/funkcjonalnosci-aplikacji/faktura-vat'" [text]="'Faktura VAT'"></read-more>

