<ng-container *ngIf="documents != null">
    <h2 class="font-bold mb-3" *ngIf="showTitle">{{title}} <small class="text-secondary fw-normal">({{resultsCount}})</small></h2>

    <div class="bg-card rounded-xl" [ngClass]="{'shadow-lg': addBoxShadow }">
    
        <div class="grid gap-6 grid-cols-1 md:grid-cols-2 border-b p-4 items-center">
            <div>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-icon matIconPrefix [svgIcon]="'tabler_outline:search'"></mat-icon>
                    <input type="text" matInput [formControl]="searchControl" placeholder="Szukaj dokumentu">
                    <button mat-icon-button matIconSuffix  (click)="reset()" *ngIf="searchControl.value.length > 0">
                        <mat-icon [svgIcon]="'tabler_outline:x'"></mat-icon>
                    </button>
                  
                    <mat-hint *ngIf="searchControl.value.length > 0" >
                        <span>Wyniki wyszukiwania dla frazy: </span> {{searchControl.value}}
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="justify-self-end">
                <pagination *ngIf="documents.length > 0" class="flex items-center" [currentPage]="currentPage"
                [totalPages]="allPages" (onPageChange)="onPageChange($event)"></pagination>
            </div>
        </div>
    
        <div class="grid gap-6 grid-cols-1 border-b p-4" *ngIf="documents.length > 0 && (showMarkAsPaidButton || showMarkAsTemplateButton || showIssueSimilarButton)">
            <div class="flex items-center">
                <mat-icon [svgIcon]="'tabler_outline:corner-up-right'"   class="tools-icon"></mat-icon>
    
                <div *ngIf="showMarkAsPaidButton" class="tools-button" [ngClass]="{'disabled' : selectedDocs.size <= 0}"
                    (click)="markAsPaidOrTemplate(true)">
                    <mat-icon [ngClass]="{'text-gray-300' : selectedDocs.size <= 0, 'text-primary': selectedDocs.size > 0}">check</mat-icon>
                    <span class="ml-2 hidden md:inline-block">Oznacz jako wystawione</span> 
                </div>
    
                <div *ngIf="showMarkAsTemplateButton" class="tools-button" [ngClass]="{'disabled' : selectedDocs.size <= 0}"
                    (click)="markAsPaidOrTemplate(false)">
                    <mat-icon [ngClass]="{'text-gray-300' : selectedDocs.size <= 0, 'text-primary': selectedDocs.size > 0}">edit</mat-icon>
                    <span class="ml-2 hidden md:inline-block">Oznacz jako szkic</span> 
                </div>
    
                <div *ngIf="showIssueSimilarButton" class="tools-button" [ngClass]="{'disabled' : selectedDocs.size != 1}"
                    (click)="issueSimilar()">
                    <mat-icon  [ngClass]="{ 'text-gray-300': selectedDocs.size != 1, 'text-primary': selectedDocs.size == 1 }">content_copy</mat-icon>
                    <span class="ml-2 hidden md:inline-block">Wystaw podobny</span> 
                </div>
    
            </div>
        </div>
    
        <div class="flex bg-gray-50 items-center border-b px-4 content-center" *ngIf="documents.length > 0">
            <div>
                <mat-checkbox [matTooltip]="'Zaznacz wszystko'" class="w-12 h-12 max-w-12 max-h-12" *ngIf="showSelectors" [disabled]="selection == 'single'" color="primary" (change)="selectAll($event)"></mat-checkbox>
            </div>
            <div class="w-full font-bold">Dokument</div>
            <div class="w-full font-bold text-end pr-4">Kwota</div>
        </div>
    
        <div class="flex items-center border-b p-8 content-center" *ngIf="documents.length == 0">
            <div class="text-secondary" *ngIf="searchControl.value.length > 0">Nie znaleziono dokumentów.</div>
            <div class="text-secondary" *ngIf="searchControl.value.length == 0 && showAddNewButton && resultsCount == 0">
    
                <button mat-button color="primary" [routerLink]="'/konto/dokumenty/wystaw'">
                    <mat-icon matPrefix [svgIcon]="'tabler_outline:plus'"></mat-icon>
                    <span class="ml-2">Dodaj pierwszy dokument</span>
                </button>
            </div>
        </div>

        <!-- Listing documents -->
        <div *ngFor="let document of documents" class="flex items-center border-b p-4 content-center">
            <div>
                <mat-checkbox *ngIf="showSelectors" [checked]="selectedDocs.has(document._id) ? true : false" color="primary"
                (change)="toggleSelect(document._id)"></mat-checkbox>
            </div>
            <div class="w-full cursor-pointer" (click)="navigateToDocument(document._id)">

                <div>
                    <company-name [company]="document.buyer"></company-name>
                </div>
                <div class="text-xl text-secondary">
                    {{document.documentNumber}}
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div class="flex space-x-2">
                        <mat-icon class="bg-sky-100 p-1 rounded-xl text-primary"  *ngIf="document.isSent" matTooltip="Wysłano do klienta" [svgIcon]="'tabler_outline:mail-check'"></mat-icon>
                        <mat-icon class="bg-sky-100 p-1 rounded-xl text-primary"  *ngIf="document.isDownloaded" matTooltip="Dokument został pobrany" [svgIcon]="'tabler_outline:download'"></mat-icon>
                        <mat-icon class="bg-sky-100 p-1 rounded-xl text-primary"  *ngIf="document.isPrinted" matTooltip="Dokument został wydrukowany" [svgIcon]="'tabler_outline:printer'"></mat-icon>
                    </div>
                    <div class="text-end">
                        <span class="font-semibold">{{document.paymentStatus.value == 1 ? 'Do zapłaty' : 'Zapłacono'}}:</span>    {{toDecimal(document.grossSum)}} <span class="invoice-card-currency">{{document.currency}}</span>
                    </div>
                </div>
               
            </div>
        </div>

        <div class="grid gap-6 grid-cols-1 border-b p-4 items-center" *ngIf="documents.length > 0">
            <div class="justify-self-end">
                <pagination  class="d-flex align-items-center" [currentPage]="currentPage"
                [totalPages]="allPages" (onPageChange)="onPageChange($event)"></pagination>
            </div>
        </div>
    
    </div>
</ng-container>

<mat-spinner *ngIf="documents == null" color="primary" class="mx-auto"></mat-spinner>
